import React, { useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ReactPlayer from 'react-player';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const VideoPlayer = ({ url, translate }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [showPlay, setShowPlay] = useState(true);
	const { t } = translate ? { t: translate } : useTranslation('form');

	return (
		<Box
			sx={{
				'&:hover': {
					'.MuiBox-root': {
						display: 'flex',
					},
				},
				margin: 'auto',
			}}
		>
			<ReactPlayer
				controls={isPlaying}
				playing={!showPlay && isPlaying}
				style={{
					margin: '0 auto',
					borderRadius: '10px',
				}}
				url={url}
				width="unset"
				onEnded={() => setShowPlay(true)}
				onPause={() => setShowPlay(true)}
				onPlay={() => setShowPlay(false)}
			/>
			<Box
				sx={{
					display: 'none',
					position: 'absolute',
					top: '35%',
					left: '42%',
					'& .MuiIconButton-root': {
						width: '100px',
						height: '100px',
					},
					'& .MuiSvgIcon-root': {
						color: '#FFFFFF',
						fontSize: '70px',
						'&:hover': {
							color: '#FFFFFF',
						},
					},
				}}
			>
				<IconButton
					data-testid="play-button"
					onClick={() => {
						setIsPlaying(showPlay);
						setShowPlay(!showPlay);
					}}
				>
					<Tooltip
						PopperProps={{
							modifiers: [
								{
									name: 'offset',
									options: {
										offset: [0, -25],
									},
								},
							],
						}}
						title={showPlay ? t('Play') : t('Pause')}
					>
						{showPlay ? <PlayArrowIcon /> : <PauseIcon />}
					</Tooltip>
				</IconButton>
			</Box>
		</Box>
	);
};

export default VideoPlayer;
