import React from 'react';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import { useTranslation } from 'react-i18next';
import { useDrawersStore } from '@worklist-2/patientPortal/src/stores';

const AppointmentDrawerButtonScheduler = ({ title, showCloseBtn }) => {
	const { setIsOpen, setIsFullscreen, setBannerSelectStatus } = useAppointmentContext();
	const { t } = useTranslation('appointments');

	const setSearchText = useDrawersStore(state => state.setSearchText);

	return (
		<>
			<Button
				disableElevation
				startIcon={<KeyboardDoubleArrowRightIcon />}
				sx={{
					background: 'var(--element-header-button-background-color)',
					color: 'var(--element-header-button-foreground-color)',
					fontSize: '16px',
					height: '40px',
					borderRadius: '0px 40px 40px 0px',
					fontVariant: 'small-caps',
					fontWeight: 500,
					letterSpacing: '0.1em',
					marginLeft: '10px',
					paddingLeft: '24px',
					paddingRight: '12px',
					'&:hover': {
						background: 'var(--element-header-button-background-color)',
					},
				}}
				variant="contained"
				onClick={() => {
					setIsFullscreen(false);
					setIsOpen('');
					setBannerSelectStatus('LATEST');
					setSearchText('');
				}}
			>
				{t(title)}
			</Button>
			{showCloseBtn && (
				<ButtonBase
					sx={{
						position: 'absolute',
						right: '16px',
						width: '36px',
						height: '36px',
						backgroundColor: '#F9FAFB',
					}}
					onClick={() => {
						setIsFullscreen(false);
						setIsOpen('');
						setBannerSelectStatus('LATEST');
						setSearchText('');
					}}
				>
					<svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M11 1L1 11M1 1L11 11"
							stroke="#9AA4B2"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
						/>
					</svg>
				</ButtonBase>
			)}
		</>
	);
};

export default AppointmentDrawerButtonScheduler;
