import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ListItemIcon, MenuItem, TextField, Typography } from '@mui/material';
import { mediaTabletOnly } from '@worklist-2/core/src';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';

import AppointmentCardActionButtons from './AppointmentCardActionButtons';

const options = [
	'Unable to get time off',
	'Not well',
	'Some urgent work came up',
	'Inclement weather',
	'Rescheduling to another facility close by',
	'Offered another earlier slot',
	'Other',
];

const CancelAppointment = ({ appointment, patchAppointment, setRenderCancelReason }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [error, setError] = useState(false);
	const open = Boolean(anchorEl);
	const { setToastMsg } = useToastMessageContext();
	const { t } = useTranslation('appointments');
	const [disableButtonForAPICall, setDisableButtonForAPICall] = useState(false);

	const handleClick = event => {
		if (anchorEl == null) {
			setAnchorEl(event.currentTarget);
		} else {
			setAnchorEl(null);
		}
	};

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setError(false);
		setAnchorEl(null);
	};

	const handleOnSubmitAction = async () => {
		if (selectedIndex === -1) {
			setError(true);
			return;
		}
		setDisableButtonForAPICall(true);
		const status = await patchAppointment(
			appointment.id,
			[
				{ op: 'add', path: '/status', value: 'cancelled' },
				{ op: 'add', path: '/cancelationReason', value: { text: `${t(options[selectedIndex])}` } },
			],
			options[selectedIndex]
		);
		setDisableButtonForAPICall(false);
		handleOnCancelButton();
		if (status === 'SUCCESS') {
			setToastMsg(t('Appointment cancelled successfully'));
		}
	};

	const handleOnCancelButton = () => {
		setAnchorEl(null);
		setRenderCancelReason(false);
	};

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					margin: '0px 0px 10px 0px',
					[mediaTabletOnly]: { margin: '0px 0px 10px 0px' },
				}}
			>
				<TextField
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<ArrowDropDownIcon
									sx={{
										transform: open ? 'rotate(180deg)' : '',
										transition: '0.2s',
									}}
								/>
							</InputAdornment>
						),
					}}
					error={error}
					helperText={t('Required')}
					placeholder={t('Select Reason for Cancellation')}
					sx={{
						width: 1,
						'.MuiOutlinedInput-root': {
							height: '40px',
							borderRadius: '10px',
						},
						'.MuiFormHelperText-root': {
							lineHeight: '12px',
						},
						'& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
							borderColor: error ? '#d32f2f' : undefined,
						},
					}}
					value={t(options[selectedIndex]) || ''}
					onClick={handleClick}
				/>

				{open && (
					<Box
						sx={{
							height: open ? '229px' : 0,
							borderRadius: '10px',
							padding: '4px 16px 4px 16px',
							marginTop: '8px',
							overflowY: 'auto',
							transition: '0.2s',
						}}
					>
						{options.map((option, index) => (
							<MenuItem
								key={option}
								selected={index === selectedIndex}
								sx={{ paddingLeft: 0, paddingRight: 0 }}
								onClick={event => handleMenuItemClick(event, index)}
							>
								<ListItemIcon>
									{index === selectedIndex ? (
										<CheckCircle
											sx={{
												color: '#42A5F5',
											}}
										/>
									) : (
										<svg
											fill="none"
											height="24"
											viewBox="0 0 24 24"
											width="24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle cx="12" cy="12.0001" r="9.5" stroke="#42A5F5" />
											<circle cx="12" cy="12.0001" r="7.5" stroke="#42A5F5" />
										</svg>
									)}
								</ListItemIcon>
								<Typography noWrap variant="inherit">
									{t(option)}
								</Typography>
							</MenuItem>
						))}
					</Box>
				)}
			</Box>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'end',
				}}
			>
				<AppointmentCardActionButtons
					isCancelReasonRendered
					appointment={appointment}
					disableButtonForAPICall={disableButtonForAPICall}
					handleOnCancelButton={handleOnCancelButton}
					handleOnSubmitAction={handleOnSubmitAction}
					hideFormButton={Boolean(anchorEl)}
				/>
			</Box>
		</>
	);
};

export default CancelAppointment;
