// core
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal, useSelector, breakpoints, useIsMobile, mediaMobile } from '@worklist-2/core/src';
// libraries
import PropTypes from 'prop-types';
// mui
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
// partials
import HelpModal, { HelpModalContent } from '../Help/HelpModal';
import HyperlinkButton from '../HyperlinkButton/HyperlinkButton';
import LanguageModal from '../LanguageSelect/LanguageModal';
import PrivacyModal from '../PrivacyPolicy/PrivacyPolicyModal';
import TermsModal from '../PrivacyPolicy/TermsModal';
import RegionModal from '../RegionSelect/RegionModal';
import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import MobileTermsDrawer from './components/MobileTermsDrawer';
import MobilePrivacyDrawer from './components/MobilePrivacyDrawer';
import MobileLanguageDrawer from './components/MobileLanguageDrawer';
import MobileRegionDrawer from './components/MobileRegionDrawer';
import { useTranslation } from 'react-i18next';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const NewMainFooter = ({ style, linkStyle, loginMode }) => {
	const { t } = useTranslation('login');
	const { modal, openModal, closeModal } = useModal();
	const { region, regions, language, languages } = useSelector();
	const [mobileDrawer, setMobileDrawer] = useState();
	const [mobileDrawerProps, setMobileDrawerProps] = useState({});
	const sprinterOaibreezeblumeFooterHelp = useBooleanFlagValue('sprinter-oaibreezeblume-footer-help');
	const sprinterOaibreezeblumeFooterGeography = useBooleanFlagValue('sprinter-oaibreezeblume-footer-geography');
	const [isMenuItemsContainerOpen, setIsMenuItemsContainerOpen] = useState(false);

	const isMobile = useIsMobile();

	const isSmall = useMediaQuery(theme => theme.breakpoints.down(breakpoints.tablet + 177));

	const selectedCountry = regions.find(({ code }) => code === region)?.label.toUpperCase();

	const selectedLanguage = languages.find(({ code }) => code === language)?.name.toUpperCase();

	const menuItems = useMemo(() => {
		const footerMenu = [
			{ id: 'terms', text: 'TERMS OF SERVICE' },
			{ id: 'privacy', text: 'PRIVACY POLICY' },
		];
		if (sprinterOaibreezeblumeFooterHelp) {
			footerMenu.push({ id: 'help', text: 'HELP' });
		}
		if (sprinterOaibreezeblumeFooterGeography) {
			footerMenu.push({ id: 'region', text: selectedCountry || 'REGION' });
		}
		return footerMenu;
	}, [selectedCountry]);

	const languageItem = useMemo(
		() => [
			{
				id: 'language',
				text: selectedLanguage || 'LANGUAGE',
			},
		],
		[selectedLanguage]
	);

	const onLinkClick = useCallback(e => {
		e.preventDefault();

		if (isMobile) {
			const DrawerComponent = {
				terms: MobileTermsDrawer,
				privacy: MobilePrivacyDrawer,
				help: HelpModalContent,
				language: MobileLanguageDrawer,
				region: MobileRegionDrawer,
			}[e.target.id];

			if (DrawerComponent) {
				setMobileDrawer(<DrawerComponent setMobileDrawerProps={setMobileDrawerProps} />);
			}

			if (isMenuItemsContainerOpen) {
				onToggleIsMenuItemsContainerOpen();
			}

			return;
		}

		if (['terms', 'privacy'].includes(e.target.id)) {
			window.location.hash = 'introduction?blur=true';
		}

		const ModalComponent = {
			terms: TermsModal,
			privacy: PrivacyModal,
			help: HelpModal,
			language: LanguageModal,
			region: RegionModal,
		}[e.target.id];

		if (ModalComponent) {
			openModal(<ModalComponent open onClose={closeModal} />);
		}
	});

	const onToggleIsMenuItemsContainerOpen = useCallback(() => {
		setIsMenuItemsContainerOpen(prev => !prev);
	}, []);

	useEffect(() => {
		setIsMenuItemsContainerOpen(false);
	}, [isSmall, !!modal]);

	useEffect(() => {
		if (!mobileDrawer) {
			setMobileDrawerProps({});
		}
	}, [mobileDrawer]);

	const renderHyperLinkButton = props => (
		<HyperlinkButton
			key={props.id}
			id={props.id}
			link={props.id}
			style={linkStyle}
			text={t(props.text)}
			onClick={onLinkClick}
		/>
	);

	return (
		<>
			{isMobile ? (
				<MobileBottomDrawer
					ModalProps={{
						BackdropProps: {
							invisible: true,
						},
					}}
					background="rgba(255, 255, 255, 0.6)"
					open={Boolean(mobileDrawer)}
					sx={{
						backdropFilter: 'blur(10px)',
						boxShadow: 'unset',
						minHeight: '450px',
						marginBottom: '50px',
						'& .MobileBottomDrawerHandleWrapper': {
							background: 'unset',
						},
					}}
					onClose={() => setMobileDrawer(undefined)}
					{...mobileDrawerProps}
				>
					<Box sx={{ marginBottom: '30px' }}>{mobileDrawer}</Box>
				</MobileBottomDrawer>
			) : (
				modal
			)}

			<Backdrop
				data-testid="backdrop"
				open={isMenuItemsContainerOpen}
				sx={{ zIndex: 9998, background: 'transparent' }}
				onClick={onToggleIsMenuItemsContainerOpen}
			/>

			<footer
				data-testid="Footer"
				style={{
					height: isSmall ? '40px' : '50px',
					width: '100%',
					bottom: '0',
					backgroundColor: 'rgba(255,255,255,.6)',
					zIndex: 1111,

					...style,
					':after': {
						opacity: '0.6',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'wrap',
						alignItems: 'center',
						height: '100%',
						position: 'relative',
						padding: '0 2rem',
						justifyContent: 'space-between',
						'@media (max-width:1200px)': {
							borderBottom: '1px solid #D0D5DD',
						},
						[mediaMobile]: {
							padding: '0px',
						},
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '5px',
							'& > .hyperlink:not(:first-of-type)': {
								ml: '30px',
							},
						}}
					>
						<PublicRoundedIcon
							sx={{
								height: '0.7em',
								width: '0.7em',
								opacity: '0.7',
								[mediaMobile]: {
									fontSize: '18px',
								},
							}}
						/>
						{languageItem.map(renderHyperLinkButton)}
					</Box>

					<Box
						sx={{
							height: '100%',
							alignItems: 'center',
							display: 'flex',
							'& > .hyperlink:not(:first-of-type)': {
								ml: '30px',
								[mediaMobile]: {
									ml: '10px',
								},
							},
						}}
					>
						{menuItems.map(renderHyperLinkButton)}
					</Box>
				</Box>
			</footer>
		</>
	);
};

export default NewMainFooter;

NewMainFooter.propTypes = {
	/**
	 * Custom styles for the footer container
	 */
	style: PropTypes.object,
};

NewMainFooter.defaultProps = {
	style: {},
};
