import React from 'react';
import AppointmentDrawerStickyTop from './AppointmentDrawerStickyTop';
import Notifications from '@worklist-2/patientPortal/src/components/Notifications';
import { useTranslation } from 'react-i18next';

const NotificationDrawerContent = ({ setIsOpen }) => {
	const { t } = useTranslation('drawer');
	return (
		<>
			<AppointmentDrawerStickyTop showCloseBtn title={t('Notifications')} />

			<Notifications setDrawerOpen={setIsOpen} />
		</>
	);
};

export default NotificationDrawerContent;
