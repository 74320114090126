import React from 'react';
import Box from '@mui/material/Box';
import { mediaDesktopL, mediaTablet } from '@worklist-2/core/src';
import { getAppointmentColor } from '../AppointmentUtils/AppointmentUtils';

const AppointmentCardWrapper = ({ children, status, highlight }) => {
	const color = getAppointmentColor(status);
	const border = `1px solid ${color}33`;

	return (
		<Box
			data-testid="appointmentCard"
			sx={{
				cursor: 'pointer',
				background: '#FFFFFF',
				border,
				borderRadius: '10px',
				display: 'flex',
				position: 'relative',
				overflow: 'hidden',
				marginBottom: '16px',
				transition: '0.2s',
				flexDirection: 'column',
				padding: '16px 20px',
				zIndex: highlight ? 9999 : 0,
				'& .AppointmentCardHoverButton': {
					display: 'flex',
					opacity: '1',
					pointerEvents: 'all',
				},
				'&:hover': {
					boxShadow: '4px 8px 10px rgba(196, 128, 220, 0.05)',
					background: !highlight && `${color}10`,
				},
				[mediaTablet]: {
					padding: '16px 16px',
					boxShadow: '4px 8px 10px rgba(196, 128, 220, 0.05)',
					'& .AppointmentCardHoverButton': {
						display: 'flex',
						opacity: '1',
						pointerEvents: 'all',
					},
				},
				[mediaDesktopL]: { padding: '16px 16px' },
			}}
		>
			{children}
		</Box>
	);
};

export default AppointmentCardWrapper;
