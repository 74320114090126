import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, IconButton, Stack } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const terms_conditions = {
	description:
		'By using our OmegAI Blume and uploading documents, images, and studies, you, the patient, provide your informed consent for the sharing of these materials with healthcare provider(s) who will use them to deliver treatment or care. Please carefully read and understand the following consent terms before proceeding:',
	items: [
		{
			title: 'Purpose of Sharing',
			description:
				'The documents, images, and radiology studies that you upload to our website are shared with healthcare provider(s) solely to provide you with healthcare treatment and care. These may include diagnostic assessments, treatment planning, and ongoing care management.',
		},
		{
			title: 'Access to Information',
			description:
				'By giving your consent, you agree that authorized healthcare provider(s), including but not limited to physicians, nurses, technicians, support staff, and medical specialists involved in your treatment, may access and use the uploaded materials as needed.',
		},
		{
			title: 'Privacy and Security',
			description:
				'We are committed to protecting the privacy and security of your personal health information. We will implement all appropriate administrative, physical, and technical safeguards to protect your information from unauthorized access, use, or disclosure. However, please be aware that no electronic transmission of information can be entirely secure.',
		},
		{
			title: 'Voluntary Participation',
			description:
				'Your decision to upload documents, images, and radiology studies and share them with healthcare provider(s) is completely voluntary. You have the right to withhold or withdraw consent at any time without affecting your right to future care or treatment or risking the loss or withdrawal of any program benefits to which you would otherwise be entitled.',
		},
		// SKIPPED FOR NOW
		// {
		// 	title: 'Duration of Consent',
		// 	description:
		// 		'Your consent remains effective until your treatment is complete, or until you choose to revoke it. You may revoke your consent at any time by submitting a written request to the contact information provided on our website.',
		// },
		{
			title: 'Potential Risks and Benefits',
			description:
				'The primary benefit of sharing your documents, images, and radiology studies is to ensure that healthcare provider(s) have the necessary information to provide effective treatment and care. While we strive to protect the privacy and security of your information, as with any data sharing, there is a risk of unauthorized use or disclosure.',
		},
		{
			title: 'Limitation of Liability',
			description:
				'While we take significant measures to ensure the security and confidentiality of your health information, we cannot guarantee that unauthorized third parties will never be able to defeat our security measures or use your personal information for improper purposes. Therefore, by using our services, you acknowledge that you provide your health information at your own risk. RamSoft shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to any misuse or breach of your health records.',
		},
		{
			title: 'Questions or Concerns',
			description:
				'If you have any questions or concerns about sharing your documents, images, and radiology studies, or about this consent statement, please contact our support team through the contact information available on our website.',
		},
	],
};

const ConsentTCPopop = ({ open, onClose }) => {
	const { t } = useTranslation('profile');

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				data-testid="consent-tc-popup"
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 'min(95%,900px)',
					bgcolor: 'background.paper',
					border: '2px solid #000',
					boxShadow: 24,
					p: '20px 30px',
					borderRadius: '8px',
				}}
			>
				<Stack alignItems="center" direction="row" justifyContent="space-between" pb={2}>
					<Typography fontSize={22} fontWeight={600} variant="h2">
						{t('Informed Consent Statement')}
					</Typography>
					<IconButton data-testid="close-button" onClick={onClose}>
						<Close color="disabled" />
					</IconButton>
				</Stack>
				<Divider variant="fullWidth" />
				<Box mt={3} sx={{ maxHeight: '78dvh', overflowY: 'auto' }}>
					<Typography
						color="#515151"
						display="block"
						fontFamily="roboto"
						fontSize={15}
						lineHeight={1.5}
						variant="p"
					>
						{t(terms_conditions.description)}
					</Typography>
					{terms_conditions.items.map(({ title, description }, i) => (
						<Typography
							key={i}
							color="#515151"
							display="block"
							fontFamily="roboto"
							fontSize={15}
							lineHeight={1.5}
							mt={2}
							variant="p"
						>
							<b>{t(title)}:</b> {t(description)}
						</Typography>
					))}
				</Box>
			</Box>
		</Modal>
	);
};

export default ConsentTCPopop;
