import React from 'react';
import Box from '@mui/material/Box';
import { getAppointmentColor } from '../AppointmentUtils/AppointmentUtils';

const AppointmentCalendarDot = ({ appointment, size = 10, sx }) => {
	const backgroundColor = getAppointmentColor(appointment.status);

	return (
		<Box
			sx={{
				width: `${size}px`,
				height: `${size}px`,
				borderRadius: '50%',
				backgroundColor,
				...sx,
			}}
		/>
	);
};

export default AppointmentCalendarDot;
