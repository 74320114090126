/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Typography, Box, Button, IconButton, Skeleton, Grid } from '@mui/material';
import { useIsMobile, useIsTablet } from '@worklist-2/core/src';

const Slots = ({ slotsLoading, availableTimeSlots = [], setAvailableTimeSlots = Function.prototype }) => {
	const [slotView, setSlotView] = useState(0);
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	return slotsLoading ? (
		<Grid container alignItems="center" mt={isTablet ? 2 : 10} spacing={1} xl={8} xs={12}>
			<Grid item xs={2}>
				<Skeleton height={40} sx={{ borderRadius: '100%' }} variant="rounded" width={40} />
			</Grid>
			<Grid item xs={8}>
				{Array(8)
					.fill(1)
					.map((_d, i) => (
						<Skeleton
							key={i}
							data-testid="progressbar"
							height="40px"
							sx={{
								mt: 1,
								borderRadius: '5px',
								width: 'min(100%,170px)',
								mx: 'auto',
							}}
							variant="rectangular"
						/>
					))}
			</Grid>
			<Grid item xs={2}>
				<Skeleton height={40} sx={{ borderRadius: '100%' }} variant="rounded" width={40} />
			</Grid>
		</Grid>
	) : !availableTimeSlots?.length ? (
		<Box sx={{ py: isTablet ? 0 : 6, opacity: 0.6 }}>
			<Typography color="#4B5565" pt={isTablet ? 2 : 10} px={2}>
				No slots available for selected date!
			</Typography>
		</Box>
	) : (
		<Grid container alignItems="center" mt={isTablet ? 0 : 10} spacing={1} xl={8} xs={12}>
			<Grid item md={2} xs={1}>
				<IconButton data-testid="prev" disabled={slotView === 0} onClick={() => setSlotView(prev => prev - 1)}>
					<KeyboardArrowLeft />
				</IconButton>
			</Grid>
			<Grid container item alignItems="center" columnSpacing={1} justifyContent="space-between" md={8} xs={10}>
				{availableTimeSlots?.slice(slotView * 8, slotView * 8 + 8).map((slot, i) => (
					<Grid key={slot?.id} item display="flex" justifyContent="center" md={12} xs={6}>
						<Button
							data-cy={`slot${i}`}
							data-testid={`time-${slot.id}`}
							sx={{
								border: '1px solid var(--color-primary)',
								width: isMobile ? '139px' : isTablet ? '156px' : 'min(100%,178px)',
								height: isMobile ? '32px' : undefined,
								mt: 1,
								color: slot.selected ? '#FFFFFF' : 'var(--color-primary)',
								bgcolor: slot.selected ? 'var(--color-primary)' : undefined,
								fontSize: isMobile || isTablet ? '12px' : '14px',
								fontWeight: isMobile || isTablet ? 300 : 400,
								whiteSpace: 'nowrap',
								letterSpacing: isMobile || isTablet ? -0.5 : 0.1,
								borderRadius: '4px',
								transition: 'unset',
								':hover': {
									bgcolor: 'var(--color-primary)',
									color: '#FFFFFF',
								},
							}}
							variant="outlined"
							onClick={() => {
								setAvailableTimeSlots(prev =>
									prev.map(item => {
										if (item.id === slot?.id) {
											item.selected = !item.selected;
										} else {
											item.selected = false;
										}
										return item;
									})
								);
							}}
						>
							{slot.time}
						</Button>
					</Grid>
				))}
			</Grid>
			<Grid item md={2} xs={1}>
				<IconButton
					data-testid="next"
					// eslint-disable-next-line no-unsafe-optional-chaining
					disabled={slotView === Math.ceil((availableTimeSlots || [])?.length / 8) - 1}
					onClick={() => setSlotView(prev => prev + 1)}
				>
					<KeyboardArrowRight />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default Slots;
