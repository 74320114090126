import * as yup from 'yup';
import moment from 'moment';

const validationSchema = yup.object().shape(
	{
		modality: yup.string().required(),
		location: yup.string().required(),
		startTime: yup.date().required(),
		endTime: yup.date().required(),
		healthcareServiceId: yup.number().required(),
		email: yup.string().email().optional(),
		phone: yup
			.string()
			.transform(value => (!value ? '' : value.replace(/[+()\s]/g, '')))
			.when('phone', val => {
				if (val?.length > 0) {
					return yup
						.string()
						.min(8, 'Phone number must be of at least 8 digits')
						.max(15, 'Phone number cannot be more than 15 digits')
						.required('Required');
				}

				return yup.string().notRequired();
			}),
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		dateOfBirth: yup.date().max(moment().subtract(16, 'years').toDate(), 'Selected age is less than 16 years'),
		pin: yup.number().optional(),
		signupConsent: yup.boolean().optional(),
	},
	[['phone', 'phone']]
);

export default validationSchema;
