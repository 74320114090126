import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CardWrapper from './CardWrapper';
import { CARD_HEIGHT, CARD_SPACING } from '../consts/consts';

const CardContainer = ({
	Card,
	data,
	order = 0,
	id,
	setClubNotifications,
	shown,
	onClick,
	cardHeight,
	cardSpacing,
	isNotificationCollapsed,
	appointmentStatusChange,
	setCollapsed,
	collapsed,
}) => {
	const scale = 1 - order * 0.05;

	const [state, setState] = useState({ shown, scale });

	const classList = [state.shown ? 'card-shown' : '', order === 0 ? 'card-first' : ''].filter(Boolean);

	useEffect(() => {
		if (!shown) {
			setState(prevState => ({ ...prevState, scale }));
			return;
		}

		setState(prevState => ({ ...prevState, shown }));
	}, [shown, scale]);

	const style = {
		'--element-order': order,
		'--element-z-index': -order,
	};

	return (
		<CardWrapper
			Card={Card}
			appointmentStatusChange={appointmentStatusChange}
			className={classList.join(' ')}
			collapsed={collapsed}
			component={Button}
			id={id}
			selectedCard={data}
			setClubNotifications={setClubNotifications}
			setCollapsed={setCollapsed}
			style={style}
			sx={{
				'--element-order': 0,
				opacity: 1,
				bottom: isNotificationCollapsed ? `calc(${cardSpacing || CARD_SPACING}px * var(--element-order))` : '',
				marginTop: isNotificationCollapsed ? `-${(cardHeight || CARD_HEIGHT) / 2}px` : '10px',
				position: isNotificationCollapsed ? 'absolute' : '',
				transform: isNotificationCollapsed ? `scale(${scale})` : '',
				transformOrigin: '50%',
				padding: '0px',
				width: '100%',
				zIndex: isNotificationCollapsed ? 'var(--element-z-index)' : '',
			}}
			onClick={onClick}
		/>
	);
};

export default CardContainer;
