import React from 'react';
//MUI
import { Icon, IconButton, Stack, Button, Box, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTranslation } from 'react-i18next';
//Context
import { useChatContext } from './ChatContext';

//Component
import { ChatHeading2 } from './ChatHeading';
import ChatSearch from './ChatSearch';
import ChatSelectedUsers from './ChatSelectedUsers';
import CHAT_SECTIONS from './CHAT_SECTIONS';
import Avatar from '@mui/material/Avatar';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FormTextFieldVariant from '../FormNew/formPartials/FormFieldVariants/variants/FormTextFieldVariant';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const ChatNavigation = ({ onClick, heading, handleConversation, addTwilioParticipantUser }) => {
	const {
		showNavigation,
		showSelectedUsers,
		selectedContacts,
		setSelectedContacts,
		section,
		setSection,
		appBlumeMode,
	} = useChatContext();
	const { t } = useTranslation('chat');
	const schema = yup
		.object()
		.shape({
			groupName: yup.object().required('Required'),
		})
		.required();
	const form = useForm({
		resolver: yupResolver(schema),
	});
	const onRemoveContact = contact => {
		const newSelectedContacts = selectedContacts.filter(c => c.text !== contact.text);
		setSelectedContacts(newSelectedContacts);
	};
	const groupName = form.watch('groupName');
	const onCreateGroup = async () => {
		if (section?.name === CHAT_SECTIONS.NEW_GROUP_CREATE) {
			form.handleSubmit();
			await handleConversation(selectedContacts, groupName);
			setSelectedContacts([]);
		} else if (section?.name === CHAT_SECTIONS.GROUP_ADD_PARTICIPANTS) {
			selectedContacts.forEach(async eachContact => {
				await addTwilioParticipantUser(
					eachContact?.text,
					eachContact?.user?.name,
					section?.conversation,
					eachContact?.user?.internalId,
					eachContact?.user?.role,
					eachContact?.isBlumeUser
				);
			});
			setSelectedContacts([]);
			onClick();
		}
	};
	return (
		<Stack>
			<Stack
				alignItems="center"
				direction="row"
				gap="10px"
				sx={{
					padding:
						'var(--element-content-spacing) var(--element-content-spacing-right) 5px var(--element-content-spacing-left)',
				}}
			>
				{showNavigation ? (
					<>
						<IconButton sx={{ flex: 'none' }} onClick={onClick}>
							<Icon component={ChevronLeftIcon} />
						</IconButton>

						<ChatHeading2 sx={{ flex: 'auto' }}>{heading}</ChatHeading2>
					</>
				) : (
					<ChatHeading2 sx={{ flex: 'auto' }}>{heading}</ChatHeading2>
				)}
				{selectedContacts.length > 0 && section?.name === CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS && (
					<Button
						color="rsPrimary"
						sx={{ fontSize: '18px', fontWeight: '500' }}
						onClick={() =>
							setSection({
								name: CHAT_SECTIONS.NEW_GROUP_CREATE,
							})
						}
					>
						{t('newChat.next')}
					</Button>
				)}
				{section?.name === CHAT_SECTIONS.NEW_GROUP_CREATE && (
					<Button color="rsPrimary" sx={{ fontSize: '18px', fontWeight: '500' }} onClick={onCreateGroup}>
						{t('newChat.create')}
					</Button>
				)}
				{selectedContacts.length > 0 && section?.name === CHAT_SECTIONS.GROUP_ADD_PARTICIPANTS && (
					<Button color="rsPrimary" sx={{ fontSize: '18px', fontWeight: '500' }} onClick={onCreateGroup}>
						{t('newChat.add')}
					</Button>
				)}
			</Stack>

			{(section?.name === CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS ||
				section?.name === CHAT_SECTIONS.GROUP_ADD_PARTICIPANTS) && (
				<ChatSearch placeholder={t('chatSearch.searchContactsPlaceholder')} t={t} />
			)}

			{section?.name === CHAT_SECTIONS.NEW_GROUP_CREATE && (
				<>
					<Stack
						alignItems="center"
						direction="row"
						gap="10px"
						sx={{
							padding:
								'var(--element-content-spacing) var(--element-content-spacing-right) 5px var(--element-content-spacing-left)',
						}}
					>
						{!appBlumeMode && (
							<Stack sx={{ width: '60px' }}>
								<Stack
									sx={{
										marginBottom: '5px',
										position: 'relative',
									}}
								>
									<Avatar
										sx={{
											width: 60,
											height: 60,
											backgroundColor: '#F3F3F3',
										}}
									>
										<CameraAltOutlinedIcon
											sx={{
												color: '#42A5F5',
												border: '5px',
											}}
										/>
									</Avatar>

									<IconButton
										className="chat-selected-user-button"
										size="small"
										sx={{
											background: '#42A5F5',
											height: '16px',
											padding: 0,
											position: 'absolute',
											right: '0px',
											top: '40px',
											width: '16px',

											'&:hover': {
												background: '#42A5F5',
											},
										}}
										onClick={() => {}}
									>
										<Icon
											component={AddOutlinedIcon}
											sx={{
												fontSize: '12px',
												color: '#FFFFFF',
											}}
										/>
									</IconButton>
								</Stack>
							</Stack>
						)}
						<FormTextFieldVariant
							formHook={form}
							name="groupName"
							props={{
								placeholder: t('newChat.groupName'),
								variant: 'standard',
								disableUnderline: true,
								sx: {
									paddingBottom: '16px',
									label: { paddingBottom: '16px' },
								},
								autoFocus: true,
							}}
						/>
					</Stack>
					<Box
						sx={{
							bgcolor: '#F3F3F3',
							margin: '33px 21px 0px 31px',
							height: '37px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Typography
							sx={{
								fontSize: '16px',
								marginLeft: '24px',
								color: '#12121299',
							}}
						>
							{`${t('chatConversation.participants')} : ${selectedContacts.length}`}
						</Typography>
					</Box>
				</>
			)}

			{showSelectedUsers ? <ChatSelectedUsers data={selectedContacts} onRemoveClick={onRemoveContact} /> : null}
		</Stack>
	);
};

export default ChatNavigation;
