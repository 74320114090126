// Core

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Material UI

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// Custom

import {
	FormToolFieldset,
	FormToolGrid,
	FormToolTextField,
	FormToolDateField,
	FormToolSelectField,
	FormToolCheckboxes,
	FormToolCheckboxGrid,
	FormToolRadioGroupField,
	FormToolSurveyTable,
	FormToolSignature,
} from '@worklist-2/ui/src/components/FormTool';
import VideoPlayer from '@worklist-2/ui/src/views/OrganizationDetailView/organizationDetailSections/components/VideoPlayer';
import axios from 'axios';
import { useConfig } from '@worklist-2/core/src';
import PDFFiller from '@worklist-2/ui/src/components/PDFFiller/PDFFiller';

const FormQuestionType = ({ question, isReadOnly, handleInput, formId, setPdfInstance, pdfUrl }) => {
	const __config = useConfig();
	const [fileUrl, setFileUrl] = useState(null);
	const { t: translate } = useTranslation('form');

	const location = useLocation();
	const isExternal = location.pathname.startsWith('/external-form');

	const handleInputData = (e, index) => {
		handleInput(question.unique_id, index, e.target.value);
	};

	const handleDateInput = value => {
		handleInput(question.unique_id, null, value);
	};

	const getBlobFile = useCallback(
		async (fileName, blobType) => {
			axios
				.get(`${__config.data_sources.blume}Form/attachment/${formId}?fileName=${fileName}`, {
					responseType: 'blob',
				})
				.then(response => {
					if (response) {
						let options = {};
						if (blobType) {
							options = { type: blobType };
						}
						const urlFile = window.URL.createObjectURL(new Blob([response.data], options));
						setFileUrl(urlFile);
					}
				})
				.catch(err => {
					console.error(err);
				});
		},
		[__config, formId]
	);

	useEffect(() => {
		if (['uploadImage', 'video'].indexOf(question.type) > -1 && question?.description?.fileName) {
			getBlobFile(question.description.fileName);
		}
		if (['pdf'].indexOf(question.type) > -1 && question?.fileName) {
			if (pdfUrl) {
				setFileUrl(pdfUrl);
				return;
			}
			getBlobFile(question.fileName, 'application/pdf');
		}
	}, []);

	switch (question.type) {
		case 'multipleChoice':
			return (
				<FormToolFieldset error={question?.error} isCompact={false}>
					<FormToolRadioGroupField
						answer={question?.answer}
						description={question.description}
						isCompact={false}
						isReadOnly={isReadOnly}
						options={question.options}
						questionType="multipleChoice"
						required={question.required}
						title={question.question}
						translate={translate}
						onChangeData={handleDateInput}
					/>
				</FormToolFieldset>
			);

		case 'multipleChoiceGrid':
			return (
				<FormToolFieldset error={question?.error} isCompact={false}>
					<FormToolSurveyTable
						answer={question?.answer}
						description={question.description}
						error={question?.error}
						isCompact={false}
						isReadOnly={isReadOnly}
						options={question.answerOptions}
						questions={question.options}
						required={question.required}
						title={question.question}
						onChangeData={handleInputData}
					/>
				</FormToolFieldset>
			);

		case 'paragraph':
			return (
				<FormToolFieldset isCompact={false} required={question.required} title={question.question}>
					<FormToolTextField
						multiline
						defaultValue={
							question?.answer?.length > 0 ? question.answer[question.answer.length - 1]?.value : ''
						}
						disabled={isReadOnly}
						error={question?.error}
						isCompact={false}
						maxRows={4}
						minRows={4}
						onChange={e => handleInputData(e, null)}
					/>
				</FormToolFieldset>
			);

		case 'dropdown':
			return (
				<FormToolFieldset
					isCentered={false}
					isCompact={false}
					isRow={false}
					isSpaceBetween={false}
					required={question.required}
					title={question.question}
				>
					<FormToolSelectField
						answer={question?.answer}
						disabled={isReadOnly}
						error={question?.error}
						isCompact={false}
						label={translate('Select')}
						options={question.options}
						onChangeData={handleInputData}
					/>
				</FormToolFieldset>
			);

		case 'date':
			return (
				<FormToolFieldset
					isCentered={false}
					isCompact={false}
					isRow={false}
					isSpaceBetween={false}
					required={question.required}
					title={question.question}
				>
					<FormToolGrid columns={2} isCompact={false} sx={{ width: '100%' }}>
						<FormToolDateField
							InputAdornmentProps={{
								position: 'start',
							}}
							answer={
								question?.answer?.length > 0 ? question.answer[question.answer.length - 1]?.value : ''
							}
							disabled={isReadOnly}
							error={question?.error}
							inputFormat="MM/dd/yyyy"
							isCompact={false}
							label={translate('mm/dd/yyyy')}
							onChangeData={handleDateInput}
						/>
					</FormToolGrid>
				</FormToolFieldset>
			);

		case 'shortAnswer':
			return (
				<FormToolFieldset isCompact={false} required={question.required} title={question.question}>
					{!!question.options &&
						question.options.map((option, shortAnswerIndex) => (
							<FormToolGrid key={`Grid-${shortAnswerIndex}`} columns={1} isCompact={false}>
								<Stack
									direction="row"
									spacing={2}
									sx={{
										height: '50px',
									}}
									width="100%"
								>
									<Typography
										style={{
											whiteSpace: 'pre-line',
											color: '#000000',
											width: '60%',
											height: '50px',
											overflow: 'auto',
											fontSize: '14px',
											lineHeight: '24px',
										}}
									>
										{option.description}
									</Typography>
									<FormToolTextField
										defaultValue={
											question?.answer?.length > 0
												? question.answer[question.answer.length - 1]?.value[shortAnswerIndex]
												: ''
										}
										disabled={isReadOnly}
										error={question?.error}
										isCompact={false}
										placeholder={translate ? translate('Your Answer') : 'Your Answer'}
										style={{
											width: '68%',
										}}
										sx={{
											'& .MuiOutlinedInput-root': {
												height: '56px',
												color: 'rgba(0,0,0,1)',
												'.MuiInputBase-input.Mui-disabled': {
													color: 'rgba(0,0,0,0.26)',
												},
											},
										}}
										onChange={e => handleInputData(e, shortAnswerIndex)}
									/>
								</Stack>
							</FormToolGrid>
						))}
				</FormToolFieldset>
			);

		case 'checkbox':
			return (
				<FormToolFieldset error={question?.error} isCompact={false}>
					<FormToolCheckboxes
						answer={question?.answer}
						description={question.description}
						error={question?.error}
						isCompact={false}
						isReadOnly={isReadOnly}
						options={question.options}
						required={question.required}
						title={question.question}
						translate={translate}
						onChangeData={handleDateInput}
					/>
				</FormToolFieldset>
			);

		case 'checkboxGrid':
			return (
				<FormToolFieldset error={question?.error} isCompact={false}>
					<FormToolCheckboxGrid
						answer={question?.answer}
						description={question.description}
						error={question?.error}
						isCompact={false}
						isReadOnly={isReadOnly}
						options={question.answerOptions}
						questions={question.options}
						required={question.required}
						title={question.question}
						onChangeData={handleDateInput}
					/>
				</FormToolFieldset>
			);

		case 'signature':
			return (
				<FormToolFieldset
					isCompact
					error={question?.error}
					required={question.required}
					title={question.question}
				>
					<FormToolSignature
						isCompact
						isReadOnly={isReadOnly}
						question={question}
						translate={translate}
						userSignature={question?.answer?.length ? question?.answer[0]?.value : null}
						onChangeData={handleDateInput}
					/>
				</FormToolFieldset>
			);

		case 'textBox':
			return (
				<FormToolFieldset isCompact={false} required={question.required} title={question.question}>
					<Typography
						style={{
							whiteSpace: 'pre-line',
							color: 'rgba(0, 0, 0, 0.6)',
						}}
					>
						{question.description}
					</Typography>
				</FormToolFieldset>
			);
		case 'uploadImage':
			return (
				<FormToolFieldset required={question.required} title={question.question}>
					<Box
						height="100%"
						style={{
							width: '100%',
							position: 'relative',
						}}
					>
						<Box
							component="img"
							src={fileUrl}
							sx={{
								objectFit: 'contain',
								maxWidth: '640px',
							}}
						/>
					</Box>
				</FormToolFieldset>
			);

		case 'video':
			return (
				<FormToolFieldset required={question.required} title={question.question}>
					<Box
						height="100%"
						style={{
							width: '100%',
							position: 'relative',
						}}
					>
						{!fileUrl ? (
							<LinearProgress
								sx={{
									backgroundColor: 'rgb(167, 202, 237)',
									'& .MuiLinearProgress-bar': {
										backgroundColor: 'rgb(66, 165, 245)',
									},
								}}
							/>
						) : (
							<VideoPlayer url={fileUrl} />
						)}
					</Box>
				</FormToolFieldset>
			);
		case 'pdf':
			return (
				<Box
					sx={{
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<PDFFiller
						height="82vh"
						isReadOnly={isReadOnly}
						pdfFileName={question?.fileName}
						pdfSrc={fileUrl}
						setPdfInstance={setPdfInstance}
						showDownload={!isExternal}
						showPrint={!isExternal}
						width="100%"
					/>
				</Box>
			);
		default:
			return null;
	}
};

export default FormQuestionType;
