import Typography from '@mui/material/Typography';
import GpsFixedTwoToneIcon from '@mui/icons-material/GpsFixedTwoTone';
import InputAdornment from '@mui/material/InputAdornment';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import FormAPIAutocompleteVariant from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/variants/FormAPIAutocompleteVariant';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const UserLocation = () => {
	const phoenixBlumeOaiPatientDemographicSync = useBooleanFlagValue('phoenix-blume-oai-patient-demographic-sync');
	const { t } = useTranslation('appointments');

	const {
		isUpdateForm,
		hasPendingCRs,
		form,
		searchPlaces,
		filterOptions,
		helperText,
		googleSearchResults,
		setGoogleSearchResults,
	} = useNewAppointmentContentV2Context();

	const getSelectLabel = option => {
		let label = '';
		if (option?.description) {
			label = option.description;
			if (label === t('Use my current location')) {
				return option.value;
			}
		}
		return label;
	};

	return (
		<FormAPIAutocompleteVariant
			TextFieldProps={{
				placeholder: t('Allow us to find the closest facility around you'),
				label: t('Your Location'),
				helperText:
					form.getValues()?.userLocation?.place_id === 'current_location'
						? 'Current location pre-filled'
						: helperText,
				InputProps: {
					onFocus: () => {
						!form.getValues('scanType') &&
							form.setError('scanType', {
								type: 'manual',
								message: t('Please select a Scan Type first'),
							});
					},
					startAdornment: (
						<InputAdornment
							position="start"
							sx={{
								'& .MuiSvgIcon-root': {
									opacity: '1.6 !important',
								},
							}}
						>
							<PersonPinCircleOutlinedIcon />
						</InputAdornment>
					),
				},
			}}
			formHook={form}
			hideClear={
				Boolean(form.getValues('userLocation')?.place_id === 'current_location') ||
				!form.getValues('userLocation')
			}
			name="userLocation"
			options={googleSearchResults}
			props={{
				disabled: !!(isUpdateForm || (hasPendingCRs && phoenixBlumeOaiPatientDemographicSync)),
				isOptionEqualToValue: (option, value) => !value || option?.place_id === value?.place_id,
				getOptionLabel: getSelectLabel,
				renderOption: (props, option) => (
					<li {...props} key={option?.place_id}>
						{option?.description ? (
							option?.place_id === 'current_location' ? (
								<Typography
									sx={{
										marginLeft: '8%',
										fontSize: '14px',
									}}
								>
									<GpsFixedTwoToneIcon fontSize="inherit" /> {option?.description}
								</Typography>
							) : (
								<Typography
									sx={{
										marginLeft: '8%',
										fontSize: '16px',
										color: 'black',
									}}
								>
									{option?.description}
								</Typography>
							)
						) : (
							''
						)}
					</li>
				),
				filterOptions,
			}}
			setOptions={setGoogleSearchResults}
			onSearch={searchPlaces}
		/>
	);
};

export default UserLocation;
