import React, { useEffect, useState, useRef } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TextField, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import DoubleArrowRight from '@worklist-2/ui/src/assets/icons/double_arrow_right.svg';

import { useBlumeAssistantContext } from '../../context/BlumeAssistantContext';
import { mediaMobile, mediaTablet, mediaDesktopL } from '@worklist-2/core/src';

import Lottie from 'lottie-web';
import InitialAnimation from '@worklist-2/ui/src/assets/animation/Blume/BlumeAssistant/Initial/data.json';
import ThinkingAnimation from '@worklist-2/ui/src/assets/animation/Blume/BlumeAssistant/Thinking/data.json';
import { useTranslation } from 'react-i18next';

const BlumeAssistant = () => {
	const { askAQuestion, completions, fullScreenView, isLoading } = useBlumeAssistantContext();
	const [question, setQuestion] = useState(null);
	const [disclaimerOpen, setDisclaimerOpen] = useState(false);

	const [isTyping, setIsTyping] = useState(false);

	const messageSectionEndRef = useRef(null);
	const formRef = useRef();
	const { t } = useTranslation('home');

	useEffect(() => {
		const animationRegion = document.querySelector('#AnimationInDrawer');
		if (animationRegion) {
			document.querySelector('#AnimationInDrawer').innerHTML = '';
		}
		if (Lottie.loadAnimation) {
			Lottie.loadAnimation({
				container: animationRegion,
				animationData: isLoading ? ThinkingAnimation : InitialAnimation,
				autoplay: true,
				loop: true,
			});
		}
	}, [isLoading]);

	useEffect(() => {
		messageSectionEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
	}, [completions, question, fullScreenView, isLoading]);

	const onClickSend = () => {
		if (question) {
			askAQuestion(question);
			formRef.current.reset();
		}
	};

	return (
		<Grid
			container
			data-testid="blume-assistant-grid-wrapper"
			sx={{
				flexDirection: 'row',
				justifyContent: 'space-around',
				backgroundColor: 'linear-gradient(180deg, #FFFFFF 20.6%, #EFF5FC 149.31%)',
				alignItems: 'start',
			}}
		>
			<Grid
				item
				id="AnimationInDrawer"
				sx={{
					position: 'relative',
				}}
				xs={8}
			/>
			<Grid
				item
				sx={{
					overflow: 'scroll',
					maxHeight: 'calc(100vh - 380px)',
					scrollbarWidth: 'none',
					'&::-webkit-scrollbar': {
						width: 0,
					},
					paddingLeft: '42px',
					paddingTop: '25px',
					paddingBottom: '25px',
					[mediaTablet]: {
						maxHeight: 'calc(100vh - 430px)',
					},
					[mediaMobile]: {
						maxHeight: 'calc(100vh - 420px)',
					},
				}}
				xs={12}
			>
				{completions.map((completion, i) => (
					<Typography
						key={i}
						ref={i + 1 === completions.length && !isLoading ? messageSectionEndRef : null}
						color="#00000080"
						sx={{
							fontStyle: 'Regular',
							fontFamily: 'Roboto',
							fontWeight: completion?.role === 'Assistant' ? '400' : '500',
							letterSpacing: '0.4px',
							fontSize: '14px',
							lineHeight: '16px',
							letter: '0.4px',
							textAlign: completion?.role === 'User' ? 'right' : 'left',
							alignSelf: 'flex-end',
							overflowWrap: 'break-word',
							wordWrap: 'break-word',

							marginBottom: '25px',
							[mediaDesktopL]: {
								fontSize: '18px',
								lineHeight: '25px',
							},
							[mediaTablet]: {
								fontSize: '14px',
								lineHeight: '16px',
							},
							[mediaMobile]: {
								fontSize: '11px',
								lineHeight: '17px',
							},
							paddingRight: completion?.role === 'Assistant' ? '20%' : '30px',
							paddingLeft: completion?.role === 'User' ? '20%' : '0px',
						}}
					>
						{completion?.content}
					</Typography>
				))}
				{isLoading && (
					<Stack>
						<Box
							ref={messageSectionEndRef}
							sx={{
								background: '#4BAAF60D',
								borderRadius: '5px 5px 5px 0px',
								width: '127px',
								height: '40px',
								mb: '23px',
							}}
						>
							<div
								className="dot-elastic-blume"
								style={{
									marginLeft: '60px',
									marginTop: '18px',
									alignSelf: 'center',
								}}
							/>
						</Box>
					</Stack>
				)}
			</Grid>
			<Grid item sx={{ position: 'absolute', bottom: '26px' }} xs={12}>
				<Box
					sx={{
						background: '#E6EDF3',
						borderRadius: '30px',
						marginBottom: '10px',
						padding: '7px',
						marginLeft: '36px',
						marginRight: '20px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-evenly',
						width: !disclaimerOpen ? '60px' : null,
						maxWidth: '550px',
						[mediaTablet]: {
							marginLeft: '40px',
						},
						[mediaMobile]: {
							maxWidth: '85%',
							marginLeft: '0px',
						},
					}}
				>
					<CampaignOutlinedIcon sx={{ color: '#4BAAF6', height: '20px' }} />
					{disclaimerOpen && (
						<Typography
							color="#4BAAF6"
							sx={{
								fontSize: '12px',
								fontWeight: '500',
								[mediaMobile]: {
									maxWidth: '82%',
								},
							}}
						>
							{t('ChatGPT may produce inaccurate information about people, places or facts.')}
						</Typography>
					)}
					<DoubleArrowRight
						style={{
							transform: `rotate(${disclaimerOpen ? '180' : '0'}deg)`,
							cursor: 'pointer',
						}}
						onClick={() => setDisclaimerOpen(!disclaimerOpen)}
					/>
				</Box>
				<form ref={formRef}>
					<TextField
						multiline
						InputProps={{
							disableUnderline: true,
							'data-testid': 'blume-assistant-text-field',

							sx: {
								height: '31px',
								backgroundColor:
									'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), linear-gradient(0deg, #F9F9F9, #F9F9F9)',
								borderRadius: '10px',
								textAlign: 'center',
								'&:hover': {
									backgroundColor:
										'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), linear-gradient(0deg, #F9F9F9, #F9F9F9)',
								},
								alignItems: 'center',
								padding: '0px 12px',
								'&.Mui-focused': {
									backgroundColor:
										'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), linear-gradient(0deg, #F9F9F9, #F9F9F9)',
								},
							},
							endAdornment: (
								<InputAdornment position="start">
									<IconButton
										sx={{ marginTop: '-15px', marginRight: '-15px' }}
										onClick={() => {
											onClickSend();
										}}
									>
										<SendIcon sx={{ color: isTyping ? '#42A5F5' : '' }} />
									</IconButton>
								</InputAdornment>
							),
						}}
						maxRows={1}
						placeholder={t('Send a message')}
						sx={{
							paddingLeft: '36px',
							width: '329px',
							[mediaTablet]: {
								paddingLeft: '10px',
							},
						}}
						variant="filled"
						onBlur={() => setIsTyping(false)}
						onChange={event => {
							setQuestion(event.target.value);
						}}
						onFocus={() => setIsTyping(true)}
						onKeyPress={e => {
							if (e.key === 'Enter') {
								onClickSend();
							}
						}}
					/>
				</form>
			</Grid>
		</Grid>
	);
};

export default BlumeAssistant;
