import _ from 'lodash';

export const removeArticles = (data, criteria) => {
	return _.map(data, section => {
		if (criteria[section.label]) {
			section.articles = _.reject(section.articles, article => {
				return _.some(criteria[section.label], key => article.path.endsWith(key));
			});
		}

		return section;
	});
};

export default removeArticles;
