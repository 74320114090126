// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import Typography from '@mui/material/Typography';

// Custom components
import { useChatGlobalContext } from '@worklist-2/ui/src/components/Chat/ChatGlobalContext';

const ChatCallDuration = ({ textStyle }) => {
	const [seconds, setSeconds] = useState(0);
	const { callData } = useChatGlobalContext();

	const upTimer = count => (count > 9 ? count : `0${count}`);

	useEffect(() => {
		if (callData?.status?.onCall) {
			const interval = setInterval(() => {
				setSeconds(prev =>
					prev == 0 && callData?.callStartTime !== 0
						? Math.floor((Date.now() - callData?.callStartTime) / 1000) + 1
						: prev + 1
				);
			}, 1000);
			return () => {
				clearInterval(interval);
			};
		}
	}, [callData]);

	return (
		<Typography data-testid="chatCallDuration" sx={{ ...textStyle }}>{`${upTimer(
			parseInt(seconds / 3600, 10)
		)} : ${upTimer(parseInt(seconds / 60, 10) % 60 == 0 ? 0 : parseInt(seconds / 60, 10) % 60)} : ${upTimer(
			seconds % 60
		)}`}</Typography>
	);
};

ChatCallDuration.propTypes = {
	textStyle: PropTypes.object,
};

export default ChatCallDuration;
