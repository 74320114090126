import React, { useState, useCallback, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, Tooltip } from '@mui/material';
import { GenericDrawer } from '@worklist-2/ui/src/components';
import MobileBottomDrawer from '@worklist-2/patientPortal/src/components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import { useIsMobile, useIsTablet } from '@worklist-2/core/src/utils/responsiveUtils';
import { useTranslation } from 'react-i18next';
import NewAppointmentContentV2 from './NewAppointmentContentV2/NewAppointmentContentV2';

const AppointmentGenericBottomDrawer = ({ title, openDrawer, setOpenDrawer, appointment }) => {
	useEffect(() => {
		setMapFullScreen(false);
	}, [openDrawer]);
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const updateForm = title === 'Reschedule Appointment';
	const [mapFullScreen, setMapFullScreen] = useState(false);
	const onTriggerMapFullScreen = () => {
		setMapFullScreen(!mapFullScreen);
	};

	return (
		<>
			<GenericDrawer
				dontCloseOnBackdropClick
				borderBottom={1}
				borderColor="#12121233"
				drawerColor="rsSecondary.medium"
				drawerOpen={!isMobile && openDrawer}
				left={mapFullScreen ? '90px' : 'unset'}
				setDrawerOpen={setOpenDrawer}
				title={title}
				width={isTablet ? 392 : 498}
			>
				<NewAppointmentContentV2
					appointment={appointment}
					isUpdateForm={updateForm}
					setOpenDrawer={setOpenDrawer}
					onTriggerMapFullScreen={onTriggerMapFullScreen}
				/>
			</GenericDrawer>

			{isMobile && (
				<MobileBottomDrawer
					fullHeight
					open={isMobile && openDrawer}
					title={title}
					onClose={() => setOpenDrawer(false)}
				>
					<NewAppointmentContentV2
						appointment={appointment}
						isUpdateForm={updateForm}
						setOpenDrawer={setOpenDrawer}
						onTriggerMapFullScreen={onTriggerMapFullScreen}
					/>
				</MobileBottomDrawer>
			)}
		</>
	);
};

const AppointmentDrawerButtonBook = ({ sx, disabled }) => {
	const [openDrawer, setOpenDrawer] = useState(false);
	const { t } = useTranslation('appointments');

	const handleClick = useCallback(() => {
		setOpenDrawer(!openDrawer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Tooltip title={t('New Appointment')}>
				<Button
					disableElevation
					color="rsPrimary"
					disabled={disabled}
					startIcon={<AddIcon />}
					sx={{
						fontSize: '16px',
						height: '36px',
						fontWeight: '500',
						fontVariant: 'small-caps',
						letterSpacing: '1.25px',
						textTransform: 'lowercase',
						borderRadius: '8px',
						'& .MuiButton-startIcon > *:nth-of-type(1)': {
							fontSize: '24px',
						},
						...sx,
					}}
					variant="contained"
					onClick={handleClick}
				>
					{t('Book')}
				</Button>
			</Tooltip>
			<AppointmentGenericBottomDrawer
				openDrawer={openDrawer}
				setOpenDrawer={setOpenDrawer}
				title={t('New Appointment')}
			/>
		</>
	);
};

export default AppointmentDrawerButtonBook;
export { AppointmentGenericBottomDrawer };
