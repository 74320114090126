// Core

import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

// MUI

import {
	Box,
	Icon,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Table,
	TableCell,
	TableBody,
	TableContainer,
	TableRow,
	Typography,
	styled,
} from '@mui/material';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import LinkIcon from '@mui/icons-material/Link';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Types icons

const typesIcons = {
	image: PhotoOutlinedIcon,
	video: VideocamOutlinedIcon,
	pdf: PictureAsPdfIcon,
};

// Styled

const FileTableRow = styled(TableRow)({
	'--element-background-color': 'transparent',
	background: 'var(--element-background-color)',

	'& td:first-of-type': {
		'--element-border-color': 'transparent',
		borderLeft: '4px solid var(--element-border-color)',
	},

	'&:hover, &:focus-within': {
		'--element-background-color': '#42a5f51a',
	},

	'&:hover td:first-of-type, &:focus-within td:first-of-type': {
		'--element-border-color': 'var(--color-primary)',
	},

	'&:not(:last-of-type)': {
		borderBottom: '1px solid var(--element-content-border-color)',
	},
});

const FileTableCell = styled(TableCell)({
	border: 0,
	color: 'var(--element-foreground-color)',
	padding: '10px 15px',
	verticalAlign: 'middle',
});

// Component

const ChatConversationFiles = ({ data }) => {
	const { t } = useTranslation('chat');

	if (!Array.isArray(data) || !data.length) {
		return null;
	}

	const [menuAnchor, setMenuAnchor] = React.useState(null);

	return (
		<Box
			className="chat-header-files"
			sx={{
				height: 0,
				position: 'relative',
			}}
		>
			<Box
				className="chat-header-files-container"
				sx={{
					background: 'var(--element-background-color)',
					borderRadius: '0 0 var(--border-radius-base) var(--border-radius-base)',
					boxShadow: '0px 10px 20px var(--element-content-box-shadow-color)',
					left: 'calc(var(--element-content-spacing-left) - var(--element-content-spacing))',
					padding: '10px',
					position: 'absolute',
					right: 'calc(var(--element-content-spacing-right) - var(--element-content-spacing))',
					top: 0,
					zIndex: 2,
				}}
			>
				<TableContainer>
					<Table>
						<TableBody>
							{data.map((item, index) => {
								const buttonId = `chat-header-file-button-${index}`;
								const menuId = `chat-header-file-menu-${index}`;
								const menuOpen = menuAnchor?.id === buttonId;

								const TypeIcon = typesIcons[item.type];

								return (
									<FileTableRow key={index}>
										<FileTableCell>
											{TypeIcon ? (
												<Icon component={TypeIcon} sx={{ color: 'currentcolor' }} />
											) : null}
										</FileTableCell>

										<FileTableCell component="th">{item.name}</FileTableCell>

										<FileTableCell>{item.date}</FileTableCell>

										<FileTableCell>{item.user}</FileTableCell>

										<FileTableCell>
											<IconButton
												aria-controls={menuOpen ? menuId : undefined}
												aria-expanded={menuOpen ? 'true' : undefined}
												aria-haspopup="true"
												aria-label="File options"
												id={buttonId}
												sx={{ color: 'currentcolor' }}
												onClick={event => {
													const target = event.currentTarget;

													setMenuAnchor(menuAnchor !== target ? target : null);
												}}
											>
												<Icon component={MoreHorizIcon} sx={{ color: 'currentcolor' }} />
											</IconButton>

											<Menu
												MenuListProps={{
													sx: {
														background: 'var(--chat-menu-background-color)',
														color: 'var(--chat-menu-foreground-color)',
													},
												}}
												anchorEl={menuAnchor}
												anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
												id={menuId}
												open={menuOpen}
												transformOrigin={{ horizontal: 'right', vertical: 'top' }}
												onClose={() => setMenuAnchor(null)}
											>
												<MenuItem divider onClick={() => setMenuAnchor(null)}>
													<ListItemIcon sx={{ color: 'currentColor' }}>
														<Icon component={FileDownloadIcon} fontSize="small" />
													</ListItemIcon>
													<Typography component="div" sx={{ fontSize: '12px' }}>
														{t('chatConversation.download')}
													</Typography>
												</MenuItem>

												<MenuItem onClick={() => setMenuAnchor(null)}>
													<ListItemIcon sx={{ color: 'currentColor' }}>
														<Icon component={LinkIcon} fontSize="small" />
													</ListItemIcon>
													<Typography component="div" sx={{ fontSize: '12px' }}>
														{t('chatConversation.copyLink')}
													</Typography>
												</MenuItem>
											</Menu>
										</FileTableCell>
									</FileTableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Box>
	);
};

ChatConversationFiles.propTypes = {
	data: PropTypes.array,
};

export default ChatConversationFiles;
