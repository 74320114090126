/* eslint-disable no-useless-catch */
import _ from 'lodash';

export const loadMarkdown = (name, dir, numSubFolder = []) => {
	try {
		let cat = {};
		let childrens = [];
		let articleListMenus = [];

		cat.name = name;

		let catContent = loadCat(dir, 'index');
		let catMenuItem = loadCat(dir, 'left-menu');

		cat.content = catContent;
		cat.menuItem = catMenuItem;

		for (let i = 0; i < numSubFolder.length; i++) {
			childrens.push(loadChild(name, numSubFolder[i].path, i, 'index'));
			articleListMenus.push(loadChild(name, numSubFolder[i].path, i, 'left-menu'));
		}
		cat.children = childrens;
		cat.articleListMenu = articleListMenus;
		return cat;
	} catch (error) {
		throw error;
	}
};

export const loadCat = (path, fileName) => {
	try {
		const childrenContent = require(`./${path}/${fileName}.mdx`);
		// convert to string to enable search
		const { children } = childrenContent?.default?.().props || { children: [] };
		const childrenTextContent = [];

		if (children && children.length > 0) {
			for (let child of children) {
				if (_.isObject(child) && child?.props?.children) {
					childrenTextContent.push(child.props.children);
				}
			}
		}

		return childrenContent?.default?.() || {};
	} catch (error) {
		throw error;
	}
};

export const loadChild = (name, path, i, fileName) => {
	try {
		const childrenContent = require(`./${path}/${fileName}.mdx`);
		// convert to string to enable search
		const { children } = childrenContent?.default?.().props || { children: [] };
		const childrenTextContent = [];

		if (children && children.length > 0) {
			for (let child of children) {
				if (_.isObject(child) && child?.props?.children) {
					childrenTextContent.push(child.props.children);
				}
			}
		}

		return {
			catName: name,
			name: `Article${i + 1}`,
			path: path,
			content: childrenContent?.default?.(),
			textContent: childrenTextContent,
		};
	} catch (error) {
		throw error;
	}
};
