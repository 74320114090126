import { Typography } from '@mui/material';
import React from 'react';
import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import { mediaTabletOnly } from '@worklist-2/core/src';
import Tooltip from '@mui/material/Tooltip';

const AppointmentCardTitle = ({ organization, sx }) => {
	const { isFullscreen } = useAppointmentContext();
	return (
		<Tooltip title={organization}>
			<Typography
				sx={{
					fontSize: '16px',
					lineHeight: '24px',
					minHeight: '24px',
					color: 'rgba(18, 18, 18, 0.87)',
					letterSpacing: '0.15px',
					marginBottom: '12px',
					...sx,
					[mediaTabletOnly]: isFullscreen && { fontSize: '14px' },
				}}
			>
				{organization}
			</Typography>
		</Tooltip>
	);
};

export default AppointmentCardTitle;
