import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	position: fixed;
	top: ${({ position }) => position?.x}px;
	right: ${({ position }) => position?.y}px;
	z-index: 10;
	align-items: flex-start;
	border-radius: 5px;
	overflow: auto;
	overflow-x: hidden;
	min-width: 409px !important;
	max-width: 60vw;
	min-height: 200px;
	max-height: 80vh;
	overflow-y: hidden;
`;

export const Wrapper = styled.div`
	resize: both;
	width: 409px;
	height: inherit;
	display: flex;
`;

export const PopOverWrapper = styled.div`
	width: inherit;
	height: -webkit-fill-available;
	min-height: 60vh;
	max-height: 80vh;
	display: flex;
	flex-direction: column;
	border: solid 1px rgba(255, 255, 255, 0.1);
	border-radius: 5px;
`;

export const PopoverHeader = styled.div`
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
	border: solid 1px rgba(255, 255, 255, 0.1);
	background-color: rgba(58, 149, 222, 0.7);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	display: flex;
	cursor: move;
	border-radius: 5px 5px 0px 0px;

	.header-title {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin: 5px 0px;
		justify-content: space-between;
	}

	h5 {
		font-family: Roboto;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: 0.15px;
		text-align: left;
		color: #fff;
		text-align: center;
		margin: 0px;
	}
`;

export const CallOutWrapper = styled.div`
	flex-grow: 0;
	padding: 15px 10px 15px 27.3px;
	border-radius: 6px;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
	background-color: #2d2d2d;
	margin-right: 20px;
	margin-top: 100px;
	width: 309px;
	display: ${({ visible }) => (visible ? 'block' : 'none')};

	p {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: #fff;
		margin: 0px 23px 10px 38px;
	}

	.tip-header {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		h5 {
			margin: 0px;
			margin-left: 13px;
			flex: 1;
			font-family: Roboto;
			font-size: 14px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: normal;
			text-align: left;
			color: #fff;
		}
	}
`;

export const PiPWindow = styled.iframe`
	width: 241%;
	height: 500px;
	-ms-transform: scale(0.4);
	-ms-transform-origin: 10px 10px;
	-o-transform: scale(0.4);
	-o-transform-origin: 10px 10px;
	transform: scale(0.4);
	transform-origin: 10px 10px;
	border-width: 0px;
	margin: 0px;
	position: absolute;
	top: ${({ mt }) => (mt ? '90px' : '70px')};
	background-color: rgb(18 18 18);
`;

export const ContentWrapper = styled.div`
	width: 409px;
	height: 594px;
	* svg.active {
		color: #42a5f5 !important;
	}
	* svg.inactive {
		color: #fff !important;
	}
	& :not(svg):not(.active) {
		font-family: Roboto !important;
		p, h1, h2, h3, h4, h5, span, button, li {
			color: #fff;
		}
	}

	.table-caption {
		font-size: 14px;
	}

	h3 {
		font-size: 18px !important;
	}

	.main-content {
		padding: 10px 30px;
		height: 90%;
		overflow-y: auto;
	}

	.main-content img {
		max-width: calc(100% + 40px);
		margin-left: -20px;
		margin-right: -20px;
	}

	span {
		font-size: 14px !important;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
	}

	.content-container {
		overflow: visible !important;
		padding: 20px 5px !important;
		margin: 0px 50px;
		p {
			font-family: Roboto !important;
		}
	}

	.content-banner,
	.styled-tabs {
		display: none;
	}

	#help-popover-btn {
		display: none;
	}

	.table-container {
		background: transparent;
		box-shadow: none;
		border-top: 1px solid #d7d7d7;
		border-radius: 0px;
		margin-top: 20px;

		th {
			padding: 5px 15px;
		}
	}
`;

export const HelpPopoverWrapper = styled.div`
	width: 409px;
	position: fixed;
	z-index: 600;
	top: ${({ position }) => position?.x}px;
	right: ${({ position }) => position?.y}px;
	box-shadow: none;
	.popover-menu {
		overflow: hidden;
		border-radius: 10px;
	}
`;

export const PatientHelpChatWrapper = styled.div`
	position: fixed;
	width: 421px;
	height: 549px;
	bottom: 54px;
	right: 84px;
	border-radius: 25px;
	background-color: #fff;
	filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.header {
		background: #42a5f5;
		border: 1px solid rgba(18, 18, 18, 0.1);
		border-radius: 25px 25px 0px 0px;
		min-height: 48px !important;
		height: 48px !important;
		display: flex;
		flex-direction: row;
		align-items: center !important;
		justify-content: center;
		padding: 0px 15px 0px 32px;

		button {
			color: #ffffff;
		}

		span {
			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			display: flex;
			align-items: center;
			letter-spacing: 0.1em;
			font-variant: small-caps;
			color: #ffffff;
		}
	}
`;
