import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';

const useHandleFiles = () => {
	const { filesUploaded, setFilesUploaded, fileInputRef, setViewDocDetails } = useNewAppointmentContentV2Context();

	const onUploadFile = e => {
		let currentFiles = [...e.target.files];

		for (const file of filesUploaded) {
			const temp = currentFiles.filter(item => item.name !== file.name);
			currentFiles = [...temp];
		}

		setFilesUploaded(prevFiles => [...prevFiles, ...currentFiles]);

		const emptyList = new DataTransfer();
		fileInputRef.current.files = emptyList.files;
	};

	const onDeleteFile = fileIndex => {
		const newFileList = [...filesUploaded];

		if (fileIndex > -1) {
			newFileList.splice(fileIndex, 1);
		}

		setFilesUploaded([...newFileList]);
	};

	const onSelectFile = file => {
		if (!file) return;
		if (file.valueString) {
			setViewDocDetails(file);
			return;
		}
		const { Buffer } = require('buffer');
		const fileToBuffer = async f => await f.arrayBuffer();
		if (file) {
			fileToBuffer(file)
				.then(buffer => {
					// Handle the ArrayBuffer
					setViewDocDetails({
						documentId: file.size + file.name,
						fileName: file.name,
						fileType:
							file.type === 'application/pdf'
								? 'pdf'
								: file?.type === 'doc' || file?.type === 'docx'
								? 'docx'
								: 'image',
						notesURL: `${`data:image/jpeg;base64,${Buffer.from(buffer).toString('base64')}`}`,
					});
				})
				.catch(error => {
					console.error(error);
				});
		}
	};

	return {
		onUploadFile,
		onDeleteFile,
		onSelectFile,
	};
};

export default useHandleFiles;
