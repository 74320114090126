import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import _ from 'lodash';
import moment from 'moment';

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import EmptyIllustration from '@worklist-2/patientPortal/src/components/NoDataAvailable/EmptyIllustration';
import noAppointmentIcon from '@worklist-2/ui/src/assets/img/empty/appointment.svg';
import { mediaMobile } from '@worklist-2/core/src/utils/responsiveUtils';

import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import AppointmentCard from '../AppointmentCard/AppointmentCard';
import AppointmentCardsViewHeader from './AppointmentCardsViewHeader';
import DocumentViewDrawer from '../../DocumentDrawer';
import { BANNER_SELECT_STATUS } from '@worklist-2/patientPortal/src/consts';

const AppointmentCardsView = () => {
	const { appointments, selectedDay, isFullscreen, showLoading, filter, document, setDocument, bannerSelectStatus } =
		useAppointmentContext();

	const { t } = useTranslation('appointments');

	const showFilter = appointments?.length;

	const sortedAppointments = useMemo(
		() =>
			appointments
				.filter(apt => {
					if (differenceInCalendarDays(selectedDay, apt.dateStart)) {
						return moment(apt?.dateStart).isSame(selectedDay, 'day');
					}

					return true;
				})
				.filter(({ status }) => (filter ? status === filter : true))
				.filter(appointment => {
					if (
						bannerSelectStatus === BANNER_SELECT_STATUS.FUTURE_EXAM ||
						bannerSelectStatus === BANNER_SELECT_STATUS.REQUESTED
					) {
						const appointmentDate = new Date(appointment?.dateStart);
						const currentDateWithoutTime = new Date();
						const appointmentDateWithoutTime = new Date(appointmentDate);

						return appointmentDateWithoutTime > currentDateWithoutTime;
					}

					return appointment;
				}),
		[appointments, filter, selectedDay, bannerSelectStatus]
	);

	const isEmpty = useMemo(
		() => sortedAppointments?.length === 0 && (!showLoading || filter),
		[filter, showLoading, sortedAppointments]
	);

	return (
		<>
			{showFilter ? <AppointmentCardsViewHeader /> : null}
			{isEmpty ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '90px',

						[mediaMobile]: {
							marginTop: '0px',
						},
					}}
				>
					<EmptyIllustration
						description={
							filter
								? t('There are no appointments matching the selected filters.')
								: t('There are no appointments.')
						}
						image={noAppointmentIcon}
						imageHeight="210px"
						title={t('No appointments found')}
					/>
				</Box>
			) : showLoading ? (
				<Skeleton
					data-testid="appointment-cards-view"
					sx={{ height: isFullscreen ? '650px' : '450px', borderRadius: '10px' }}
					variant="rectangular"
				/>
			) : (
				<Box>
					{sortedAppointments.map(appointment => (
						<AppointmentCard key={appointment.id} appointment={appointment} setDocument={setDocument} />
					))}
					{mediaMobile ? <Box sx={{ marginBottom: 16 }} /> : null}
				</Box>
			)}
			<DocumentViewDrawer
				documentData={document}
				drawerOpen={Boolean(document)}
				setIsOpenDocument={setDocument}
				source="Document"
			/>
		</>
	);
};

export default AppointmentCardsView;
