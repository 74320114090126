import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import AppointmentCalendarSmall from '../AppointmentCalendar/AppointmentCalendarSmall';
import AppointmentDrawerStickyTop from './AppointmentDrawerStickyTop';
import AppointmentDrawerContentFullscreen from './AppointmentDrawerContentFullscreen';
import AppointmentDrawerButtonBook from './AppointmentDrawerButtonBook';
import MobileStudyDetail from '../../MobileBottomDrawer/MobileStudyDetail/MobileStudyDetail';
import MobileBottomDrawer from '../../MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import { mediaTablet, useIsMobile, useIsTablet } from '@worklist-2/core/src';
import AppointmentCardsView from './AppointmentCardsView';

const AppointmentDrawerContent = () => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const { isFullscreen, getAppointments, studyInDetailedView, setStudyInDetailedView } = useAppointmentContext();

	useEffect(() => {
		getAppointments();
	}, []);

	return (
		<>
			{isFullscreen ? (
				<AppointmentDrawerContentFullscreen />
			) : (
				<>
					{!isMobile && <AppointmentDrawerStickyTop title="Scheduler" />}

					<AppointmentCalendarSmall />

					<Box
						sx={{
							padding: '0 12px',
							marginTop: '6px',
							marginLeft: '10px',
							overflowY: 'auto',
							[mediaTablet]: {
								marginLeft: 0,
								padding: '0 16px',
							},
						}}
					>
						<AppointmentCardsView />
					</Box>

					{isMobile && (
						<AppointmentDrawerButtonBook
							sx={{
								position: 'fixed',
								right: '16px',
								bottom: '8px',
								width: '124px',
								height: '51px',
								borderRadius: '50px',
								boxShadow: '0px 4px 4px 0px #0000001A',
							}}
						/>
					)}
				</>
			)}
			{(isMobile || isTablet) && (
				<MobileBottomDrawer
					background="#ffffff"
					open={Boolean(studyInDetailedView)}
					onClose={() => setStudyInDetailedView(null)}
				>
					<MobileStudyDetail data={studyInDetailedView} isShared={false} onShareClick={() => {}} />
				</MobileBottomDrawer>
			)}
		</>
	);
};

export default AppointmentDrawerContent;
