import styled from 'styled-components';

export const MiniHelpWrapper = styled.div`
	width: 565px;
	height: 100vh;
	flex-grow: 0;
	padding: 23px 0 16px;
	border-radius: 15px;
	box-shadow: -5px 0 4px 0 rgba(0, 0, 0, 0.05);
	background-color: #fff;
	position: fixed;
	z-index: 2000;
	top: 0px;
	right: 0px;
	display: flex;
	flex-direction: column;
`;

export const Header = styled.div`
	height: 56px;
	padding: 0px 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: -15px;
	border-bottom: ${props => (props.bordered ? '1px solid rgba(18, 18, 18, 0.1)' : '0px')};

	.icon-btn {
		font-family: Roboto;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 1.6px;
		text-align: left;
		color: #666;
		padding: 10px;
		border-radius: 50px;
	}
`;

export const MainContent = styled.div`
	flex: 1;
	padding: 10px 0px;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
`;

export const SubHeader = styled.div`
	font-family: Roboto;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: 0.25px;
	text-align: left;
	color: rgba(18, 18, 18, 0.6);
	width: 95%;
	margin: 40px 20px 20px auto;
	display: flex;
	flex-direction: row;
	align-items: center;

	span {
		margin-left: 10px;
	}
`;

export const FooterContent = styled.div`
	border-top: 1px solid rgba(18, 18, 18, 0.1);
	display: flex;
	flex-direction: row;
	padding: 0px 25px 15px 25px;
	align-items: baseline;
	justify-content: center;

	span {
		font-family: Roboto;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: rgba(57, 57, 57, 0.6);
	}
`;

export const StartChatButton = styled.button`
	height: 42px;
	flex-grow: 0;
	margin: 21.5px 20px 0 94px;
	padding: 11px 15.5px 11px 15px;
	border-radius: 8px;
	background-color: ${props => (props.outline ? 'transparent' : '#42a5f5')};
	color: ${props => (props.outline ? '#4BAAF6' : '#FFF')};
	border: ${props => (props.outline ? '2px solid #4BAAF6' : '0px')};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	&:hover {
		opacity: 0.8;
		cursor: pointer;
	}

	* {
		font-family: Roboto;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 2.4px;
	}
`;

export const PiPButton = styled.div`
	&:hover {
		button {
			color: #42a5f5 !important;
		}
	}
`;

export const Hint = styled.div`
	text-align: center;
	position: absolute;
	margin-left: -50px;
	background-color: #000000b8;
	padding: 10px 15px;
	border-radius: 5px;
	z-index: 3;

	span {
		opacity: 0.8;
		color: #ffffff !important;
	}
	&:after {
		content: '';
		position: absolute;
		display: block;
		width: 0;
		z-index: 3;
		border-style: solid;
		border-color: #000000b8 transparent;
		border-width: 0 10px 8px;
		top: -8px;
		left: 50%;
		margin-left: -12px;
	}
`;

export const ArrowUp = styled.div`
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;

	border-bottom: 5px solid black;
`;
