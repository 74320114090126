import React from 'react';
import AppointmentCalendarDot from './AppointmentCalendarDot';
import Box from '@mui/material/Box';

const AppointmentCalendarDots = ({ appointments }) => (
	<Box
		sx={{
			position: 'absolute',
			bottom: '3px',
			display: 'flex',
			gap: '2px',
		}}
	>
		{appointments.map(appointment => (
			<AppointmentCalendarDot key={appointment.id} appointment={appointment} size={4} />
		))}
	</Box>
);

export default AppointmentCalendarDots;
