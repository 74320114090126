// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI

import { Avatar, Badge } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Svg } from '@worklist-2/ui/src/components/Svg';

// Defaults

const SIZE = 36;

// Badge component

const ChatAvatarBadge = ({ isOnline, sx, children }) => (
	<Badge
		anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		overlap="circular"
		sx={{
			'& .MuiBadge-badge': {
				backgroundColor: isOnline ? '#3be775' : '#666666',
				boxShadow: '0 0 0 2px var(--element-badge-border-color)',
				zIndex: '0',
			},

			...sx,
		}}
		variant="dot"
	>
		{children}
	</Badge>
);

ChatAvatarBadge.propTypes = {
	status: PropTypes.string,
	sx: PropTypes.object,
	children: PropTypes.node,
};

// Component

const ChatAvatar = ({ data, isOnline, size = SIZE, variant, className, sx, showBadge = true, isAiChat = false }) => {
	const { t } = useTranslation('chat');

	const chatTitle = data?.name
		?.toUpperCase()
		.split(' ')
		.slice(0, 2)
		.map(name => name[0])
		.join('');

	const content = (
		<Avatar
			alt={data.name}
			className={className}
			sx={{
				flex: 'none',
				height: `${size}px`,
				width: `${size}px`,
				...sx,
				justifyContent: 'center',
				display: 'flex',
				bgcolor: !showBadge && isAiChat && 'transparent',
			}}
			variant={variant}
		>
			{isAiChat ? <Svg name="rootlogo" sx={{ width: '30px', height: '30px' }} /> : t(chatTitle)}
		</Avatar>
	);

	if (showBadge) {
		return <ChatAvatarBadge isOnline={isOnline}>{content}</ChatAvatarBadge>;
	}
	return content;
};

ChatAvatar.propTypes = {
	data: PropTypes.object.isRequired,
	isOnline: PropTypes.bool,
	size: PropTypes.number,
	variant: PropTypes.string,
	className: PropTypes.string,
	sx: PropTypes.object,
};

export default ChatAvatar;
export { ChatAvatarBadge, SIZE as CHAT_AVATAR_SIZE };
