import React from 'react';
import { Box, Typography } from '@mui/material';

import { useFormStore } from '../../../stores';
import FormToolLabel from './FormToolLabel';

const FormToolSubheader = ({ required, titleId }) => {
	const selectedForm = useFormStore(state => state.selectedForm);

	const title = selectedForm.questionData[pageIndex][0]?.question;
	const description = selectedForm.questionData[pageIndex][0]?.description;

	return title !== undefined || description ? (
		<Box>
			{title !== undefined ? (
				<FormToolLabel id={titleId} required={required}>
					{title}
				</FormToolLabel>
			) : null}

			{description ? (
				<Typography
					data-testid="form-subheader-description"
					sx={{
						color: '#999999',
						fontSize: '14px',
						marginTop: title ? '10px' : undefined,
						whiteSpace: 'pre-line',
					}}
				>
					{description}
				</Typography>
			) : null}
		</Box>
	) : null;
};

export default FormToolSubheader;
