// Core

import React from 'react';

// MUI

import { Icon, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

const NoDataAvailable = ({ direction, primaryText, secondaryText, image, spacing }) => (
	<Stack
		alignItems="center"
		direction={direction}
		justifyContent="center"
		spacing={spacing}
		sx={{
			flex: 'auto',
			padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',
		}}
	>
		<Icon
			component={image.imagePath}
			sx={{
				boxSizing: 'border-box',
				flex: 'none',
				height: image.height,
				width: image.width,
				marginTop: image.marginTop,
			}}
		/>
		<Stack alignItems="center" direction="column" justifyContent="center">
			<Typography
				component="div"
				sx={{
					color: primaryText.color,

					fontSize: primaryText.fontSize,
					fontWeight: primaryText.fontWeight,
					textAlign: 'center',
				}}
			>
				{primaryText.text}
			</Typography>

			<Typography
				component="div"
				sx={{
					color: secondaryText.color,
					fontSize: secondaryText.fontSize,
					marginTop: '5px',
					textAlign: 'center',
					fontWeight: secondaryText.fontWeight,
					letterSpacing: secondaryText.letterSpacing,
				}}
			>
				{secondaryText.text}
			</Typography>
		</Stack>
	</Stack>
);

export default NoDataAvailable;
