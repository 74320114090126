import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import {
	CallOutWrapper,
	Container,
	ContentWrapper,
	HelpPopoverWrapper,
	PopoverHeader,
	PopOverWrapper,
} from './popoverStyles';
import SearchBoxInput from './SearchBoxInput';
import Draggable from 'react-draggable';
import { useHelpCenter } from '@worklist-2/core/src';
import _ from 'lodash';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Box from '@mui/material/Box';
import { AntTab, AntTabs } from '../../../../components/Help/CustomTab';
import { useTranslation } from 'react-i18next';

const HelpPopOver = ({ dismiss, showTip, dismissTip, Content, openMenu, showingMenu }) => {
	const { t } = useTranslation('helpCenter');
	const [showSearch, setShowSearch] = useState(false);
	const [position] = useState({ x: 10, y: 50 });
	const [keyword, setKeyword] = useState('');

	const { handleQuestionChange, searchQuestion, currentPipTabs, popOverContent } = useHelpCenter();
	const [bounds, setBounds] = useState({});

	const handleDrag = () => {
		try {
			const { clientWidth, clientHeight } = document.querySelector('body');
			const popover = document.getElementsByClassName('popover-wrapper')[0].getBoundingClientRect();
			// calculate boundaries
			const { bottom, height, left, width } = popover;
			const boundaries = {
				bottom: clientHeight + 50 - height,
				right: clientWidth + 100 - (left + width),
				top: height - bottom,
				left: -(clientWidth - width) + 100,
			};
			setBounds(boundaries);
		} catch (error) {
			console.error(error.message);
		}
	};

	const searchHelp = e => {
		e.preventDefault();
		handleQuestionChange(keyword);
	};

	const clearSearch = () => {
		handleQuestionChange('');
		setKeyword('');
		document.getElementById('pip-search-box').focus();
	};

	const handleTabChange = (event, newValue) => {
		try {
			openMenu(currentPipTabs[newValue]?.path);
		} catch (error) {
			openMenu(popOverContent);
		}
	};

	return (
		<Draggable
			axis="both"
			bounds={bounds}
			data-testid="PopOverWrapperDragable"
			defaultClassName="popover-menu"
			defaultPosition={position}
			handle=".handle"
			position={null}
			scale={1}
			onDrag={handleDrag}
		>
			<HelpPopoverWrapper position={position}>
				<Container className="popover-wrapper" position={position}>
					<CallOutWrapper visible={showTip}>
						<div className="tip-header">
							<TipsAndUpdatesOutlinedIcon sx={{ color: '#FFF' }} />
							<h5>{t('Did you know?')}</h5>
							<IconButton
								data-testid="PopOverWrapperDismissTip"
								sx={{ color: '#FFFFFF50', marginTop: 0 }}
								onClick={() => dismissTip(false)}
							>
								<CloseIcon />
							</IconButton>
						</div>
						<p>
							{t('You can now use help anywhere.')}
							<br />
							{t('Click to resume where you left off.')}
						</p>
					</CallOutWrapper>
					<div>
						<PopOverWrapper>
							<PopoverHeader>
								<IconButton
									data-testid="PopOverWrapperToggleMenu1"
									sx={{ color: '#FFF' }}
									onClick={() => openMenu('menu')}
								>
									<MenuOutlinedIcon />
								</IconButton>
								<div
									className="header-title"
									data-testid="PopOverWrapperHeader"
									onMouseEnter={() => setShowSearch(true)}
									onMouseLeave={() => setShowSearch(searchQuestion?.trim().length > 0)}
								>
									<h5 className="handle">{t('Help')}</h5>
									{showSearch && (
										<form data-testid="PopOverWrapperSearchFormControlSubmit" onSubmit={searchHelp}>
											<FormControl
												data-testid="PopOverWrapperSearchFormControl"
												sx={{
													borderBottomWidth: 0,
													display: 'flex',
													flexDirection: 'row',
													backgroundColor: '#FFF',
													padding: '5px 10px',
													borderRadius: '5px',
													margin: '10px 0px',
												}}
												variant="standard"
											>
												<SearchOutlinedIcon
													style={{
														marginLeft: 5,
														marginRight: 5,
														opacity: '0.2',
													}}
												/>
												<SearchBoxInput
													autoFocus
													data-testid="pip-search-box"
													id="pip-search-box"
													style={{ width: '100%' }}
													value={keyword}
													onChange={e => setKeyword(e.target.value)}
												/>
												<CloseIcon
													data-testid="PopOverWrapperSearchFormControlClearSearch"
													style={{
														marginLeft: 5,
														marginRight: 5,
														cursor: 'pointer',
														opacity: keyword?.trim().length > 0 ? '1' : '0.2',
													}}
													onClick={clearSearch}
												/>
											</FormControl>
										</form>
									)}
								</div>
								<IconButton sx={{ color: '#FFF' }} onClick={dismiss}>
									<CloseIcon />
								</IconButton>
							</PopoverHeader>
							<Paper
								style={{
									backgroundColor: '#272727',
									flex: 1,
									overflowY: 'auto',
									borderRadius: 0,
								}}
							>
								<ContentWrapper style={{ overflow: 'hidden' }}>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										{!showingMenu && (
											<IconButton
												data-testid="PopOverWrapperIconButton"
												onClick={() => openMenu('menu')}
											>
												<KeyboardBackspaceIcon />
											</IconButton>
										)}
										{!_.isEmpty(currentPipTabs) && (
											<AntTabs
												allowScrollButtonsMobile
												aria-label="basic tabs example"
												scrollButtons="auto"
												sx={{ flex: 1 }}
												value={
													currentPipTabs.findIndex(tab => tab?.path === popOverContent) || 0
												}
												variant="scrollable"
												onChange={handleTabChange}
											>
												{_.map(currentPipTabs, (tab, i) => (
													<AntTab key={i} label={t(tab.label)} />
												))}
											</AntTabs>
										)}
									</Box>
									<Box className="main-content">
										{Content &&
											(Content?.content || <Content onChangeContent={v => openMenu(v)} />)}
									</Box>
								</ContentWrapper>
							</Paper>
						</PopOverWrapper>
					</div>
				</Container>
			</HelpPopoverWrapper>
		</Draggable>
	);
};

export default HelpPopOver;
