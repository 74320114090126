// Core
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// import { pollAudioLevel } from './pollAudioLevel';
import { useChatGlobalContext } from './ChatGlobalContext';

const ChatCallParticipantMedia = ({ participant }) => {
	const [audioTracks, setAudioTracks] = useState([]);
	// const [audioLevel, setAudioLevel] = useState(0);

	const audioRef = useRef();
	const { callRoom, setScreenShare } = useChatGlobalContext();

	useEffect(() => {
		if (participant) {
			const trackSubscribed = track => {
				if (track.kind === 'video') {
					// Both screenTrack and videoTrack use the video track, so need to update this code if turning on video feature
					setScreenShare(prev => {
						// When the remote participant shares screen, unpublish the local screen share if any
						if (prev?.localScreenTrack) {
							callRoom.localParticipant.unpublishTrack(prev?.localScreenTrack);
							prev?.localScreenTrack.stop();
							prev.localScreenTrack = null;
						}
						prev.remoteScreenShared = true;

						return { ...prev };
					});
				} else {
					setAudioTracks(prevTracks => [...prevTracks, track]);
				}
			};

			const trackUnsubscribed = track => {
				if (track.kind === 'video') {
					setScreenShare(prev => {
						prev.remoteScreenShared = false;
						return { ...prev };
					});
				} else {
					setAudioTracks(prevTracks => prevTracks.filter(a => a !== track));
				}
			};

			// Participants have videoTracks and audioTracks properties that return a Map of TrackPublication objects
			// A TrackPublication doesn't have access to its track object until it is subscribed
			// => filter out any tracks that don't exist
			const trackpubsToTracks = trackMap =>
				Array.from(trackMap.values())
					.map(publication => publication.track)
					.filter(track => track !== null);

			setAudioTracks(trackpubsToTracks(participant?.audioTracks));

			// Listeners to the participant object for when tracks are added or removed
			participant.on('trackSubscribed', trackSubscribed);
			participant.on('trackUnsubscribed', trackUnsubscribed);

			// Clean up, remove the listeners, and empty the state when the component is unmounted
			return () => {
				setAudioTracks([]);
				participant.removeAllListeners();
			};
		}
	}, [participant]);

	// Attach the audio tracks to the DOM
	useEffect(() => {
		const audioTrack = audioTracks[0];
		if (audioTrack) {
			audioTrack.attach(audioRef.current);

			// pollAudioLevel(audioTrack, (level) => setAudioLevel(level));

			return () => {
				audioTrack.detach();
			};
		}
	}, [audioTracks]);

	return participant ? (
		<>
			<audio ref={audioRef} autoPlay id="voiceCall" muted={false} />

			{
				//TODO : Chat - comment this code out until there is a story for showing it
				/* <LinearProgress
				variant="determinate"
				color="success"
				value={audioLevel * 10}
			/> */
			}
		</>
	) : null;
};

ChatCallParticipantMedia.propTypes = {
	participant: PropTypes.object,
};

export default ChatCallParticipantMedia;
