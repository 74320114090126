import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Timer = ({ minutes, seconds, onComplete }) => {
	const [time, setTime] = useState({ minutes: parseInt(minutes, 10), seconds: parseInt(seconds, 10) });

	const { t } = useTranslation('scheduling');

	useEffect(() => {
		const timer = time.minutes > 0 || time.seconds > 0 ? setInterval(() => tick(), 1000) : null;

		// Callback once timer is over
		if (time.minutes === 0 && time.seconds === 0 && onComplete) onComplete();

		return () => clearInterval(timer);
	}, [onComplete, tick, time]);

	const tick = useCallback(() => {
		if (time.minutes === 0 && time.seconds === 0) return;

		if (time.seconds === 0) {
			setTime(prevState => ({
				minutes: prevState.minutes - 1,
				seconds: 59,
			}));
		} else {
			setTime(prevState => ({
				...prevState,
				seconds: prevState.seconds - 1,
			}));
		}
	}, [time.minutes, time.seconds]);

	const formatTime = useCallback(value => (value < 10 ? `0${value}` : value), []);

	return (
		<Box display="flex" fontFamily="Roboto" fontSize="32px" gap="10px">
			{time.minutes === 0 && time.seconds === 0 ? (
				<Typography fontSize={{ xs: '14px', md: '24px' }} textAlign="center">
					{t("Time's up!")}
				</Typography>
			) : (
				<>
					<Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
						<Typography fontSize={{ xs: '14px', md: '32px' }}>{formatTime(time.minutes)}</Typography>
						<Typography fontSize={{ xs: '12px', md: '16px' }}>{t('mins')}</Typography>
					</Box>
					<Box fontSize={{ xs: '14px', md: '32px' }}>:</Box>
					<Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
						<Typography fontSize={{ xs: '14px', md: '32px' }}>{formatTime(time.seconds)}</Typography>
						<Typography fontSize={{ xs: '12px', md: '16px' }}>{t('secs')}</Typography>
					</Box>
				</>
			)}
		</Box>
	);
};

export default Timer;
