import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { IconButton, Skeleton } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const TimeSlots = ({ slots = [], loading, selectedSlot, setSelectedSlot }) => {
	const [scrollIndex, setScrollIndex] = useState(0);

	return (
		<Box my={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
			<IconButton disabled={scrollIndex === 0} onClick={() => setScrollIndex(prev => prev - 1)}>
				<KeyboardArrowLeft />
			</IconButton>
			<Grid container px={{ md: 1 }} spacing={1} sx={{ flex: 1 }}>
				{loading
					? Array(6)
							.fill(1)
							.map((_d, i) => (
								<Grid key={i} item sm={4} xs={6}>
									<Skeleton
										data-testid="skeleton"
										sx={{ height: '38px', width: '100%', borderRadius: '3px' }}
										variant="rectangular"
									/>
								</Grid>
							))
					: slots.slice(scrollIndex * 6, scrollIndex * 6 + 6).map(slot => (
							<Grid key={slot?.id} item sm={4} xs={6}>
								<Button
									color="info"
									data-testid="timeslot"
									sx={{
										whiteSpace: 'nowrap',
										backgroundColor: selectedSlot?.id === slot?.id && '#42A5F5',
										borderColor: '#42A5F5',
										color: selectedSlot?.id !== slot?.id && '#42A5F5',
										px: { xs: 0.5, sm: 1, md: 2.5 },
										letterSpacing: '-0.5px',
									}}
									variant={selectedSlot?.id === slot?.id ? 'contained' : 'outlined'}
									onClick={() => setSelectedSlot(slot)}
								>
									{slot?.time}
								</Button>
							</Grid>
					  ))}
			</Grid>
			<IconButton
				disabled={scrollIndex === Math.ceil(slots?.length / 6) - 1}
				onClick={() => setScrollIndex(prev => prev + 1)}
			>
				<KeyboardArrowRight />
			</IconButton>
		</Box>
	);
};

export default TimeSlots;
