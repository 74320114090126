import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { getAppointmentColor } from '../AppointmentUtils/AppointmentUtils';
import { mediaTabletOnly } from '@worklist-2/core/src';
import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import { useTranslation } from 'react-i18next';

const AppointmentCardDateSection = ({ status, date }) => {
	const { isFullscreen } = useAppointmentContext();
	const color = getAppointmentColor(status);
	const { t } = useTranslation('appointments');

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				width: '45px',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			}}
		>
			<Typography
				noWrap
				sx={{
					fontSize: '34px',
					lineHeight: '40px',
					color,
					[mediaTabletOnly]: isFullscreen && {
						fontSize: '24px',
						lineHeight: '28px',
					},
				}}
			>
				{t(moment(date).format('DD'))}
			</Typography>

			<Typography
				noWrap
				sx={{
					fontSize: '14px',
					lineHeight: '16px',
					color,
					fontVariant: 'small-caps',
					textTransform: 'uppercase',
				}}
			>
				{t(moment(date).format('MMMM')).substring(0, 3)}
			</Typography>
		</Box>
	);
};

export default AppointmentCardDateSection;
