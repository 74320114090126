// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI

import { Box, Button, Icon, IconButton, Stack, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTranslation } from 'react-i18next';

// Custom

import ChatContact from './ChatContact';
import { useChatContext } from './ChatContext';
import CHAT_CONVERSATION_TYPES from './CHAT_CONVERSATION_TYPES';

// Defaults

const HEIGHT = 75;
const CONTACT_SIZE = 46;

// Header Button component

const ChatHeaderButton = props => (
	<Tooltip title={props?.title ?? ''}>
		<IconButton
			className="chat-header-button"
			{...props}
			sx={{
				color: 'var(--element-foreground-color)',
				flex: 'none',
				...props?.sx,
			}}
		/>
	</Tooltip>
);

// Component

const ChatHeader = ({
	title,
	text,
	isOnline,
	onBackClick,
	onContactClick,
	sx,
	children,
	userCount,
	conversation,
	onHeaderMouseDown,
	onCallHeaderClick,
	isAiChat = false,
}) => {
	const { conversationType, callData, expanded, smallView } = useChatContext();
	const { t } = useTranslation('chat');
	const classList = [
		'chat-header',
		conversationType === CHAT_CONVERSATION_TYPES.MESSAGING || conversationType === CHAT_CONVERSATION_TYPES.CALL
			? 'chat-header--conversation-type-messaging'
			: '',
		conversationType === CHAT_CONVERSATION_TYPES.CALL ? 'chat-header--conversation-type-call' : '',
		callData?.isCall &&
		conversation?.sid === (callData?.conversation?.sid ?? callData?.callNotification?.conversationSid)
			? 'chat-header--call'
			: '',
	].filter(Boolean);

	const contactClickDefined = typeof onContactClick === 'function';

	const content = (
		<ChatContact
			isAiChat={isAiChat}
			isOnline={isOnline}
			size={CONTACT_SIZE}
			sx={{ flex: 'auto', minWidth: 0 }}
			title={title}
			titleProps={{
				sx: {
					fontSize: '16px',
					fontWeight: 500,
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					marginTop: isAiChat ? '10px' : 0,
				},
			}}
			userCount={userCount}
		>
			{text ? (
				<Typography
					className="chat-header-text"
					component="div"
					sx={{
						color: 'currentColor',
						fontSize: '14px',
						lineHeight: `${CONTACT_SIZE / 2}px`,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
				>
					{t(text)}
				</Typography>
			) : null}
		</ChatContact>
	);

	return (
		<Box
			className={classList.join(' ')}
			data-testid="chatHeader"
			sx={{
				'--element-background-color': 'var(--color-primary)',
				'--element-foreground-color': '#ffffff',

				background:
					callData?.status?.onCall && conversation?.sid === callData?.conversation?.sid
						? '#42A5F5'
						: (smallView || expanded) && !(callData?.status?.calling || callData?.status?.incoming)
						? '#272727'
						: 'var(--element-background-color)',
				boxSizing: 'border-box',
				color: 'var(--element-foreground-color)',
				display: 'grid',
				height: `${HEIGHT}px`,
				padding: !contactClickDefined
					? '10px var(--element-content-spacing-right) 10px var(--element-content-spacing-left)'
					: '10px var(--element-content-spacing-right) 10px calc(var(--element-content-spacing-left) - 10px)',
				position: 'relative',

				'&.chat-header--conversation-type-messaging.chat-header--call': {
					'--element-background-color': '#25d366',
					'--element-foreground-color': '#ffffff',
				},

				...sx,
				borderRadius: smallView ? '20px 20px 0px 0px' : '0px',
				cursor: smallView
					? 'move'
					: conversationType === CHAT_CONVERSATION_TYPES.MESSAGING &&
					  conversation?.sid === callData?.conversation?.sid
					? 'pointer'
					: undefined,
			}}
			onClick={onCallHeaderClick}
			onMouseDown={onHeaderMouseDown ?? null}
		>
			<Stack
				alignItems="center"
				direction="row"
				sx={{
					overflow: 'hidden',
				}}
			>
				{typeof onBackClick === 'function' ? (
					<ChatHeaderButton onClick={onBackClick}>
						<Icon component={ChevronLeftIcon} />
					</ChatHeaderButton>
				) : null}

				{contactClickDefined ? (
					<Button
						sx={{
							borderRadius: 'var(--border-radius-base)',
							color: 'currentColor',
							fontWeight: 400,
							margin: '-5px auto -5px 0px',
							padding: '5px 10px',
							textAlign: 'left',
							textTransform: 'none',
						}}
						onClick={onContactClick}
					>
						{content}
					</Button>
				) : conversationType === CHAT_CONVERSATION_TYPES.CALL &&
				  (callData?.status?.calling || callData?.status?.incoming) ? (
					<Typography
						sx={{
							fontFamily: 'Roboto',
							color: '#FFFFFF',
							fontSize: '16px',
							lineHeight: '19px',
							fontWeight: '400px',
							mr: '136px',
						}}
					>
						{callData?.status?.calling
							? `${t('call.youAreCalling')} ${title}`
							: callData?.status?.incoming
							? `${title} ${t('call.isCallingYou')}...`
							: ''}
					</Typography>
				) : (
					content
				)}

				{children}
			</Stack>
		</Box>
	);
};

ChatHeader.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string,
	isOnline: PropTypes.bool,
	onBackClick: PropTypes.func,
	onContactClick: PropTypes.func,
	sx: PropTypes.object,
	children: PropTypes.node,
	userCount: PropTypes.number,
	conversation: PropTypes.object,
};

export default ChatHeader;
export { ChatHeaderButton, HEIGHT as CHAT_HEADER_HEIGHT };
