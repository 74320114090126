// core
import React, { useState, forwardRef, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// material
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';

//components
import GoBackBtn from '../utils/GoBackBtn';
import Details from '../utils/Details';
import PhoneInput from '../utils/PhoneInput';
import Timer from './Timer';
import DatePicker from './DatePicker';

// store
import { useScheduleStore } from '../../../../stores';

//utils
import { SCHEDULE_APPOINTMENT_TIMEOUT } from '../../../../consts';

const ReservationWithRejectedConsent = ({ form }, ref) => {
	const { t } = useTranslation('scheduling');
	const isLoading = useScheduleStore(state => state.isLoading);
	const accountExists = useScheduleStore(state => state.accountExists);

	const [timeup, setTimeup] = useState(false);

	const { register, trigger, getValues, setValue, formState, watch } = form;
	const { location, modality, startTime, endTime } = getValues();

	// Watch for changes
	watch();

	useEffect(() => {
		trigger(['dateOfBirth', 'lastName', 'firstName', 'email', 'phone']);
	}, []);

	const isValid = useMemo(() => {
		const keys = Object.keys(formState.errors);
		if (!keys.length) {
			return true;
		}

		if ((keys.length === 1 && keys[0] === 'email') || keys[0] === 'phone') {
			return true;
		}

		return false;
	}, [formState]);

	const setDateOfBirth = useCallback(
		val => {
			setValue('dateOfBirth', val);
			trigger(['dateOfBirth']);
		},
		[setValue, trigger]
	);

	return (
		<Box
			ref={ref}
			display="flex"
			flexDirection="column"
			gap="28px"
			sx={{
				maxWidth: '890px',
				margin: { xs: '0 20px 20px 20px', md: 'auto' },
				background: '#fff',
				padding: '40px',
				boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
			}}
		>
			<Box>
				<GoBackBtn />
			</Box>
			<Box alignItems="center" display="flex" justifyContent="space-between">
				<Box>
					<Typography fontSize={{ xs: '16px', md: '24px' }} fontWeight={500} sx={{ color: '#101828' }}>
						{t('Appointment reserved')}
					</Typography>
					<Typography fontSize={{ xs: '14px', md: '18px' }} sx={{ color: '#344054' }}>
						{t('Register now and save your spot before the timer runs out')}
					</Typography>
				</Box>
				<Box>
					<Timer
						minutes={SCHEDULE_APPOINTMENT_TIMEOUT.minutes}
						seconds={SCHEDULE_APPOINTMENT_TIMEOUT.seconds}
						onComplete={() => setTimeup(true)}
					/>
				</Box>
			</Box>
			<Details endTime={endTime} location={location} modality={modality} startTime={startTime} />
			<Box display="flex" flexDirection="column" gap="24px">
				<Typography fontSize={{ xs: '16px', md: '20px' }} sx={{ color: '#344054' }}>
					{t('Contact details')}
				</Typography>
				<Box display="flex" flexDirection="column" gap={{ xs: '16px', md: '20px' }}>
					<Box display="flex" flexDirection="column">
						<Typography
							component="label"
							fontSize="14px"
							fontWeight={500}
							htmlFor="schedule-email"
							lineHeight="20px"
							sx={{ marginBottom: '8px' }}
						>
							{t('Email')}
						</Typography>
						<Input data-testid="email" id="schedule-email" {...register('email')} />
					</Box>
					<Box>
						<Typography
							component="label"
							fontSize="14px"
							fontWeight={500}
							htmlFor="schedule-phone"
							lineHeight="20px"
							sx={{ marginBottom: '8px' }}
						>
							{t('Phone number')}
						</Typography>
						<PhoneInput {...register('phone')} />
						{accountExists && (
							<Typography
								data-testid="account-exists-message"
								fontSize="14px"
								lineHeight="20px"
								sx={{ color: '#344054', margin: '6px 0 0 0' }}
							>
								{t(
									'An existing account for this phone number found, please enter your Blume Pin number below to continue reserving this appointment'
								)}
							</Typography>
						)}
					</Box>
					{accountExists && (
						<Box data-testid="account-exists">
							<Typography
								component="label"
								fontSize="14px"
								fontWeight={500}
								htmlFor="schedule-pin"
								lineHeight="20px"
								sx={{ marginBottom: '8px' }}
							>
								{t('Enter 6 digit pin')}
							</Typography>
							<Input
								id="schedule-pin"
								type="number"
								{...register('pin', {
									minLength: { value: 6, message: t('PIN should contain six digits') },
									maxLength: { value: 6, message: t('PIN should contain six digits only') },
									valueAsNumber: true,
								})}
							/>
						</Box>
					)}
				</Box>
			</Box>
			<Box display="flex" flexDirection="column" gap="24px">
				<Typography fontSize={{ xs: '16px', md: '20px' }} sx={{ color: '#344054' }}>
					{t('Profile details')}
				</Typography>
				<Box display="flex" flexDirection="column" gap={{ xs: '16px', md: '20px' }}>
					<Box display="flex" gap="20px">
						<Box display="flex" flexBasis="100%" flexDirection="column">
							<Typography
								component="label"
								fontSize="14px"
								fontWeight={500}
								htmlFor="firstName"
								lineHeight="20px"
								sx={{ marginBottom: '8px' }}
							>
								{t('First name')}
							</Typography>
							<Input
								data-testid="firstName"
								id="firstName"
								{...register('firstName', { required: true })}
							/>
						</Box>
						<Box display="flex" flexBasis="100%" flexDirection="column">
							<Typography
								component="label"
								fontSize="14px"
								fontWeight={500}
								htmlFor="lastName"
								lineHeight="20px"
								sx={{ marginBottom: '8px' }}
							>
								{t('Last name')}
							</Typography>
							<Input data-testid="lastName" id="lastName" {...register('lastName', { required: true })} />
						</Box>
					</Box>
					<Box display="flex" flexDirection="column" sx={{ position: 'relative' }}>
						<Typography
							component="label"
							fontSize="14px"
							fontWeight={500}
							htmlFor="dateOfBirth"
							lineHeight="20px"
							sx={{ marginBottom: '8px' }}
						>
							{t('Date of birth')}
						</Typography>
						<DatePicker disableFuture name="dateOfBirth" setDateOfBirth={setDateOfBirth} />
					</Box>
				</Box>
			</Box>
			<Button
				fullWidth
				color="rsPrimary"
				data-testid="proceed-btn"
				disabled={isLoading || timeup || !isValid}
				sx={{ margin: '20px 0 0 0' }}
				type="submit"
				variant="contained"
			>
				{t('Proceed')}
			</Button>
		</Box>
	);
};

const Input = styled(InputBase)(
	() => `
    height: 40px;
    width: 100%;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    background: #FFFFFF;
  }
`
);

export default forwardRef(ReservationWithRejectedConsent);
