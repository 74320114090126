export default function divideObject(object) {
	const keys = Object.keys(object);
	const firstObject = {};
	const secondObject = {};

	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		if (i < 10) {
			firstObject[key] = object[key];
		} else {
			secondObject[key] = object[key];
		}
	}

	return { firstObject, secondObject };
}
