import { menuProps } from '../../consts/consts';
import FormSelectVariant from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/variants/FormSelectVariant';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const Gender = () => {
	const phoenixBlumeOaiPatientDemographicSync = useBooleanFlagValue('phoenix-blume-oai-patient-demographic-sync');
	const { t } = useTranslation('appointments');

	const { isUpdateForm, hasPendingCRs, genderOptions, form, helperText } = useNewAppointmentContentV2Context();

	return (
		<FormSelectVariant
			formHook={form}
			name="gender"
			options={genderOptions}
			props={{
				disabled: !!(isUpdateForm || (hasPendingCRs && phoenixBlumeOaiPatientDemographicSync)),
				label: t('Gender'),
				helperText,
				SelectProps: {
					MenuProps: menuProps,
					renderValue: option => option,
				},
			}}
			type="select"
		/>
	);
};

export default Gender;
