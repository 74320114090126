import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import { useIsTablet } from '@worklist-2/core';

const Puller = styled('div')({
	width: 80,
	height: 6,
	backgroundColor: '#1212121A',
	borderRadius: 3,
	position: 'absolute',
	top: 8,
	left: '50%',
	transform: 'translate(-50%,0)',
});

const CustomDrawer = ({
	children,
	label,
	heading,
	icon,
	buttonProps,
	isResponsive,
	isOpened,
	customOpen,
	setCustomOpen,
	onOpen,
	onClose = () => null,
	paperProps = {},
}) => {
	const [open, setOpen] = useState(isOpened || false);
	const isTablet = useIsTablet();

	useEffect(() => {
		if (onOpen && (customOpen ?? open)) {
			onOpen();
		}
	}, [customOpen, open]);

	const toggleDrawer = newOpen => () => {
		if (customOpen !== undefined && typeof setCustomOpen === 'function') {
			setCustomOpen(newOpen);
			return;
		}

		setOpen(newOpen);
	};

	return (
		<>
			{icon ? (
				<IconButton onClick={toggleDrawer(true)} {...buttonProps}>
					{icon}
				</IconButton>
			) : (
				<Button onClick={toggleDrawer(true)} {...buttonProps}>
					{label}
				</Button>
			)}
			<SwipeableDrawer
				disableSwipeToOpen
				anchor={isTablet && isResponsive ? 'bottom' : 'right'}
				open={customOpen ?? open}
				sx={{
					[`.MuiBackdrop-root`]: {
						backdropFilter: 'blur(3px)',
					},

					[`.MuiPaper-root`]: {
						width: { xs: isResponsive ? '100%' : '90%', lg: 'auto' },
						...paperProps,
					},

					[`.MuiDrawer-paperAnchorBottom`]: {
						boxSizing: 'border-box',
						borderTopLeftRadius: '20px',
						borderTopRightRadius: '20px',
						overflow: 'hidden',
						padding: { xs: '16px 16px 20px 16px', lg: 0 },
					},
				}}
				onClose={() => {
					onClose();
					toggleDrawer(false);
					setOpen(false);
				}}
				onOpen={() => null}
			>
				<Puller />
				<Box
					role="presentation"
					sx={{
						padding: { xs: '20px 10px 10px 10px', lg: '30px 20px 20px 20px' },
						width: { xs: '100%', lg: 531 },
						overflowX: 'hidden',
						overflowY: 'auto',
						position: 'relative',
						boxSizing: 'border-box',
					}}
				>
					<Box display="flex" justifyContent="space-between" sx={{ maxWidth: '100%' }}>
						<Typography
							component="h2"
							fontSize="20px"
							fontWeight={500}
							lineHeight="30px"
							sx={{ color: '#364152' }}
						>
							{heading || label}
						</Typography>
						<IconButton
							sx={{ height: '36px', width: '36px' }}
							onClick={ev => {
								toggleDrawer(false)(ev);
								if (onClose) onClose();
							}}
						>
							<CloseIcon sx={{ color: '#9AA4B2', height: '20px', width: '20px' }} />
						</IconButton>
					</Box>
					{children}
				</Box>
			</SwipeableDrawer>
		</>
	);
};

export default CustomDrawer;
