import React from 'react';
import Box from '@mui/material/Box';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import moment from 'moment';

const CalendarDay = ({ day, selectedDay, today, setSelectedDay, selectedMonth }) => {
	let disableDate = false;
	if (day < today) {
		disableDate = true;
	}
	if (moment(day).format('dddd, MMMM Do YYYY') === moment(new Date()).format('dddd, MMMM Do YYYY')) {
		disableDate = false;
	}

	const isCurrentMonth =
		moment(day).format('MMMM')?.toLowerCase() === moment(selectedMonth).format('MMMM')?.toLowerCase();

	const isSelected = day?.toDateString() === selectedDay?.toDateString();

	const isToday = day?.toDateString() === today?.toDateString();

	return (
		<Box
			data-testid="calendar-day"
			sx={{
				position: 'relative',
				width: '45px',
				height: '45px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<PickersDay
				day={day}
				disabled={disableDate}
				outsideCurrentMonth={false}
				selected={isSelected}
				sx={{
					backgroundColor: 'unset',
					color: '#364152',
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: 'unset',
					overflowY: 'hidden',
					display: isCurrentMonth ? 'block' : 'none',
					'&.MuiPickersDay-today': {
						backgroundColor: '#EBF5FE',
						border: 'none',
						color: 'var(--color-primary)',
					},
					'&.Mui-selected': {
						backgroundColor: '#EBF5FE !important',
						border: 'unset',
						color: '#364152',
						'::after': {
							content: '"."',
							position: 'absolute',
							bottom: '3px',
							left: '50%',
							transform: 'translateX(-50%)',
							width: '5px',
							height: '5px',
							borderRadius: '50%',
							backgroundColor: 'var(--color-primary)',
						},
						'&:hover, &:focus': {
							backgroundColor: '#EBF5FE !important',
							border: 'unset',
							color: '#364152',
						},
					},
				}}
				today={isToday}
				onDaySelect={setSelectedDay}
			/>
		</Box>
	);
};

export default CalendarDay;
