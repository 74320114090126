import FormFieldVariants from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/FormFieldVariants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const BirthDate = () => {
	const phoenixBlumeOaiPatientDemographicSync = useBooleanFlagValue('phoenix-blume-oai-patient-demographic-sync');
	const { t } = useTranslation('appointments');

	const { isUpdateForm, hasPendingCRs, form, formErrors, helperText } = useNewAppointmentContentV2Context();

	const birthDate = form.watch('birthDate');

	return (
		<FormFieldVariants
			isEditing
			field={{
				name: 'birthDate',
				type: 'datePicker',
				props: {
					disabled: isUpdateForm || (hasPendingCRs && phoenixBlumeOaiPatientDemographicSync),
					label: t('Date of Birth'),
					type: 'date',
					helperText: formErrors?.birthDate?.type === 'max' ? formErrors.birthDate?.message : helperText,
					sx: {
						marginTop: '20px',
						marginRight: '10px',
						minHeight: '50px',
						width: '100%',
					},
				},
				datePickerProps: {
					value: birthDate,
					disabled: isUpdateForm || (hasPendingCRs && phoenixBlumeOaiPatientDemographicSync),
					maxDate: new Date(),
					format: 'YYYY/MM/DD',
				},
			}}
			formHook={form}
		/>
	);
};

export default BirthDate;
