import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import React from 'react';
import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';

const GoogleMapBackButton = () => {
	const { onTriggerMapFullScreen, setMapFullScreen } = useNewAppointmentContentV2Context();

	return (
		<IconButton
			aria-label="back"
			name="back"
			style={{
				zIndex: '1200',
				position: 'absolute',
				top: '20px',
				left: '20px',
				background: 'white',
				borderRadius: '10px',
			}}
			onClick={() => {
				setMapFullScreen(false);
				onTriggerMapFullScreen();
			}}
		>
			<ArrowBackIcon />
		</IconButton>
	);
};

export default GoogleMapBackButton;
