import React from 'react';
import Box from '@mui/material/Box';
import { getAppointmentBadgeColor } from '../AppointmentUtils/AppointmentUtils';
import AppointmentCalendarDot from './AppointmentCalendarDot';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { mediaTabletOnly, useIsTabletOnly } from '@worklist-2/core/src/utils/responsiveUtils';

const AppointmentCalendarAppointments = ({ appointments }) => {
	const isTabletOnly = useIsTabletOnly();
	const items = appointments.slice(0, 2);

	return items.map(appointment => (
		<Box
			key={appointment.id}
			sx={{
				backgroundColor: getAppointmentBadgeColor(appointment.status),
				borderRadius: '2px',
				padding: '4px 7px',
				margin: '0 4px 3px 4px',
				[mediaTabletOnly]: {
					padding: '4px 4px',
					margin: '0 1px 3px 2px',
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					marginBottom: '6px',
					[mediaTabletOnly]: {
						marginBottom: '5px',
					},
				}}
			>
				<AppointmentCalendarDot
					appointment={appointment}
					size={isTabletOnly ? 5 : 10}
					sx={{
						marginRight: '5px',
					}}
				/>

				<Typography
					sx={{
						fontSize: '14px  !important',
						lineHeight: '16px',
						letterSpacing: '0.15px',
						color: 'rgba(18, 18, 18, 0.87)  !important',
						width: 'unset !important',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						WebkitBoxOrient: 'vertical',
						WebkitLineClamp: '1',
						[mediaTabletOnly]: {
							fontSize: '9px  !important',
						},
					}}
				>
					{moment(appointment.dateStart).format('LT')}
				</Typography>
			</Box>

			<Typography
				sx={{
					fontSize: '14px !important',
					lineHeight: '16px',
					letterSpacing: '0.15px',
					color: 'rgba(18, 18, 18, 0.6)',
					width: 'unset !important',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: '1',
					[mediaTabletOnly]: {
						width: '52px !important',
						fontSize: '12px  !important',
					},
				}}
			>
				{appointment.organization}
			</Typography>
		</Box>
	));
};

export default AppointmentCalendarAppointments;
