import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import { Divider } from '@mui/material';
import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import FormCard from '../../FormCard/FormCard';
import FormDrawer from '../../FormDrawer';
import FormDrawerV2 from '../../FormDrawer/FormDrawerV2';
import StudyCardFormsIcon from '@worklist-2/ui/src/assets/icons/StudyCardFormsIcon.svg';
import { useTranslation } from 'react-i18next';
import { useFormStore } from '../../../stores/forms';

const AppointmentForms = ({ forms, setForms, appointment, showFormIcon, anchorEl, setAnchorEl, showDivider }) => {
	const [openFormDrawer, setOpenFormDrawer] = useState(false);
	const [completedAllForms, setCompletedAllForms] = useState(false);
	const { studyFormUpdated, setAppointmentFormUpdated } = useAppointmentContext();
	const setSelectedForm = useFormStore(state => state.setSelectedForm);
	const selectedForm = useFormStore(state => state.selectedForm);
	const { t } = useTranslation('appointments');

	useEffect(() => {
		setCompletedAllForms(Boolean(forms?.filter(form => form?.completed !== true).length == 0));
	}, [forms]);

	useEffect(() => {
		updateFormData(studyFormUpdated);
	}, [studyFormUpdated]);

	const handleClickFormsIcon = useCallback(event => {
		setAnchorEl(event.currentTarget);
	}, []);

	const handleOpenForm = async data => {
		setAnchorEl(null);
		if (data) {
			setSelectedForm(data);
			setOpenFormDrawer(true);
		}
	};
	const updateFormData = upadtedForm => {
		setAppointmentFormUpdated(upadtedForm);
		const updatedForms = forms.map(form => (form.id === upadtedForm.id ? upadtedForm : form));
		const pendingForms = updatedForms.filter(item => !item?.completed);
		if (pendingForms?.length === 0) {
			setCompletedAllForms(true);
		}
		setForms(updatedForms);
	};
	const getFormIcon = useCallback(() => {
		if (showFormIcon) {
			return (
				<>
					<Tooltip title={t('Forms')}>
						<Fab
							data-testid="forms"
							sx={{
								'&:hover': {
									background: completedAllForms ? '#75B678' : '#CF6679',
								},
								bgcolor: completedAllForms ? '#75B678' : '#CF6679',
								border: '1px solid rgba(255, 255, 255, 0.2)',
								boxShadow: 0,
								zIndex: '1',
								width: '24px',
								height: '24px',
								minHeight: '24px',
								marginLeft: '8px',
							}}
							onClick={handleClickFormsIcon}
						>
							<StudyCardFormsIcon
								sx={{
									color: '#FFFFFF',
									fontSize: '1rem',
								}}
							/>
						</Fab>
					</Tooltip>
					{showDivider && (
						<Typography
							sx={{
								color: '#0000004D',
								paddingLeft: '12px',
								paddingRight: '8px',
							}}
						>
							|
						</Typography>
					)}
				</>
			);
		}
	}, [anchorEl, forms, completedAllForms, showFormIcon]);

	return (
		<>
			{appointment?.attachments?.length && (
				<Divider orientation="vertical" sx={{ height: '15px', marginRight: '2px' }} />
			)}
			{getFormIcon()}
			<Popper
				anchorEl={anchorEl}
				modifiers={[
					{
						name: 'offset',
						options: {
							offset: [-50, 0],
						},
					},
				]}
				open={Boolean(anchorEl)}
				placement="bottom"
				sx={{
					minHeight: '173px',
					minWidth: '298px',
					borderRadius: '20px',
					zIndex: '1200',
					backgroundColor: '#ffffff',
					boxShadow: '0px 4px 10px 0px #00000026',
				}}
			>
				<ClickAwayListener onClickAway={() => setTimeout(() => handleOpenForm(), 0)}>
					<Box>
						<Typography
							sx={{
								color: 'text.primary',
								fontSize: '16px',
								fontWeight: '400',
								marginTop: '10px',
								marginLeft: '20px',
								marginBottom: '14px',
							}}
						>
							{completedAllForms ? t('Filled Forms') : t('Fill in the forms')}
						</Typography>
						<Stack
							sx={{
								marginBottom: '20px',
							}}
						>
							{forms?.map((eachFrom, index) => (
								<FormCard
									key={`${index}-${eachFrom?.name}`}
									data={eachFrom}
									type="appointmentCard"
									onClick={() => {
										handleOpenForm(eachFrom);
									}}
								/>
							))}
						</Stack>
					</Box>
				</ClickAwayListener>
			</Popper>
			{openFormDrawer && (
				<FormDrawerV2
					appointment={appointment.resource}
					data={selectedForm}
					open={openFormDrawer}
					setOpen={setOpenFormDrawer}
					updateForm={updateFormData}
				/>
			)}
		</>
	);
};

export default AppointmentForms;
