import React from 'react';
import { Typography, Stack, IconButton, Icon } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CHAT_SECTIONS, { CHAT_SECTIONS_titles } from './CHAT_SECTIONS';
import ShareContactNew from '@worklist-2/patientPortal/src/components/ShareContact/ShareContactNew';
import { useChatContext } from './ChatContext';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const ChatHeading = ({ heading, sx }) => (
	<Typography component="h2" data-testid="chatAddContactHeading" sx={{ ...sx }}>
		{heading}
	</Typography>
);
const ChatAddContact = () => {
	const { t } = useTranslation('chat');
	const { setSection } = useChatContext();
	return (
		<div>
			<Stack
				alignItems="center"
				direction="row"
				gap="10px"
				sx={{
					padding:
						'var(--element-content-spacing) var(--element-content-spacing-right) 5px var(--element-content-spacing-left)',
				}}
			>
				<IconButton sx={{ flex: 'none' }} onClick={() => setSection({ name: CHAT_SECTIONS.NEW })}>
					<Icon component={ChevronLeftIcon} />
				</IconButton>

				<ChatHeading
					heading={t(`newChat.${_.camelCase(CHAT_SECTIONS_titles[CHAT_SECTIONS.ADD_CONTACT])}`)}
					sx={{
						flex: 'auto',
						color: 'var(--element-heading-foreground-color, #121212)',
						fontSize: '18px',
						fontWeight: 500,
					}}
				/>
			</Stack>

			<ShareContactNew chatSection />
		</div>
	);
};
export default ChatAddContact;
