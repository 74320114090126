import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { useHelpCenter } from '@worklist-2/core/src';
import React, { useEffect } from 'react';
import PatientHelpSearchInput from '../HelpCenter/PatientHelpSearchInput';
import PatientPortalHelpCard from '../HelpCenter/PatientPortalHelpCard';
import { Flex, HelpCard, ImageSquare } from '../HelpCenter/patientHelpStyles';
import { SubHeader } from './patientHelpPopoverStyles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useBlumeHelpCenter } from '../../../context/BlumeHelpCenterContext';

const PatientHelpPipMenu = ({ setShowingMenu }) => {
	const { currentSideTab, setCurrentSideTab, searchQuestion } = useHelpCenter();

	const onSelectArticle = data => {
		setShowingMenu(data);
	};
	const { t } = useTranslation('helpCenter');
	const { helpArticles, helpIcons } = useBlumeHelpCenter();

	useEffect(() => {
		if (currentSideTab?.includes('?')) {
			const splitSideTabValue = currentSideTab?.split('?');
			const selectedIndex = splitSideTabValue[splitSideTabValue?.length - 1];
			onSelectArticle(helpArticles.find(cat => cat.name === currentSideTab)?.children[selectedIndex]);
		}
	}, []);

	return (
		<Flex
			className="invisible-scrollbar"
			style={{
				flex: 1,
				flexDirection: 'column',
				display: 'flex',
				height: '102%',
				overflowX: 'hidden',
				padding: 20,
			}}
		>
			<PatientHelpSearchInput
				isPip={true}
				formStyle={{
					width: '450px',
					margin: '0px 40px',
					border: 'solid 1px rgba(112, 112, 112, 0.15)',
				}}
				inputStyle={{ width: 400 }}
				onSelect={v => onSelectArticle(v)}
			/>
			{!searchQuestion && (
				<>
					<SubHeader>
						<RocketLaunchIcon />
						<span>{t('BROWSE HELP CATEGORIES')}</span>
					</SubHeader>
					<div className="invisible" style={{ flex: 1, overflow: 'auto' }}>
						{helpArticles.map(
							(entry, i) =>
								entry.name !== 'all' && (
									<>
										<HelpCard
											onClick={() => setCurrentSideTab(entry.name)}
											selected={currentSideTab === entry.name}
											key={i}
											style={{
												flex: 'none',
												display: 'flex',
												flexDirection: 'row',
												padding: 0,
												margin: 5,
												borderRadius: '10px',
											}}
										>
											<ImageSquare
												src={helpIcons[entry.banner]}
												style={{
													width: '135px',
													backgroundColor: 'transparent',
												}}
											/>
											<div
												className="footer"
												style={{
													padding: 20,
													width: '100%',
													backgroundColor: '#f3f3f3',
												}}
											>
												<div className="indicator">
													{currentSideTab === entry.name && (
														<CheckCircleIcon
															style={{
																color: '#4baaf6',
															}}
														/>
													)}
												</div>
												<div className="content" style={{ width: '100%' }}>
													{entry.content}
												</div>
											</div>
										</HelpCard>
										{currentSideTab === entry.name && (
											<div style={{ marginBottom: 30 }}>
												{entry.children.map((child, j) => (
													<PatientPortalHelpCard
														key={j}
														index={j}
														category={child}
														data={child}
														isListItem={true}
														onClick={() => onSelectArticle(child)}
													/>
												))}
											</div>
										)}
									</>
								)
						)}
					</div>
				</>
			)}
		</Flex>
	);
};

PatientHelpPipMenu.propTypes = {
	setShowingMenu: PropTypes.func.isRequired,
};

export default PatientHelpPipMenu;
