import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth, useIsMobile } from '@worklist-2/core/src';
import { GenericDrawer } from '@worklist-2/ui/src/components';

import { useShareDrawerStore } from '../../stores/share-drawer';
import { useSettingsViewContext } from '../../context/SettingsViewContext';
import MobileBottomDrawer from '../MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import ShareContactList from '../ShareContact/ShareContactList';

const ShareDrawer = () => {
	const { contacts } = useAuth();
	const { isOpen, setContacts, setUser, setUsers, toggleDrawer } = useShareDrawerStore(state => ({
		isOpen: state.isOpen,
		setContacts: state.setContacts,
		setUser: state.setUser,
		setUsers: state.setUsers,
		toggleDrawer: state.toggleDrawer,
	}));
	const { user } = useSettingsViewContext();

	const { t } = useTranslation('profile');
	const isMobile = useIsMobile();

	useEffect(() => {
		// set Contacts
		if (!contacts.length) return;

		setContacts(contacts);
	}, [contacts, setContacts, setUsers]);

	useEffect(() => {
		// set User Profile details
		if (!user) return;

		setUser(user);
	}, [setUser, user]);

	return isMobile ? (
		<MobileBottomDrawer background="#ffffff" open={isOpen} textAlign="left" onClose={() => toggleDrawer(false)}>
			<ShareContactList setIsOpenShare={toggleDrawer} />
		</MobileBottomDrawer>
	) : (
		<GenericDrawer
			headerFlag
			drawerColor="rsSecondary.medium"
			drawerOpen={isOpen}
			setDrawerOpen={toggleDrawer}
			title={t('SHARE')}
			width={422}
		>
			<ShareContactList setIsOpenShare={toggleDrawer} />
		</GenericDrawer>
	);
};

export default ShareDrawer;
