// Core

import React from 'react';

// Context

const BlumeNavigationContext = React.createContext({ navigationState: {}, setNavigationState: () => {} });

// Hook

const useBlumeNavigationContext = () => React.useContext(BlumeNavigationContext);

// Component

const BlumeNavigationContextProvider = ({ children }) => {
	const [navigationState, setNavigationState] = React.useState(null);

	return (
		<BlumeNavigationContext.Provider value={{ navigationState, setNavigationState }}>
			{children}
		</BlumeNavigationContext.Provider>
	);
};

export default BlumeNavigationContextProvider;
export { useBlumeNavigationContext };
