import { useEffect } from 'react';
import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';
import { useSettingsViewContext } from '../../../../../context/SettingsViewContext';

const useSyncFormWatcher = () => {
	const { user } = useSettingsViewContext();

	const {
		isUpdateForm,
		setPage,
		form,
		appointment,
		hasLocationPermission,
		current_location_option,
		selectedProfile,
		accounts,
		setSelectedProfile,
	} = useNewAppointmentContentV2Context();

	useEffect(() => {
		if (isUpdateForm) {
			setPage('second');
			// set family member based on flag
			form.setValue(
				'notes',
				appointment?.resource?.extension?.find(item => item?.url.includes('blumeNotes'))?.valueString
			);

			return;
		}

		if (hasLocationPermission && !form.watch('userLocation')) {
			form.setValue('userLocation', current_location_option);
		}

		let initialSelectedPatient;

		if (!selectedProfile) {
			initialSelectedPatient = accounts?.find(acc => acc?.profileId === user?.profileId);

			setSelectedProfile(initialSelectedPatient?.profileId);
		} else {
			initialSelectedPatient = accounts.filter(item => item.profileId == selectedProfile)[0];
		}
		form.setValue('patient', initialSelectedPatient);
	}, [isUpdateForm, accounts, hasLocationPermission, user]);
};

export default useSyncFormWatcher;
