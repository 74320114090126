import React from 'react';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import FormTextFieldVariant from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/variants/FormTextFieldVariant';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import { ALLOWED_DOCUMENT_MIME_TYPES, ALLOWED_DOCUMENT_VIDEO_MIME_TYPES } from '../../../../../../consts';

import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';
import useHandleFiles from '../../hooks/useHandleFiles';
import FileList from '../../components/FileList';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const Notes = ({ uploadedAttachments }) => {
	const { t } = useTranslation('appointments');

	const { setToastMsg } = useToastMessageContext();
	const { filesUploaded, fileInputRef, isRequestLoading, form } = useNewAppointmentContentV2Context();

	const phoenixBlumeTurnOffVideoFileUpload = useBooleanFlagValue('phoenix-blume-turn-off-video-file-upload');

	const { onUploadFile, onDeleteFile, onSelectFile } = useHandleFiles();

	return (
		<FormTextFieldVariant
			formHook={form}
			name="notes"
			props={{
				label: t('Note'),
				placeholder: t('Leave note here'),
				multiline: true,
				rows: 5,
				sx: {
					'& .MuiOutlinedInput-root': {
						flexDirection: 'column',
						justifyContent: 'flex-end',
					},
				},
				InputProps: {
					disabled: isRequestLoading,
					endAdornment: (
						<InputAdornment
							position="end"
							sx={{
								minHeight: '20px',
								width: '100%',
								justifyContent: 'flex-end',
								'& .MuiBox-root': {
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
								},
								'& .MuiSvgIcon-root': {
									opacity: 1.6,
									color: 'grey',
								},
							}}
						>
							<Box>
								<FileList
									filesUploaded={filesUploaded}
									uploadedAttachments={uploadedAttachments}
									onDeleteFile={onDeleteFile}
									onSelectFile={onSelectFile}
								/>
								<IconButton
									aria-label="uploadPicture"
									color="primary"
									component="label"
									name="uploadPicture"
									sx={{
										marginTop: '0px !important',
										justifyContent: 'flex-end',
										color: 'black',
										opacity: '0.5',
									}}
								>
									<input
										ref={fileInputRef}
										hidden
										multiple
										accept="image/*, application/pdf, .doc,.docx"
										aria-label="uploadInput"
										name="uploadInput"
										type="file"
										onChange={ev => {
											for (const acceptedFile of ev.target.files) {
												const fileType = acceptedFile.type;

												if (
													![
														...ALLOWED_DOCUMENT_MIME_TYPES,
														...(phoenixBlumeTurnOffVideoFileUpload
															? []
															: ALLOWED_DOCUMENT_VIDEO_MIME_TYPES),
													].includes(fileType)
												) {
													setToastMsg(t('File type not supported'));
													return;
												}
											}

											onUploadFile(ev);
										}}
									/>
									<AttachFileIcon />
								</IconButton>
							</Box>
						</InputAdornment>
					),
				},
			}}
		/>
	);
};

export default Notes;
