import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const CardWrapper = ({
	Card,
	selectedCard,
	primary,
	current,
	component,
	className,
	sx,
	onClick,
	appointmentStatusChange,
	setCollapsed,
	collapsed,
	id,
	setClubNotifications,
	...props
}) => {
	const classList = [className, primary ? 'card-primary' : '', current ? 'card-current' : ''].filter(Boolean);

	const style = { ...props?.style };

	const clickDefined = typeof onClick === 'function';

	if (clickDefined) {
		style['--element-cursor'] = 'pointer';
	}

	return React.createElement(
		component || (clickDefined ? Button : Stack),
		{
			className: classList.join(' '),
			'aria-label': clickDefined ? 'button' : 'stack',
			sx: {
				...sx,
			},
			style,
			onClick,
		},
		<Card
			clubbed
			appointmentStatusChange={appointmentStatusChange}
			collapsed={collapsed}
			details={selectedCard}
			id={id}
			setClubNotifications={setClubNotifications}
			setCollapsed={setCollapsed}
		/>
	);
};

export default CardWrapper;
