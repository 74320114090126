// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI

import { Box } from '@mui/material';

// Custom

import { useChatContext } from './ChatContext';
import { CHAT_SECTIONS_titles } from './CHAT_SECTIONS';
import ChatNavigation from './ChatNavigation';
import ChatContactList from './ChatContactList';

// Component

const ChatConversationAddUsers = ({ className, sx }) => {
	const { section, setSection } = useChatContext();

	const classList = ['chat-conversation-add-users', className].filter(Boolean);

	const contacts = Array(20).fill({
		user: { name: 'Lorem ipsum' },
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris posuere nulla nec diam rutrum, ut dapibus justo cursus. Maecenas sodales nibh erat, non malesuada leo consectetur non.',
	});
	return (
		<Box
			className={classList.join(' ')}
			flex="auto"
			sx={{
				boxSizing: 'border-box',
				display: 'grid',
				gridTemplateRows: 'min-content 1fr',
				paddingBottom: 'var(--element-content-spacing)',
				...sx,
			}}
		>
			<ChatNavigation
				heading={CHAT_SECTIONS_titles.addUsers}
				onClick={() => setSection({ ...section, addUsers: false })}
			/>

			<ChatContactList
				buttonText="Add"
				checkedListMenuItems={[{ text: 'Share chat history' }, { text: "Don't share history" }]}
				contactList={contacts}
				iconButtonSx={{
					color: 'var(--color-primary)',
				}}
			/>
		</Box>
	);
};

ChatConversationAddUsers.propTypes = {
	className: PropTypes.string,
	sx: PropTypes.object,
};

export default ChatConversationAddUsers;
