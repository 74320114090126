import React, { useEffect } from 'react';
import { useSelector, mediaMobile } from '@worklist-2/core/src';
import { Box } from '@mui/system';
import BlumePatientPortalLogo from '@worklist-2/ui/src/assets/branding/png/BlumePatientPortalLogo.png';
import BlumeAuthVector from '@worklist-2/ui/src/assets/branding/png/BlumeAuthVector.png';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LeftPanel = () => {
	const { updateValue } = useSelector();
	const { i18n } = useTranslation();
	const { t } = useTranslation('login');
	const [searchParams] = useSearchParams();
	const hashID = searchParams.get('language');

	useEffect(() => {
		i18n.changeLanguage(hashID);
		updateValue('language', hashID);
	}, []);

	return (
		<Box
			className="leftPanel"
			sx={{
				fontFamily: 'Roboto',
				flexGrow: 0,
				width: '35%',
				padding: '0rem 6rem',
				justifyContent: 'center',
				display: 'flex',
				alignItems: 'normal',
				position: 'sticky',
				top: 0,
				height: '100%',
				minHeight: '95vh',
				background: 'rgba(242, 244, 247, 1)',
				'@media (max-width:1200px)': {
					display: 'none',
				},
				[mediaMobile]: {
					display: 'none',
				},
			}}
		>
			<header
				style={{ backgroundColor: 'transparent', boxShadow: 'none', display: 'none', fontFamily: 'Roboto' }}
			>
				<div className="header">
					<div className="headerLogo">
						<a href="https://www.ramsoft.com/" id="ramsoftlinkid" rel="noreferrer" target="_blank">
							<img alt="Blume Patient Portal Logo" className="ramSoftLogo" src={BlumePatientPortalLogo} />
						</a>
					</div>
					{/* <div className="headerButtons">
            <button id="closepopup" className="headerButton btn" tabIndex="0" style={{ display: 'none !important' }}>
            </button>
          </div> */}
				</div>
			</header>
			<div
				className="leftPanelContent"
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: '50px',
				}}
			>
				<table
					id="container"
					style={{
						height: '100%',
						borderCollapse: 'collapse',
					}}
				>
					<tr>
						<td valign="top">
							<div className="textBox">
								<a
									href="https://www.ramsoft.com/"
									id="ramsoftlinkid"
									rel="noreferrer"
									style={{ display: 'flex', padding: '3rem 0', paddingBottom: '2rem' }}
									target="_blank"
								>
									<img
										alt="Blume Patient Portal Logo"
										className="ramSoftLogo"
										src={BlumePatientPortalLogo}
										style={{ height: '43px' }}
									/>
								</a>
								<p
									className="textBoxHeading"
									style={{
										fontWeight: 500,
										fontFamily: 'Roboto',
										fontSize: '20px',
										lineHeight: '24px',
										letterSpacing: '0.1em',
										color: '#667085',
										margin: '0 0 10px',
									}}
								>
									{t('BLUME FEATURES')}
								</p>
								<Box
									sx={{
										'& li': {
											fontSize: '20px',
											fontWeight: 500,
											lineHeight: '32px',
											letterSpacing: 'normal',
											color: '#667085',
											fontFamily: 'Roboto',
											marginBottom: '10px',
										},
										'& li::marker': {
											content: '"- "',
											fontSize: '1.2em',
										},
									}}
								>
									<ul
										className="list"
										style={{
											padding: '10px',
										}}
									>
										<li className="listItem">{t('View medical images and reports')}</li>
										<li className="listItem">{t('Schedule your appointments')}</li>
										<li className="listItem">{t('Upload images and documents')}</li>
										<li className="listItem">
											{t('Share your record with physicians and loved ones')}
										</li>
									</ul>
								</Box>
							</div>
						</td>
					</tr>
					<tr>
						<td valign="bottom">
							<div className="blumeVectorContainer">
								<img
									alt="Blume Authentication Pages Vector"
									className="blumeAuthVector"
									src={BlumeAuthVector}
									style={{ width: '100%' }}
								/>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</Box>
	);
};

export default LeftPanel;
