// Core
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ChatCallParticipantVideo = ({ participant }) => {
	const videoRef = useRef();
	const screenRef = useRef();

	useEffect(() => {
		if (participant) {
			if (participant?.videoTracks) {
				const videoTracks = Array.from(participant?.videoTracks.values())
					.map(publication => publication.track)
					.filter(track => track !== null);

				const screenTrack = videoTracks[0];
				const videoTrack = videoTracks[1];
				if (videoTrack) {
					videoTrack.attach(videoRef.current);

					return () => {
						videoTrack.detach();
					};
				}
				if (screenTrack) {
					screenTrack.attach(screenRef.current);

					return () => {
						screenTrack.detach();
					};
				}
			}
		}
	}, [participant]);

	// TODO - Chat - revert to this flag and fix the video and screen share issue when we want to enable video
	// const size = proactEnableVideoCalling ? '50px' : '100%';
	const size = false ? '50px' : '100%';

	return (
		<video
			// id={proactEnableVideoCalling ? 'videoShare' : 'screenShare'}
			// ref={proactEnableVideoCalling ? videoRef : screenRef}
			ref={false ? videoRef : screenRef}
			height={size}
			id={false ? 'videoShare' : 'screenShare'}
			width={size}
		/>
	);
};

ChatCallParticipantVideo.propTypes = {
	participant: PropTypes.object,
};

export default ChatCallParticipantVideo;
