import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material';
import { useSettingsViewContext } from '../../context/SettingsViewContext';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ClearIcon from '@mui/icons-material/Clear';
import UserDetailDrawer from '../../views/NewSettingsView/Components/UserDetailDrawer/UserDetailDrawer';
import UserBmiDetailDrawer from '../../views/NewSettingsView/Components/UserDetailDrawer/UserBmiDetailDrawer';
import axios from 'axios';
import { useConfig, useIsTablet, useAuth } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import DateTime from '../DateTime';
import Chip from '@mui/material/Chip';
import moment from 'moment';
import { useNewAppointmentContentV2Context } from '../Appointment/AppointmentDrawer/NewAppointmentContentV2/contexts/NewAppointmentContentV2ContextProvider';
import ClubbedNotification from './ClubbedNotification';
import EmergencyContactNotification from './EmergencyContactNotification';
import { useToastMessageContext } from '../../context/ToastMessageContext';
import { useUserInfoStore } from '../../stores';
import _ from 'lodash';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

export const textStyle = { fontSize: '14px', fontWeight: '400', lineHeight: '20px', testTransform: 'none' };

export const toSentenceCase = camelCase => {
	if (camelCase == 'SSN') return 'ssn';
	if (camelCase) {
		const result = camelCase.replace(/([A-Z])/g, ' $1');
		const text = result[0].toUpperCase() + result.substring(1).toLowerCase();

		return text;
	}
	return '';
};

export const norAcceptedRejected = not =>
	Boolean(
		!not?.status &&
			not?.properties?.status?.toLowerCase() !== 'accepted' &&
			not?.properties?.status?.toLowerCase() !== 'rejected' &&
			not?.properties?.status?.toLowerCase() !== 'updated'
	);

export const handleCheckboxClick = (e, id, setSelectedClubbed) => {
	e.preventDefault();
	e.stopPropagation();
	setSelectedClubbed(prev => {
		if (prev.includes(id)) {
			return prev.filter(d => d !== id);
		}
		return [...prev, id];
	});
};

const OaiPatientSyncNotification = ({
	details,
	appointmentStatusChange,
	clubbed,
	setNotifications = Function.prototype,
	setCollapsed = Function.prototype,
	collapsed = false,
	id,
	setClubNotifications = Function.prototype,
	expandAppointmentView,
	onNext = Function.prototype,
	hideNext = false,
	setHasPendingCRs: setHasPendingCRsV2 = Function.prototype,
}) => {
	const notificationDetails = clubbed ? details[0] : details;
	const [loading, setLoading] = useState();
	const __config = useConfig();
	const { profiles } = useAuth();
	const { onProfileSelection, userUpdateNotification, emergencyContacts, getUserInfo } = useSettingsViewContext();
	const getUserInfoInStore = useUserInfoStore(state => state.getUserInfo);
	const { setHasPendingCRs } = useNewAppointmentContentV2Context();
	const { setToastMsg } = useToastMessageContext();
	const profile = profiles[notificationDetails?.properties?.userHash];
	const [isOpenDetail, setIsOpenDetail] = useState(false);
	const [isOpenBmi, setIsOpenBmi] = useState(false);
	const [status, setStatus] = useState(null);
	const [showLoader, setShowLoader] = useState(false);
	const [showEmergencyContactDetail, setShowEmergencyContactDetail] = useState(!!expandAppointmentView);
	const [selectedClubbed, setSelectedClubbed] = useState(clubbed ? details?.map((_d, i) => i) : []);
	const [v2PayloadStore, setV2PayloadStore] = useState(null);
	const [showNotificationLoader, setShowNotificationLoader] = useState(false);
	const isTablet = useIsTablet();
	const isEmergencyContact =
		notificationDetails?.properties?.NotificationType === 'OAI_PATIENT_DATA_SYNC' ||
		notificationDetails?.properties?.notificationType === 'OAI_PATIENT_DATA_SYNC';

	const getField = not =>
		isEmergencyContact
			? 'emergencyContacts'
			: not.properties.fieldName == 'SSN'
			? 'ssn'
			: not?.properties?.fieldName?.charAt(0).toLowerCase() + not?.properties?.fieldName.slice(1);

	const field = useMemo(() => getField(notificationDetails), [notificationDetails, isEmergencyContact]);

	const phoenixBlumeOaiPatientDemographicSync = useBooleanFlagValue('phoenix-blume-oai-patient-demographic-sync');
	const { t } = useTranslation('notifications');
	const getPatientName = () => {
		if (profile) {
			return `${profile.firstName} ${profile.lastName}`;
		}
		return 'NA';
	};
	const [clubbedNotificationDetails, setClubbedNotificationDetails] = useState([]);

	//grouping data by organization
	const groupNotificationsByOrganization = data =>
		data.reduce((acc, item) => {
			const { organization } = item.properties;
			if (!acc[organization]) {
				acc[organization] = [];
			}
			acc[organization].push(item);
			return acc;
		}, {});
	useEffect(() => {
		if (clubbed && details) {
			const groupedData = groupNotificationsByOrganization(details);
			const groupedNotifications = Object.entries(groupedData).map(([organization, items]) => ({
				organization,
				data: items,
			}));
			setClubbedNotificationDetails(groupedNotifications);
			setSelectedClubbed(groupedNotifications.flatMap(item => item.data.map(i => i.id)));
		}
	}, [clubbed, details]);

	useEffect(() => {
		if (collapsed && showEmergencyContactDetail) {
			setShowEmergencyContactDetail(false);
		}
	}, [collapsed]);

	const { changeValue, operation } = notificationDetails?.properties || {};

	const onAccept = _details => {
		setShowLoader(true);
		setShowNotificationLoader(true);
		if (isEmergencyContact) {
			let updatedDetails = {};
			if (operation === 'update') {
				(Array.isArray(JSON.parse(changeValue)) ? JSON.parse(changeValue) : []).forEach(d => {
					updatedDetails[d?.fieldName?.toLowerCase()] = d?.newValue;
					updatedDetails.emergencyContactId = _details?.properties?.uniqueId;
				});
			} else if (operation === 'add') {
				updatedDetails = JSON.parse(changeValue);
			} else if (operation === 'delete') {
				updatedDetails = _details?.properties?.uniqueId;
			}
			const updatedUser = onProfileSelection(
				{ ...profile, profileId: _details?.properties?.userHash },
				{
					update: operation === 'update' ? updatedDetails : null,
					add: operation === 'add' ? updatedDetails : null,
					delete: operation === 'delete' ? updatedDetails : null,
				}
			);
			handleAfterAccept(_details, updatedUser);
			return;
		}
		const updatedUser = onProfileSelection({ ...profile, profileId: _details?.properties?.userHash });
		handleAfterAccept(_details, updatedUser);
	};
	const onRejected = _details => {
		if (isEmergencyContact) updateNotification('rejected');
		else if (_details.properties.oldValue) updateNotification('rejected');
		else {
			onProfileSelection({ ...profile, profileId: details?.properties?.userHash });
			openDetailDrawersOnReject(_details);
		}
	};

	const openDetailDrawersOnReject = _details => {
		const _feild = getField(_details);
		if (
			_details?.properties?.fieldName?.toLowerCase() === 'height' ||
			_details?.properties?.fieldName?.toLowerCase() === 'weight'
		) {
			setIsOpenBmi(_feild);
		} else {
			setIsOpenDetail(_feild);
		}
	};

	const updateNotification = updateStatus => {
		setShowLoader(true);
		setShowNotificationLoader(true);
		if (phoenixBlumeOaiPatientDemographicSync && v2PayloadStore) {
			handleAcceptRejectBulkNotifications(v2PayloadStore, true, true);
			setV2PayloadStore(null);
		}
		// Update the profiles object here
		const url = `${__config.data_sources.blume}User/UpdateChangeRequest`;
		axios
			.put(
				url,
				{
					userHash: notificationDetails?.properties?.userHash,
					notificationId: notificationDetails?.id,
					patientId: notificationDetails?.properties?.patientId,
					fieldName: isEmergencyContact
						? notificationDetails?.properties?.modelName
						: notificationDetails?.properties?.fieldName,
					status: updateStatus,
					uniqueId: isEmergencyContact ? JSON.parse(changeValue)?.emergencyContactId : null,
				},
				{
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json-patch+json',
					},
				}
			)
			.then(res => {
				if (res?.status === 200) {
					if (appointmentStatusChange) {
						// For the appointments drawer
						appointmentStatusChange(notificationDetails?.id);
					}
					setStatus(updateStatus);
				}
				setShowLoader(false);
				setShowNotificationLoader(false);
			})
			.catch(error => {
				console.error('Error in change request : ', error);
				setShowLoader(false);
				setShowNotificationLoader(false);
			});
	};

	const handleAfterAccept = (_details, updatedUser) => {
		const newValue = ['ethnicity', 'race', 'language'].includes(field)
			? _details.properties.newValue.split('|').map(value => value.trim())
			: field === 'emergencyContacts'
			? updatedUser?.emergencyContacts
			: _details.properties.newValue;
		userUpdateNotification(field, newValue, {
			...updatedUser,
			[field == 'addressLine1' ? 'address' : field]: newValue,
		})
			.then(() => {})
			.catch(() => {
				console.error(err);
			});
		updateNotification('accepted');
		setShowLoader(false);
		setShowNotificationLoader(false);
	};

	const buttonStyle = {
		borderRadius: '8px',
		width: '78px',
		height: expandAppointmentView ? '35px' : '30px',
		fontSize: expandAppointmentView ? '14px' : '12px',
		fontWeight: '500',
		letterSpacing: '1.25px',
		whiteSpace: 'nowrap',
		textTransform: expandAppointmentView && 'none',
	};

	const accepted = status === 'accepted' || notificationDetails.properties?.status?.toLowerCase() === 'accepted';
	const rejected =
		status === 'rejected' ||
		notificationDetails.properties?.status?.toLowerCase() === 'rejected' ||
		status === 'updated' ||
		notificationDetails.properties?.status?.toLowerCase() === 'updated';

	function formatRelativeDate(dateStr) {
		const date = moment(dateStr);
		const today = moment().startOf('day');
		const yesterday = today.clone().subtract(1, 'day');

		return date.isSame(today, 'd') ? 'Today' : date.isSame(yesterday, 'd') ? 'Yesterday' : date.format('DD MMM YY');
	}

	const hasPendingAcceptReject = () => Boolean(!clubbed || details?.some(d => !d?.status));

	function triggerAcceptRejectBulkNotifications(ids, isReject, afterUpdate) {
		setLoading(isReject ? 'reject' : 'accept');
		setShowNotificationLoader(true);
		setShowLoader(true);
		const url = `${__config.data_sources.blume}User/handleBulkNotificationsV2`;
		axios
			.post(
				url,
				{
					userHash: notificationDetails?.properties?.userHash,
					notificationIds: ids,
					operation: afterUpdate ? 'Updated' : isReject ? 'Rejected' : 'Accepted',
				},
				{
					headers: {
						Accept: '*/*',
						'Content-Type': 'application/json',
					},
				}
			)
			.then(res => {
				const _status = isReject ? 'rejected' : 'accepted';
				if (res.status === 200 || res.status === 404) {
					if (clubbed && !expandAppointmentView) {
						setClubNotifications(prev => {
							prev[id] = (prev[id] || [])?.map(d => {
								if (ids.includes(d?.id)) d.status = _status;
								return d;
							});
							(expandAppointmentView ? setHasPendingCRsV2 : setHasPendingCRs)(
								Boolean(prev?.some(d => d?.length && d?.some(r => norAcceptedRejected(r))))
							);
							return prev;
						});
					} else {
						setNotifications(prev =>
							prev?.map(d => {
								if (expandAppointmentView ? ids.includes(d?.id) : d?.id === ids[0]) {
									d.status = _status;
									if (d?.properties) d.properties.status = _status;
								}
								return d;
							})
						);
					}
				}
				setLoading();
				setSelectedClubbed([]);
				setShowLoader(false);
				if (!clubbed) setStatus(_status);
				getUserInfo();
				getUserInfoInStore({ __config });
				setShowNotificationLoader(false);
				setToastMsg(
					t(`${afterUpdate ? 'Updated' : isReject ? 'Rejected' : 'Accepted'} the change successfully!`)
				);
			})
			.catch(error => {
				console.error(error);
				setLoading();
				setShowLoader(false);
				setShowNotificationLoader(false);
				setToastMsg(error.message || `Unable to ${isReject ? 'reject' : 'accept'} the action!`);
			});
	}

	const handleAcceptRejectBulkNotifications = (ids, isReject, afterUpdate) => {
		const isEC = d =>
			d?.properties?.NotificationType === 'OAI_PATIENT_DATA_SYNC' ||
			d?.properties?.notificationType === 'OAI_PATIENT_DATA_SYNC';
		if (isReject && !afterUpdate) {
			if (isOpenBmi || isOpenDetail) return;
			if (clubbed) {
				const selectedDetails = details.filter((_d, id) => selectedClubbed.includes(id));
				if (selectedDetails?.some(d => !d.properties.oldValue && !isEC(d))) {
					openDetailDrawersOnReject(selectedDetails?.find(d => !d.properties.oldValue && !isEC(d)));
				} else {
					triggerAcceptRejectBulkNotifications(ids, isReject, afterUpdate);
				}
			} else if (!notificationDetails?.properties?.oldValue && !isEmergencyContact) {
				openDetailDrawersOnReject(notificationDetails);
			} else {
				triggerAcceptRejectBulkNotifications(ids, isReject, afterUpdate);
			}
			setV2PayloadStore(ids);
			return;
		}
		triggerAcceptRejectBulkNotifications(ids, isReject, afterUpdate);
	};

	const handleAcceptReject = isReject => {
		if (!clubbed) {
			if (phoenixBlumeOaiPatientDemographicSync) {
				handleAcceptRejectBulkNotifications([notificationDetails?.id], isReject);
			} else {
				isReject ? onRejected(notificationDetails) : onAccept(notificationDetails);
			}
		} else if (phoenixBlumeOaiPatientDemographicSync) {
			handleAcceptRejectBulkNotifications(
				details.filter(item => selectedClubbed.includes(item.id)).map(d => d?.id),
				isReject
			);
		} else {
			const promises = details
				.filter((_d, i) => selectedClubbed.includes(i))
				.map(d => (isReject ? onRejected(d) : onAccept(d)));
			Promise.all(promises);
		}
	};

	const handleAccept = () => handleAcceptReject();
	const handleReject = () => handleAcceptReject(true);

	const disableAcceptReject = useMemo(
		() => (!!clubbed && !selectedClubbed?.length) || !!loading || !!showNotificationLoader,
		[loading, showNotificationLoader, selectedClubbed]
	);

	const spanStyle = { color: '#42A5F5', cursor: 'pointer', marginLeft: 5 };

	// function that returns the span element
	const renderSpanElement = () => {
		const handleSpanClick = e => {
			e.preventDefault();
			e.stopPropagation();
			setCollapsed(false);
			setShowEmergencyContactDetail(val => !val);
		};

		const getSpanText = () => {
			if (showEmergencyContactDetail) {
				return t('less');
			}
			return t(details?.length > 1 || isEmergencyContact ? t('details') : t('detail'));
		};

		return (
			<span style={spanStyle} onClick={handleSpanClick}>
				{getSpanText()}
			</span>
		);
	};

	return (
		<Box
			sx={{
				maxWidth: isTablet ? '100%' : !expandAppointmentView ? '448px' : '100%',
				background: '#f9f9f9',
				padding: !expandAppointmentView && '16px',
				borderRadius: '10px',
				boxShadow: !isTablet && '-4px -4px 40px 0px #0000000D',
				color: '#121212',
				textTransform: 'none',
				textAlign: 'left',
				position: 'relative',
				paddingTop: clubbed && '10px',
			}}
		>
			{clubbed && (
				<Chip
					label={formatRelativeDate(notificationDetails?.generationTime)}
					sx={{
						height: '22px',
						fontSize: expandAppointmentView ? 13 : 12,
						position: 'absolute',
						top: expandAppointmentView ? '15px' : '6px',
						right: !expandAppointmentView && '5px',
						left: expandAppointmentView && '15px',
						padding: '0px 0px',
						background: '#42A5F510',
						color: '#42A5F5',
						fontWeight: 400,
						borderRadius: expandAppointmentView && '6px',
						border: expandAppointmentView && '1.5px solid var(--color-primary)',
					}}
				/>
			)}
			{showLoader && (
				<Skeleton
					sx={{
						borderRadius: '10px',
						width: '100%',
						height: '100%',
						display: 'inline-block',
						left: 0,
						zIndex: 100,
						position: 'absolute',
						top: 0,
					}}
					variant="rectangular"
				/>
			)}
			{accepted || rejected ? (
				<Grid container>
					<Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={2}>
						<Box
							sx={{
								background: rejected ? '#CF667914' : '#EEEEFB',
								height: '36px',
								width: '36px',
								borderRadius: '18px',
								padding: '4px',
								display: 'flex',
								justifyContent: 'center',
								marginRight: '5px',
							}}
						>
							{rejected ? (
								<ClearIcon sx={{ color: '#CF6679', width: '24px', height: 'auto' }} />
							) : (
								<ThumbUpOffAltIcon
									sx={{ color: '#B0A6DC', width: '24px', height: 'auto', transform: 'scale(-1,1)' }}
								/>
							)}
						</Box>
					</Grid>
					<Grid item xs={8}>
						<Typography
							sx={{
								fontSize: '14px',
								fontWeight: '400',
								lineHeight: '20px',
							}}
						>
							<b>{`${getPatientName()}'s`}</b>{' '}
							{t(toSentenceCase(notificationDetails.properties.fieldName).slice(1))}{' '}
							{t('profile change from')} {notificationDetails.properties.organization} {t('is')}{' '}
							{rejected ? t('rejected') : t('accepted')}
						</Typography>
					</Grid>
					<Grid item pt={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }} xs={2}>
						<DateTime
							datetime={new Date(notificationDetails.generationTime)}
							fontColor="#393939"
							format="long"
							sx={{
								paddingRight: '7px',
								fontSize: '12px',
								lineHeight: '16px',
								fontWeight: '400',
								letterSpacing: -0.2,
								whiteSpace: 'nowrap',
								marginBottom: '-13px',
							}}
							type="relative_Blume"
						/>
					</Grid>
				</Grid>
			) : (
				<Box
					sx={{
						paddingTop: expandAppointmentView ? '40px' : '18px',
					}}
				>
					{isEmergencyContact || clubbed ? (
						notificationDetails.properties.operation == 'update' ||
						notificationDetails.properties.operation == 'add' ||
						clubbed ? (
							<>
								{clubbed ? (
									clubbedNotificationDetails?.map(({ organization, data }, index) => (
										<div
											key={index}
											style={{
												marginBottom: '10px',
												padding: expandAppointmentView && '0px 16px',
											}}
										>
											<Typography sx={textStyle}>
												{`${organization} ${t('is trying to')} ${
													isEmergencyContact
														? t('update your ')
														: data?.properties?.operation == 'add'
														? t('add')
														: t('change')
												} `}
												<u>
													{t(
														isEmergencyContact
															? `emergency contact information`
															: `personal information`
													)}
												</u>{' '}
												{index === clubbedNotificationDetails?.length - 1 &&
													renderSpanElement()}
											</Typography>
											<ClubbedNotification
												details={data}
												emergencyContacts={emergencyContacts}
												expandAppointmentView={expandAppointmentView}
												getPatientName={getPatientName}
												handleCheckboxClick={(s, id) =>
													handleCheckboxClick(s, id, setSelectedClubbed)
												}
												selectedClubbed={selectedClubbed}
												show={showEmergencyContactDetail}
												t={t}
											/>
										</div>
										//add space for each item of the map data
									))
								) : (
									<div>
										<Typography sx={textStyle}>
											{`${details?.properties?.organization} ${t('is trying to')}${
												details?.properties?.operation == 'add' ? t('add') : t('change')
											} `}
											<b>{`${getPatientName()}'s `}</b>
											<u>
												{t(
													isEmergencyContact
														? `emergency contact information`
														: `personal information`
												)}
											</u>{' '}
											<b>
												{isEmergencyContact
													? (notificationDetails.properties.operation == 'add'
															? typeof changeValue === 'object'
																? changeValue?.name
																: t(JSON.parse(changeValue)?.name)
															: emergencyContacts?.find(
																	d =>
																		d?.emergencyContactId ===
																		notificationDetails?.properties?.uniqueId
															  )?.name) || ''
													: ''}
											</b>
											{renderSpanElement()}
										</Typography>
										<EmergencyContactNotification
											changeValue={changeValue}
											notificationDetails={notificationDetails}
											show={showEmergencyContactDetail}
										/>
									</div>
								)}
							</>
						) : (
							<Typography sx={textStyle}>
								{`${notificationDetails.properties.organization} ${t('is trying to')} ${t(
									notificationDetails.properties.operation
								)} `}
								<b>{`${getPatientName()}'s `}</b>
								<u>{t('emergency contact')}</u>{' '}
								{typeof changeValue === 'object' ? changeValue?.name : t(JSON.parse(changeValue)?.name)}
							</Typography>
						)
					) : (
						<Typography sx={textStyle}>
							{`${notificationDetails.properties.organization} ${t('is trying to change')} `}
							<b>{`${getPatientName()}'s `}</b>
							<u>{t(toSentenceCase(notificationDetails.properties.fieldName).slice(1))}</u> {t('from')}{' '}
							{t(notificationDetails.properties.oldValue) || 'NA'} {t('to')}{' '}
							{t(notificationDetails.properties.newValue)}
						</Typography>
					)}
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							mt: expandAppointmentView && '10px',
							borderTop: expandAppointmentView && '1px solid #EAECF0',
							padding: expandAppointmentView && '15px 15px 15px 0px',
						}}
					>
						{expandAppointmentView && (
							<Button
								sx={{
									color: 'var(--color-primary)',
									textTransform: 'none',
									visibility: hideNext ? 'hidden' : 'visible',
								}}
								variant="text"
								onClick={onNext}
							>
								Next
							</Button>
						)}
						{hasPendingAcceptReject() && (
							<Box
								sx={{
									marginTop: !expandAppointmentView && '20px',
									display: 'flex',
									flex: 1,
									gap: expandAppointmentView && '10px',
									justifyContent: expandAppointmentView ? 'flex-end' : 'space-between',
								}}
							>
								<Button
									disabled={disableAcceptReject}
									id="reject-btn"
									sx={{
										color: 'var(--color-primary)',
										borderColor: 'var(--color-primary)',
										...buttonStyle,
									}}
									variant="outlined"
									onClick={handleReject}
								>
									{loading === 'reject' ? (
										<CircularProgress color="inherit" size={20} />
									) : (
										t('Reject')
									)}
								</Button>
								<Button
									disabled={disableAcceptReject}
									id="accept-btn"
									sx={{
										background: 'var(--color-primary)',
										...buttonStyle,
									}}
									variant="contained"
									onClick={handleAccept}
								>
									{loading === 'accept' ? <CircularProgress size={20} /> : t('Accept')}
								</Button>
							</Box>
						)}
					</Box>
				</Box>
			)}
			{/* To update all the patient information */}
			<UserDetailDrawer
				isOaiNotification
				fieldChangeRequest={isOpenDetail}
				isOpenDetail={Boolean(isOpenDetail)}
				setIsOpenDetail={setIsOpenDetail}
				updateNotification={updateNotification}
			/>

			{/* To update only the BMI */}
			<UserBmiDetailDrawer
				isOaiNotification
				fieldChangeRequest={isOpenBmi}
				isOpenDetail={Boolean(isOpenBmi)}
				setIsOpenDetail={setIsOpenBmi}
				updateNotification={updateNotification}
			/>
		</Box>
	);
};

export default OaiPatientSyncNotification;
