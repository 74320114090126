// Core
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { Box, IconButton, Typography, Tooltip } from '@mui/material';

// MUI icons
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';

// Custom components
import ChatCallingAvatar from './ChatCallingAvatar';
import { useChatGlobalContext } from '@worklist-2/ui/src/components/Chat/ChatGlobalContext';
import { useAuth, useConfig } from '@worklist-2/core/src';
import { joinCall, endCall, muteAudio } from './ConversationHelper';

// Assets
import TriangleIcon from '@worklist-2/ui/src/assets/icons/Chat/triangle.svg';

import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const ChatCallingBubble = ({ onBubbleClick, onChatClick, chatIsOpen, remoteParticipantName, isCaller, isBlume }) => {
	const {
		setSection,
		setConversationType,
		state,
		callData,
		setCallData,
		callRoom,
		setCallRoom,
		callParticipants,
		setCallParticipants,
		setScreenShare,
		appBlumeMode,
	} = useChatGlobalContext();

	const __config = useConfig();
	const { loggedInUser } = useAuth();
	const proactEnableVideoCalling = useBooleanFlagValue('proact-enable-video-calling');
	const { t } = useTranslation('chat');

	const handleEndCall = () => {
		endCall(
			callRoom,
			setCallRoom,
			setCallData,
			setCallParticipants,
			setSection,
			setConversationType,
			setScreenShare,
			callParticipants
		);
	};

	const handleAcceptCall = () => {
		joinCall(
			appBlumeMode,
			callData,
			setCallData,
			state,
			setSection,
			setConversationType,
			__config,
			loggedInUser,
			callRoom,
			setCallRoom,
			proactEnableVideoCalling,
			setCallParticipants,
			callParticipants,
			setScreenShare,
			false,
			chatIsOpen,
			onChatClick
		);
	};

	const handleMuteAudio = () => {
		muteAudio(setCallData, callRoom, callData);
	};

	const displayText = (label, sx) => (
		<Typography
			sx={{
				color: 'rgba(255, 255, 255, 0.6)',
				fontSize: '12px',
				lineHeight: '14px',
				fontWeight: '400px',
				...sx,
			}}
		>
			{label}
		</Typography>
	);

	const toolTip = (element, title) => <Tooltip title={title ?? ''}>{element}</Tooltip>;

	return (
		<Box
			data-testid="chatCallingBubble"
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
			}}
		>
			<Box
				sx={{
					background: 'rgba(255, 255, 255, 0.05)',
					borderRadius: '20px',
					width: '305px',
					height: '70px',
					backgroundColor: '#272727',
					boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.5)',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					pl: '10px',
				}}
			>
				<Box sx={{ display: 'contents' }} onClick={onBubbleClick}>
					<ChatCallingAvatar
						avatarSx={{
							label: remoteParticipantName,
							color: isBlume && 'white',
						}}
						isCalling={callData?.status?.incoming}
					/>
					<Box
						data-testid="participantCallStatus"
						flexGrow={1}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'left',
							pl: '10px',
						}}
					>
						{isCaller && callData?.status?.calling
							? displayText(`${t('call.ringing')}...`, { mb: '6px', mt: '6px' })
							: callData?.status?.incoming
							? displayText(
									callData?.callNotification?.caller?.role
										? callData.callNotification.caller.role
												.split(' ')
												.map(word => word[0].toUpperCase() + word.substring(1)?.toLowerCase())
												.join(' ')
										: '',
									{
										mb: '6px',
										mt: '6px',
									}
							  )
							: null}
						<Typography
							sx={{
								color: 'rgba(255, 255, 255, 0.8)',
								fontSize: '14px',
								lineHeight: '16px',
								fontWeight: '500px',
								mb: '6px',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								width: '112px',
								whiteSpace: 'nowrap',
							}}
						>
							{remoteParticipantName}
						</Typography>
						{callData?.status?.onCall && displayText(t('call.inCallWithYou'))}
					</Box>
				</Box>
				<Box>
					{callData?.status?.onCall &&
						toolTip(
							<IconButton
								sx={{
									height: '32px',
									width: '32px',
									backgroundColor: '#4f4d4d',
									mr: '5px',
								}}
								onClick={handleMuteAudio}
							>
								{callData?.audioIsMute ? (
									<MicOffOutlinedIcon data-testid="micOffIcon" sx={{ color: isBlume && 'white' }} />
								) : (
									<MicNoneOutlinedIcon data-testid="micOnIcon" sx={{ color: isBlume && 'white' }} />
								)}
							</IconButton>,
							callData?.audioIsMute ? t('icon.unmute') : t('icon.mute')
						)}
					{toolTip(
						<IconButton
							data-testid="endCallIcon"
							sx={{
								height: '30px',
								width: '30px',
								backgroundColor: '#FF6666',
								mr: callData?.status?.onCall || callData?.status?.calling ? '20px' : '5px',
							}}
							onClick={handleEndCall}
						>
							<CallEndOutlinedIcon sx={{ height: '20px', width: '20px', color: isBlume && 'white' }} />
						</IconButton>,
						!callData?.status?.onCall && !isCaller ? t('icon.reject') : t('icon.endCall')
					)}
					{callData?.status?.incoming &&
						!isCaller &&
						toolTip(
							<IconButton
								data-testid="acceptCallIcon"
								sx={{
									height: '30px',
									width: '30px',
									backgroundColor: '#32C26C',
									mr: '20px',
									ml: isCaller ? '20px' : '5px',
								}}
								onClick={handleAcceptCall}
							>
								<CallOutlinedIcon sx={{ height: '20px', width: '20px', color: isBlume && 'white' }} />
							</IconButton>,
							t('icon.answer')
						)}
				</Box>
			</Box>
			<TriangleIcon style={{ display: isBlume && 'none' }} />
		</Box>
	);
};

ChatCallingBubble.propTypes = {
	/**
	 * A callback function opening the Chat drawer
	 */
	onChatClick: PropTypes.func,
	/**
	 * A boolean value determining of the Chat drawer is opened or not
	 */
	chatIsOpen: PropTypes.bool,
	/**
	 * The remote participant name that is displayed on the bubble
	 */
	remoteParticipantName: PropTypes.string,
	/**
	 * A bool value determining if the logged in user is the caller
	 */
	isCaller: PropTypes.bool,
};

export default ChatCallingBubble;
