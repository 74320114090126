import axios from 'axios';

const addAttachments = async ({ __config, appointmentId, userHash, internalStudyId, fileData }) => {
	const response = await axios.post(
		`${__config.data_sources.blume}Appointment/attachmentv2?appointmentId=${appointmentId}&userHash=${userHash}&internalStudyId=${internalStudyId}`,
		fileData
	);

	if (!response?.status?.toString()?.startsWith(2)) {
		return null;
	}

	return response.data;
};

export default addAttachments;
