import moment from 'moment-timezone';

const getTimeFromMins = mins => {
	const h = (mins / 60) | 0;
	const m = mins % 60 | 0;

	return moment.utc().hours(h).minutes(m).format('hh:mm A');
};

export { getTimeFromMins };
