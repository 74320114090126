import { Box, borderBottom } from '@mui/system';
import React from 'react';
import { mediaTablet, mediaMobile } from '@worklist-2/core/src';
import NewMainFooter from '@worklist-2/ui/src/components/Footer/NewMainFooter';
import SocialSignup from './SocialSignUp';
import { FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BlumePatientPortalLogo from '@worklist-2/ui/src/assets/branding/png/BlumePatientPortalLogo.png';

const RightPanel = ({ children }) => {
	const { t } = useTranslation('login');
	return (
		<Box
			className="rightPanel"
			data-testid="rightPanel"
			sx={{
				fontFamily: 'Roboto',
				flexGrow: 0,
				width: '100%',
				justifyContent: 'flex-start',
				display: 'flex',
				height: '100%',
				minHeight: '100vh',
				background: 'white',
				flexDirection: 'column',
				padding: '0px',
				[mediaMobile]: {
					padding: '0 10px',
				},
			}}
		>
			{/* footer */}
			<NewMainFooter
				loginMode
				linkStyle={{
					color: '#121212',
					opacity: '.87',
					marginTop: '1px',
					[mediaMobile]: {
						fontSize: '10px',
					},
				}}
			/>
			<Box
				sx={{
					padding: '17px',
					margin: '0 auto',
					maxWidth: '560px',
					width: '100%',
					[mediaTablet]: {
						maxWidth: '560px',
						width: '100%',
					},
					[mediaMobile]: {
						maxWidth: '400px',
						width: '100%',
						padding: '17px 0px',
					},
				}}
			>
				<Box
					sx={{
						width: '100%',
						alignItems: 'start',
						display: 'grid',
						[mediaMobile]: {
							marginLeft: 'unset',
						},
						'.ramSoftLogo': {
							height: '32px',
							width: '107px',
							display: 'none',
							'@media (max-width:1200px)': {
								display: 'block',
								marginTop: '10px',
							},
						},
					}}
				>
					<a href="https://www.ramsoft.com/" id="ramsoftlinkid" rel="noreferrer" target="_blank">
						<img alt="Blume Patient Portal Logo" className="ramSoftLogo" src={BlumePatientPortalLogo} />
					</a>
					<FormLabel
						sx={{
							fontSize: '34px',
							lineHeight: '56px',
							letterSpacing: '-0.5px',
							color: '#121212',
							opacity: '0.87',
							fontWeight: '600',
							marginTop: '20px',
							[mediaTablet]: {
								fontSize: '24px',
								lineHeight: '28px',
							},
						}}
					>
						{t('Hi there! Welcome back')}
					</FormLabel>
					<FormLabel
						sx={{
							marginTop: '6px',
							letterSpacing: '0.18px',
							lineHeight: '24px',
							color: 'rgba(71, 84, 103, 1)',
							fontStyle: 'normal',
							fontWeight: '500',
							[mediaTablet]: {
								fontSize: '16px',
								lineHeight: '24px',
								fontWeight: '400',
							},
							[mediaMobile]: {
								display: 'flex',
								'flex-direction': 'column',
							},
						}}
					>
						{t('Sign in to use Blume')}
					</FormLabel>
				</Box>
				{/* <SocialSignup/> */}
				{children}
			</Box>
		</Box>
	);
};
export default RightPanel;
