import axios from 'axios';

const getValueSetByName = async ({ __config, name }) => {
	const fieldName = `name:exact=${name}&_count=1000`;

	try {
		const response = await axios.get(
			`${__config.data_sources.blume}ValueSet?criteria=${encodeURIComponent(fieldName)}`
		);

		const codes = response?.data?.entry[0]?.resource?.compose?.include[0]?.concept;
		const displayName = ['language', 'ethnicity', 'race'].includes(name) ? codes : codes.map(item => item.display);
		return displayName;
	} catch (error) {
		console.error(`Failed to fetch country. reason: ${error}`);
		return [];
	}
};

export default getValueSetByName;
