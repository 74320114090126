// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI

import { Button } from '@mui/material';

// Custom

import { useTranslation } from 'react-i18next';

// Component

const ChatRemoveButton = ({ onClick }) => {
	const { t } = useTranslation('chat');
	return (
		<Button
			size="small"
			sx={{
				color: '#cf6679',
				fontSize: '12px',
				fontWeight: 400,
				textTransform: 'none',
			}}
			onClick={onClick}
		>
			{t('chatConversation.remove')}
		</Button>
	);
};

ChatRemoveButton.propTypes = {
	onClick: PropTypes.func.isRequired,
};

export default ChatRemoveButton;
