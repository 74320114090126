import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { ReactComponent as UploadIconSvg } from '../../assets/icons/uploadIcon.svg';
import FileUploadOutlined from '@mui/icons-material/FileUploadOutlined';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import { useImportTabContext, mediaTablet } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

function FileUpload({ isBlume, handleInputFiles }) {
	const { filesUploading } = useImportTabContext();
	const onDrop = useCallback(
		acceptedFiles => {
			const files = [];
			for (const acceptedFile of acceptedFiles) {
				files.push(acceptedFile);
			}
			handleInputFiles(files);
		},
		[filesUploading]
	);

	const handleUpload = e => {
		e.preventDefault();
		const files = [];
		for (const acceptedFile of e.target.files) {
			files.push(acceptedFile);
		}
		handleInputFiles(files);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	const bgColor = isBlume ? '#D1D1D1' : '#2c2c2c';
	const { t } = useTranslation('profile');

	return (
		<div
			className={classnames({ importSection: !isBlume }, { importSectionBlume: isBlume })}
			style={{ width: '100%' }}
		>
			{isMobile ? (
				<div className={classnames({ importContent: !isBlume }, { importContentBlume: isBlume })}>
					<div className={classnames({ content: !isBlume }, { contentBlume: isBlume })}>
						<input
							multiple
							className={classnames({ addFiles: !isBlume }, { addFilesBlume: isBlume })}
							data-testid="uploadInput"
							name="files"
							type="file"
							onChange={handleUpload}
						/>
						{isBlume ? (
							<>
								<FileUploadOutlined sx={{ fontSize: 30 }} />
								<Typography
									className="importTextBlume"
									sx={{
										fontSize: 18,
										[mediaTablet]: {
											fontSize: 20,
										},
									}}
								>
									{t('Upload files')}
								</Typography>
								<Typography
									className="importTextBlume"
									sx={{
										opacity: '0.5 !important',
										fontSize: 14,
										[mediaTablet]: {
											fontSize: 12,
										},
									}}
								>
									{t('Supported file format are JPEG, PNG, PDF, Mp4')}
								</Typography>
							</>
						) : (
							<>
								<UploadIconSvg
									style={{
										height: '100px',
										width: '100px',
										marginBottom: '10px',
									}}
									width="100%"
								/>
								<Typography className="importText" fontSize={24}>
									{isMobile ? (
										''
									) : (
										<>
											<br />
											{t('Drag and drop folders or')}
										</>
									)}
									<span>{t('click to browse files')}</span>
								</Typography>
							</>
						)}
					</div>
				</div>
			) : (
				<div
					className={classnames({ importContent: !isBlume }, { importContentBlume: isBlume })}
					{...getRootProps()}
					style={{ backgroundColor: isDragActive && bgColor }}
				>
					<div className={classnames({ content: !isBlume }, { contentBlume: isBlume })}>
						<input
							multiple
							className={classnames({ addFiles: !isBlume }, { addFilesBlume: isBlume })}
							data-testid="uploadInput"
							name="files"
							type="file"
							{...getInputProps()}
						/>
						{isBlume ? (
							<>
								<FileUploadOutlined sx={{ fontSize: 30 }} />
								<Typography
									className="importTextBlume"
									sx={{
										fontSize: 18,
										[mediaTablet]: {
											fontSize: 20,
										},
									}}
								>
									<>
										{t('Drag and drop folders or')} <br />
										<span>{t('click to browse files')}</span>
									</>
								</Typography>
								<Typography
									className="importTextBlume"
									sx={{
										opacity: '0.5 !important',
										fontSize: 14,
										[mediaTablet]: {
											fontSize: 12,
										},
									}}
								>
									{t('Supported file format are JPEG, PNG, PDF, Mp4')}
								</Typography>
							</>
						) : (
							<>
								<UploadIconSvg
									style={{
										height: '200px',
										width: '200px',
										marginBottom: '20px',
									}}
									width="100%"
								/>
								<Typography className="importText" fontSize={24}>
									{t('Drag and drop folders or')} <br />
									<span>{t('click to browse files')}</span>
								</Typography>
							</>
						)}
					</div>
				</div>
			)}
		</div>
	);
}

FileUpload.defaultProps = {
	isBlume: false,
};

FileUpload.propTypes = {
	handleInputFiles: PropTypes.func,
};

export default FileUpload;
