import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import AppointmentCalendarBigDay from './AppointmentCalendarBigDay';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import { MonthPicker, YearPicker } from '@mui/x-date-pickers';
import { mediaTabletOnly } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';
import { en, fr, es } from 'date-fns/locale';

const AppointmentCalendarBig = () => {
	const { appointments, selectedDay, setSelectedDay, today, selectedMonth, setSelectedMonth } =
		useAppointmentContext();

	const displayDay = selectedDay || today;
	const [highlightedDay, setHighlightedDay] = useState();
	const [anchorEl, setAnchorEl] = useState();
	const [isMonthPicker, setIsMonthPicker] = useState(true);
	const { t, i18n } = useTranslation('appointments');

	const getCurrentLanguage = () => {
		if (i18n?.language === 'en') return en;
		if (i18n?.language === 'fr') return fr;
		if (i18n?.language === 'es') return es;
	};

	const selectDay = day => {
		setSelectedDay(day);
		setHighlightedDay(day);
	};

	const renderDay = day => (
		<AppointmentCalendarBigDay
			appointments={appointments.filter(
				appointment => appointment.dateStart.toDateString() === day.toDateString()
			)}
			day={day}
			selectedDay={highlightedDay}
			selectedMonth={selectedMonth}
			setSelectedDay={selectDay}
			today={today}
		/>
	);

	const CustomAdapter = options => {
		const adapter = new AdapterDateFns(options);

		const constructDayObject = day => ({
			charAt: () => day,
		});

		return {
			...adapter,

			getWeekdays() {
				const customWeekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map(day => t(day));

				return customWeekdays.map(day => constructDayObject(day));
			},
		};
	};

	return (
		<Box
			data-testid="bigCalendar"
			sx={{
				background: 'linear-gradient(133.7deg, #B6DEFF 5.27%, rgba(66, 165, 245, 0) 31.29%)',
				padding: '1px',
				borderRadius: '10px',
				flex: '1',
				overflow: 'hidden',
			}}
		>
			<Box
				sx={{
					background: '#FCFCFC',
					borderRadius: '10px',
					paddingTop: '26px',
					boxShadow: 'inset 5px 4px 20px rgba(66, 165, 245, 0.08)',
					width: '100%',
					height: '100%',
					boxSizing: 'border-box',
					[mediaTabletOnly]: { paddingTop: '20px' },
				}}
			>
				<Box
					sx={{
						width: '100%',
						height: '100%',
						'& .MuiCalendarPicker-root': {
							width: '100%',
							height: '94%',
							maxHeight: 'unset',
						},
						'& .MuiCalendarPicker-root > div': {
							padding: '0',
							flex: '1',
							'&:first-child': {
								display: 'none',
							},
						},
						'& .MuiPickersDay-root': {
							fontWeight: 400,
						},
						'& .PrivatePickersFadeTransitionGroup-root': {
							fontSize: '20px',
							lineHeight: '23px',
							letterSpacing: '0.15px',
							color: 'rgba(18, 18, 18, 0.6)',
							'& > div': {
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								'& > div': {
									'& .MuiTypography-root': {
										fontSize: '16px',
										color: 'rgba(18, 18, 18, 0.6)',
										flex: '1',
									},
								},
							},
						},
						'& .PrivatePickersSlideTransition-root': {
							minHeight: 'unset',
							height: '100%',
							'& > div': {
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								'& > div': {
									flex: '1',
									margin: '0',
									borderBottom: '1px solid rgba(18, 18, 18, 0.2)',
									'&:first-child': {
										borderTop: '1px solid rgba(18, 18, 18, 0.2)',
									},
									'&:last-child': {
										border: 'none',
									},
									'& > div': {
										borderLeft: '1px solid rgba(18, 18, 18, 0.2)',
										'&:first-child': {
											border: 'none',
										},
									},
								},
							},
						},
					}}
				>
					<Box
						data-testid="bigCalendarHeader"
						sx={{
							display: 'flex',
							padding: '0 31px',
							marginBottom: '10px',
							alignItems: 'center',
							[mediaTabletOnly]: { padding: '0 16px' },
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								marginRight: '10px',
							}}
						>
							<Typography
								key={displayDay}
								sx={{
									fontWeight: '500',
									fontSize: '32px',
									lineHeight: '37.5px',
									letterSpacing: '0.15px',
									marginRight: '10px',
									color: '#42A5F5',
									[mediaTabletOnly]: {
										fontSize: '24px',
										lineHeight: '28.13px',
									},
								}}
							>
								{t(moment(displayDay).format('MMMM'))}
							</Typography>

							<Typography
								sx={{
									fontWeight: '400',
									fontSize: '32px',
									lineHeight: '37.5px',
									letterSpacing: '0.15px',
									color: '#42A5F5',
									[mediaTabletOnly]: {
										fontSize: '24px',
										lineHeight: '28.13px',
									},
								}}
							>
								{moment(displayDay).format('YYYY')}
							</Typography>
						</Box>

						<IconButton onClick={e => (anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget))}>
							<Tooltip title={t('Select Year/Month')}>
								<ArrowDropDownIcon
									sx={{
										color: '#42A5F5',
										transform: anchorEl ? 'rotate(180deg)' : '',
										transition: '0.2s',
									}}
								/>
							</Tooltip>
						</IconButton>

						<Popper
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							sx={{
								zIndex: '100',
							}}
						>
							<Box
								sx={{
									background: '#fff',
									boxShadow: '0px 4px 8px 2px rgba(0, 0, 0, 0.08)',
									borderRadius: '6px',
									width: '350px',
									height: '310px',
									overflow: 'auto',
									padding: '12px',
									boxSizing: 'border-box',
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										marginBottom: '15px',
									}}
								>
									<Typography
										sx={{
											fontSize: '16px',
											lineHeight: '24px',
											letterSpacing: '0.15px',
											color: 'rgba(18, 18, 17, 0.87)',
										}}
									>
										{t(moment(displayDay).format('MMMM'))}
									</Typography>

									<IconButton onClick={() => setIsMonthPicker(!isMonthPicker)}>
										<Tooltip title={t('Select Month')}>
											<ArrowDropDownIcon
												sx={{
													color: 'rgba(18, 18, 17, 0.87)',
													opacity: '0.6',
													transform: isMonthPicker ? 'rotate(180deg)' : '',
													transition: '0.2s',
												}}
											/>
										</Tooltip>
									</IconButton>

									<Typography
										sx={{
											fontSize: '16px',
											lineHeight: '24px',
											letterSpacing: '0.15px',
											color: 'rgba(18, 18, 17, 0.87)',
										}}
									>
										{moment(displayDay).format('YYYY')}
									</Typography>

									<IconButton onClick={() => setIsMonthPicker(!isMonthPicker)}>
										<Tooltip title={t('Select Year')}>
											<ArrowDropDownIcon
												sx={{
													color: 'rgba(18, 18, 17, 0.87)',
													opacity: '0.6',
													transform: !isMonthPicker ? 'rotate(180deg)' : '',
													transition: '0.2s',
												}}
											/>
										</Tooltip>
									</IconButton>
								</Box>

								<Box
									sx={{
										maxHeight: '240px',
										overflow: 'auto',
										'& .MuiMonthPicker-root': {
											margin: '0 auto',
										},
										'& .PrivatePickersMonth-root': {
											width: '50px',
											height: '50px',
											flex: 'unset',
											borderRadius: '50%',
											margin: '5px 26px',
											'&.Mui-selected': {
												backgroundColor: '#42A5F5',
											},
										},
										'& .PrivatePickersYear-root': {
											'& .Mui-selected': {
												backgroundColor: '#42A5F5',
											},
										},
									}}
								>
									<LocalizationProvider dateAdapter={AdapterDateFns} locale={getCurrentLanguage()}>
										{isMonthPicker ? (
											<MonthPicker date={displayDay} onChange={selectDay} />
										) : (
											<YearPicker
												date={displayDay}
												minDate={moment().subtract(5, 'years').toDate()}
												onChange={setSelectedDay}
											/>
										)}
									</LocalizationProvider>
								</Box>
							</Box>
						</Popper>

						<Box
							sx={{
								flex: '1',
							}}
						/>

						<Button
							sx={{
								height: '24px',
								fontSize: '16px',
								lineHeight: '16px',
								letterSpacing: '0.15px',
								fontVariant: 'small-caps',
								textTransform: 'lowercase',
								borderRadius: '6px',
								marginRight: '10px',
								backgroundColor: '#42A5F51A',
								color: '#42A5F5',
								[mediaTabletOnly]: {
									fontSize: '14px',
									lineHeight: '16.41px',
								},
							}}
							onClick={() => {
								setSelectedDay(today);
							}}
						>
							{t('Today')}
						</Button>

						<IconButton
							data-testid="prevMonthButton"
							onClick={() => {
								setSelectedDay(moment(displayDay).add(-1, 'month').toDate());
							}}
						>
							<Tooltip title={t('Prev')}>
								<KeyboardArrowLeftIcon
									sx={{
										color: 'rgba(18, 18, 18, 0.6)',
									}}
								/>
							</Tooltip>
						</IconButton>

						<IconButton
							data-testid="nextMonthButton"
							onClick={() => {
								setSelectedDay(moment(displayDay).add(1, 'month').toDate());
							}}
						>
							<Tooltip title={t('Next')}>
								<KeyboardArrowRightIcon
									sx={{
										color: 'rgba(18, 18, 18, 0.6)',
									}}
								/>
							</Tooltip>
						</IconButton>
					</Box>

					<LocalizationProvider dateAdapter={CustomAdapter}>
						<CalendarPicker
							date={displayDay}
							renderDay={renderDay}
							onChange={setSelectedDay}
							onMonthChange={setSelectedMonth}
						/>
					</LocalizationProvider>
				</Box>
			</Box>
		</Box>
	);
};

export default AppointmentCalendarBig;
