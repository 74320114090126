import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Icon from '@mui/material/Icon';
import SearchIcon from '@mui/icons-material/Search';
import { Dialog, DialogContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIsMobile } from '@worklist-2/core/src';

import { useScheduleStore } from '../../../../stores';

const useStyles = makeStyles()(() => ({
	backdrop: {
		backdropFilter: 'blur(3px)',
		backgroundColor: 'rgba(0, 0, 30, 0.4)',
	},
	dialog: {
		position: 'fixed',
		left: '50%',
		transform: 'translateX(-50%)',
		bottom: '0',
		width: '100%',
		height: '230px',
		maxWidth: '100%',
		margin: 0,
	},
}));

const ModalityScreen = ({ form }, ref) => {
	const nextStep = useScheduleStore(state => state.nextStep);
	const modalities = useScheduleStore(state => state.modalities);
	const getModalities = useScheduleStore(state => state.getModalities);
	const isLoading = useScheduleStore(state => state.isLoading);
	const [searchKeyword, setSearchKeyword] = useState('');
	const { getValues, setValue, watch } = form;
	const [dialogOpen, setDialogOpen] = useState(true);
	const isMobile = useIsMobile();

	const { classes } = useStyles();

	const closeDialog = () => {
		setDialogOpen(false);
	};

	const switchApp = () => {
		if (navigator.userAgent.includes('Safari/') && !navigator.userAgent.includes('Chrome/')) {
			window.open('https://apps.apple.com/in/app/blume-omegaai/id1645542099', '_blank');
		} else {
			window.open('https://play.google.com/store/apps/details?id=com.omegaai.blume&hl=en_IN&gl=US', '_blank');
		}
	};

	const [searchParams] = useSearchParams();
	const { t } = useTranslation('scheduling');

	const orgId = searchParams.get('orgId');

	// Watch for modality form state change
	watch('modality');

	useEffect(() => {
		getModalities({ orgId });
	}, [getModalities, orgId]);

	const setModalityOption = useCallback(
		value => {
			setValue('modality', value);
		},
		[setValue]
	);

	const gotToNextStep = useCallback(() => {
		nextStep();
	}, [nextStep]);

	const filteredModalities = useMemo(() => {
		if (!searchKeyword) return modalities;

		const matches = modalities.filter(
			({ Name, DicomModalities }) =>
				Name?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
				Name?.replace(/[-\s]/g, '').toLowerCase().includes(searchKeyword.replace(/[-\s]/g, '').toLowerCase()) ||
				DicomModalities?.toString().toLowerCase().includes(searchKeyword.toLowerCase())
		);
		const selectedModality = modalities.filter(({ Name }) => Name === getValues().modality);

		// If selected modality is not included in search result, Add it to the top
		return matches.includes(selectedModality[0]) ? [...matches] : [...selectedModality, ...matches];
	}, [getValues, modalities, searchKeyword]);

	const showSearch = modalities.length > 4;
	const noResultsFound =
		searchKeyword && !isLoading ? (
			<Box
				data-testid="search-no-results"
				display="flex"
				flexDirection="column"
				gap="16px"
				sx={{ margin: 'auto', textAlign: 'center' }}
			>
				<Icon
					sx={{
						width: '40px',
						height: '40px',
						padding: '10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: '4px',
						border: '1px solid #eaecf0',
						boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
						margin: 'auto',
					}}
				>
					<SearchIcon sx={{ width: '20px', height: '20px' }} />
				</Icon>
				<Box>
					<Typography fontWeight={600} lineHeight="24px" sx={{ marginBottom: '4px' }}>
						{t('No results found')}
					</Typography>
					<Typography fontSize="14px" lineHeight="20px" sx={{ color: '#475467' }}>
						{t('We couldn’t find any relevant matches for your search')}
					</Typography>
				</Box>
				<Button
					color="rsPrimary"
					data-testid="clear-search-btn"
					sx={{ fontWeight: 500, whiteSpace: 'nowrap', padding: '8px 12px', margin: 'auto' }}
					variant="outlined"
					onClick={() => setSearchKeyword('')}
				>
					{t('Clear search')}
				</Button>
			</Box>
		) : null;

	return (
		<Box
			ref={ref}
			display="flex"
			flexDirection="column"
			gap="28px"
			sx={{
				maxWidth: '700px',
				margin: 'auto',
				background: '#fff',
				padding: '32px 32px 36px 32px',
				boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
			}}
		>
			{showSearch ? (
				<Box>
					<Input
						data-testid="search-term-input"
						placeholder={t('Search for study types')}
						startAdornment={
							<Box
								sx={{
									margin: '8px',
									display: 'inline-flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<svg fill="none" height="20" viewBox="0 0 20 20" width="20">
									<path
										d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
										stroke="#667085"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="1.66667"
									/>
								</svg>
							</Box>
						}
						value={searchKeyword}
						onChange={ev => setSearchKeyword(ev.target.value)}
					/>
				</Box>
			) : null}
			<Typography component="h2" fontWeight={500} sx={{ color: '#475467' }}>
				{searchKeyword ? t('Search Results') : t('Popular')}
			</Typography>
			<Box display="flex" flexDirection="column" gap="20px">
				{filteredModalities.length && !isLoading
					? filteredModalities.map(({ Name }) => (
							<OptionButton
								key={Name}
								disableRipple
								active={getValues().modality === Name}
								data-testid="modality-option"
								onClick={() => setModalityOption(Name)}
							>
								{Name}
							</OptionButton>
					  ))
					: noResultsFound}
				{isLoading ? (
					<div>
						<Skeleton
							data-testid="modality-loader"
							sx={{
								height: '260px',
								maxWidth: '100%',
								borderRadius: '10px',
							}}
							variant="rectangular"
						/>
					</div>
				) : null}
			</Box>
			<Box display="flex" justifyContent="flex-end" sx={{ margin: '28px 0 0 0' }}>
				{filteredModalities.length ? (
					<Button
						color="rsPrimary"
						data-testid="modality-next-btn"
						disabled={!getValues().modality}
						sx={{
							textTransform: 'uppercase',
							boxShadow: 'none',
							padding: '12px 14px',
							width: { xs: '100%', lg: '150px' },
						}}
						type="button"
						variant="contained"
						onClick={gotToNextStep}
					>
						{t('Next')}
					</Button>
				) : null}
			</Box>
			{isMobile && (
				<Dialog
					BackdropProps={{ classes: { root: classes.backdrop } }}
					PaperProps={{ width: '100%' }}
					classes={{ paper: classes.dialog }}
					open={dialogOpen}
				>
					<DialogContent
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							padding: '32px 20px',
						}}
					>
						<Box>
							<Typography
								sx={{
									fontFamily: 'Roboto',
									fontSize: '16px',
									fontWeight: '500',
									color: 'rgba(16, 24, 40, 1)',
								}}
							>
								Experience is better on the app
							</Typography>
							<Typography
								sx={{
									color: 'rgba(52, 64, 84, 1)',
									fontFamily: 'Roboto',
									fontSize: '14px',
									fontWeight: '400',
								}}
							>
								Never miss an update on your appointments. Keep track of your health scans. Open this in
								the Blume app to get the full experience
							</Typography>
						</Box>

						<Box
							sx={{
								width: '100%',
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Button
								data-testid="not-now"
								sx={{
									width: '120px',
									height: '42px',
									borderRadius: '8px',
									border: '2px solid #42A5F5',
									color: '#42A5F5',
									fontSize: '16px',
								}}
								variant="outlined"
								onClick={closeDialog}
							>
								Not now
							</Button>
							<Button
								sx={{
									width: '190px',
									height: '42px',
									borderRadius: '8px',
									backgroundColor: '#42A5F5',
									fontSize: '16px',
									padding: '12px 14px',
								}}
								variant="contained"
								onClick={switchApp}
							>
								Switch to the app
							</Button>
						</Box>
					</DialogContent>
				</Dialog>
			)}
		</Box>
	);
};

const Input = styled(InputBase)`
	display: flex;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	border: 1px solid #d0d5dd;
	background: #fff;
	height: 44px;
`;

const OptionButton = styled(ButtonBase)(
	({ active }) => `
	height: 50px;
  display: flex;
  align-items: center;
	justify-content: flex-start;
  padding: 16px;
  border-radius: 8px;
  border: ${active ? '2px solid #42A5F5' : '1px solid #D0D5DD'};
	transition: background 250ms ease-in-out;
  color: #101828;
  font-weight: 600;
	background: ${active ? '#F3FAFF' : 'transparent'};
	font-family: Roboto;

  &:hover {
    // 
  }
`
);

export default forwardRef(ModalityScreen);
