import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import { mediaTabletOnly, useConfig } from '@worklist-2/core/src';

import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import AppointmentCardTopLine from './AppointmentCardTopLine';
import AppointmentCardDateSection from './AppointmentCardDateSection';
import AppointmentCardDate from './AppointmentCardDate';
import AppointmentCardBadge from './AppointmentCardBadge';
import AppointmentCardTitle from './AppointmentCardTitle';
import AppointmentCardList from './AppointmentCardList';
import AppointmentCardButtonShare from './AppointmentCardButtonShare';
import AppointmentCardActionButtons from './AppointmentCardActionButtons';
import AppointmentCardWrapper from './AppointmentCardWrapper';
import AppointmentCardExclamationMarkSection from './AppointmentCardExclamationMarkSection';
// import AppointmentCardRiskAssessment from './AppointmentCardRiskAssessment';
import CancelAppointment from './CancelAppointment';
import { AppointmentNotes } from './AppointmentButtons';
import AppointmentForms from './AppointmentForms';
import { useFormStore } from '../../../stores/forms';
import { AppointmentGenericBottomDrawer } from '../AppointmentDrawer/AppointmentDrawerButtonBook';

const parkIndexExtUrl = 'http://www.ramsoft.com/fhir/extension/appointment/parkingIndex';

const AppointmentCard = ({ appointment, setDocument }) => {
	const { isFullscreen, patchAppointment } = useAppointmentContext();
	const [renderCancelReason, setRenderCancelReason] = useState(false);
	const { setToastMsg } = useToastMessageContext();
	const [anchorEl, setAnchorEl] = useState(null);
	const [showFormIcon, setShowFormIcon] = useState(false);
	const [highlight, setHighlight] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);

	const [forms, setForms] = useState([]);
	const { t } = useTranslation('appointments');
	const __config = useConfig();
	const { getFormsByPatientId } = useFormStore(state => ({
		getFormsByPatientId: state.getFormsByPatientId,
	}));

	const shouldRenderDate = appointment.status !== 'booking';
	const shouldRenderExclamationMark = appointment.status === 'booking';
	const shouldRenderButtons =
		appointment.status !== 'cancelled' &&
		appointment.status !== 'requested' &&
		!renderCancelReason &&
		moment(new Date().toLocaleString('en-US', { timeZone: 'US/Eastern' })).isBefore(
			appointment?.dateStart,
			'second'
		);
	const shouldRenderShareButton =
		appointment.status === 'scheduled' || appointment.status === 'progressing' || appointment.status === 'complete';

	const shouldShowDivider = !(
		(appointment.resource.extension?.some(item => item?.url?.includes('isReScheduleCancelHidden') ?? false) ??
			false) &&
		appointment.resource.status === 'booked'
	);

	const handleOnSubmitAction = async () => {
		const status = await patchAppointment(appointment.id, [
			{
				op: 'add',
				path: '/status',
				value: 'booked',
			},
		]);

		if (status === 'SUCCESS') {
			setToastMsg(t('Appointment confirmed'));
		}
	};

	const handleOnCancelButton = () => {
		setRenderCancelReason(true);
	};

	useEffect(() => {
		let patientId = null;
		let studyId = null;
		const participant = appointment.resource?.participant;
		const supportingInformation = appointment.resource?.supportingInformation;
		if (participant) {
			patientId = participant
				?.find(item => item?.actor?.reference.toLowerCase().includes('patient'))
				?.actor.reference.split('/')[1];
		}
		if (supportingInformation) {
			studyId = supportingInformation[0]?.id;
		}
		if (patientId) {
			fetchFormData(patientId, studyId);
		}
	}, [appointment]);

	useEffect(() => {
		if (!openDrawer) setHighlight(null);
	}, [openDrawer]);

	const fetchFormData = async (patientId, studyId) => {
		if (patientId) {
			const result = await getFormsByPatientId({ __config, patientId });
			const filteredForms = result?.filter(
				item =>
					item.resource?.type === 'registration' ||
					(item.resource?.type === 'clinic' && item?.resource?.study?.id == studyId)
			);
			const transformedForms = filteredForms?.map(item => ({
				id: item.resource.id,
				patient: item.resource?.patient,
				completed: item.resource?.completed,
				organizationId: item.resource.organizationId,
				status: item.resource.status.charAt(0).toUpperCase() + item.resource.status.slice(1),
				title: item.resource.name,
				type: item.resource.type,
				description: item.resource.description,
				questionData: item.resource.formTemplate?.content,
				lastModified: item.resource.lastModified,
				resource: item.resource,
				referenceTemplate: item.resource?.referenceTemplate,
				pdfFileName: item.resource.pdfFileName,
			}));
			setForms(transformedForms);
			if (transformedForms?.length > 0) {
				setShowFormIcon(true);
			}
		}
	};

	const isParked = useMemo(() => {
		const { extension } = appointment.resource;

		if (!extension) return false;

		const parkingIndex = extension.find(ext => ext.url === parkIndexExtUrl);

		return !!parkingIndex;
	}, [appointment]);

	const parkedStatus = useMemo(() => (isParked ? 'parked' : null), [isParked]);

	return (
		<AppointmentCardWrapper highlight={highlight === appointment?.id} status={appointment.status}>
			<AppointmentCardTopLine
				highlight={highlight === appointment?.id}
				status={appointment.status || parkedStatus}
			/>
			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				{shouldRenderDate &&
					(isParked ? (
						''
					) : (
						<AppointmentCardDateSection date={appointment.dateStart} status={appointment.status} />
					))}

				<Box
					sx={{
						margin: '0px 0px 10px 23px',
						width: 1,
						[mediaTabletOnly]: isFullscreen
							? { margin: '0px 0px 10px 4px' }
							: { margin: '0px 0px 10px 24px' },
					}}
				>
					<Box
						sx={{
							display: 'flex',
						}}
					>
						{shouldRenderExclamationMark && (
							<AppointmentCardExclamationMarkSection status={appointment.status} />
						)}

						<Box>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: '1px',
								}}
							>
								{shouldRenderDate &&
									(isParked ? (
										''
									) : (
										<AppointmentCardDate
											dateEnd={appointment.dateEnd}
											dateStart={appointment.dateStart}
										/>
									))}

								<AppointmentCardBadge status={appointment.status || parkedStatus} />
							</Box>
							{appointment?.organizationTimeZone && (
								<Typography
									sx={{
										fontSize: '14px',
										lineHeight: '24px',
										minHeight: '24px',
										color: 'rgba(18, 18, 18, 0.87)',
										letterSpacing: '0.15px',
									}}
								>
									{`(${t(`timezones.${appointment?.organizationTimeZone}`)})`}
								</Typography>
							)}
							<AppointmentCardTitle
								organization={appointment.organization}
								sx={{
									margin: isParked ? '7px 0 11px 0' : null,
								}}
							/>
						</Box>
					</Box>

					<AppointmentCardList appointment={appointment} />
				</Box>
			</Box>
			{renderCancelReason && (
				<CancelAppointment
					appointment={appointment}
					patchAppointment={patchAppointment}
					setRenderCancelReason={setRenderCancelReason}
				/>
			)}
			{shouldRenderShareButton && <AppointmentCardButtonShare />}

			<Box
				className={anchorEl ? '' : 'AppointmentCardHoverButton'}
				sx={{
					display: anchorEl ? 'flex' : 'none',
					alignItems: 'center',
					justifyContent: 'end',
				}}
			>
				{!renderCancelReason && (
					<AppointmentNotes attachments={appointment?.attachments} onClick={setDocument} />
				)}
				{!renderCancelReason && showFormIcon && (
					<AppointmentForms
						anchorEl={anchorEl}
						appointment={appointment}
						forms={forms}
						setAnchorEl={setAnchorEl}
						setForms={setForms}
						showDivider={shouldShowDivider && shouldRenderButtons}
						showFormIcon={showFormIcon}
					/>
				)}
				{shouldRenderButtons && (
					<AppointmentCardActionButtons
						appointment={appointment}
						handleOnCancelButton={handleOnCancelButton}
						handleOnSubmitAction={handleOnSubmitAction}
						openDrawer={openDrawer}
						setHighlight={() => isFullscreen && setHighlight(appointment?.id)}
						setOpenDrawer={setOpenDrawer}
					/>
				)}
			</Box>
			<AppointmentGenericBottomDrawer
				appointment={appointment}
				openDrawer={openDrawer}
				setOpenDrawer={setOpenDrawer}
				title="Reschedule Appointment"
			/>
		</AppointmentCardWrapper>
	);
};

export default AppointmentCard;
