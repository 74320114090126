import React from 'react';
import AppointmentDrawerStickyTop from './AppointmentDrawerStickyTop';
import AppointmentDrawerNavigation, { AppointmentHeader } from './AppointmentDrawerNavigation';
import Box from '@mui/material/Box';
import AppointmentCalendarBig from '../AppointmentCalendar/AppointmentCalendarBig';
import { useIsTabletOnly, mediaTabletOnly } from '@worklist-2/core/src';

const AppointmentDrawerContentFullscreen = () => {
	const isTabletOnly = useIsTabletOnly();
	return (
		<>
			{isTabletOnly ? (
				<Box
					sx={{
						marginLeft: '31px',
						marginRight: '21px',
						[mediaTabletOnly]: {
							paddingTop: '24px',
							marginLeft: 0,
							marginRight: 0,
						},
					}}
				>
					<AppointmentHeader showExpandIcon />
				</Box>
			) : (
				<AppointmentDrawerStickyTop title="Scheduler" />
			)}
			<Box
				sx={{
					display: 'flex',
					marginLeft: '48px',
					marginTop: '19px',
					marginRight: '38px',
					flexDirection: 'row',
					height: 'calc(100vh - 120px)',
					[mediaTabletOnly]: {
						flexDirection: 'row-reverse',
						margin: '19px 16px 16px 16px',
					},
				}}
			>
				<AppointmentDrawerNavigation />

				<AppointmentCalendarBig />
			</Box>
		</>
	);
};

export default AppointmentDrawerContentFullscreen;
