import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { fr } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import Box from '@mui/material/Box';
import { mediaTablet, useIsTablet } from '@worklist-2/core/src/utils/responsiveUtils';

import moment from 'moment';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { YearPicker } from '@mui/x-date-pickers';
import Tooltip from '@mui/material/Tooltip';
import AppointmentCalendarSmallDay from '../../../Appointment/AppointmentCalendar/AppointmentCalendarSmallDay';
import { useTranslation } from 'react-i18next';

const Calendar = ({ date, setDate }) => {
	const isTablet = useIsTablet();
	const [openToView, setOpenToView] = useState('day');
	const [selectedMonth, setSelectedMonth] = useState(new Date());

	const handleClick = day => {
		setDate(moment(date).add(day, 'month').toDate());
	};

	const { t, i18n } = useTranslation('appointments');

	const renderDay = day => (
		<AppointmentCalendarSmallDay
			appointments={[]}
			day={day}
			selectedDay={date}
			selectedMonth={selectedMonth || new Date()}
			setSelectedDay={setDate}
			sx={{
				height: '40px',
				fontSize: '16px',
			}}
			today={new Date()}
		/>
	);

	const CustomAdapter = options => {
		const adapter = new AdapterDateFns(options);

		const constructDayObject = day => ({
			charAt: () => day,
		});

		return {
			...adapter,

			getWeekdays() {
				const customWeekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map(day => t(day)[0]);

				return customWeekdays.map(day => constructDayObject(day));
			},
		};
	};

	return (
		<>
			<Box sx={STYLES.BOX}>
				<Box sx={STYLES.BOX2}>
					<Box sx={STYLES.BOX3} onClick={() => setOpenToView(openToView === 'day' ? 'year' : 'day')}>
						<Typography key={date} sx={STYLES.TEXT}>
							{isTablet
								? t(moment(date).format('MMMM'))
								: i18n?.language === 'en'
								? t(moment(date).format('MMMM')).substring(0, 3)
								: t(moment(date).format('MMMM')).substring(0, 4)}
						</Typography>
						<Typography pl={1} sx={STYLES.TEXT}>
							{moment(date).format('YYYY')}
						</Typography>
						<Tooltip title={openToView === 'day' ? t('Select Year') : t('Select Day')}>
							<ArrowDropDownIcon
								sx={{
									color: '#42A5F5',
									transform: openToView === 'year' ? 'rotate(180deg)' : '',
									transition: '0.2s',
								}}
							/>
						</Tooltip>
					</Box>
					<Box>
						<IconButton disabled={openToView === 'year'} onClick={() => handleClick(-1)}>
							<Tooltip title={t('Prev')}>
								<KeyboardArrowLeftIcon color="#12121299" />
							</Tooltip>
						</IconButton>

						<IconButton disabled={openToView === 'year'} onClick={() => handleClick(1)}>
							<Tooltip title={t('Next')}>
								<KeyboardArrowRightIcon color="#12121299" />
							</Tooltip>
						</IconButton>
					</Box>
				</Box>
			</Box>
			<Box sx={STYLES.CALENDAR_WRAPPER}>
				{openToView === 'day' ? (
					<LocalizationProvider dateAdapter={CustomAdapter}>
						<CalendarPicker
							date={date}
							openTo="day"
							renderDay={renderDay}
							onChange={setDate}
							onMonthChange={setSelectedMonth}
						/>
					</LocalizationProvider>
				) : (
					<LocalizationProvider dateAdapter={CustomAdapter} locale={fr}>
						<YearPicker
							date={date}
							minDate={moment().subtract(5, 'years').toDate()}
							onChange={newDate => {
								setSelectedDay(newDate);
								setOpenToView('day');
							}}
						/>
					</LocalizationProvider>
				)}
			</Box>
		</>
	);
};

export default Calendar;

const STYLES = {
	BOX: {
		display: 'flex',
		alignItems: 'center',
		width: '60%',
		justifyContent: 'space-between',
		margin: '30px auto 10px auto',
		[mediaTablet]: {
			width: '90%',
		},
	},
	BOX2: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flex: 1,
		marginLeft: '15px',
	},
	BOX3: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		cursor: 'pointer',
	},
	TEXT: {
		fontSize: '20px',
		lineHeight: '23px',
		letterSpacing: '0.15px',
		color: '#42A5F5',
	},
	CALENDAR_WRAPPER: {
		width: '60%',
		margin: '0px auto',
		'& .MuiYearPicker-root': {
			maxHeight: '284px',
			'& .PrivatePickersYear-root': {
				'& .Mui-selected': {
					backgroundColor: '#42A5F5',
				},
			},
		},
		'& .MuiCalendarPicker-root': {
			width: '100%',
			'& > div': {
				'&:first-of-type': {
					paddingLeft: '0px',
					paddingRight: '0px',
					display: 'none',
					'& > div:first-of-type': {
						'& > div': {
							fontSize: '18px',
							fontWeight: '400',
							color: '#42A5F5',
							letterSpacing: '0.15px',
						},
						'& > button': {
							color: '#42A5F5',
						},
					},
				},
				'&:last-child': {
					'& > div': {
						justifyContent: 'space-between',
					},
				},
			},
		},
		'& .PrivatePickersFadeTransitionGroup-root': {
			minHeight: '244px',
			fontSize: '18px',
			lineHeight: '23px',
			letterSpacing: '0.15px',
			color: 'rgba(18, 18, 18, 0.6)',
			'& > div > div': {
				justifyContent: 'space-between',
				height: 'fit-content',
				overflowY: 'hidden',
			},
		},
		'& .PrivatePickersSlideTransition-root': {
			'& > div': {
				paddingBottom: '2px',
				'& > div': {
					justifyContent: 'space-between',
				},
			},
		},
		'& .MuiTypography-root': {
			fontSize: '15px',
			fontWeight: 500,
			color: '#000000',
			opacity: 0.9,
			width: '42px',
		},
		[mediaTablet]: {
			marginLeft: '16px',
			marginRight: '16px',
			width: '90%',
		},
	},
};
