import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const formValidationSchema = formType => {
	const { t } = useTranslation('drawer');

	const commonSchema = {
		name: Yup.string().required(t('Required')),
		email: Yup.string().email(t('Please enter a valid email')),
		phone: Yup.string().required(t('Required')),
	};

	const formSchema = {
		myPhysician: {
			...commonSchema,
			specialisation: Yup.string().required(t('Required')),
			phone: Yup.string()
				.transform(value => {
					if (!value) return '';
					return value.replace(/\s/g, '');
				})
				.min(8, 'Phone number must be of atleast 8 digits')
				.max(15, 'Phone number cannot be more than 15 digits')
				.required('Required'),
		},
		emergencyContact: {
			...commonSchema,
			relation: Yup.string().required(t('Required')),
			phone: Yup.string()
				.transform(value => (value ? value.replace(/\s/g, '') : value))
				.min(8, 'Phone number must be of atleast 8 digits')
				.max(15, 'Phone number cannot be more than 15 digits')
				.required('Required'),
		},
	};

	return Yup.object().shape(formSchema[formType]);
};

export default formValidationSchema;
