import { IconButton } from '@mui/material';
import CropFreeIcon from '@mui/icons-material/CropFree';
import GoogleMapReact from 'google-map-react';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import React from 'react';
import { useIsMobile, useIsTablet } from '@worklist-2/core/src';
import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
	[`& .${tooltipClasses.tooltip}`]: {
		minWidth: '400px',
		fontSize: '16px',
		fontWeight: 400,
		leterSpacing: '1px',
		padding: '10px 10px',
	},
});

const GoogleMap = () => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();

	const {
		onTriggerMapFullScreen,
		mapFullScreen,
		setMapFullScreen,
		facilityList,
		mapDefaultCenter,
		showUserMarker,
		tooltipIsOpen,
		setTooltipIsOpen,
	} = useNewAppointmentContentV2Context();
	return (
		<Box
			sx={
				!mapFullScreen
					? {
							margin: isMobile ? '0px 10px 0px 10px' : '10px 30px 0px 30px',
							height: isMobile
								? 'calc(100vh - 600px)'
								: isTablet
								? 'calc(100vh - 580px)'
								: 'calc(100vh - 530px)',
							position: 'relative',
					  }
					: {
							margin: '0px',
							position: 'absolute',
							zIndex: 1000,
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
					  }
			}
		>
			{!mapFullScreen && (
				<IconButton
					aria-label="expand"
					name="expand"
					style={{
						position: 'absolute',
						top: '10px',
						right: '10px',
						zIndex: '10',
						backgroundColor: 'white',
						'&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
					}}
					onClick={() => {
						setMapFullScreen(true);
						onTriggerMapFullScreen();
					}}
				>
					<CropFreeIcon />{' '}
				</IconButton>
			)}
			<GoogleMapReact
				bootstrapURLKeys={{ key: '' }}
				center={mapDefaultCenter}
				options={{ fullscreenControl: false }}
				zoom={showUserMarker ? 10 : 1}
			>
				{showUserMarker && (
					<RadioButtonCheckedIcon
						fontSize="large"
						lat={mapDefaultCenter.lat}
						lng={mapDefaultCenter.lng}
						sx={{ color: '#C480DC' }}
					/>
				)}
				{facilityList.map((value, index) => (
					<IconButton key={value + index} lat={value.lat} lng={value.lng}>
						<Tooltip
							open={tooltipIsOpen}
							sx={{ minWidth: '80vw' }}
							title={
								<>
									<Typography color="inherit">{value.organizationName}</Typography>
									<Typography color="inherit">{value.address}</Typography>
								</>
							}
							onClose={() => setTooltipIsOpen(false)}
							onOpen={() => setTooltipIsOpen(true)}
						>
							<LocationOnIcon
								fontSize="large"
								sx={{ color: '#42A5F5' }}
								onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
							/>
						</Tooltip>
					</IconButton>
				))}
			</GoogleMapReact>
		</Box>
	);
};

export default GoogleMap;
