// Core
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import Box from '@mui/material/Box';

// Custom components
import Avatar from '../Avatar';

const ChatCallingAvatar = ({ isCalling, outsideBoxSx, avatarSx }) => (
	<Box
		sx={{
			height: outsideBoxSx?.height ?? '62px',
			width: outsideBoxSx?.width ?? '62px',
			backgroundColor: outsideBoxSx?.backgroundColor ?? 'rgba(66, 165, 245, 0.2)',
			borderRadius: outsideBoxSx?.borderRadius ?? '50%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			border: outsideBoxSx?.border,
		}}
	>
		<div className={isCalling ? 'call-pulse-animation' : ''} data-testid="pulseAnimation">
			<Avatar
				backgroundColor={avatarSx?.backgroundColor ?? '#5F95FE'}
				color={avatarSx?.color}
				fontSize={avatarSx?.fontSize}
				height={avatarSx?.height ?? '42px'}
				label={avatarSx?.label}
				transparent={false}
				width={avatarSx?.width ?? '42px'}
			/>
		</div>
	</Box>
);

ChatCallingAvatar.propTypes = {
	/**
	 * Call status
	 */
	isCalling: PropTypes.bool,
	/**
	 * Style for the outer box of the avatar
	 */
	outsideBoxSx: PropTypes.object,
	/**
	 * Style for the avatar
	 */
	avatarSx: PropTypes.object,
};

export default ChatCallingAvatar;
