import Close from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import OaiPatientSyncNotification from '../../Notifications/OaiPatientSyncNotification';

const Notifications = ({
	clubNotifications = [],
	setClubNotifications = Function.prototype,
	setHasPendingCRs = Function.prototype,
	setNotifications = Function.prototype,
	onClose = Function.prototype,
}) => {
	const [current, setCurrent] = useState(0);
	return (
		<Box
			sx={{
				zIndex: 99999999,
				backgroundColor: '#00000030',
				position: 'fixed',
				height: '100vh',
				width: '100vw',
				top: 0,
				left: 0,
				display: 'grid',
				placeItems: 'center',
			}}
		>
			<Box sx={{ width: 'min(500px,95vw)', background: '#ffffff', borderRadius: '10px' }}>
				<Box
					alignItems="start"
					borderBottom="1px solid #EAECF0"
					component="header"
					display="flex"
					height="60px"
					justifyContent="space-between"
					pt={2}
					px={2}
				>
					<div>
						<Typography color="" fontSize={17} fontWeight={600}>
							Notifications ({current + 1}/{clubNotifications?.length})
						</Typography>
						<Typography color="#4B5565" fontSize={13} lineHeight="90%" mt={0.5}>
							Please, accept or reject the changes before continuing the booking
						</Typography>
					</div>
					<IconButton onClick={onClose}>
						<Close color="disabled" />
					</IconButton>
				</Box>
				<OaiPatientSyncNotification
					clubbed
					expandAppointmentView
					appointmentStatusChange={Function.prototype}
					details={clubNotifications[current]}
					hideNext={current === clubNotifications?.length - 1}
					setClubNotifications={setClubNotifications}
					setHasPendingCRs={setHasPendingCRs}
					setNotifications={setNotifications}
					onNext={() => setCurrent(prev => prev + 1)}
				/>
			</Box>
		</Box>
	);
};

export default Notifications;
