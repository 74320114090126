import React, { useCallback } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import FeedIcon from '@mui/icons-material/Feed';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import { useAppointmentContext } from '../Appointment/AppointmentContext/AppointmentContext';
import { useBlumeNavigationContext } from '@worklist-2/patientPortal/src/components/BlumeNavigationBar/BlumeNavigationContext';
import BlumeNavigationBubbleIcon from '@worklist-2/ui/src/assets/icons/BlumeNavigationBubbleIcon.png';
import { useToastMessageContext } from '../../context/ToastMessageContext';
import { searchScopes, useAuth, useSearchScope } from '@worklist-2/core/src';
import Badge from '@mui/material/Badge';
import { useTranslation } from 'react-i18next';
import CalendarMonthIconGradient from './calendar_month-gradient.svg';
import './ripple.css';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const BlumeNavigationBar = () => {
	const { isOpen, setIsOpen, isFullscreen, setIsFullscreen, setSelectedDay, hasFirstAppointment, setFilter } =
		useAppointmentContext();
	const { setToastMsg } = useToastMessageContext();
	const { t } = useTranslation('appointments');

	const { navigationState } = useBlumeNavigationContext();

	const { updateScope } = useSearchScope(useSearchScope);
	const { notificationReceived, chatMsgReceived, isEmailVerified } = useAuth();

	const phoenixBlumeDisableChat = useBooleanFlagValue('phoenix-blume-disable-chat');
	const enableBlumeChatGptAssistant = useBooleanFlagValue('enable-blume-chat-gpt-assistant');

	const location = useLocation();

	const onClickRelevantArticles = useCallback(() => {
		setOpenRelevantArticles(true);
	}, []);

	const onIconClick = type => {
		setIsOpen(isOpen === type ? '' : type);
		setIsFullscreen(false);
	};

	const buttonStyle = {
		padding: '10px 13.5px',
		borderRadius: '10px 0 0 10px',
		position: 'relative',
	};

	const activeButtonStyle = {
		...buttonStyle,
		background: 'var(--app-drawer-background-color, #fcfcfc)',
		position: 'relative',
		color: '#42a5f5',
		boxShadow: `inset 0 6px 5px -5px rgba(0, 0, 0, 0.15),
										inset 6px 0 5px -5px rgba(0, 0, 0, 0.15),
										inset 0 -6px 5px -5px rgba(0, 0, 0, 0.15)`,
		'&:hover': {
			backgroundColor: 'var(--app-drawer-background-color, #fcfcfc)',
		},
		'&:after': !navigationState?.dontFixActiveButtonShadow
			? {
					content: '" "',
					background: 'var(--app-drawer-background-color, #fcfcfc)',
					width: '8px',
					height: '37px',
					position: 'absolute',
					top: '3.5px',
					left: '50px',
					boxShadow: '0px 0px 2px 2px var(--app-drawer-background-color, #fcfcfc)',
			  }
			: {},
	};

	const activeAssistantButtonStyle = {
		...buttonStyle,
		background: 'var(--app-drawer-background-color, #fcfcfc)',
		color: '#42a5f5',
		boxShadow: `inset 0 6px 5px -5px rgba(0, 0, 0, 0.15),
										inset 6px 0 5px -5px rgba(0, 0, 0, 0.15),
										inset 0 -6px 5px -5px rgba(0, 0, 0, 0.15)`,
		'&:hover': {
			backgroundColor: 'var(--app-drawer-background-color, #fcfcfc)',
		},
		'&:after': !navigationState?.dontFixActiveButtonShadow
			? {
					content: '" "',
					background: 'var(--app-drawer-background-color, #fcfcfc)',
					width: '8px',
					height: '37px',
					position: 'absolute',
					top: '3.5px',
					left: '50px',
					boxShadow: '0px 0px 2px 2px var(--app-drawer-background-color, #fcfcfc)',
			  }
			: {},
	};

	const handleNotVerified = () => {
		if (!isEmailVerified) {
			setToastMsg(t('Please verify your account to continue'));
		}
	};

	return (
		<Box
			sx={{
				padding: isFullscreen ? '50px 0 50px 12px' : '50px 0 50px 11px',
				background: '#F3F3F3',
				boxSizing: 'border-box',
				borderRadius: '0 var(--app-drawer-border-radius) var(--app-drawer-border-radius) 0',
				display: location.pathname.startsWith('/externalImageViewer') ? 'none' : 'flex',
				height: '100vh',
				flexDirection: 'column',
				boxShadow: '6px 0 5px -5px rgba(0, 0, 0, 0.15)',
				zIndex: '2',
				marginRight: isOpen ? 'calc(0px - var(--app-drawer-offset))' : '0',
				borderLeft: '1px solid rgba(18, 18, 18, 0.04)',
				overflow: 'hidden',
			}}
			onClick={handleNotVerified}
		>
			<IconButton
				sx={isOpen === 'Search' ? activeButtonStyle : buttonStyle}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					updateScope(searchScopes.patientPortalAll);
					onIconClick('Search');
				}}
			>
				<Tooltip placement="left" title={t('Search')}>
					<SearchIcon />
				</Tooltip>
			</IconButton>
			<IconButton
				disabled={!isEmailVerified}
				sx={isOpen === 'Scheduler' ? activeButtonStyle : buttonStyle}
				onClick={() => {
					setSelectedDay();
					onIconClick('Scheduler');
					setFilter(null);
				}}
			>
				<Tooltip placement="left" title={t('Scheduler')}>
					{!hasFirstAppointment ? (
						<div className="ripple-scheduler">
							<CalendarMonthIconGradient />
						</div>
					) : (
						<CalendarMonthIcon />
					)}
				</Tooltip>
			</IconButton>
			{phoenixBlumeDisableChat ? null : (
				<IconButton
					disabled={!isEmailVerified}
					sx={isOpen === 'Chat' ? activeButtonStyle : buttonStyle}
					onClick={() => {
						onIconClick('Chat');
					}}
				>
					<Badge
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						badgeContent=""
						color={chatMsgReceived ? 'error' : undefined}
						sx={{ '& .MuiBadge-badge': { top: '3px', right: '2px' } }}
						variant="dot"
					>
						<Tooltip placement="left" title={t('Chat')}>
							<SmsOutlinedIcon />
						</Tooltip>
					</Badge>
				</IconButton>
			)}

			<IconButton
				disabled={!isEmailVerified}
				sx={isOpen === 'Notifications' ? activeButtonStyle : buttonStyle}
				onClick={() => {
					onIconClick('Notifications');
				}}
			>
				<Badge
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					badgeContent=""
					color={notificationReceived ? 'error' : undefined}
					sx={{ '& .MuiBadge-badge': { top: '6px', right: '6px' } }}
					variant="dot"
				>
					<Tooltip placement="left" title={t('Notification')}>
						<NotificationsIcon />
					</Tooltip>
				</Badge>
			</IconButton>

			<IconButton
				disabled
				sx={{
					...buttonStyle,
					display: 'none',
				}}
				onClick={onClickRelevantArticles}
			>
				<FeedIcon />
			</IconButton>

			{enableBlumeChatGptAssistant && location.pathname !== '/home' && (
				<IconButton
					sx={{
						position: 'absolute',
						bottom: 0,
						borderRadius: '10px 0 0 10px',
						width: '50px',
						height: '50px',
						marginBottom: '10px',
						...(isOpen === 'BlumeAssistant' && activeAssistantButtonStyle),
					}}
					onClick={() => {
						onIconClick('BlumeAssistant');
					}}
				>
					<img src={BlumeNavigationBubbleIcon} />
				</IconButton>
			)}
		</Box>
	);
};

export default BlumeNavigationBar;
