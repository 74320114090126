import Box from '@mui/material/Box';
import React from 'react';
import { getAppointmentColor } from '../AppointmentUtils/AppointmentUtils';

const AppointmentCardTopLine = ({ status, highlight }) => {
	const color = getAppointmentColor(status);

	return (
		<Box
			sx={{
				position: 'absolute',
				width: '100%',
				height: highlight ? '6px' : '4px',
				background: color,
				top: '0',
				left: '0',
			}}
		/>
	);
};

export default AppointmentCardTopLine;
