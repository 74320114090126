import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React from 'react';
import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';

const useCustomRenderMultiSelectValue = () => {
	const { setSelectedStudies } = useNewAppointmentContentV2Context();

	const customRenderMultiSelectValue = selectedValues => {
		const deleteValue = valueId => {
			const finalValues = selectedValues.filter(value => value?.id !== valueId);
			setSelectedStudies(finalValues);
		};

		return (
			<Box sx={{ display: 'flex', gap: 0.5, overflowX: 'auto' }}>
				{selectedValues.map(
					(value, index) =>
						index < 3 && (
							<Chip
								key={value?.id}
								deleteIcon={<ClearOutlinedIcon sx={{ fill: '#121212DE' }} />}
								label={value?.modality}
								sx={{
									'& .MuiChip-label': {
										fontWeight: '400',
									},
								}}
								onDelete={() => deleteValue(value?.id)}
								onMouseDown={event => {
									event.stopPropagation();
								}}
							/>
						)
				)}
				{selectedValues?.length > 3 && <Chip key="plusIcon" label={`+${selectedValues?.length - 2}`} />}
			</Box>
		);
	};

	return {
		customRenderMultiSelectValue,
	};
};

export default useCustomRenderMultiSelectValue;
