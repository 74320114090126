import { useEffect } from 'react';
import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';
import useGetNotificationsPatient from '../hooks/useGetNotificationsPatient';

const usePatientNotificationWatcher = isUpdateForm => {
	const { form, oaiSyncPendingNotifications } = useNewAppointmentContentV2Context();

	const { getNotificationsPatient } = useGetNotificationsPatient();

	const patient = form.watch('patient');

	useEffect(() => {
		if (patient && oaiSyncPendingNotifications?.length && !isUpdateForm) {
			// Get the notifications for the selected patient
			getNotificationsPatient(patient?.profileId);
		}
	}, [patient, oaiSyncPendingNotifications]);
};

export default usePatientNotificationWatcher;
