import React from 'react';
import { NewAppointmentContentV2ContextProvider } from './contexts/NewAppointmentContentV2ContextProvider';
import NewAppointmentContentV2Wrapper from './NewAppointmentContentV2Wrapper';

const NewAppointmentContentV2 = ({ isUpdateForm = false, setOpenDrawer, appointment, onTriggerMapFullScreen }) => (
	<NewAppointmentContentV2ContextProvider
		appointment={appointment}
		isUpdateForm={isUpdateForm}
		setOpenDrawer={setOpenDrawer}
		onTriggerMapFullScreen={onTriggerMapFullScreen}
	>
		<NewAppointmentContentV2Wrapper isUpdateForm={isUpdateForm} />
	</NewAppointmentContentV2ContextProvider>
);

export default NewAppointmentContentV2;
