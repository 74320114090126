// Core

import React from 'react';

// MUI

import { Typography, styled } from '@mui/material';

// Components

const ChatHeading2 = styled(props => <Typography component="h2" {...props} />)({
	color: 'var(--element-heading-foreground-color, #121212)',
	fontSize: '18px',
	fontWeight: 500,
});

const ChatHeading3 = styled(props => <Typography component="h3" {...props} />)({
	color: 'var(--element-heading-foreground-color, #121212)',
	fontSize: '16px',
	fontWeight: 400,
});

export { ChatHeading2, ChatHeading3 };
