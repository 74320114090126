// Core

import React from 'react';

// MUI

import { Box } from '@mui/material';

// Custom

import Chat from '@worklist-2/ui/src/components/Chat';
import { useTranslation } from 'react-i18next';
import { useBlumeNavigationContext } from '@worklist-2/patientPortal/src/components/BlumeNavigationBar/BlumeNavigationContext';

import AppointmentDrawerStickyTop, { APPOINTMENT_DRAWER_STICKY_TOP_HEIGHT } from './AppointmentDrawerStickyTop';

// Component

const ChatDrawerContent = ({ resolveDontFixActiveButtonShadow }) => {
	const { t } = useTranslation('chat');

	const { setNavigationState } = useBlumeNavigationContext();

	React.useEffect(() => {
		setNavigationState(navigationState => ({
			...navigationState,
			dontFixActiveButtonShadow: resolveDontFixActiveButtonShadow(navigationState?.conversationType),
		}));
	}, [resolveDontFixActiveButtonShadow]);

	React.useEffect(
		() => () => {
			setNavigationState(null);
		},
		[]
	);

	return (
		<Box
			sx={{
				'--app-drawer-header-height': `${APPOINTMENT_DRAWER_STICKY_TOP_HEIGHT}px`,

				display: 'grid',
				gridTemplateRows: 'var(--app-drawer-header-height) calc(100vh - var(--app-drawer-header-height))',
			}}
		>
			<AppointmentDrawerStickyTop title={t('CHAT')} />
			<Chat />
		</Box>
	);
};

export default ChatDrawerContent;
