import styled from 'styled-components';

export const LoginViewContent = styled.div`
	display: contents;

	#AnimationData > svg {
		height: 90vh !important;
		width: 100% !important;
	}

	.AnimationVisible {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.2s linear;
	}

	.AnimationHidden {
		visibility: hidden;
		opacity: 0;
		transition:
			visibility 0s 0.2s,
			opacity 0.2s linear;
	}

	#PatientLoginForm #FormWrapper {
		max-height: 528px;
		background-image: radial-gradient(circle 100px at 5px 50%, transparent 50px, white 0);
	}

	@media only screen and (max-width: 1280px) {
		width: 100%;
		#Animation {
			display: none;
		}
		#PatientLoginForm {
			-webkit-flex-basis: 100%;
			-ms-flex-preferred-size: 100%;
			flex-basis: 100%;
			max-width: 100%;
			margin: auto;
			width: 100%;
			background: linear-gradient(268.97deg, #ddeaf8 3.59%, #eff5fc 32.98%, #ffffff 93.74%);
		}
	}
`;
