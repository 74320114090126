import { Checkbox, FormControl, InputLabel, OutlinedInput, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import StudySmallIcon from '@worklist-2/ui/src/assets/icons/Appointments/StudySmallIcon.svg';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import SectionUploadDocument from '../../../../../../views/SettingsView/views/sections/SectionUpload/SectionUploadDocument';
import React, { useEffect } from 'react';
import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';
import { useTranslation } from 'react-i18next';
import useCustomRenderMultiSelectValue from '../../hooks/useCustomRenderMultiSelectValue';
import useFetchStudies from '../../hooks/useFetchStudies';

const PriorStudies = () => {
	const { t } = useTranslation('appointments');

	const { selectedStudies, setSelectedStudies, studies, isRequestLoading, form, priorStudiesId, setPriorStudies } =
		useNewAppointmentContentV2Context();

	const patient = form.watch('patient');
	const { customRenderMultiSelectValue } = useCustomRenderMultiSelectValue();
	const { fetchStudies, fetchStudiesAfterUpload } = useFetchStudies();

	useEffect(() => {
		fetchStudies();
	}, []);

	useEffect(() => {
		if (priorStudiesId) {
			priorStudiesId.forEach(studyId => {
				const study = studies.find(item => item?.studyThumbnail?.studyUID === studyId);
				const studyExists = selectedStudies.findIndex(item => item.id === study.id) >= 0;
				if (study && !studyExists) {
					setSelectedStudies(prevState => [...prevState, study]);
				}
			});
		}
	}, [priorStudiesId]);
	return (
		<FormControl sx={{ m: 1 }}>
			<InputLabel shrink>{t('Prior Studies')}</InputLabel>

			<Select
				multiple
				notched
				MenuProps={{
					sx: { maxHeight: '300px', maxWidth: '350px' },
				}}
				disabled={isRequestLoading}
				input={<OutlinedInput label={t('Prior Studies')} />}
				placeholder="Select Studies"
				renderValue={selected => customRenderMultiSelectValue(selected)}
				value={selectedStudies}
				onChange={e => {
					const data = e.target.value;
					let updatedPriorStudies = [];
					data.forEach(item => {
						if (!priorStudiesId.includes(item?.studyThumbnail?.studyUID)) {
							updatedPriorStudies = priorStudiesId.filter(ele => ele !== item.studyThumbnail.studyUID);
						}
					});
					setPriorStudies(updatedPriorStudies);
					setSelectedStudies(data);
				}}
			>
				{selectedStudies.map(study => (
					<MenuItem key={study.id} sx={{ maxWidth: '100%' }} value={study}>
						<Checkbox checked={selectedStudies.findIndex(item => item.id === study.id) >= 0} />
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								width: '100%',
								justifyContent: 'space-between',
								paddingRight: '10px',
								alignItems: 'center',
							}}
						>
							<StudySmallIcon />
							<ListItemText primary={study.modality} sx={{ marginLeft: '7px' }} />
							<Typography variant="span">
								{study.createdTimeUTC
									? moment(study.createdTimeUTC).format('D MMM h:mm a')
									: moment(study.scanDate, 'YYYY-MM-DD HH:mm:ss').format('D MMM h:mm a')}
							</Typography>
						</Box>
					</MenuItem>
				))}

				<SectionUploadDocument
					isAppointment
					isNewSetting
					patient={patient}
					reloadAppointmentStudy={fetchStudiesAfterUpload}
				/>
			</Select>
		</FormControl>
	);
};

export default PriorStudies;
