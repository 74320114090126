import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import EditCalendarIcon from '@worklist-2/ui/src/assets/icons/edit-calendar.svg';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileList from '../AppointmentDrawer/NewAppointmentContentV2/components/FileList';

const iconsList = {
	chatIcon: TextsmsOutlinedIcon,
	editIcon: EditCalendarIcon,
	cancelIcon: EventBusyIcon,
};

const buttonProp = {
	height: '29px',
	borderRadius: '8px',
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '16px',
	letterSpacing: '1.25px',
	textTransform: 'uppercase',
};

export const AppointmentNotes = ({ attachments, onClick }) => {
	if (!attachments?.length) return null;
	return (
		<Box
			sx={{
				'& .files': {
					display: 'none',
				},
				':hover': {
					'& .notes': {
						display: 'none',
					},
					'& .files': {
						display: 'block',
					},
				},
			}}
		>
			<IconButton className="notes">
				<AttachFileIcon fontSize="small" />
			</IconButton>
			<Box className="files" sx={{ height: '31px' }}>
				<FileList
					sx={{ gap: '2px', marginTop: '5px', marginRight: '4px' }}
					uploadedAttachments={attachments}
					onSelectFile={onClick}
				/>
			</Box>
		</Box>
	);
};

export const ActionIconButton = ({ icon, tooltipTxt, onClick, sx }) => (
	<IconButton
		data-testid="button"
		sx={{
			padding: '5px',
			height: '28px',
			width: '28px',
			transition: 'background-color 0.3s ease',
			background: 'rgba(255, 255, 255, 0.6)',

			'&:hover': {
				background: 'rgba(0, 0, 0, 0.04)',
			},

			'&:active': {
				background: 'rgba(0, 0, 0, 0.10)',
			},
			...sx,
		}}
		onClick={onClick}
	>
		{tooltipTxt ? (
			<Tooltip title={tooltipTxt}>
				<div>
					<Icon
						component={iconsList[icon]}
						data-testid="icon"
						sx={{
							color: '#42A5F5',
							marginTop: '5px',
						}}
					/>
				</div>
			</Tooltip>
		) : (
			<Icon
				component={iconsList[icon]}
				data-testid="icon"
				sx={{
					color: '#42A5F5',
				}}
			/>
		)}
	</IconButton>
);

export const ActionButtons = ({ onCancel, onSubmit, isCancelReason, disableButtonForAPICall, shouldRenderCancel }) => {
	const { t } = useTranslation('appointments');

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			{shouldRenderCancel && (
				<Button
					color="rsPrimary"
					data-testid="Cancel"
					disabled={disableButtonForAPICall}
					sx={{
						...buttonProp,
						marginRight: '5px',
						border: '2px solid #42A5F5',
					}}
					variant="outlined"
					onClick={onCancel}
				>
					{t(isCancelReason ? 'BACK' : 'CANCEL')}
				</Button>
			)}
			<Button
				color="rsPrimary"
				data-testid="Confirm"
				disabled={disableButtonForAPICall}
				sx={{
					...buttonProp,
				}}
				variant="contained"
				onClick={onSubmit}
			>
				{t(isCancelReason ? 'SUBMIT' : 'CONFIRM')}
			</Button>
		</Box>
	);
};

export default ActionButtons;
