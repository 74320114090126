// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI

import { Grid, Icon, IconButton, Stack, Typography } from '@mui/material';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

// Custom

import ChatAvatar from './ChatAvatar';

const ChatSelectedUsers = ({ data = [], onRemoveClick }) => {
	if (!Array.isArray(data) || !data.length) {
		return null;
	}

	const removeClickDefined = typeof onRemoveClick === 'function';

	return (
		<Grid
			container
			flexDirection="row"
			flexWrap="wrap"
			gap="var(--element-content-spacing)"
			sx={{
				padding: 'var(--element-content-spacing-all)',
			}}
		>
			{data.map((item, index) => (
				<Stack key={index} sx={{ width: '42px' }}>
					<Stack sx={{ marginBottom: '5px', position: 'relative' }}>
						<ChatAvatar data={item.user} size={42} />

						{removeClickDefined ? (
							<IconButton
								className="chat-selected-user-button"
								size="small"
								sx={{
									background: 'var(--element-background-color)',
									height: '20px',
									padding: 0,
									position: 'absolute',
									right: '-6px',
									top: '-6px',
									width: '20px',

									'&:hover': {
										background: 'var(--element-background-color)',
									},
								}}
								onClick={() => {
									onRemoveClick(item);
								}}
							>
								<Icon component={ClearOutlinedIcon} sx={{ fontSize: '12px' }} />
							</IconButton>
						) : null}
					</Stack>

					<Typography
						sx={{
							color: 'var(--element-foreground-color, #414141)',
							fontSize: '12px',
							textAlign: 'center',
						}}
					>
						{item.user.name.split(' ')[0]}
					</Typography>
				</Stack>
			))}
		</Grid>
	);
};

ChatSelectedUsers.propTypes = {
	data: PropTypes.array,
	onRemoveClick: PropTypes.func,
};

export default ChatSelectedUsers;
