import LoginBlumeView from './LoginBlumeView';
import NewLoginBlumeView from './NewLoginBlumeView';
import React from 'react';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const BlumeLoginView = () => {
	const sprinterBlumeNewSignup = useBooleanFlagValue('sprinter-blume-new-signup');

	return sprinterBlumeNewSignup ? <NewLoginBlumeView /> : <LoginBlumeView />;
};

export default BlumeLoginView;
