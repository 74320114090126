import React from 'react';
import PatientChat from '../HelpCenter/PatientChat';
import { Flex } from '../HelpCenter/patientHelpStyles';
import { SubHeader } from './patientHelpPopoverStyles';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

const PatientHelpPipChat = () => {
	return (
		<Flex
			className="invisible-scrollbar"
			style={{
				flex: 1,
				flexDirection: 'column',
				display: 'flex',
				height: '102%',
				overflowX: 'hidden',
				padding: 20,
				marginBottom: -25,
			}}
		>
			<SubHeader style={{ margin: '0px 0px 0px 10px' }}>
				<SupportAgentOutlinedIcon />
				<span>SUPPORT LIVE CHAT</span>
			</SubHeader>
			<PatientChat />
		</Flex>
	);
};

export default PatientHelpPipChat;
