// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI

import { Stack, Typography } from '@mui/material';
import ChatAvatar from './ChatAvatar';

// Component

const ChatMessage = ({ title, datetime, incoming, first, last, footer, sx, showAvatar, children }) => {
	const classList = [
		'chat-message',
		incoming ? 'chat-message--incoming' : '',
		!incoming ? 'chat-message--outgoing' : '',
		first ? 'chat-message--first' : '',
		last ? 'chat-message--last' : '',
	].filter(Boolean);

	return (
		<Stack
			className={classList.join(' ')}
			data-testid="chat-message-container"
			sx={{
				...sx,
				display: 'inline-flex',
				maxWidth: '85%',

				'&.chat-message--incoming': {
					alignSelf: 'flex-start',
				},

				'&.chat-message--outgoing': {
					alignSelf: 'flex-end',
				},

				'&.chat-message--last': {
					marginBottom: '20px',
				},
			}}
		>
			<Stack
				className="chat-message-content"
				data-testid="chat-message-box"
				sx={{
					'--element-border-radius': 'var(--border-radius-large)',
					backgroundColor: 'var(--element-background-color)',
					color: 'var(--element-foreground-color)',
					marginBottom: '3px',
					padding: '15px',
					borderRadius: incoming ? '20px 20px 20px 0px' : '20px 20px 0px 20px',
				}}
			>
				{title || datetime ? (
					<Stack
						alignItems="center"
						direction="row"
						sx={{
							marginBottom: '5px',
							justifyContent: 'space-between',
						}}
					>
						<Typography
							className="chat-message-title"
							component="div"
							data-testid="chat-message-title"
							sx={{
								width: '65%',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							{showAvatar ? (
								<ChatAvatar
									data={{ name: title }}
									showBadge={false}
									status={null}
									sx={{
										marginRight: '9px',
									}}
								/>
							) : null}
							<Typography
								component="span"
								sx={{
									color: 'var(--element-foreground-color)',
									fontSize: '16px',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
								}}
							>
								{title}
							</Typography>
						</Typography>

						{datetime ? (
							<Typography
								className="chat-message-datetime"
								component="time"
								data-testid="chat-message-datetime"
								dateTime={datetime}
								sx={{
									color: 'var(--element-foreground-color)',
									fontSize: '12px',
									marginLeft: '10px',
									whiteSpace: 'nowrap',
								}}
							>
								{datetime}
							</Typography>
						) : null}
					</Stack>
				) : null}

				{children}
			</Stack>
			{footer ? (
				<Typography
					className="chat-message-footer"
					component="div"
					sx={{
						'--element-foreground-color': 'var(--element-foreground-color-tertiary)',
						color: 'var(--element-foreground-color)',
						fontSize: '12px',
						textAlign: 'right',
					}}
				>
					{footer}
				</Typography>
			) : null}
		</Stack>
	);
};

ChatMessage.propTypes = {
	title: PropTypes.string,
	datetime: PropTypes.any,
	incoming: PropTypes.bool,
	first: PropTypes.bool,
	last: PropTypes.bool,
	footer: PropTypes.string,
	children: PropTypes.node,
};

export default ChatMessage;
