import React from 'react';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import { mediaTabletOnly } from '@worklist-2/core/src';
import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';

const AppointmentCardButtonShare = () => {
	const { isFullscreen } = useAppointmentContext();
	return (
		<Box
			className="AppointmentCardHoverButton"
			sx={{
				position: 'absolute',
				top: '16px',
				right: '10px',
			}}
		>
			<IconButton
				disabled
				data-testid="button-testid"
				size="small"
				sx={{
					width: '24px',
					height: '24px',
					background: '#42A5F5',
					color: 'rsPrimary.main',
					'&:hover': {
						background: '#42A5F5',
					},
					[mediaTabletOnly]: isFullscreen
						? {
								width: '20px',
								height: '20px',
						  }
						: {},
				}}
			>
				<ShareIcon
					data-testid="icon"
					sx={{
						color: '#fff',
						fontSize: '16px',
						[mediaTabletOnly]: isFullscreen && {
							fontSize: '12.5px',
						},
					}}
				/>
			</IconButton>
		</Box>
	);
};

export default AppointmentCardButtonShare;
