import { FormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const FormToolLabel = styled(FormLabel)({
	color: 'var(--element-content-label-foreground-color)',
	fontSize: 'var(--element-content-label-font-size)',
	minWidth: '15%',
	'&.Mui-focused': {
		color: 'var(--element-content-label-foreground-color)',
	},

	'.MuiFormLabel-asterisk': {
		color: '#cf6697',
	},
});

export default FormToolLabel;
