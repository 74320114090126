import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const InformationFormValidationSchema = disable => {
	const { t } = useTranslation('drawer');

	const formSchema = {
		firstName: disable ? null : Yup.string().required(t('Required')),
		ssn: disable
			? null
			: Yup.string().test('valid-ssn', 'enter valid SSN (like: 856-45-6789)', value => {
					if (!value) {
						return true;
					}
					return /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(value);
			  }),
	};

	return Yup.object().shape(formSchema);
};

export default InformationFormValidationSchema;
