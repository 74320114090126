import Box from '@mui/material/Box';
import styled from 'styled-components';
import React from 'react';

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

export const SelectorCard = styled.div`
	width: 255px;
	max-width: calc(50vw - 20px);
	height: 30vh;
	max-height: 308px;
	min-height: 208px;
	border-radius: ${props => (props.invert ? '0px 20px 20px 0px' : '20px 0px 0px 20px')};
	margin: 3px;
	background-color: transparent;
	overflow: hidden;
	padding: 10px 0px;

	&:hover {
		.card {
			border: 2px solid rgba(66, 165, 245, 0.5);
			.right-cutout-border,
			.left-cutout-border {
				opacity: 1;
			}
		}
	}

	.card {
		height: 100%;
		flex-grow: 0;
		overflow: hidden;
		border-radius: ${props => (props.invert ? '0px 20px 20px 0px' : '20px 0px 0px 20px')};
		margin: 3px;
		display: flex;
		flex-direction: row-reverse;
		border: 2px solid rgb(231 231 233);
		background-color: white;
		position: relative;

		h4 {
			font-family: Roboto;
			font-size: 28px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.29;
			letter-spacing: normal;
			text-align: left;
			color: rgba(18, 18, 18, 0.87);
			margin: 17px 0px 19px;
		}

		span {
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 0.5px;
			text-align: left;
			color: rgba(18, 18, 18, 0.6);
		}

		.right-cutout-border,
		.left-cutout-border {
			border: 2px solid rgba(66, 165, 245, 0.5);
			width: 102px;
			height: 104px;
			box-sizing: border-box;
			border-radius: 50%;
			background: transparent;
			pointer-events: none;
			z-index: 1;
			position: absolute;
			right: -58px;
			top: -52px;
			opacity: 0;
		}

		.left-cutout-border {
			right: unset;
			left: -58px;
		}
	}

	&:hover {
		.card {
			cursor: pointer;
		}
	}
`;

export const Circle = styled.div`
	box-sizing: border-box;
	position: absolute;
	height: 104px;
	width: 102px;
	border-radius: 50%;
	background-color: rgb(231 231 233);
	margin-top: -35px;
	padding: 18px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 10;

	div {
		width: 70px;
		height: 78px;
		background: #fdfdfd;
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`;

export const SelectorWrapper = styled.div`
	h1 {
		font-family: Roboto;
		font-size: 60px;
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		text-align: left;
		color: #000;
		margin: 0px 0px 32px 0px;
	}

	span.hint {
		font-family: Roboto;
		font-size: 20px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: rgba(18, 18, 18, 0.6);
	}
`;

export const LoginWrapper = props => (
	<Box
		style={{
			zIndex: '10',
			display: 'inline-flex',
			height: '100%',
			borderRadius: '20px',
			width: '550px',
			margin: 'auto',
			backgroundColor: 'transparent',
			maxWidth: 'calc(100vw  48px)',

			'@media only screen and (max-width: 1280px)': {
				maxWidth: '100%',
				backgroundImage: 'unset',
				backgroundColor: '#fff',
				borderRadius: '20px 20px 0 0',
				height: 'unset',
				margin: 'unset',
			},
		}}
		{...props}
	>
		{props.children}
	</Box>
);

export const RoundedButton = styled.button`
	min-width: 66px;
	height: 66px;
	flex-grow: 0;
	padding: 21px 20px 20px 21px;
	background-color: #fff;
	border-radius: 100px;
	margin-right: 15px;
	border-width: 0px;
	cursor: pointer;
	margin-top: ${props => (props.alt ? '-24px' : 'inherit')};
	margin: auto;
	margin-left: -33px;
	display: flex;
	flex-direction: row;
	align-items: center;
	background: #fff !important;
	box-shadow: 4px 1px 6px 0px rgba(0, 0, 0, 0.07);
	z-index: 1;

	&:hover {
		margin-left: -125px !important;
	}

	span {
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		color: #42a5f5;
		font-family: Roboto;
		letter-spacing: 1.25px;
		text-transform: uppercase;
		margin-right: 15px;
		margin-left: 10px;
	}
	margin-top: ${props => (props.alt ? 'inherit' : 'calc(50% - 46px)')};
`;

export const SpaceViewWrapper = styled.div`
	background: ${props =>
		props.active ? 'linear-gradient(287.56deg, #57A9D7 0%, #5244F2 76.56%, #2662FC 100%);' : '#e7e7e9'};
	min-height: 100vh;
	height: -webkit-fill-available;
	position: fixed;
	width: 100vw;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.cell-block {
		animation: fadeIn 1s;
	}

	.active-cell-block {
		animation: grow 2s infinite;
		background-color: transparent;
		transform: scale(0);
	}

	.active-cell-block::before {
		content: '';
		position: absolute;
		width: 40px;
		height: 40px;
		background-color: inherit;
		border-radius: 50%;
		z-index: -1;
		animation: ripple 2s ease-out infinite;
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes grow {
		0% {
			-webkit-transform: scale(0);
			-moz-transform: scale(0);
			-o-transform: scale(0);
			-ms-transform: scale(0);
			transform: scale(0);
			opacity: 0;
		}

		25% {
			-webkit-transform: scale(0.8);
			-moz-transform: scale(0.8);
			-o-transform: scale(0.8);
			-ms-transform: scale(0.8);
			transform: scale(0);
			opacity: 0.9;
		}

		50% {
			-webkit-transform: scale(0.8);
			-moz-transform: scale(0.8);
			-o-transform: scale(0.8);
			-ms-transform: scale(0.8);
			transform: scale(0.8);
			opacity: 0.7;
			content: '';
			width: 40px;
			height: 40px;
			border-radius: 50%;
			animation: ripple 0.5s ease-out infinite;
		}

		100% {
			-webkit-transform: scale(0.8);
			-moz-transform: scale(0.8);
			-o-transform: scale(0.8);
			-ms-transform: scale(0.8);
			transform: scale(0);
			opacity: 0.5;
		}
	}

	@keyframes ripple {
		0% {
			opacity: 0;
			transform: scale(0);
		}
		50% {
			opacity: 1;
			transform: scale(0);
		}
		100% {
			opacity: 0;
			transform: scale(3);
		}
	}
`;
