import React from 'react';
import { Button } from '@mui/material';
import { CHAT_SECTIONS_default } from './CHAT_SECTIONS';
import { useChatContext } from './ChatContext';
import { useTranslation } from 'react-i18next';

const ChatConversationDelete = ({ conversation }) => {
	const { setSection, dispatch } = useChatContext();
	const { t } = useTranslation('chat');

	return (
		<Button
			data-testid="end-support-conversation-btn"
			sx={{
				minWidth: '51px',
				height: '27px',
				background: '#FF6666',
				borderRadius: '50px',
				textTransform: 'none',
				color: 'rgba(255, 255, 255, 0.8)',
				fontSize: '16px',
				fontStyle: 'normal',
				fontWeight: '400px',
				'&:hover': {
					background: '#FF6666',
				},
			}}
			onMouseDown={event => {
				event.stopPropagation();

				!conversation && setSection(CHAT_SECTIONS_default);

				if (conversation) {
					conversation.delete();
					dispatch({
						msgtype: 'CONVERSATION_LEFT',
						payload: conversation,
					});
				}

				setSection(CHAT_SECTIONS_default);
			}}
		>
			{t('chatWithSupport.end')}
		</Button>
	);
};

export default ChatConversationDelete;
