// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI

import { Box } from '@mui/material';

// Custom

import ChatAvatar, { CHAT_AVATAR_SIZE } from './ChatAvatar';

// Component

const ChatAvatarGroup = ({ data = [], size = CHAT_AVATAR_SIZE }) => {
	const usersLength = data.length;

	if (!Array.isArray(data) || !usersLength) {
		return null;
	}

	let gridTemplateAreas = '';

	if (usersLength >= 4) {
		gridTemplateAreas = '"a1 a2" "a3 a4"';
	} else if (usersLength === 3) {
		gridTemplateAreas = '"a1 a2" "a1 a3"';
	} else if (usersLength === 2) {
		gridTemplateAreas = '"a1 a2"';
	} else if (usersLength === 1) {
		gridTemplateAreas = '"a1"';
	}

	return (
		<Box
			sx={{
				display: 'grid',
				borderRadius: '50%',
				flex: 'none',
				gridTemplateAreas,
				gridTemplateColumns: usersLength > 1 ? '1fr 1fr' : '1fr',
				height: `${size}px`,
				overflow: 'hidden',
				width: `${size}px`,
				gap: '1px',
			}}
		>
			{data.map((item, index) => {
				if (index >= 4) {
					return null;
				}

				return (
					<Box key={index} sx={{ gridArea: `a${index + 1}` }}>
						<ChatAvatar
							data={item}
							showBadge={false}
							status={null}
							sx={{
								fontSize: usersLength > 1 ? '12px' : undefined,
								height: '100%',
								width: '100%',
							}}
							variant="square"
						/>
					</Box>
				);
			})}
		</Box>
	);
};

ChatAvatarGroup.propTypes = {
	data: PropTypes.array,
	size: PropTypes.number,
};

export default ChatAvatarGroup;
