import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useConfig } from '@worklist-2/core/src';
import { useFormStore } from '../../stores';
import getForm from '../../api/getForm';
import FormDrawerV2 from '../../components/FormDrawer/FormDrawerV2';

export default function ExternalFormsView() {
	function useQueryParams() {
		const params = new URLSearchParams(window ? window.location.search : {});

		return new Proxy(params, {
			get(target, prop) {
				return target.get(prop);
			},
		});
	}
	const { formId, patientId } = useQueryParams();

	const __config = useConfig();

	const { data, setSelectedForm } = useFormStore(state => ({
		data: state.selectedForm,
		setSelectedForm: state.setSelectedForm,
	}));

	useEffect(() => {
		getForm({ __config, patientId })
			.then(forms => {
				const transformedForms = forms.entry.map(item => ({
					id: item.resource.id,
					patient: item.resource?.patient,
					completed: item.resource?.completed,
					organizationId: item.resource.organizationId,
					status: item.resource.status.charAt(0).toUpperCase() + item.resource.status.slice(1),
					title: item.resource.name,
					type: item.resource.type,
					description: item.resource.description,
					questionData: item.resource.formTemplate?.content,
					lastModified: item.resource.lastModified,
					resource: item.resource,
					referenceTemplate: item.resource?.referenceTemplate,
					pdfFileName: item.resource.pdfFileName,
				}));

				const form = transformedForms.find(f => f.id === formId);

				if (form) {
					setSelectedForm(form);
				}
			})
			.catch(() => {
				console.log('Failed to fetch forms');
			});
	}, []);

	if (!data) {
		return null;
	}

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
			}}
		>
			<FormDrawerV2 isExternal />
		</Box>
	);
}
