/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Box, Button, Skeleton, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormSelectVariant from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/variants/FormSelectVariant';
import { GpsFixedTwoTone } from '@mui/icons-material';
import { ScanTypeIcon, inputStyles, menuProps } from './consts/consts';
import FormAPIAutocompleteVariant from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/variants/FormAPIAutocompleteVariant';
import userIcon from '@worklist-2/ui/src/assets/img/homepage/user.png';
import location from '@worklist-2/ui/src/assets/img/homepage/location.png';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import { getFacilitiesByLocation, getLocationLabel, searchGooglePlaces, showPatientName } from './utils';
import FacilityListV2 from './components/FacilityListV2';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const FirstPage = ({
	form,
	patientList,
	isUpdateForm,
	onChangePatient,
	modalities,
	genders = [],
	isGenderAvailable,
	patient,
	scanType,
	facility,
	userLocation,
	setShowUserMarker,
	setMapDefaultCenter,
	hasLocationPermission,
	setHasLocationPermission,
	isTablet,
	__config,
}) => {
	const [facilities, setFacilities] = useState([]);
	const [isLoadingFacility, setIsLoadingFacility] = useState(false);
	const [googleSearchResults, setGoogleSearchResults] = useState([]);
	const { t } = useTranslation('appointments');
	const _filterOptions = createFilterOptions();
	const phoenixBlumeIsAssetEnabledInBreeze = useBooleanFlagValue('phoenix-blume-is-asset-in-breeze');

	const current_location_option = {
		description: t('Use my current location'),
		place_id: 'current_location',
		value: t('Current location selected'),
	};

	useEffect(() => {
		if (!userLocation) {
			form.setValue('userLocation', current_location_option);
			return;
		}
		getFacilitiesByLocation(
			__config,
			userLocation,
			setHasLocationPermission,
			setMapDefaultCenter,
			setShowUserMarker,
			patient,
			scanType,
			form,
			setIsLoadingFacility,
			setFacilities
		);
	}, [patient, scanType, userLocation]);

	const searchPlaces = useCallback(
		debounce(async query => {
			searchGooglePlaces(query, setGoogleSearchResults, googleSearchResults);
		}, 500),
		[setGoogleSearchResults]
	);

	return (
		<>
			<Typography color="#475467" display="block" fontSize={14} fontWeight={500} mb={1} variant="p">
				{t('Patient')}
			</Typography>
			<FormSelectVariant
				formHook={form}
				name="patient"
				options={patientList}
				props={{
					disabled: isUpdateForm,
					onChangePatient,
					SelectProps: {
						MenuProps: menuProps,
						renderValue: showPatientName,
						sx: inputStyles,
					},
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<img alt="user" height="24px" src={userIcon} />
							</InputAdornment>
						),
					},
				}}
				type="select"
			/>
			{!isGenderAvailable && (
				<>
					<Typography
						color="#475467"
						display="block"
						fontSize={14}
						fontWeight={500}
						mb={1}
						mt={2}
						variant="p"
					>
						{t('Gender')}
					</Typography>
					<FormSelectVariant
						formHook={form}
						name="gender"
						options={genders}
						props={{
							disabled: !!isUpdateForm,
							SelectProps: {
								MenuProps: menuProps,
								renderValue: option => option,
								sx: inputStyles,
							},
						}}
						type="select"
					/>
				</>
			)}
			<Typography color="#475467" display="block" fontSize={14} fontWeight={500} mb={1} mt={2} variant="p">
				{t('Scan Type')}
			</Typography>
			<FormSelectVariant
				formHook={form}
				name="scanType"
				options={modalities}
				props={{
					disabled: !!isUpdateForm,
					SelectProps: {
						MenuProps: {
							...menuProps,
							onClick: () => form.clearErrors('scanType'),
						},
						renderValue: option => t(option?.Name),
						sx: inputStyles,
						'data-testid': 'scan-type-select',
					},
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<ScanTypeIcon />
							</InputAdornment>
						),
					},
				}}
			/>
			<Typography color="#475467" display="block" fontSize={14} fontWeight={500} mb={1} mt={2} variant="p">
				{t('User Location')}
			</Typography>
			<FormAPIAutocompleteVariant
				TextFieldProps={{
					placeholder: t('Allow us to find the closest facility around you'),
					sx: {
						maxHeight: '40px',
						padding: '0px 0px !important',
					},
					'data-testid': 'location-select',
					InputProps: {
						onFocus: () => {
							if (!form.getValues('scanType'))
								form.setError('scanType', {
									type: 'manual',
									message: t('Please select a Scan Type first'),
								});
						},
						startAdornment: (
							<InputAdornment position="start" sx={{ ml: 1.6 }}>
								<img alt="location" height="20px" src={location} />
							</InputAdornment>
						),
					},
				}}
				formHook={form}
				hideClear={
					Boolean(form.getValues('userLocation')?.place_id === 'current_location') ||
					!form.getValues('userLocation')
				}
				name="userLocation"
				options={googleSearchResults}
				props={{
					disabled: !!isUpdateForm,
					isOptionEqualToValue: (option, value) => !value || option?.place_id === value?.place_id,
					getOptionLabel: getLocationLabel,
					renderOption: (props, option) => (
						<li {...props} key={option?.place_id}>
							{option?.description ? (
								option?.place_id === 'current_location' ? (
									<Typography
										sx={{
											marginLeft: '8%',
											fontSize: '14px',
										}}
									>
										<GpsFixedTwoTone fontSize="inherit" /> {option?.description}
									</Typography>
								) : (
									<Typography
										sx={{
											marginLeft: '8%',
											fontSize: '16px',
											color: 'black',
										}}
									>
										{option?.description}
									</Typography>
								)
							) : (
								''
							)}
						</li>
					),
					filterOptions: (options, state) => {
						const results = _filterOptions(options, state);
						if (!results.includes(current_location_option) && hasLocationPermission) {
							results.unshift(current_location_option);
						}
						return results;
					},
				}}
				setOptions={setGoogleSearchResults}
				sx={{
					...inputStyles,
					fontSize: 14,
				}}
				onSearch={searchPlaces}
			/>
			<Typography
				color="#475467"
				display="block"
				fontSize={14}
				fontWeight={500}
				mb={1}
				mt={2}
				sx={{
					display:
						(!facilities?.length && !isLoadingFacility) ||
						(facilities?.length &&
							phoenixBlumeIsAssetEnabledInBreeze &&
							facilities?.every(d => !d?.isAppointmentBookingAllowed))
							? 'none'
							: 'block',
				}}
				variant="p"
			>
				{t('Choose healthcare providers')}
			</Typography>
			{facilities?.length ? (
				phoenixBlumeIsAssetEnabledInBreeze ? (
					<FacilityListV2
						facilities={facilities}
						facility={facility}
						isTablet={isTablet}
						t={t}
						onSelectFacility={fac => () => form.setValue('facility', fac)}
					/>
				) : (
					<Box sx={{ maxHeight: '300px', overflowY: 'auto', height: 'auto', overflowX: 'hidden', mt: 1.5 }}>
						{facilities.map((fac, i) => (
							<Box
								key={fac?.internalOrganizationID}
								data-testid={`facility${i}`}
								sx={{
									width: 'min(550px,100%)',
									borderRadius: '8px',
									paddingY: 1,
									mb: 1.4,
									display: 'flex',
									alignItems: 'start',
									cursor: 'pointer',
									border:
										facility?.internalOrganizationID === fac?.internalOrganizationID
											? '2px solid var(--color-primary)'
											: '1px solid #D0D5DD',
								}}
								onClick={() => {
									form.setValue('facility', fac);
								}}
							>
								<Box sx={{ flex: 1, mx: 1 }}>
									<Typography color="#101828" fontSize="14px" fontWeight={500}>
										{fac?.organizationName}
									</Typography>
									<Typography color="#344054" fontSize="13px" mt={0.1}>
										{fac?.address}
									</Typography>
								</Box>
								<Button
									color="primary"
									component="a"
									href={`https://www.google.com/maps/place/${encodeURIComponent(fac?.address)}`}
									rel="noopener"
									sx={{ color: '#42A5F5', textTransform: 'none' }}
									target="_blank"
									variant="text"
								>
									{t('View in maps')}
								</Button>
							</Box>
						))}
					</Box>
				)
			) : isLoadingFacility ? (
				Array(3)
					.fill(1)
					.map((_d, i) => (
						<Box
							key={i}
							data-testid="progressbar"
							sx={{
								width: 'min(550px,100%)',
								border: '1px solid #D0D5DD',
								borderRadius: '8px',
								paddingY: 1.5,
								mt: 1.5,
								display: 'flex',
								alignItems: 'start',
							}}
						>
							<Box sx={{ flex: 1, mx: 2 }}>
								<Skeleton height="20px" variant="text" width="90%" />
								<Skeleton height="5px" sx={{ mt: 1 }} variant="text" width="90%" />
								<Skeleton height="5px" sx={{ mt: 1 }} variant="text" width="50%" />
							</Box>
							<Skeleton
								height="20px"
								sx={{ mr: 1, borderRadius: '8px' }}
								variant="rectangular"
								width="80px"
							/>
						</Box>
					))
			) : null}
		</>
	);
};

export default FirstPage;
