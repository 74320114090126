import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid, Divider, Button } from '@mui/material';
import { useImportTabContext, useAuth, useConfig, useIsTablet } from '@worklist-2/core';
import { useTranslation } from 'react-i18next';
import { useAppointmentStore, useScheduleStore } from '../../../stores';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { publicBookingSchema } from './consts/consts';

import { scanTypes } from '../V2/utils';
import axios from 'axios';
import {
	getSlots,
	validatePayload,
	initialize,
	fetchNotifications,
	processResponse,
	processNotifications,
} from './utils';
import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';
import { useSettingsViewContext } from '@worklist-2/patientPortal/src/context/SettingsViewContext';
import SuccessPopup from './components/SuccessPopup';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import Stepper from './components/Stepper';
import Header from './components/Header';
import Maps from './components/Maps';
import Slots from './components/Slots';
import UploadProgress from './components/UploadProgress';
import ConsentTCPopop from '@worklist-2/patientPortal/src/views/NewSettingsView/Sections/MyInfo/Documents/ConsentT&C';
import sendAnalyticsEvent from '@worklist-2/patientPortal/src/analytics';
// eslint-disable-next-line import/no-unresolved
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import {
	APPOINTMENT_FAILURE,
	APPOINTMENT_RESCHEDULE_FAILURE,
} from '@worklist-2/patientPortal/src/analytics/eventTypes';
import Notifications from './Notifications';
import { ChevronLeftOutlined } from '@mui/icons-material';

const AppointmentDrawerV2 = ({ onClose = Function.prototype, isUpdateForm = false, appointment = {} }) => {
	const { sideBarIsOpen } = useImportTabContext();
	const { profiles } = useAuth();
	const { patientList, setPatientList, getModalities, modalities, setModalities } = useScheduleStore(state => ({
		patientList: state.patientList,
		setPatientList: state.setPatientList,
		getModalities: state.getModalities,
		modalities: state.modalities,
		setModalities: state.setModalities,
	}));
	const { setToastMsg } = useToastMessageContext();
	const getAppointmentsFromStore = useAppointmentStore(state => state.getAppointments);
	const phoenixBlumeRestrictHomeScreenFunctionality = useBooleanFlagValue(
		'phoenix-blume-restrict-home-screen-functionality'
	);
	const { user, setTriggerDocumentSectionRefresh } = useSettingsViewContext();
	const { t } = useTranslation('appointments');
	const [isGenderAvailable, setIsGenderAvailable] = useState([]);
	const [openTC, setOpenTC] = useState(false);
	const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
	const [slotsLoading, setSlotsLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const [showUserMarker, setShowUserMarker] = useState(false);
	const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
	const [appointmentResponse, setAppointmentResponse] = useState(null);
	const [hasLocationPermission, setHasLocationPermission] = useState(false);
	const [genders, setGenders] = useState([]);
	const [mapDefaultCenter, setMapDefaultCenter] = useState({ lat: 12.843774508382714, lng: 77.66221950654179 });
	const [step, setStep] = useState(0);
	const __config = useConfig();
	const isTablet = useIsTablet();

	const form = useForm({
		resolver: !isUpdateForm && yupResolver(publicBookingSchema),
	});
	const patient = form.watch('patient');
	const scanType = form.watch('scanType');
	const facility = form.watch('facility');
	const userLocation = form.watch('userLocation');

	const [selectedDate, setSelectedDate] = useState(
		isUpdateForm && Boolean(appointment?.dateStart) ? new Date(appointment?.dateStart) : new Date()
	);
	const [isRequestLoading, setIsRequestLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [clubNotifications, setClubNotifications] = useState([]);
	const [showNotifications, setShowNotifications] = useState(false);
	const [hasPendingCRs, setHasPendingCRs] = useState(false);

	useEffect(() => {
		if (isUpdateForm) {
			return;
		}
		if (profiles) {
			const accounts = Object.entries(profiles || {}).map(([key, value]) => ({ ...value, profileId: key }));
			setIsGenderAvailable(Boolean(accounts?.find(acc => acc?.profileId === user?.profileId)?.gender));
		}
		initialize(__config, setGenders, setHasLocationPermission, setMapDefaultCenter, setShowUserMarker);
		fetchNotifications(__config, setNotifications);
	}, []);

	useEffect(() => {
		const orgId = sessionStorage.getItem('organization');
		if (orgId) {
			getModalities({ orgId });
		} else {
			setModalities(scanTypes);
		}
	}, [getModalities, setModalities]);

	useEffect(() => {
		if (!profiles) return;
		const accounts = Object.entries(profiles || {}).map(([key, value]) => ({ ...value, profileId: key }));
		const _patientList = accounts.filter(item => item.firstName !== null || item.lastName !== null);
		setPatientList(_patientList);
		if (!patient) {
			const initProfile = accounts?.find(acc => acc?.profileId === user?.profileId);
			form.setValue('patient', initProfile);
		}
	}, [profiles]);

	const onChangePatient = (_, value) => {
		const selectedPatient = value?.props?.value;
		form.setValue('patient', selectedPatient);
		setIsGenderAvailable(Boolean(selectedPatient?.gender));
	};

	useEffect(() => {
		if (step === 1 || isUpdateForm)
			getSlots(
				selectedDate,
				setSlotsLoading,
				scanType,
				facility,
				setAvailableTimeSlots,
				__config,
				isUpdateForm,
				form,
				appointment
			);
	}, [selectedDate, step]);

	const handleNext = e => {
		if (step === 0 && !isUpdateForm) {
			if (hasPendingCRs && !showNotifications) {
				setShowNotifications(true);
				return;
			}
			setStep(prev => prev + 1);
		} else if (step === 1 || isUpdateForm) {
			onSubmit(e);
		}
	};

	useEffect(() => {
		processNotifications(patient, notifications, setHasPendingCRs, setShowNotifications, setClubNotifications);
	}, [patient, notifications]);

	const onSubmit = async () => {
		const selectedSlot = availableTimeSlots.filter(eachSlot => eachSlot.selected === true);
		if (!selectedSlot.length) return;
		setIsRequestLoading(true);
		const payload = validatePayload(
			selectedDate,
			patient,
			facility,
			selectedSlot,
			scanType,
			form,
			isUpdateForm,
			appointment
		);
		const request = isUpdateForm ? axios.put : axios.post;

		request(`${__config.data_sources.blume}Appointment/${isUpdateForm ? appointment?.id : 'schedule'}`, payload)
			.then(async response => {
				await processResponse({
					response,
					setAppointmentResponse,
					setTriggerDocumentSectionRefresh,
					setShowSuccess,
					setToastMsg,
					getAppointmentsFromStore,
					isUpdateForm,
					appointment,
					scanType,
					patient,
					userLocation,
					facility,
					t,
					phoenixBlumeRestrictHomeScreenFunctionality,
					user,
					files,
					__config,
				});
			})
			.catch(err => {
				setToastMsg(err.message || 'Something might went wrong!');
				sendAnalyticsEvent(isUpdateForm ? APPOINTMENT_RESCHEDULE_FAILURE : APPOINTMENT_FAILURE, {
					message: err.message || 'Something might went wrong!',
					date: selectedDate,
					facility,
					scanType,
					id: isUpdateForm ? appointment?.id : null,
				});
			})
			.finally(() => {
				setIsRequestLoading(false);
				setFiles([]);
			});
	};

	return (
		<Box
			sx={{
				position: 'fixed',
				height: '100dvh',
				width: isTablet ? '100vw' : `calc(100dvw - ${sideBarIsOpen ? '200px' : '64px'})`,
				top: isTablet ? '60px' : 0,
				left: isTablet ? 0 : sideBarIsOpen ? '200px' : '64px',
				zIndex: 99,
				fontFamily: 'ROBOTO',
				borderLeft: '1px solid #EAECF0',
				overflowY: isTablet && 'auto',
			}}
		>
			{showNotifications && (
				<Notifications
					clubNotifications={clubNotifications}
					setClubNotifications={setClubNotifications}
					setHasPendingCRs={setHasPendingCRs}
					setNotifications={setNotifications}
					onClose={() => setShowNotifications(false)}
				/>
			)}
			<Header
				disableNext={
					step === 0 && !isUpdateForm
						? !patient || !scanType || !facility
						: !selectedDate || !availableTimeSlots?.some(d => d?.selected) || isRequestLoading
				}
				handleNext={handleNext}
				isRequestLoading={isRequestLoading}
				isTablet={isTablet}
				isUpdateForm={isUpdateForm}
				step={step}
				t={t}
				onClose={onClose}
			/>
			{isUpdateForm ||
				(step === 1 && (
					<Box
						sx={{
							height: isTablet ? '45px' : '30px',
							width: '100%',
							background: '#E6F4FF',
							color: '#4B5565',
							display: 'flex',
							alignItems: 'center',
							placeContent: 'center',
							textAlign: 'center',
						}}
					>
						<Typography fontSize={12} fontWeight={500} letterSpacing={0.5}>
							Need help? Reach out to <b>+1-212-456-7890 </b> or visit{' '}
							<b>www.healthcareproviderwebsite.com </b>
						</Typography>
					</Box>
				))}
			<Box
				sx={{
					width: '100%',
					position: 'relative',
					height: '100%',
					background: '#F9FAFB',
					overflowY: 'auto',
					paddingBottom: '100px',
				}}
			>
				{isRequestLoading && (
					<Box
						sx={{
							position: 'absolute',
							width: '100%',
							top: 0,
							left: 0,
							bottom: isTablet ? '-600px' : '-500px',
							background: '#00000020',
							zIndex: 99999,
						}}
					/>
				)}
				<Grid
					container
					mt={5}
					px={{ xs: 1 }}
					spacing={{ xs: 0, md: 4 }}
					sx={{
						margin: '0 auto !important',
						maxWidth: 'min(1300px,95%)',
						paddingBottom: isTablet && '50px',
					}}
				>
					<Grid item md={2} xs={12}>
						<Stepper
							disableNext={!patient || !scanType || !facility}
							isTablet={isTablet}
							isUpdateForm={isUpdateForm}
							setStep={setStep}
							step={step}
							t={t}
						/>
					</Grid>

					<Grid item md={step === 1 ? 5 : 6} my={1} pb={step === 1 && 4} xs={12}>
						{!isUpdateForm && step === 1 && (
							<Button
								sx={{ color: 'var(--color-primary)', textTransform: 'none', ml: -2 }}
								onClick={() => setStep(0)}
							>
								<ChevronLeftOutlined /> Go Back
							</Button>
						)}
						<Typography color="#475467" fontSize={18} fontWeight={500} variant="h4">
							{step === 1 || isUpdateForm ? t('Choose a slot') : t('Enter details')}
						</Typography>
						<Typography color="#475467" display="block" fontSize={15} mt={1} variant="p">
							{step === 1 || isUpdateForm
								? t('Choose a date from the calendar and a convenient timeslot')
								: t('Give us the patient and scan details')}
						</Typography>
						<Divider sx={{ my: 4, width: 'min(550px,100%)' }} variant="fullWidth" />
						{step === 0 && !isUpdateForm ? (
							<FirstPage
								__config={__config}
								facility={facility}
								form={form}
								genders={genders}
								hasLocationPermission={hasLocationPermission}
								hasPendingCRs={hasPendingCRs}
								isGenderAvailable={isGenderAvailable}
								isTablet={isTablet}
								isUpdateForm={isUpdateForm}
								modalities={modalities}
								patient={patient}
								patientList={patientList}
								scanType={scanType}
								setHasLocationPermission={setHasLocationPermission}
								setMapDefaultCenter={setMapDefaultCenter}
								setShowUserMarker={setShowUserMarker}
								userLocation={userLocation}
								onChangePatient={onChangePatient}
							/>
						) : (
							<SecondPage
								availableTimeSlots={availableTimeSlots}
								form={form}
								isTablet={isTablet}
								selectedDate={selectedDate}
								setAvailableTimeSlots={setAvailableTimeSlots}
								setFiles={setFiles}
								setOpenTC={setOpenTC}
								setSelectedDate={setSelectedDate}
								setToastMsg={setToastMsg}
								slotsLoading={slotsLoading}
							/>
						)}
					</Grid>
					<Grid item md={step === 1 ? 5 : 4} xs={12}>
						{step === 0 && !isUpdateForm ? (
							<Maps
								facility={facility}
								mapDefaultCenter={mapDefaultCenter}
								setTooltipIsOpen={setTooltipIsOpen}
								showUserMarker={showUserMarker}
								tooltipIsOpen={tooltipIsOpen}
							/>
						) : (
							<>
								{!isTablet && (
									<Slots
										availableTimeSlots={availableTimeSlots}
										setAvailableTimeSlots={setAvailableTimeSlots}
										slotsLoading={slotsLoading}
									/>
								)}
								<Box sx={{ mt: 28, width: '100%' }}>
									<UploadProgress
										files={files}
										isRequestLoading={isRequestLoading}
										setFiles={setFiles}
									/>
									{isUpdateForm && appointment?.attachments?.length && (
										<UploadProgress isUploaded files={appointment?.attachments} />
									)}
								</Box>
							</>
						)}
					</Grid>
				</Grid>
				{isTablet && (
					<Button
						color="primary"
						disabled={
							step === 0 && !isUpdateForm
								? !patient || !scanType || !facility
								: !selectedDate || !availableTimeSlots?.some(d => d?.selected) || isRequestLoading
						}
						sx={{
							width: 'calc(100% - 30px)',
							height: '40px',
							my: 2,
							backgroundColor: 'var(--color-primary)',
							color: '#ffffff',
							mx: '15px',
							position: 'fixed',
							bottom: '10px',
							'&.Mui-disabled': {
								backgroundColor: '#dadada',
							},
						}}
						variant="contained"
						onClick={handleNext}
					>
						{step === 0 ? (isUpdateForm ? t('Save and confirm') : t('Next')) : t('Submit')}
					</Button>
				)}
			</Box>
			{showSuccess && <SuccessPopup open={showSuccess} values={appointmentResponse} onClose={onClose} />}
			{openTC && <ConsentTCPopop open={openTC} onClose={() => setOpenTC(false)} />}
		</Box>
	);
};

export default AppointmentDrawerV2;
