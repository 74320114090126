import React from 'react';
import Box from '@mui/material/Box';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import moment from 'moment';

const AppointmentCalendar = ({ day, selectedDay, today, setSelectedDay, selectedMonth }) => {
	let disableDate = false;
	if (day < today) {
		disableDate = true;
	}
	if (moment(day).format('dddd, MMMM Do YYYY') === moment(new Date()).format('dddd, MMMM Do YYYY')) {
		disableDate = false;
	}

	const isCurrentMonth =
		moment(day).format('MMMM')?.toLowerCase() === moment(selectedMonth).format('MMMM')?.toLowerCase();

	const isSelected = day?.toDateString() === selectedDay?.toDateString();

	const isToday = day?.toDateString() === today?.toDateString();

	return (
		<Box
			sx={{
				position: 'relative',
				width: '46px',
				height: '45px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<PickersDay
				day={day}
				disabled={disableDate}
				outsideCurrentMonth={false}
				selected={isSelected}
				sx={{
					backgroundColor: 'unset',
					fontSize: '16px',
					color: 'rgba(18, 18, 18, 0.6)',
					fontWeight: '300',
					width: '25px',
					height: '25px',
					lineHeight: 'unset',
					overflowY: 'hidden',
					display: isCurrentMonth ? 'block' : 'none',
					'&.MuiPickersDay-today': {
						border: '1px solid #42A5F5',
						color: '#42A5F5',
						backgroundColor: 'unset',
					},
					'&.Mui-selected': {
						backgroundColor: '#42A5F5',
						border: 'unset',
						color: '#fff',
						'&:hover, &:focus': {
							backgroundColor: '#42A5F5',
							border: 'unset',
							color: '#fff',
						},
					},
				}}
				today={isToday}
				onDaySelect={setSelectedDay}
			/>
		</Box>
	);
};

export default AppointmentCalendar;
