import React from 'react';
import Box from '@mui/material/Box';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import AppointmentCalendarAppointments from './AppointmentCalendarAppointments';
import { mediaTabletOnly } from '@worklist-2/core/src/utils/responsiveUtils';
import { Typography } from '@mui/material';
import moment from 'moment';

const AppointmentCalendarBigDay = ({ appointments, day, selectedDay, today, selectedMonth, setSelectedDay }) => {
	const isSelected = day.toDateString() === selectedDay?.toDateString();

	const isToday = day.toDateString() === today.toDateString();

	const isOutsideCurrentMonth = day.getMonth() !== selectedMonth.getMonth();
	const isAPastDate = moment(day).isBefore(today, 'day');

	return (
		<Box
			sx={{
				position: 'relative',
				flex: '1',
			}}
			onClick={() => !isAPastDate && setSelectedDay(day)}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'end',
					margin: '4px 10px 0 0',
					[mediaTabletOnly]: {
						justifyContent: 'center',
						margin: '4px 0px 0 0',
					},
				}}
			>
				<PickersDay
					data-testid="calendarDay"
					day={day}
					outsideCurrentMonth={isOutsideCurrentMonth}
					selected={isSelected}
					sx={{
						backgroundColor: 'unset',
						fontSize: '16px',
						color: isAPastDate ? '#12121240' : '#212121',
						cursor: isAPastDate && 'default',
						fontWeight: '300',
						width: '34px',
						height: '34px',
						lineHeight: 'unset',
						margin: '0',
						marginBottom: '2px',
						zIndex: 9,
						'&.MuiPickersDay-root': {
							'&:hover, &:focus': {
								backgroundColor: isAPastDate && 'transparent',
								pointerEvents: isAPastDate && 'none',
							},
						},
						'&.MuiPickersDay-today': {
							backgroundColor: '#42A5F5 !important',
							border: 'unset',
							color: '#fff !important',
						},
						'&.Mui-selected': {
							border: '1px solid #42A5F5',
							color: '#42A5F5',
							backgroundColor: 'unset',
							'&:hover, &:focus': {
								backgroundColor: 'unset',
							},
						},
					}}
					today={isToday}
					onDaySelect={setSelectedDay}
				/>
			</Box>

			<AppointmentCalendarAppointments appointments={appointments} />
			{appointments?.length > 2 && (
				<Typography
					sx={{
						fontSize: '12px !important',
						lineHeight: '14px',
						letterSpacing: '0.15px',
						marginBottom: '8px',
						marginLeft: '4px',
						[mediaTabletOnly]: {
							marginLeft: '3px',
						},
					}}
				>
					{`${appointments.length - 2} more..`}
				</Typography>
			)}
		</Box>
	);
};

export default AppointmentCalendarBigDay;
