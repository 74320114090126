import React from 'react';
import PropTypes from 'prop-types';

const ChatSearchHighlightMatches = ({ text, searchString }) => {
	// Split text using searchString, find all matches, case-insensitive matching, multiline matching
	const parts = text.split(new RegExp(`(${searchString})`, 'gim'));
	return parts.map((part, index) =>
		part.toLowerCase() === searchString?.toLowerCase() ? (
			<b key={index} style={{ backgroundColor: '#FFFF00', color: 'rgb(0, 0, 0)' }}>
				{part}
			</b>
		) : (
			part
		)
	);
};

ChatSearchHighlightMatches.propTypes = {
	text: PropTypes.string.isRequired,
	searchString: PropTypes.string,
};

export default ChatSearchHighlightMatches;
