import React, { useMemo } from 'react';
import { Link, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotesIcon from '@mui/icons-material/Notes';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { mediaTabletOnly } from '@worklist-2/core/src';

import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';

const AppointmentCardList = ({ appointment }) => {
	const { isFullscreen } = useAppointmentContext();

	const patientName = useMemo(
		() =>
			appointment.resource?.participant.find(participant =>
				participant?.actor.reference?.toLowerCase().includes('patient')
			)?.actor.display,
		[appointment]
	);

	const shouldRenderPhone = appointment.status !== 'booking';
	const shouldRenderAddress =
		appointment.status !== 'booking' && appointment.status !== 'rejected' && appointment.status !== 'cancelled';
	const shouldRenderDescription =
		appointment.status === 'booking' || appointment.status === 'rejected' || appointment.status === 'cancelled';

	const renderMap = address => `https://www.google.com/maps/place/${address}`;

	return (
		<List
			disablePadding
			sx={{
				'& .MuiListItem-root': {
					margin: '0 0 8px 0',
					'&:last-child': {
						margin: '0',
					},
				},
				'& .MuiListItemText-root': {
					margin: '1.5px 0',
				},
				'& .MuiListItemIcon-root': {
					minWidth: '30px',
					color: 'rgba(18, 18, 18, 0.4)',
					alignSelf: 'flex-start',
				},
				'& .MuiSvgIcon-root': {
					fontSize: '18px',
				},
				'& .MuiTypography-root': {
					fontSize: '14px',
					lineHeight: '16px',
					color: 'rgba(18, 18, 18, 0.6)',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: '2',
					padding: '0',
				},
				'& .MuiLink-root': {
					color: '#42A5F5',
					'&:hover': {
						textDecoration: 'none',
					},
				},
				[mediaTabletOnly]: isFullscreen && {
					'& .MuiListItem-root': {
						margin: '0 0 11px 0',
						'&:last-child': {
							margin: '0',
						},
					},
					'& .MuiTypography-root': {
						fontSize: '12px',
						lineHeight: '14px',
					},
				},
			}}
		>
			{patientName && (
				<ListItem disablePadding>
					<ListItemIcon>
						<AccountCircleIcon />
					</ListItemIcon>

					<ListItemText primary={patientName} />
				</ListItem>
			)}

			{appointment?.type && (
				<ListItem disablePadding>
					<ListItemIcon>
						<MedicalServicesOutlinedIcon />
					</ListItemIcon>

					<ListItemText primary={appointment.type} />
				</ListItem>
			)}

			{shouldRenderPhone && (
				<ListItem disablePadding>
					<ListItemIcon>
						<LocalPhoneOutlinedIcon />
					</ListItemIcon>

					<ListItemText primary={appointment.phone} />
				</ListItem>
			)}

			{shouldRenderAddress && (
				<ListItem disablePadding>
					<ListItemIcon>
						<LocationOnIcon />
					</ListItemIcon>

					<ListItemText
						primary={
							<Link href={renderMap(appointment.address)} rel="noreferrer" target="_blank">
								{appointment.address}
							</Link>
						}
					/>
				</ListItem>
			)}

			{shouldRenderDescription && (
				<ListItem disablePadding>
					<ListItemIcon>
						<NotesIcon />
					</ListItemIcon>

					<ListItemText primary={appointment.description} />
				</ListItem>
			)}
		</List>
	);
};

export default AppointmentCardList;
