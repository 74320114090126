import React from 'react';
import AppointmentDrawerStickyTop from './AppointmentDrawerStickyTop';
import SearchBar from '@worklist-2/ui/src/components/SearchBar';
import { useTranslation } from 'react-i18next';

const SearchDrawerContent = ({ setIsOpen }) => {
	const { t } = useTranslation('Search');
	return (
		<>
			<AppointmentDrawerStickyTop showCloseBtn title={t('Search')} />

			<SearchBar placeholder="All" setIsOpen={setIsOpen} />
		</>
	);
};

export default SearchDrawerContent;
