import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { mediaTabletOnly } from '@worklist-2/core/src';

import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import { getAppointmentBadgeColor, getAppointmentColor } from '../AppointmentUtils/AppointmentUtils';

const AppointmentCardBadge = ({ status }) => {
	const { isFullscreen } = useAppointmentContext();
	const { t } = useTranslation('appointments');

	const color = getAppointmentColor(status);
	const badgeColor = getAppointmentBadgeColor(status);

	const getBadgeText = () => {
		switch (status) {
			case 'booking':
				return 'appointment booking needed';
			case 'parked':
				return 'Rescheduled';
			default:
				return status;
		}
	};

	return (
		<Typography
			sx={{
				fontSize: '14px',
				lineHeight: '14px',
				color,
				marginRight: '5px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				fontVariant: 'small-caps',
				padding: '3px 6px',
				background: badgeColor,
				borderRadius: '5px',
				textTransform: 'lowercase',
				[mediaTabletOnly]: isFullscreen && { fontSize: '12px' },
			}}
		>
			{t(getBadgeText())}
		</Typography>
	);
};

export default AppointmentCardBadge;
