import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Icon, IconButton } from '@mui/material';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import Tooltip from '@mui/material/Tooltip';

import AppointmentDrawerButtonScheduler from './AppointmentDrawerButtonScheduler';
import AppointmentDrawerButtonBook from './AppointmentDrawerButtonBook';

import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import { useIsTabletOnly, useIsTablet, mediaTablet, mediaTabletOnly } from '@worklist-2/core/src';

// Defaults

const HEIGHT = 60;

const AppointmemntExpandIcon = () => {
	const { isFullscreen, setIsFullscreen } = useAppointmentContext();
	const { t } = useTranslation('chat');

	const _clickHandler = React.useCallback(() => {
		setIsFullscreen(value => !value);
	}, [setIsFullscreen]);

	return (
		<Tooltip title={!isFullscreen ? t('icon.fullScreen') : t('icon.exitFullScreen')}>
			<IconButton
				aria-label={!isFullscreen ? 'Expand' : 'Collapse'}
				sx={{
					background: 'var(--element-header-button-background-color)',
					borderRadius: 'var(--border-radius-small)',
					color: 'var(--element-header-button-foreground-color)',
					width: '36px',
					height: '36px',
					marginLeft: '16px',
					'&:hover': {
						background: 'var(--element-header-button-background-color)',
					},
				}}
				onClick={_clickHandler}
			>
				<Icon component={!isFullscreen ? FullscreenOutlinedIcon : FullscreenExitOutlinedIcon} />
			</IconButton>
		</Tooltip>
	);
};

const AppointmentDrawerStickyTop = ({ title, showCloseBtn }) => {
	const { isFullscreen, isOpen } = useAppointmentContext();
	const isTabletOnly = useIsTabletOnly();
	const isTablet = useIsTablet();

	const shouldRenderBookButton = !isFullscreen && isOpen === 'Scheduler';

	return (
		<Box
			sx={{
				background: 'var(--element-background-color)',
				height: `${HEIGHT}px`,
				position: 'sticky',
				top: '0',
				padding: '0 24px 0 0',
				zIndex: '1',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				[mediaTablet]: {
					padding: '16px 16px 8px 16px',
				},
			}}
		>
			{!isTablet && <AppointmentDrawerButtonScheduler showCloseBtn={showCloseBtn} title={title} />}

			<Box
				sx={{
					[mediaTabletOnly]: {
						width: 1,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
					},
				}}
			>
				{shouldRenderBookButton && isTablet && <AppointmentDrawerButtonBook />}

				{(isOpen === 'Scheduler' || isOpen === 'Chat' || isTabletOnly) && <AppointmemntExpandIcon />}
			</Box>
		</Box>
	);
};

export default AppointmentDrawerStickyTop;
export { AppointmemntExpandIcon, HEIGHT as APPOINTMENT_DRAWER_STICKY_TOP_HEIGHT };
