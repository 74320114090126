/* eslint-disable react/jsx-filename-extension */
import * as yup from 'yup';
import { mediaMobile, mediaTablet } from '@worklist-2/core/src';
import React from 'react';

const CARD_HEIGHT = 150;
const CARD_SPACING = 16;

const notificationType = {
	OAI_PATIENT_SYNC: 'OAI_PATIENT_SYNC',
	OAI_PATIENT_DATA_SYNC: 'OAI_PATIENT_DATA_SYNC',
};

const publicBookingSchema = yup
	.object()
	.shape({
		patient: yup.object().required('Required'),
		facility: yup.object().required('Required').nullable(),
		scanType: yup.object().required('Required'),
		gender: yup.string().required('Required'),
		// todo
		// birthDate: yup.date().required('Required'),
	})
	.required();

const newAppointmentSchema = yup
	.object()
	.shape({
		patient: yup.object().required('Required'),
		facility: yup.object().required('Required').nullable(),
		scanType: yup.object().required('Required'),
	})
	.required();

const menuProps = {
	PaperProps: {
		sx: {
			width: 'min(90%,550px) !important',
			minWidth: 'min(90%,550px) !important',
			maxHeight: '340px !important',
			marginTop: '4px',
			border: '1px solid rgba(255, 255, 255, 0.1)',
			boxShadow: '2px 2px 8px 2px rgba(18, 18, 18, 0.08)',
			borderRadius: '6px',
			'& .MuiMenuItem-root.Mui-selected': {
				backgroundColor: '#00000010 !important',
			},
			[mediaTablet]: {
				minWidth: '324px !important',
			},
			[mediaMobile]: {
				minWidth: 'calc(100% - 36px) !important',
			},
		},
	},
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'right',
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'right',
	},
};

const inputStyles = {
	height: '40px',
	borderRadius: '4px',
	width: 'min(550px,100%)',
	background: '#ffffff',
};

const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const ScanTypeIcon = () => (
	<svg fill="none" height="20" viewBox="0 0 18 20" width="18">
		<path
			d="M9.66683 9.16675H4.66683M6.3335 12.5001H4.66683M11.3335 5.83341H4.66683M14.6668 8.75008V5.66675C14.6668 4.26662 14.6668 3.56655 14.3943 3.03177C14.1547 2.56137 13.7722 2.17892 13.3018 1.93923C12.767 1.66675 12.067 1.66675 10.6668 1.66675H5.3335C3.93336 1.66675 3.2333 1.66675 2.69852 1.93923C2.22811 2.17892 1.84566 2.56137 1.60598 3.03177C1.3335 3.56655 1.3335 4.26662 1.3335 5.66675V14.3334C1.3335 15.7335 1.3335 16.4336 1.60598 16.9684C1.84566 17.4388 2.22811 17.8212 2.69852 18.0609C3.2333 18.3334 3.93336 18.3334 5.3335 18.3334H7.5835M16.3335 18.3334L15.0835 17.0834M15.9168 15.0001C15.9168 16.6109 14.611 17.9167 13.0002 17.9167C11.3893 17.9167 10.0835 16.6109 10.0835 15.0001C10.0835 13.3893 11.3893 12.0834 13.0002 12.0834C14.611 12.0834 15.9168 13.3893 15.9168 15.0001Z"
			stroke="#697586"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.67"
		/>
	</svg>
);

const scheduleSuccessIcon = (
	<svg fill="none" height="140" viewBox="0 0 140 140" width="140" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M103.511 97.2474L12.3367 136.919C6.39355 139.284 0.487299 133.429 2.80085 127.466L42.6432 34.8569"
			fill="#FFB636"
		/>
		<path
			d="M2.80094 127.466L5.96735 120.106C6.23887 119.692 6.50957 119.278 6.78137 118.864C22.5306 94.8713 38.5105 71.0254 55.0363 47.5601L73.5658 66.553C59.5849 76.1512 14.1888 123.064 4.68 135.508C2.61008 133.604 1.60411 130.551 2.80094 127.466Z"
			fill="#FFD469"
		/>
		<path
			d="M83.2296 55.9C100.143 72.8132 109.393 90.9842 103.892 96.4863C98.3898 101.988 80.2187 92.7375 63.3053 75.8243C46.3918 58.9111 37.1414 40.74 42.6432 35.2379C48.1451 29.7358 66.3161 38.9865 83.2296 55.9Z"
			fill="#A06C33"
		/>
		<path
			d="M42.547 89.5705C42.547 91.2144 41.2142 92.5468 39.5706 92.5468C37.927 92.5468 36.5942 91.2144 36.5942 89.5705C36.5942 87.9266 37.9267 86.5941 39.5706 86.5941C41.2145 86.5941 42.547 87.9266 42.547 89.5705Z"
			fill="#F7F9AA"
		/>
		<path
			d="M137.262 58.2859C137.262 60.6451 135.349 62.5576 132.99 62.5576C130.631 62.5576 128.719 60.6451 128.719 58.2859C128.719 55.9267 130.631 54.0143 132.99 54.0143C135.349 54.0143 137.262 55.9267 137.262 58.2859ZM108.736 115.167C106.377 115.167 104.464 117.079 104.464 119.439C104.464 121.798 106.377 123.71 108.736 123.71C111.095 123.71 113.008 121.798 113.008 119.439C113.008 117.079 111.095 115.167 108.736 115.167Z"
			fill="#FFB636"
		/>
		<path
			d="M97.3297 21.7446C96.9633 24.2233 95.3617 26.4127 92.9355 27.7515C91.2604 28.6757 89.2906 29.1561 87.2461 29.1561C86.6024 29.1561 85.9516 29.1086 85.3 29.0123C83.8541 28.7985 82.4068 29.0167 81.3297 29.6109C80.8468 29.8772 80.0172 30.4599 79.8824 31.3713C79.7479 32.2826 80.3735 33.0805 80.7582 33.4751C81.5944 34.3323 82.8706 34.9464 84.2716 35.1759C84.3102 35.18 84.3485 35.1813 84.387 35.1871C90.2985 36.061 94.5209 40.7433 93.799 45.625C93.4326 48.104 91.8311 50.2934 89.4046 51.6322C87.7295 52.5564 85.76 53.0368 83.7155 53.0368C83.0718 53.0368 82.421 52.9892 81.7694 52.893C80.3238 52.6789 78.8759 52.8971 77.7988 53.4915C77.3159 53.7579 76.4863 54.3405 76.3515 55.2519C76.1355 56.7145 77.9315 58.6455 80.855 59.0776C82.4456 59.3127 83.5446 60.7929 83.3094 62.3837C83.0956 63.8302 81.8525 64.8698 80.4329 64.8698C80.2909 64.8698 80.1477 64.8594 80.0033 64.8381C74.0918 63.9642 69.8697 59.2816 70.5913 54.4002C70.9577 51.9212 72.5592 49.7318 74.9857 48.393C77.1883 47.1779 79.8999 46.73 82.6212 47.1322C84.0671 47.3457 85.5141 47.1278 86.5915 46.5336C87.0744 46.2673 87.904 45.6846 88.0388 44.7732C88.2513 43.3344 86.5138 41.4452 83.6739 40.973C83.6274 40.9684 83.5807 40.9645 83.5339 40.9577C80.8129 40.5552 78.3465 39.3422 76.59 37.5416C74.6549 35.5581 73.7552 32.9988 74.1219 30.5195C74.4883 28.0408 76.0898 25.8514 78.5161 24.5126C80.7186 23.2975 83.4303 22.8496 86.1515 23.2518C87.5969 23.4651 89.0448 23.2474 90.1218 22.6532C90.6047 22.3869 91.4343 21.8042 91.5691 20.8929C91.7037 19.9815 91.0781 19.1836 90.6933 18.789C89.8342 17.9086 88.5121 17.2807 87.0656 17.0672C85.475 16.832 84.3761 15.3519 84.6113 13.7611C84.8461 12.1702 86.3268 11.0726 87.9174 11.3064C90.6384 11.7089 93.1048 12.9219 94.8613 14.7225C96.7967 16.7063 97.6963 19.2656 97.3297 21.7446Z"
			fill="#BEA4FF"
		/>
		<path
			d="M22.3483 43.665C22.3483 46.3384 20.1811 48.5057 17.5077 48.5057C14.8343 48.5057 12.667 46.3384 12.667 43.665C12.667 40.9916 14.8343 38.8243 17.5077 38.8243C20.1811 38.8243 22.3483 40.9913 22.3483 43.665ZM108.876 38.0724C105.787 38.0724 103.284 40.5763 103.284 43.6647C103.284 46.7532 105.788 49.2571 108.876 49.2571C111.965 49.2571 114.469 46.7532 114.469 43.6647C114.469 40.5763 111.965 38.0724 108.876 38.0724ZM58.9337 108.254C55.845 108.254 53.3414 110.758 53.3414 113.846C53.3414 116.935 55.8452 119.438 58.9337 119.438C62.0225 119.438 64.5261 116.935 64.5261 113.846C64.5261 110.758 62.0225 108.254 58.9337 108.254ZM133.118 82.6473C133.964 81.0097 133.322 78.9964 131.684 78.1507C131.031 77.8138 130.252 77.3665 129.35 76.8488C121.422 72.3007 104.926 62.8374 85.5034 78.5193C84.0692 79.677 83.8455 81.7784 85.0032 83.2123C86.1607 84.6465 88.2618 84.8707 89.6963 83.7124C105.565 70.9002 118.375 78.2477 126.028 82.6383C126.959 83.1721 127.837 83.6763 128.621 84.081C129.111 84.3339 129.634 84.4537 130.15 84.4537C131.359 84.4537 132.525 83.7952 133.118 82.6473Z"
			fill="#FF6E83"
		/>
		<path
			d="M118.9 17.1653C118.9 18.8092 117.567 20.1417 115.924 20.1417C114.28 20.1417 112.947 18.8092 112.947 17.1653C112.947 15.5214 114.28 14.1889 115.924 14.1889C117.568 14.1887 118.9 15.5214 118.9 17.1653ZM12.6668 3.25226C11.0229 3.25226 9.69043 4.58472 9.69043 6.22863C9.69043 7.87253 11.0229 9.205 12.6668 9.205C14.3107 9.205 15.6434 7.87253 15.6434 6.22863C15.6434 4.58472 14.3107 3.25226 12.6668 3.25226ZM59.3374 42.096C59.674 41.4359 60.0891 40.6842 60.5285 39.8885C62.7581 35.8496 65.8113 30.3185 65.995 23.8763C66.2157 16.1369 62.185 9.11011 54.0152 2.99113C52.5969 1.92882 50.5866 2.21785 49.5251 3.63562C48.463 5.05367 48.7515 7.06398 50.1696 8.12574C56.6637 12.9896 59.7426 18.0819 59.5827 23.6934C59.4432 28.5802 56.9311 33.1313 54.9123 36.788C54.4472 37.631 54.0075 38.427 53.6236 39.1798C52.8184 40.7578 53.4448 42.6896 55.0225 43.4949C55.4896 43.7333 55.9875 43.8462 56.4781 43.8462C57.6448 43.8462 58.7705 43.2069 59.3374 42.096Z"
			fill="#59CAFC"
		/>
	</svg>
);

const calendarStyles = {
	'& .MuiCalendarPicker-root': {
		width: 'min(550px,100%)',
		maxHeight: '500px',
		overflow: 'hidden',
		background: '#ffffff',
		border: '1px solid #EAECF0',
		borderRadius: '8px',
		margin: '0px 0px',
	},
	'& .PrivatePickersFadeTransitionGroup-root': {
		fontSize: '18px',
		color: '#344054',
		'& > div > div': {
			justifyContent: 'space-evenly',
		},
	},
	'& .PrivatePickersSlideTransition-root': {
		minHeight: '280px',
		'& > div': {
			'& > div': {
				justifyContent: 'space-evenly',
				paddingBottom: '6px',
			},
		},
	},
};

export {
	DAYS,
	MONTHS,
	CARD_HEIGHT,
	CARD_SPACING,
	notificationType,
	publicBookingSchema,
	newAppointmentSchema,
	menuProps,
	ScanTypeIcon,
	scheduleSuccessIcon,
	inputStyles,
	calendarStyles,
};
