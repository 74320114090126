import { useEffect } from 'react';
import axios from 'axios';
import useGetNotifications from './useGetNotifications';
import { useConfig } from '@worklist-2/core/src';
import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const useInitialize = () => {
	const __config = useConfig();
	const phoenixBlumeOaiPatientDemographicSync = useBooleanFlagValue('phoenix-blume-oai-patient-demographic-sync');

	const { setGenderOptions, setDefaultMapCenter, setHasLocationPermission, setShowUserMarker, setMapDefaultCenter } =
		useNewAppointmentContentV2Context();

	const { getNotificationsApi } = useGetNotifications();

	useEffect(() => {
		const initialize = async () => {
			// If the OAI - Blume sync feature is enabled
			if (phoenixBlumeOaiPatientDemographicSync) {
				// Get the notifications (for OAI data sync)
				getNotificationsApi();
			}

			// fetch gender options
			const valueSetResponse = await axios.get(`${__config.data_sources.blume}ValueSet?criteria=name:exact=sex`);
			const codes = valueSetResponse?.data?.entry[0]?.resource?.compose?.include[0]?.concept || [];
			setGenderOptions(codes.map(item => item.display));
		};

		const initializeLocation = () => {
			if (!navigator.permissions) {
				return;
			}

			navigator.permissions.query({ name: 'geolocation' }).then(PermissionStatus => {
				if (PermissionStatus.state !== 'granted') {
					setDefaultMapCenter();

					return;
				}

				setHasLocationPermission(true);

				navigator.geolocation.getCurrentPosition(
					position => {
						setShowUserMarker(true);
						setMapDefaultCenter({
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						});
					},
					() => {
						setDefaultMapCenter();
					}
				);
			});
		};

		initialize();
		initializeLocation();
	}, []);
};

export default useInitialize;
