import React from 'react';

import { FormTool } from '@worklist-2/ui/src/components/FormTool';

import { useFormStore } from '../../../stores';
import { handleFormData } from '../../../utils/forms';

const FormFilling = ({
	data,
	fromHistory,
	lastSyncedForm,
	autoSave,
	formData,
	setFormData,
	validateFormData,
	autoSaveDebounce,
	isOrderForm,
	isCompact,
	isPdfForm,
	isValidForm,
	pageRef,
	showBackBtn,
	showCloseBtn,
	setPdfInstance,
	userSignature,
	closeDrawer,
	setValidateFormData,
	updateForm,
	saveDocumentReference,
	setSubmitInProgress,
	useFhirUrl,
	getCompletedFormData,
	setLastSyncedForm,
	studyId,
	patientId,
	appointmentId,
	pdfInstance,
	appModeIsPatientPortal,
	onSubmit,
	sectionName,
	progressPercentage,
	pdfUrl,
	canEditWhenCompleted,
	getBlobStringFromBlumeForm,
}) => {
	const saveData = useFormStore(state => state.saveData);
	return (
		<FormTool
			noDefaultBanner
			banner={data?.resource?.formTemplate?.header?.banner}
			colour={data?.resource?.formTemplate?.header?.colour}
			descriptionColor="rgba(255, 255, 255, 0.6)"
			formDescription={data?.description}
			formId={isPdfForm ? data?.id : data?.referenceTemplate ? data?.referenceTemplate : data?.id}
			formTitle={data?.title}
			fromHistory={fromHistory}
			handleFormData={(key, index, value) =>
				handleFormData({
					key,
					index,
					value,
					lastSyncedForm,
					autoSave,
					formData,
					setFormData,
					validateFormData,
					autoSaveDebounce,
					data,
				})
			}
			headerString={isOrderForm ? 'Order Form' : data?.title}
			isCompact={isCompact}
			isReadOnly={data?.completed && !canEditWhenCompleted}
			isValidForm={isPdfForm ? true : isValidForm}
			logo={data?.resource?.formTemplate?.header?.logoImage}
			pageRef={pageRef}
			pdfHeight={fromHistory ? 'calc(100vh - 85px)' : '82vh'}
			pdfUrl={pdfUrl}
			progressPercentage={`${progressPercentage}%`}
			questionsData={fromHistory ? formData?.formTemplate?.content : data?.questionData}
			sectionName={sectionName}
			setPdfInstance={setPdfInstance}
			showBackBtn={showBackBtn}
			showCloseBtn={showCloseBtn}
			userSignature={userSignature}
			onCloseClick={closeDrawer}
			onSave={() =>
				saveData({
					forceCloseDrawer: true,
					autoSave: false,
					isPdfForm,
					formData,
					setFormData,
					setValidateFormData,
					isOrderForm,
					updateForm,
					saveDocumentReference,
					setSubmitInProgress,
					useFhirUrl,
					data,
					getCompletedFormData,
					closeDrawer,
					setLastSyncedForm,
					studyId,
					patientId,
					appointmentId,
					pdfInstance,
					appModeIsPatientPortal,
					getBlobStringFromBlumeForm,
				})
			}
			onSubmit={onSubmit}
		/>
	);
};

export default React.memo(FormFilling);
