import axios from 'axios';
import { useConfig } from '@worklist-2/core/src';
import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';

const useFetchStudies = () => {
	const __config = useConfig();

	const { setStudies } = useNewAppointmentContentV2Context();

	const fetchStudies = () => {
		axios.get(`${__config.data_sources.blume}Study/userstudies?isUploaded=true`).then(res => {
			if (res?.data) {
				setStudies(res.data);
			}
		});
	};

	const fetchStudiesAfterUpload = () => {
		axios.get(`${__config.data_sources.blume}Study/userstudies?isUploaded=true`).then(res => {
			setStudies(res.data);
		});
	};

	return {
		fetchStudies,
		fetchStudiesAfterUpload,
	};
};

export default useFetchStudies;
