// Core
import React from 'react';

import PropTypes from 'prop-types';

// MUI
import { Grid, Icon, IconButton, Stack, Typography, styled, Tooltip } from '@mui/material';

import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import PresentToAllOutlinedIcon from '@mui/icons-material/PresentToAllOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

// Custom
import ChatAvatar from './ChatAvatar';
import { useChatContext } from './ChatContext';
import CHAT_CONVERSATION_TYPES from './CHAT_CONVERSATION_TYPES';
import ChatCallDuration from './ChatCallDuration';

import ChatCallParticipantVideo from './ChatCallParticipantVideo';

import { useTranslation } from 'react-i18next';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

// Styled
const ChatConversationCallButton = styled(props => (
	<Tooltip title={props?.title ?? ''}>
		<IconButton {...props} />
	</Tooltip>
))({
	'--element-background-color': '#2e2d2d',
	'--element-foreground-color': '#ababab',

	background: 'var(--element-background-color)',
	color: 'var(--element-foreground-color)',
	height: '40px',
	width: '40px',

	'&:hover': {
		background: 'var(--element-background-color)',
	},

	'&.color-red': {
		'--element-background-color': '#da2911',
		'--element-foreground-color': '#ffffff',
	},

	'&.color-green': {
		'--element-background-color': '#25d366',
		'--element-foreground-color': '#ffffff',
	},
});

// Component
const ChatConversationCall = ({
	onAddUsersClick,
	onEndCallClick,
	loggedInEmail,
	onAcceptCallClick,
	handleMuteAudio,
	handleScreenShare,
}) => {
	const { t } = useTranslation('chat');
	const {
		setConversationType,
		expanded,
		MessagingIcon,
		callData,
		callParticipants,
		screenShare,
		callRoom,
		appBlumeMode,
	} = useChatContext();

	const proactEnableVideoCalling = useBooleanFlagValue('proact-enable-video-calling');

	const remoteParticipants = callData?.conversation?.attributes?.participants?.filter(
		participant => participant.email.toLowerCase() !== loggedInEmail?.toLowerCase()
	);

	const singleUser = remoteParticipants?.length === 1;
	const fewUsers = remoteParticipants?.length <= 3;

	const avatarSize = !expanded ? (singleUser ? 80 : 50) : singleUser ? 160 : fewUsers ? 150 : 100;
	const avatarOutlineSize = Math.max(4, Math.floor(avatarSize / 20));

	const isCaller = loggedInEmail === callData?.caller?.email?.toLowerCase();

	return (
		<Grid
			sx={{
				'--element-background-color': '#121212',
				backgroundColor: 'var(--element-background-color)',
				display: appBlumeMode && screenShare?.remoteScreenShared ? 'flex' : 'grid',
				gridTemplateRows: `${
					screenShare?.remoteScreenShared
						? `minmax(100px, ${expanded ? '0.72fr' : '1fr'})`
						: 'min-content 4fr 1fr'
				} `,
				padding: `${
					screenShare?.remoteScreenShared
						? 0
						: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)'
				}`,
			}}
		>
			{screenShare?.remoteScreenShared ? (
				<Stack alignItems="center" data-testid="screenShareStack" justifyContent="center">
					{callData?.status?.onCall &&
						callParticipants?.map(participant => (
							<ChatCallParticipantVideo key={participant.identity} participant={participant} />
						))}
				</Stack>
			) : (
				<>
					<Stack
						alignItems="start"
						data-testid="settingIcon"
						sx={{
							paddingTop: 'var(--element-content-spacing)',
						}}
					>
						{
							//TODO: CHAT - keep PersonAddOutlinedIcon and onAddUsersClick for future use
							// <ChatConversationCallButton
							// 	sx={{
							// 		visibility: section?.addUsers
							// 			? 'hidden'
							// 			: 'visible',
							// 	}}
							// 	onClick={onAddUsersClick}
							// >
							// 	<Icon component={PersonAddOutlinedIcon} />
							// </ChatConversationCallButton>
						}
						<ChatConversationCallButton
							sx={{
								// visibility: section?.addUsers ? 'hidden' : 'visible',
								visibility: 'hidden', // TODO - Chat - PRO-2040 - temporarily hide it until we implement Setting feature
							}}
						>
							<Icon component={SettingsOutlinedIcon} />
						</ChatConversationCallButton>
					</Stack>
					<Stack
						data-testid="participantAvatarContainer"
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Stack
							alignItems="center"
							direction="row"
							gap={!expanded ? '20px' : fewUsers ? '60px' : '40px'}
							justifyContent="center"
							sx={{
								paddingTop: 'var(--element-content-spacing)',
							}}
						>
							{remoteParticipants?.map((item, index) => (
								<Stack key={index} alignItems="center">
									<ChatAvatar
										className={index === 0 ? 'user-talking' : ''}
										data={item}
										showBadge={false}
										size={avatarSize}
										status={null}
										sx={{
											'--element-border-color': '#d0d0d0',
											'--element-box-shadow-color': '#c4c4c480',
											'--element-outline-size': `${avatarOutlineSize}px`,
											border: 'var(--element-outline-size) solid var(--element-border-color)',
											boxShadow:
												'0 0 0 var(--element-outline-size) var(--element-box-shadow-color)',
											marginBottom: '20px',

											'&.user-talking': {
												'--element-border-color': 'var(--color-primary)',
												'--element-box-shadow-color': '#42a5f580',
											},
										}}
									/>

									<Typography
										component="div"
										sx={{
											color: '#d0d0d0',
											fontSize: !expanded
												? '18px'
												: singleUser
												? '20px'
												: fewUsers
												? '16px'
												: '14px',
											fontWeight: 500,
											textAlign: 'center',
										}}
									>
										{item.name}
									</Typography>
								</Stack>
							))}
						</Stack>
						<Stack
							data-testid="callStatusAndDuration"
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{(callData?.status?.calling || callData?.status?.incoming) && (
								<Typography
									sx={{
										color: '#ffffff',
										fontSize: '14px',
										fontWeight: 400,
										marginTop: '5px',
									}}
								>
									{isCaller ? `${t('call.ringing')}......` : `${t('call.incomingCall')}......`}
								</Typography>
							)}
							{callData?.status?.onCall && callRoom?.participants?.size >= 1 && (
								<ChatCallDuration
									textStyle={{
										color: '#ffffff',
										fontSize: '18px',
										fontWeight: 400,
										marginTop: '5px',
									}}
								/>
							)}
						</Stack>
					</Stack>
				</>
			)}
			<Stack
				key="call"
				alignItems="center"
				direction="row"
				gap="10px"
				justifyContent="center"
				sx={
					appBlumeMode && screenShare?.remoteScreenShared
						? {
								padding: 'var(--element-content-spacing) 0',
								position: 'absolute',
								top: '80%',
								left: '50%',
								transform: 'translate(-50%, 0%)',
								background: '#0000005e',
								width: '360px',
								backdropFilter: 'blur(6px)',
								borderRadius: '20px',
						  }
						: { padding: 'var(--element-content-spacing) 0' }
				}
			>
				{callData?.status?.onCall && callRoom?.participants?.size >= 1 && (
					<>
						<ChatConversationCallButton
							title={callData?.audioIsMute ? t('icon.unmute') : t('icon.mute')}
							onClick={handleMuteAudio}
						>
							<Icon component={callData?.audioIsMute ? MicOffOutlinedIcon : MicNoneOutlinedIcon} />
						</ChatConversationCallButton>
						<ChatConversationCallButton
							title={screenShare?.localScreenTrack ? t('icon.stopShareScreen') : t('icon.shareScreen')}
							onClick={handleScreenShare}
						>
							<Icon
								component={
									screenShare?.localScreenTrack
										? CancelPresentationOutlinedIcon
										: PresentToAllOutlinedIcon
								}
							/>
						</ChatConversationCallButton>

						{proactEnableVideoCalling && (
							<ChatConversationCallButton>
								<Icon component={VideocamOutlinedIcon} />
							</ChatConversationCallButton>
						)}
					</>
				)}
				<ChatConversationCallButton
					title={t('icon.chat')}
					onClick={() => setConversationType(CHAT_CONVERSATION_TYPES.MESSAGING)}
				>
					{MessagingIcon || <Icon component={SmsOutlinedIcon} />}
				</ChatConversationCallButton>

				<ChatConversationCallButton
					className="color-red"
					title={callData?.status?.incoming ? t('icon.reject') : t('icon.endCall')}
					onClick={onEndCallClick}
				>
					<Icon component={CallEndOutlinedIcon} />
				</ChatConversationCallButton>
				{callData?.status?.incoming && !isCaller && (
					<ChatConversationCallButton
						className="color-green"
						title={t('icon.answer')}
						onClick={onAcceptCallClick}
					>
						<Icon component={CallOutlinedIcon} />
					</ChatConversationCallButton>
				)}
			</Stack>
		</Grid>
	);
};

ChatConversationCall.propTypes = {
	onAddUsersClick: PropTypes.func,
	onEndCallClick: PropTypes.func,
	loggedInEmail: PropTypes.string,
	onAcceptCallClick: PropTypes.func,
	handleMuteAudio: PropTypes.func,
};

export default ChatConversationCall;
