import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import ChangeLog from './ChangeLog';
import CurrentVersion from './CurrentVersion';
import { BackDrop, mainBox, NewsBox, NewsBoxFooter, NewsBoxWrapper } from './newsStyles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import BackdropComp from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';

const NewsModal = ({ open, onClose, isPatientView }) => {
	const [tab, setTab] = useState(0);
	const { t } = useTranslation('login');

	return (
		<Modal
			BackdropComponent={BackdropComp}
			BackdropProps={{
				timeout: 500,
			}}
			open={open}
			onClose={onClose}
		>
			<Fade in={open} timeout={500}>
				<BackDrop>
					<Box sx={mainBox}>
						<NewsBoxWrapper>
							<NewsBox>
								<IconButton
									sx={{
										margin: 0,
										alignSelf: 'flex-end',
										marginTop: '20px',
										marginRight: '20px',
									}}
									onClick={onClose}
								>
									<CloseIcon sx={{ color: '#000' }} />
								</IconButton>
								{tab == 0 && <CurrentVersion isPatientView={isPatientView} />}
								{tab == 1 && <ChangeLog isPatientView={isPatientView} />}
							</NewsBox>
							<NewsBoxFooter>
								<Button className={`${tab == 0 ? 'active' : 'inactive'}`} onClick={() => setTab(0)}>
									{t('Current Version')}
								</Button>
								<Button className={`${tab == 1 ? 'active' : 'inactive'}`} onClick={() => setTab(1)}>
									{t('Change Log')}
								</Button>
							</NewsBoxFooter>
						</NewsBoxWrapper>
					</Box>
				</BackDrop>
			</Fade>
		</Modal>
	);
};

export default NewsModal;

NewsModal.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func,
	isPatientView: PropTypes.bool,
};
