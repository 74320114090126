import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';

import { useIsTablet } from '@worklist-2/core/src';
// eslint-disable-next-line import/no-unresolved
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

import { useAppointmentStore, useFormStore } from '../../../stores';
import { APPOINTMENT_STATUS, FORM_TYPES } from '../../../consts';
import Drawer from '../../Drawer';
import DrawerV2 from '../../Drawer/DrawerV2';
import { FORM_FILLED_SUCCESS } from '../../../analytics/eventTypes';
import sendAnalyticsEvent from '../../../analytics';
import FormFillingDrawer from './FormDrawerV3';
import RenderFormsList from './RenderFormsList';

const FormsDrawer = () => {
	const getFormsByPatientId = useFormStore(state => state.getFormsByPatientId);
	const updateFormsInStore = useFormStore(state => state.updateForms);
	const isOpen = useFormStore(state => state.isOpen);
	const setOpenDrawer = useFormStore(state => state.setIsOpen);
	const selectedForm = useFormStore(state => state.selectedForm);
	const setSelectedForm = useFormStore(state => state.setSelectedForm);
	const date = useFormStore(state => state.date);
	const appointmentId = useFormStore(state => state.appointmentId);
	const appointmentStatus = useFormStore(state => state.appointmentStatus);
	const type = useFormStore(state => state.type);
	const patientId = useFormStore(state => state.patientId);
	const studyId = useFormStore(state => state.studyId);
	const studyForms = useFormStore(state => state.studyForms);
	const setHideForms = useFormStore(state => state.setHideForms);
	const resetFormDrawer = useFormStore(state => state.closeFormDrawer);
	const appointments = useAppointmentStore(state => state.appointments);
	const [forms, setForms] = useState([]);
	const [isFormOpenOnMobile, setIsFormOpenOnMobile] = useState(false);
	const [closeFormsList, setCloseFormsList] = useState(false);

	const { t } = useTranslation('appointments');
	const phoenixBlumeViewFormsInFullScreen = useBooleanFlagValue('phoenix-blume-view-forms-in-full-screen');
	const isTablet = useIsTablet();

	useEffect(() => {
		if (!isOpen || !patientId) return;

		if (studyForms?.length) setForms(studyForms);

		// set forms in local state to prevent rerenders
		getFormsByPatientId({ patientId }).then(res => {
			const transformedForms = res
				?.filter(
					form =>
						form.resource?.type === FORM_TYPES.REGISTRATION_FORM ||
						(form.resource?.type === FORM_TYPES.CLINICAL_FORM && form?.resource?.study?.id === studyId)
				)
				.map(item => ({
					id: item.resource.id,
					patient: item.resource?.patient,
					completed: item.resource?.completed,
					organizationId: item.resource.organizationId,
					status: item.resource.status.charAt(0).toUpperCase() + item.resource.status.slice(1),
					title: item.resource.name,
					type: item.resource.type,
					description: item.resource.description,
					questionData: item.resource.formTemplate?.content,
					lastModified: item.resource.lastModified,
					resource: item.resource,
					referenceTemplate: item.resource?.referenceTemplate,
					pdfFileName: item.resource.pdfFileName,
				}));

			if (setHideForms)
				setHideForms(
					!transformedForms?.length ||
						(appointmentStatus === APPOINTMENT_STATUS.CANCELLED &&
							!transformedForms.filter(d => d?.completed)?.length)
				);

			if (transformedForms?.length) setForms(transformedForms);
		});
	}, [appointmentId, appointmentStatus, getFormsByPatientId, isOpen, patientId, setHideForms, studyForms, studyId]);

	useEffect(() => {
		if (!isOpen) {
			resetFormDrawer();
			setForms([]);
		}
	}, [isOpen, resetFormDrawer]);

	const pendingForms = useMemo(() => forms.filter(({ completed }) => !completed), [forms]);

	const completedForms = useMemo(() => forms.filter(({ completed }) => completed), [forms]);

	const onOpenFormsDrawer = useCallback(() => {
		if (phoenixBlumeViewFormsInFullScreen) {
			if (pendingForms.length > 0 && appointmentStatus !== APPOINTMENT_STATUS.CANCELLED) {
				setSelectedForm(pendingForms[0]);
			} else if (completedForms.length > 0) {
				setSelectedForm(completedForms[0]);
			}
		} else {
			setSelectedForm({});
		}
	}, [phoenixBlumeViewFormsInFullScreen, pendingForms, appointmentStatus, completedForms, setSelectedForm]);

	const updateFormData = useCallback(
		async updatedForm => {
			const updatedForms = forms.map(form => (form.id === updatedForm.id ? updatedForm : form));
			setForms(updatedForms);
			updateFormsInStore({ patientId, formId: updatedForm.id });
		},
		[forms, patientId, updateFormsInStore]
	);

	const onFormSubmit = useCallback(async () => {
		if (!phoenixBlumeViewFormsInFullScreen || !forms) return;

		const currentForm = pendingForms.find(form => form.id === selectedForm.id);
		const currentFormIndex = pendingForms.findIndex(form => form.id === selectedForm.id);

		const nextForm =
			// eslint-disable-next-line no-nested-ternary
			pendingForms.length > 1 && Boolean(pendingForms[currentFormIndex + 1])
				? pendingForms[currentFormIndex + 1]
				: completedForms.length
				? completedForms[0]
				: {
						...currentForm,
						completed: true,
						resource: {
							...currentForm?.resource,
							completed: true,
						},
				  };

		sendAnalyticsEvent(FORM_FILLED_SUCCESS, {
			appointmentId,
			studyId,
			formId: currentForm.id,
			appointmentTime: new Date(date)?.toISOString(),
			modality: type,
			timestamp: Date.now(),
		});

		const updatedForms = forms.map(form => {
			if (currentForm.id === form.id) {
				return {
					...form,
					completed: true,
					resource: { ...form?.resource, completed: true },
				};
			}

			return form;
		});

		setForms(updatedForms);
		setSelectedForm(nextForm);
		setOpenDrawer(true);
	}, [
		appointmentId,
		completedForms,
		date,
		forms,
		pendingForms,
		phoenixBlumeViewFormsInFullScreen,
		selectedForm?.id,
		setOpenDrawer,
		setSelectedForm,
		studyId,
		type,
	]);

	const appointment = useMemo(
		() => appointments.find(appt => appt?.id === appointmentId),
		[appointmentId, appointments]
	);

	if (!isOpen || !forms.length) return null;

	return phoenixBlumeViewFormsInFullScreen && !isTablet ? (
		<DrawerV2
			isResponsive
			customOpen={isOpen}
			heading={closeFormsList ? '' : t('Forms')}
			setCustomOpen={setOpenDrawer}
			sx={{ width: '100%', flexShrink: 0 }}
			variant="temporary"
			onClose={() => setCloseFormsList(false)}
			onOpen={onOpenFormsDrawer}
		>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<Grid container spacing={0}>
						{!closeFormsList && (
							<Grid item xs={3}>
								<RenderFormsList
									closeFormsList={closeFormsList}
									completedForms={completedForms}
									dateStart={date}
									hidePendingForms={appointmentStatus === APPOINTMENT_STATUS.CANCELLED}
									pendingForms={pendingForms}
									setCloseFormsList={setCloseFormsList}
									setOpenDrawer={setOpenDrawer}
									type={type}
								/>
							</Grid>
						)}
						{_.isEmpty(selectedForm) ? (
							<Grid
								sx={{
									height: '60vh',
									width: '100%',
									borderLeft: '1px solid lightgrey',
									display: 'grid',
									placeItems: 'center',
								}}
								xs={closeFormsList ? 12 : 9}
							>
								<Typography fontFamily="ROBOTO" fontSize={16} opacity={0.6} variant="p">
									{t('Please choose an item from the list!')}
								</Typography>
							</Grid>
						) : (
							<Grid
								item
								style={{
									position: 'absolute',
									top: '50%',
									left: closeFormsList ? '75.7%' : '81.7%',
									transform: 'translate(-75%, -50%)',
									width: closeFormsList ? '100%' : '90%',
									borderLeft: '1px solid lightgrey',
									height: '100%',
								}}
								xs={closeFormsList ? 12 : 9}
							>
								{isOpen && (
									<Box>
										<IconButton
											className="collapse-icon"
											data-testid="blume-KeyboardDoubleArrowRight"
											sx={{
												height: '30px',
												width: '30px',
												background: '#dadada',
												position: 'fixed',
												zIndex: '99',
												left: '-7px',
												top: '85px',
												backgroundColor: '#EBF5FE',
												color: 'var(--color-primary)',
												visibility: closeFormsList ? 'visible' : 'hidden',
											}}
											onClick={() => setCloseFormsList(false)}
										>
											<KeyboardDoubleArrowRight fontSize="small" />
										</IconButton>
										<FormFillingDrawer
											key={selectedForm?.id}
											appointment={appointment?.resource ?? ''}
											appointmentId={appointmentId}
											data={selectedForm}
											open={isOpen}
											patientId={patientId}
											selectedForm={selectedForm}
											setOpen={setOpenDrawer}
											showCloseBtn={false}
											studyId={studyId}
											updateForm={updateFormData}
											onSubmit={onFormSubmit}
										/>
									</Box>
								)}
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</DrawerV2>
	) : (
		<Drawer
			isResponsive
			customOpen={isOpen}
			heading={t('Forms')}
			paperProps={{ top: { xs: '40px', lg: 0 } }}
			setCustomOpen={setOpenDrawer}
			onOpen={onOpenFormsDrawer}
		>
			<RenderFormsList
				closeFormsList={closeFormsList}
				completedForms={completedForms}
				dateStart={date}
				hidePendingForms={appointmentStatus === APPOINTMENT_STATUS.CANCELLED}
				pendingForms={pendingForms}
				setCloseFormsList={setCloseFormsList}
				setOpenDrawer={setIsFormOpenOnMobile}
				type={type}
			/>
			{isFormOpenOnMobile && (
				<FormFillingDrawer
					appointment={appointment?.resource ?? ''}
					appointmentId={appointmentId}
					data={selectedForm}
					open={isFormOpenOnMobile}
					patientId={patientId}
					selectedForm={selectedForm}
					setOpen={setIsFormOpenOnMobile}
					showCloseBtn={false}
					studyId={studyId}
					updateForm={updateFormData}
					onSubmit={onFormSubmit}
				/>
			)}
		</Drawer>
	);
};

export default FormsDrawer;
