import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment-timezone';

import { useConfig, useAuth } from '@worklist-2/core/src';
import { useImportDispatcher } from '@worklist-2/ui/src/views/ImportView/useImportDispatcher';

import { useAppointmentStore } from '../../../../../stores/appointment';
import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';
import { useToastMessageContext } from '../../../../../context/ToastMessageContext';
import { useSettingsViewContext } from '../../../../../context/SettingsViewContext';
import { useAppointmentContext } from '../../../AppointmentContext/AppointmentContext';
import addAttachments from '../../../../../api/addAttachments';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const useOnSubmit = () => {
	const getAppointmentsFromStore = useAppointmentStore(state => state.getAppointments);
	const { setToastMsg } = useToastMessageContext();
	const __config = useConfig();
	const { getAppointments, setHasFirstAppointment } = useAppointmentContext();
	const { t } = useTranslation('appointments');
	const { user, setTriggerDocumentSectionRefresh } = useSettingsViewContext();
	const { accessToken, sessionId } = useAuth();
	const phoenixBlumeNewHomepageDesign = useBooleanFlagValue('phoenix-blume-new-homepage-design');
	const phoenixBlumeRestrictHomeScreenFunctionality = useBooleanFlagValue(
		'phoenix-blume-restrict-home-screen-functionality'
	);
	let importDispatcher = null;
	const studyDataLoader = null;
	const patientDataLoader = null;
	const coordinatorWorkerURL = `${__config.blume_base_url}scripts/importcoordinatorworker.js`;
	const userHash = user?.profileId;

	if (!window.importDispatcher) {
		importDispatcher = useImportDispatcher(
			studyDataLoader,
			patientDataLoader,
			accessToken,
			sessionId,
			coordinatorWorkerURL
		);
		window.importDispatcher = importDispatcher;
	} else {
		importDispatcher = window.importDispatcher;
	}

	const {
		isUpdateForm,
		setOpenDrawer,
		appointment,
		filesUploaded,
		setValidate,
		availableTimeSlots,
		selectedDay,
		selectedStudies,
		setIsRequestLoading,
		organizationTimeZone,
		rescheduleFacility,
		form,
		setPage = Function.prototype,
	} = useNewAppointmentContentV2Context();

	const patient = form.watch('patient');
	const scanType = form.watch('scanType');
	const facility = form.watch('facility');

	const attachFile = useCallback(
		async (id, fileData, internalStudyId) => {
			try {
				if (!filesUploaded?.length || !id) return null;

				for (const file of filesUploaded) {
					fileData.append('files', file);
				}

				await addAttachments({ __config, appointmentId: id, fileData, userHash, internalStudyId });
				setTriggerDocumentSectionRefresh(true);

				setIsRequestLoading(false);
			} catch (err) {
				setToastMsg(err.message || 'Something might went wrong!');
			}
		},
		[filesUploaded, setIsRequestLoading, userHash]
	);

	const onSubmit = form.handleSubmit(async () => {
		setIsRequestLoading(true);
		const fileData = new FormData();
		const selectedSlot = availableTimeSlots.filter(eachSlot => eachSlot.selected === true);

		if (selectedSlot.length > 0) {
			const startDateTime = `${selectedDay} ${new Date(selectedSlot[0].start * 60 * 1000)
				.toISOString()
				.substring(11, 19)}`;
			const endDateTime = `${selectedDay} ${new Date(selectedSlot[0].end * 60 * 1000)
				.toISOString()
				.substring(11, 19)}`;

			const startDateTimeDateObj = new Date(startDateTime);
			const endDateTimeDateObj = new Date(endDateTime);

			if (isUpdateForm) {
				const NewStartTimeInOrgTimeZone = organizationTimeZone
					? moment(startDateTime).tz(rescheduleFacility?.ianaTimezone, true)
					: startDateTimeDateObj;
				const EndTimeInOrgTimeZone = organizationTimeZone
					? moment(endDateTime).tz(rescheduleFacility?.ianaTimezone, true)
					: endDateTimeDateObj;
				let updatedResourceObj = appointment?.resource.extension;
				if (updatedResourceObj.find(item => item?.url.includes('blumeNotes'))) {
					updatedResourceObj = updatedResourceObj?.map(item =>
						item.url.includes('blumeNotes')
							? {
									url: 'http://www.ramsoft.com/fhir/StructureDefinition/appointment/blumeNotes',
									valueString: form.getValues('notes'),
							  }
							: item
					);
				} else {
					updatedResourceObj.push({
						url: 'http://www.ramsoft.com/fhir/StructureDefinition/appointment/blumeNotes',
						valueString: form.getValues('notes'),
					});
				}
				const updatedAppointmentJson = {
					...appointment?.resource,
					start: new moment(NewStartTimeInOrgTimeZone).utc(),
					end: new moment(EndTimeInOrgTimeZone).utc(),
					extension: updatedResourceObj,
					status: 'proposed',
				};
				await axios
					.put(`${__config.data_sources.blume}Appointment/${appointment?.id}`, updatedAppointmentJson)
					.then(async response => {
						if (response.status == 200) {
							await attachFile(appointment?.id, fileData, response.data?.supportingInformation[0]?.id);
							setToastMsg('Appointment Rescheduled Successfully');
							setIsRequestLoading(false);
							setOpenDrawer(false);
							if (phoenixBlumeNewHomepageDesign) {
								await getAppointmentsFromStore(phoenixBlumeRestrictHomeScreenFunctionality);
							} else {
								await getAppointments();
							}
						} else {
							setToastMsg('Appointment reschedule failed. Please contact the facility');
							setIsRequestLoading(false);
							setOpenDrawer(false);
						}
					})
					.catch(err => {
						setToastMsg(err.message || 'Something might went wrong!');
					});
			} else {
				const StartTimeInOrgTimeZone = organizationTimeZone
					? moment(startDateTime).tz(facility?.ianaTimezone, true)
					: startDateTimeDateObj;
				const EndTimeInOrgTimeZone = organizationTimeZone
					? moment(endDateTime).tz(facility?.ianaTimezone, true)
					: endDateTimeDateObj;
				const patientName = [patient.lastName, patient.firstName];
				if (patient.middleName) {
					patientName.splice(1, 0, patient.middleName);
				}

				const submitData = {
					patientName: patientName.join(' '),
					internalOrganizationId: facility.internalOrganizationID,
					internalHealthcareServiceId: selectedSlot[0].healthcareServices[0],
					blumeDescription: form.getValues('notes'),
					start: new moment(StartTimeInOrgTimeZone).utc(),
					patientFriendlyModality: scanType.Name,
					end: new moment(EndTimeInOrgTimeZone).utc(),
				};
				submitData.familyMemberHash = patient?.profileId;

				await axios
					.post(`${__config.data_sources.blume}Appointment/schedule`, submitData)
					.then(async response => {
						if (response.status == 200) {
							await attachFile(response.data.id, fileData, response.data?.supportingInformation[0]?.id);
							setToastMsg(t('Appointment Requested Successfully'));
							setHasFirstAppointment(true);
							setPage('success');
							if (phoenixBlumeNewHomepageDesign) {
								await getAppointmentsFromStore(phoenixBlumeRestrictHomeScreenFunctionality);
							} else {
								await getAppointments();
							}

							// share studies
							// add shared study ID's to appointment doc based on flag

							if (selectedStudies.length > 0) {
								const internalPatientID = response?.data?.participant
									?.find(eachParticipant =>
										eachParticipant?.actor?.reference?.toLowerCase().includes('patient')
									)
									?.actor.reference.split('/')[1];
								const shareStudyPromiseArray = [];
								selectedStudies.forEach(item => {
									const shareStudyPromise = new Promise((resolve, reject) => {
										axios
											.post(
												`${__config.data_sources.blume}Study/dicomshare?internalManagingOrgId=${submitData.internalOrganizationId}&InternalStudyId=${item.id}&internalPatientID=${internalPatientID}`
											)
											.then(data => resolve(data))
											.catch(error => reject(error));
									});
									shareStudyPromiseArray.push(shareStudyPromise);
								});
								Promise.all(shareStudyPromiseArray).catch(error => {
									console.error(error);
									setToastMsg(error.message || 'Something might went wrong!');
								});
							}
						} else {
							setToastMsg('Appointment Request Failed');
							setIsRequestLoading(false);
						}
					})
					.catch(err => {
						setToastMsg(err.message || 'Something might went wrong!');
					});
			}
		} else {
			setValidate(true);
			setIsRequestLoading(false);
		}
	});

	return {
		onSubmit,
	};
};

export default useOnSubmit;
