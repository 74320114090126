import React from 'react';
import { Icon, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import { useToastMessageContext } from '../../context/ToastMessageContext';

import { useIsTablet, useIsMobile, useImportTabContext } from '@worklist-2/core/src';

const BlumeToastMessage = () => {
	const { toastMsg, setToastMsg } = useToastMessageContext();
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const { sideBarIsOpen } = useImportTabContext();
	const marginLeft = sideBarIsOpen ? '210px' : '70px';
	const toastMessage = () => (
		<Box
			sx={{
				borderRadius: '20px',
				display: 'flex',
				alignItems: 'left',
				marginBottom: '20px',
			}}
			onClick={() => {
				setToastMsg(false);
			}}
		>
			<Icon component={CheckIcon} sx={{ marginRight: '10px', color: 'rsPrimary.main' }} />

			<Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{toastMsg}</Typography>
		</Box>
	);
	return (
		<Snackbar
			disableWindowBlurListener
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: isMobile || isTablet ? 'center' : 'left',
			}}
			autoHideDuration={3000}
			message={toastMessage()}
			open={!!toastMsg}
			style={{ bottom: '-15px' }}
			sx={{
				marginLeft: isMobile || isTablet ? undefined : marginLeft,
				'@media (min-width: 600px)': {
					'& .MuiSnackbarContent-root': {
						minWidth: '100%',
						height: '70px',
						borderRadius: '20px',
						justifyContent: 'left',
						fontSize: '14px',
						color: '#FFFFFF',
					},
				},
				'& .MuiSnackbarContent-root ': {
					justifyContent: 'left',
					height: '70px',
					borderRadius: '20px',
				},
			}}
			onClose={() => {
				setToastMsg(false);
			}}
		/>
	);
};

export default BlumeToastMessage;
