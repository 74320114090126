// Core

import React from 'react';

import PropTypes from 'prop-types';

// MUI

import { Box, Typography } from '@mui/material';

// Custom

import { useChatContext } from './ChatContext';
import ChatMain from './ChatMain';
import ChatEmpty from './ChatEmpty';
import ChatConversation from './ChatConversation';
import ChatConversationAddUsers from './ChatConversationAddUsers';
import ChatNew from './ChatNew';
import CHAT_SECTIONS from './CHAT_SECTIONS';
import { useTranslation } from 'react-i18next';
import ChatAddContact from './ChatAddContact';

// Chat Layout component

const ChatLayout = ({ children }) => {
	const { expanded } = useChatContext();

	const classList = ['chat-layout', !expanded ? 'chat-layout--not-expanded' : 'chat-layout--expanded'];

	return (
		<Box
			className={classList.join(' ')}
			sx={{
				display: 'grid',
				gridTemplateColumns: !expanded ? '100%' : '340px 1fr',
				gridTemplateRows: '100%',
			}}
		>
			{children}
		</Box>
	);
};

ChatLayout.propTypes = {
	children: PropTypes.node,
};

// Chat Container component

const ChatContainer = ({ sx, children }) => (
	<Box
		className="chat-container"
		sx={{
			display: 'grid',
			gridTemplateRows: '100%',
			...sx,
		}}
	>
		{children}
	</Box>
);

ChatContainer.propTypes = {
	sx: PropTypes.object,
	children: PropTypes.node,
};

// Chat Container Primary Content Empty component

const ChatContainerPrimaryContentEmpty = () => {
	const { t } = useTranslation('chat');
	return (
		<Box className="chat-primary-content" sx={{ ...chatExpandedPrimaryContentSx, display: 'grid' }}>
			<ChatEmpty
				sx={{
					borderRadius: 'var(--border-radius-base)',
					boxShadow: '0px 10px 20px var(--element-content-box-shadow-color)',
					padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',
					'& .MuiIcon-root': { display: 'none' },
				}}
			>
				<Typography
					component="div"
					sx={{
						color: 'var(--element-foreground-color)',
						fontSize: '30px',
						marginTop: '40px',
					}}
				>
					{t('chatMain.noChatAvailable')}
				</Typography>

				<Typography
					component="div"
					sx={{
						color: 'var(--element-foreground-color-secondary)',
						fontSize: '18px',
						marginTop: '5px',
					}}
				>
					{t('chatMain.startNewChat')}
				</Typography>
			</ChatEmpty>
		</Box>
	);
};

// Primary container props

const chatExpandedPrimarySx = {
	padding: '0 var(--element-content-spacing) var(--element-content-spacing) 0',
	transition: 'padding .2s',
};

const chatExpandedPrimaryContentSx = {
	'--element-content-spacing-left': '20px',
	'--element-content-spacing-right': '20px',
	'--element-content-spacing-all':
		'var(--element-content-spacing) var(--element-content-spacing-right) var(--element-content-spacing) var(--element-content-spacing-left)',
	height: 'auto',
};

// Secondary container props

const chatExpandedSecondarySx = {
	padding:
		'0 var(--element-content-spacing-right) var(--element-content-spacing) var(--element-content-spacing-left)',
	transition: 'padding .2s',
};

const chatExpandedSecondaryContentSx = {
	'--element-content-spacing-left': '20px',
	'--element-content-spacing-right': '20px',
	'--element-content-spacing-all':
		'var(--element-content-spacing) var(--element-content-spacing-right) var(--element-content-spacing) var(--element-content-spacing-left)',
	height: 'auto',
};

// Component

const Chat = ({ mainHeaderChildren, commonHeaderIcons, onHeaderMouseDown, setIsClickAction, isAiChat }) => {
	const { section, expanded, smallView } = useChatContext();

	const sectionName = section?.name || null;

	switch (sectionName) {
		case CHAT_SECTIONS.CONVERSATION:
		case CHAT_SECTIONS.SUPPORT:
			return (
				<ChatLayout>
					{expanded ? (
						<ChatContainer sx={chatExpandedSecondarySx}>
							{!section.addUsers ? (
								<ChatMain
									className="chat-secondary-content"
									headerChildren={mainHeaderChildren}
									setIsClickAction={setIsClickAction}
									sx={chatExpandedSecondaryContentSx}
								/>
							) : (
								<ChatConversationAddUsers
									className="chat-secondary-content"
									sx={chatExpandedSecondaryContentSx}
								/>
							)}
						</ChatContainer>
					) : null}

					<ChatContainer sx={expanded ? chatExpandedPrimarySx : smallView ? { height: '70vh' } : undefined}>
						<ChatConversation
							className="chat-primary-content"
							commonHeaderIcons={commonHeaderIcons}
							conversation={section?.conversation}
							isAiChat={isAiChat}
							sx={expanded ? chatExpandedPrimaryContentSx : undefined}
							onHeaderMouseDown={onHeaderMouseDown}
						/>
					</ChatContainer>
				</ChatLayout>
			);
		case CHAT_SECTIONS.ADD_CONTACT:
			return <ChatAddContact />;

		case CHAT_SECTIONS.NEW:
		case CHAT_SECTIONS.NEW_GROUP_ADD_PARTICIPANTS:
		case CHAT_SECTIONS.NEW_GROUP_CREATE:
		case CHAT_SECTIONS.GROUP_ADD_PARTICIPANTS:
		case CHAT_SECTIONS.GROUP_REMOVE_PARTICIPANTS:
			return (
				<ChatLayout>
					<ChatContainer sx={expanded ? chatExpandedSecondarySx : undefined}>
						<ChatNew
							className="chat-secondary-content"
							sx={expanded ? chatExpandedSecondaryContentSx : undefined}
						/>
					</ChatContainer>

					{expanded ? (
						<ChatContainer sx={chatExpandedPrimarySx}>
							<ChatConversation
								className="chat-primary-content"
								commonHeaderIcons={commonHeaderIcons}
								conversation={section?.conversation}
								sx={expanded ? chatExpandedPrimaryContentSx : undefined}
								onHeaderMouseDown={onHeaderMouseDown}
							/>
						</ChatContainer>
					) : null}
				</ChatLayout>
			);
	}

	return (
		<ChatLayout>
			<ChatContainer sx={expanded ? chatExpandedSecondarySx : undefined}>
				<ChatMain
					headerChildren={mainHeaderChildren}
					setIsClickAction={setIsClickAction}
					sx={expanded ? chatExpandedSecondaryContentSx : undefined}
				/>
			</ChatContainer>

			{expanded ? (
				<ChatContainer sx={chatExpandedPrimarySx}>
					<ChatContainerPrimaryContentEmpty />
				</ChatContainer>
			) : null}
		</ChatLayout>
	);
};

Chat.propTypes = {
	mainHeaderChildren: PropTypes.node,
	commonHeaderIcons: PropTypes.object,
	onHeaderMouseDown: PropTypes.func,
};

export default Chat;
