import React from 'react';
import AppointmentDrawerStickyTop from './AppointmentDrawerStickyTop';
import { useTranslation } from 'react-i18next';

import BlumeAssistant from '../../BlumeAssistant';

const BlumeAssistantDrawerContent = () => {
	const { t } = useTranslation('drawer');

	return (
		<>
			<AppointmentDrawerStickyTop title={t('Assistant')} />
			<BlumeAssistant />
		</>
	);
};

export default BlumeAssistantDrawerContent;
