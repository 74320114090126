// core
import React from 'react';

// MUI
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import PropTypes from 'prop-types';
import { DeleteProgress } from '@worklist-2/ui/src/components';

const EditDeleteButton = ({ sx, onEdit, onDelete }) => (
	<Box
		className="CardItemActions"
		sx={{
			display: 'none',
			columnGap: '8px',
			position: 'absolute',
			right: '10px',
			bottom: '10px',
			...sx,
		}}
	>
		<IconButton
			data-testid="edit-icon"
			sx={{
				padding: '4px',
				background: '#FFFFFF',
				boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
				'&:hover': { background: '#FFFFFF' },
			}}
			onClick={onEdit}
		>
			<Icon component={EditOutlinedIcon} />
		</IconButton>
		<DeleteProgress
			backgroundColor="#FFFFFF"
			circleSize={32}
			color="#CF6679"
			sx={{
				background: '#FFFFFF',
				padding: '4px',
				boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.08)',
				'&:hover': { background: '#FFFFFF' },
			}}
			onDelete={onDelete}
		/>
	</Box>
);

EditDeleteButton.propTypes = {
	sx: PropTypes.object, //style object
	onEdit: PropTypes.func, // edit onclick function
	onDelete: PropTypes.func, // delete onclick function
};

export default EditDeleteButton;
