import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useToastMessageContext } from '@worklist-2/patientPortal/src/context/ToastMessageContext';

import { ActionButtons, ActionIconButton } from './AppointmentButtons';
import moment from 'moment';

const AppointmentCardActionButtons = ({
	appointment,
	handleOnCancelButton,
	handleOnSubmitAction,
	isCancelReasonRendered,
	disableButtonForAPICall,
	openDrawer = false,
	setOpenDrawer = Function.prototype,
	setHighlight = Function.prototype,
}) => {
	const { setToastMsg } = useToastMessageContext();

	const { status } = appointment;
	const shouldRenderButtons = status !== 'booking';
	const renderActionButtons = status === 'pending' || isCancelReasonRendered;
	const isFfTurnedForAppointment = !(
		appointment.resource.extension?.some(item => item?.url?.includes('isReScheduleCancelHidden') ?? false) ?? false
	);
	const { t } = useTranslation('appointments');

	const handleClick = useCallback(() => {
		const date1 = moment(new Date());
		const date2 = moment(appointment?.dateStart || new Date());
		const diffInHours = Math.abs(date1.diff(date2, 'hours'));
		if (diffInHours < 12) {
			setToastMsg(t('Appointment cannot be rescheduled within 12 hrs of scheduled time'));
		} else {
			setOpenDrawer(!openDrawer);
			if (!openDrawer) setHighlight();
		}
	}, []);

	return (
		<>
			{renderActionButtons ? (
				<ActionButtons
					disableButtonForAPICall={disableButtonForAPICall}
					isCancelReason={isCancelReasonRendered}
					shouldRenderCancel={isFfTurnedForAppointment}
					onCancel={handleOnCancelButton}
					onSubmit={handleOnSubmitAction}
				/>
			) : (
				<>
					{shouldRenderButtons && isFfTurnedForAppointment && (
						<>
							<ActionIconButton icon="chatIcon" sx={{ paddingLeft: '8px', display: 'none' }} />
							<ActionIconButton
								icon="editIcon"
								sx={{ paddingLeft: '8px' }}
								tooltipTxt={t('Reschedule Appointment')}
								onClick={handleClick}
							/>
						</>
					)}
					{isFfTurnedForAppointment && (
						<ActionIconButton
							icon="cancelIcon"
							tooltipTxt={t('Cancel Appointment')}
							onClick={handleOnCancelButton}
						/>
					)}
				</>
			)}
		</>
	);
};

export default AppointmentCardActionButtons;
