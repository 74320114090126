// Core
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';

// Custom
import ChatAvatar, { CHAT_AVATAR_SIZE } from './ChatAvatar';
import ChatAvatarGroup from './ChatAvatarGroup';
import { useChatContext } from './ChatContext';
import ChatSearchHighlightMatches from './ChatSearchHighlightMatches';

// Icons
import BlumeIcon from '@worklist-2/ui/src/assets/icons/Chat/blume.svg';

// Defaults
const SPACING = 10;

// Component
const ChatContact = ({
	title,
	titleProps,
	userCount,
	isOnline,
	isBlumeUser = false,
	size = CHAT_AVATAR_SIZE,
	children,
	sx,
	isAiChat = false,
}) => {
	const { search } = useChatContext();
	const chatNamesList = title?.toUpperCase().split(', ');
	const data = chatNamesList?.map(chatName => ({ name: chatName }));

	return (
		<Stack direction="row" sx={sx}>
			{userCount > 2 ? (
				<ChatAvatarGroup data={data} size={size} />
			) : (
				<ChatAvatar
					data={{ name: title }}
					isAiChat={isAiChat}
					isOnline={isOnline}
					showBadge={!isAiChat}
					size={size}
				/>
			)}
			<Stack
				sx={{
					flex: 'auto',
					marginLeft: `${SPACING}px`,
					overflow: 'hidden',
					// marginTop: userCount > 2 ? '10px' : undefined,
				}}
			>
				<Typography
					component="div"
					sx={{
						...titleProps?.sx,
						color: 'currentColor',
						fontSize: '14px',
						lineHeight: `${size / 2}px`,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						width: '100%',
						whiteSpace: 'nowrap',
					}}
					{...titleProps}
				>
					{search != null && (search.main?.searchString || search.new?.searchString) !== '' ? (
						<ChatSearchHighlightMatches
							searchString={search.main?.searchString ?? search.new?.searchString}
							text={title}
						/>
					) : (
						title
					)}
				</Typography>
				{children}
			</Stack>
			{isBlumeUser ? (
				<Box alignItems="center" display="flex" justifyContent="center">
					<Icon
						component={BlumeIcon}
						style={{
							height: '22px',
							width: '22x',
						}}
					/>
				</Box>
			) : null}
		</Stack>
	);
};

ChatContact.propTypes = {
	title: PropTypes.string.isRequired,
	titleProps: PropTypes.object,
	userCount: PropTypes.number,
	isOnline: PropTypes.bool,
	children: PropTypes.node,
	sx: PropTypes.object,
};

export default ChatContact;
export { SPACING as CHAT_CONTACT_SPACING };
