import React from 'react';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';

import FormToolLabel from './FormToolLabel';

const FormToolFieldset = ({
	required,
	title,
	children,
	sx,
	error = false,
	isCompact,
	isRow,
	isCentered,
	isSpaceBetween,
	isLineBreak = true,
}) => {
	// Adjust style for children
	const childWithStyle = React.Children.map(children, child => (
		<Box
			sx={{
				whiteSpace: isLineBreak ? 'pre-line' : 'unset',
				width: isCompact ? '-webkit-fill-available' : 'unset',
			}}
		>
			{child}
		</Box>
	));

	return (
		<Stack
			direction={isRow ? 'row' : 'column'}
			gap={isCompact ? '5px' : 'var(--element-spacing)'}
			sx={{
				backgroundColor: '#ffffff',
				border: error ? '1px solid #fc0303' : '1px solid #e6e6e6',
				borderRadius: 'var(--element-content-border-radius)',
				boxSizing: 'border-box',
				alignItems: isCentered && 'center',
				justifyContent: isSpaceBetween && 'space-between',
				flex: 'auto',
				padding: isCompact ? '10px 30px' : '30px',

				...sx,
			}}
		>
			{title !== undefined ? (
				<FormToolLabel required={required} sx={isLineBreak ? { whiteSpace: 'pre-line' } : {}}>
					{title}
				</FormToolLabel>
			) : null}

			{childWithStyle}
		</Stack>
	);
};

export default FormToolFieldset;
