import React from 'react';
import Box from '@mui/material/Box';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Svg } from '@worklist-2/ui/src/components';

const FileList = ({ filesUploaded, onDeleteFile, onSelectFile, uploadedAttachments, sx = {} }) => (
	<Box
		aria-label="fileList"
		name="fileList"
		sx={{
			display: 'flex',
			justifyContent: 'flex-start !important',
			gap: '10px',
			maxWidth: '380px',
			overflowX: 'auto',
			...sx,
		}}
	>
		{uploadedAttachments &&
			uploadedAttachments?.map(({ valueString, url }) => (
				<Tooltip
					key={url}
					sx={{
						zIndex: 1000,
						cursor: 'pointer',
					}}
					title={valueString?.split('/')?.reverse()?.[0]}
				>
					<Box
						sx={{
							width: 'unset !important',
							transition: '0.4s ease',
							position: 'relative',
							'&:hover': {
								opacity: '0.8',
								'.MuiButtonBase-root ': {
									display: 'inline-flex',
								},
							},
						}}
					>
						<Svg
							key={valueString}
							name={
								valueString?.endsWith('pdf')
									? 'pdfIcon'
									: valueString?.endsWith('doc') || valueString?.endsWith('docx')
									? 'docxIcon'
									: 'imageUploadIcon'
							}
							sx={{ filter: 'drop-shadow(grey 1px 0px 2px)' }}
							onClick={() =>
								onSelectFile({
									documentId: valueString?.split('/')[3],
									fileType: valueString?.endsWith('pdf')
										? 'pdf'
										: valueString?.endsWith('doc') || valueString?.endsWith('docx')
										? 'docx'
										: 'image',
									valueString,
								})
							}
						/>
					</Box>
				</Tooltip>
			))}
		{filesUploaded &&
			Object.keys(filesUploaded).map(index => {
				const fileIndex = index;
				const file = filesUploaded[index];

				return (
					<Tooltip
						key={filesUploaded[index].name}
						sx={{
							zIndex: 1000,
							cursor: 'pointer',
						}}
						title={filesUploaded[index].name}
					>
						<Box
							sx={{
								width: 'unset !important',
								transition: '0.4s ease',
								position: 'relative',
								'&:hover': {
									opacity: '0.8',
									'.MuiButtonBase-root ': {
										display: 'inline-flex',
									},
								},
							}}
						>
							<IconButton
								aria-label={`delete ${filesUploaded[index].name}`}
								name={`delete ${filesUploaded[index].name}`}
								sx={{
									position: 'absolute',
									top: 0,
									padding: '5px',
									background: 'white',
									transform: 'translate(10px, -10px) scale(0.6)',
									zIndex: 1,
									boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
									display: 'none',
									transition: '0.4s ease',
								}}
								onClick={() => onDeleteFile(fileIndex)}
							>
								<CloseIcon sx={{ fontSize: 15, color: 'grey' }} />
							</IconButton>
							<Svg
								key={filesUploaded[index].name}
								alt={filesUploaded[index].name}
								data-testid={filesUploaded[index].name}
								name={
									filesUploaded[index]?.type === 'application/pdf'
										? 'pdfIcon'
										: filesUploaded[index]?.name?.endsWith('.docx') ||
										  filesUploaded[index]?.name?.endsWith('.doc')
										? 'docxIcon'
										: 'imageUploadIcon'
								}
								sx={{ filter: 'drop-shadow(grey 1px 0px 2px)' }}
								onClick={() => onSelectFile(file)}
							/>
						</Box>
					</Tooltip>
				);
			})}
	</Box>
);

export default FileList;
