import { useEffect } from 'react';
import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';

const usePatientWatcher = () => {
	const { setSelectedProfile, form } = useNewAppointmentContentV2Context();
	const patient = form.watch('patient');

	useEffect(() => {
		setSelectedProfile(patient?.profileId);
		form.setValue('gender', patient?.gender?.toUpperCase());
		form.setValue('birthDate', patient?.birthDate);
	}, [patient]);
};

export default usePatientWatcher;
