import { useEffect } from 'react';
import axios from 'axios';
import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';
import { useConfig } from '@worklist-2/core/src';

const useFacilityReloadWatcher = () => {
	const __config = useConfig();

	const { form, setFacilityList, setIsLoadingFacility, setMapDefaultCenter, setShowUserMarker, getLocation } =
		useNewAppointmentContentV2Context();

	const patient = form.watch('patient');
	const scanType = form.watch('scanType');
	const userLocation = form.watch('userLocation');

	useEffect(() => {
		const callback = async () => {
			let latitude;
			let longitude;
			if (userLocation) {
				if (userLocation?.place_id === 'current_location') {
					const location = await getLocation();
					if (location) {
						latitude = location.coords.latitude;
						longitude = location.coords.longitude;
					} else {
						latitude = 12.843774508382714;
						longitude = 77.66221950654179;
					}
				} else {
					const geoService = new window.google.maps.Geocoder();
					await geoService.geocode({ placeId: userLocation?.place_id }, predictions => {
						if (predictions) {
							latitude = predictions[0]?.geometry?.location.lat();
							longitude = predictions[0]?.geometry?.location.lng();
						}
					});
				}
				setMapDefaultCenter({
					lat: latitude,
					lng: longitude,
				});
				setShowUserMarker(true);
			}
			if (patient && scanType && longitude && latitude) {
				form.setValue('facility', '');
				setIsLoadingFacility(true);
				// change to new facility endpoint based on flag
				let requestUrl = '';
				let orgId = '';
				orgId = sessionStorage.getItem('organization');
				if (!orgId) {
					orgId = '';
				}
				requestUrl = `${
					__config.data_sources.blume
				}Appointment/findOrganizationsByServiceModalitiesAndPreference?familyMemberHash=${
					patient?.profileId
				}&serviceModalities=${scanType.DicomModalities.toString()}&userLat=${latitude}&userLong=${longitude}&organizationId=${orgId}`;

				axios.get(requestUrl).then(result => {
					const facilityResultList = result.data;
					if (facilityResultList?.length > 0) {
						facilityResultList?.forEach(eachFacility => {
							const addressJson = JSON.parse(eachFacility.extJson).address[0];
							const isAddressPresent = addressJson.line || addressJson.city;
							eachFacility.address =
								(addressJson.line ? addressJson.line[0] : '') +
								(addressJson.city ? ` ${addressJson.city}` : '') +
								(isAddressPresent != null ? '- ' : '');
							eachFacility.lat = addressJson.latitude;
							eachFacility.lng = addressJson.longitude;
						});
						const key = 'internalOrganizationID';
						const uniqueFacilityList = [
							...new Map(facilityResultList.map(item => [item[key], item])).values(),
						];
						setFacilityList(uniqueFacilityList);
					} else {
						setFacilityList([]);
					}
					setIsLoadingFacility(false);
				});
			}
		};

		callback();
	}, [patient?.birthDate, scanType?.Name, userLocation?.place_id]);
};

export default useFacilityReloadWatcher;
