import React from 'react';
import { CancelOutlined } from '@mui/icons-material';
import { Typography, Box, IconButton, Stack, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const UploadProgress = ({ files, isRequestLoading, setFiles, isUploaded }) => {
	const { t } = useTranslation('appointments');
	if (!files?.length) return null;
	return (
		<Box
			my={2}
			sx={{
				background: '#FFFFFF',
				borderRadius: '4px',
				py: 1.5,
				width: 'min(100%,320px)',
				mt: 2,
			}}
		>
			<Stack alignItems="center" direction="row" justifyContent="space-between" mx={2}>
				<Typography color="#344054" fontSize={15} fontWeight={500}>
					{isUploaded ? 'Uploaded Files' : t('Uploading queue 1')}
				</Typography>
				{!isUploaded && (
					<IconButton disabled={isRequestLoading} sx={{ height: 30, width: 30 }} onClick={() => setFiles([])}>
						<CancelOutlined color="error" />
					</IconButton>
				)}
			</Stack>
			<Typography color="#7B7B7B" fontSize={13} fontWeight={500} mx={2} my={1}>
				{files?.length}/{files?.length}{' '}
				{isUploaded
					? 'previously uploaded files'
					: isRequestLoading
					? t('uploading . 2 mins remaining')
					: t('Not Started')}
			</Typography>
			<LinearProgress
				color="success"
				sx={{ mx: 2 }}
				value={isUploaded ? 100 : 0}
				variant={isRequestLoading && !isUploaded ? 'buffer' : 'determinate'}
			/>
		</Box>
	);
};

export default UploadProgress;
