/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
/* eslint-disable no-inner-declarations */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-sort-props */
import { Typography, Box, Button, Stack, Divider, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FileUpload } from '@mui/icons-material';
import { DAYS, MONTHS, calendarStyles } from './consts/consts';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';

import CalendarDay from './components/CalendarDay';
import moment from 'moment';
import Slots from './components/Slots';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { uploadFiles } from './utils';

const SecondPage = ({
	selectedDate,
	setSelectedDate,
	setFiles,
	form,
	setOpenTC,
	availableTimeSlots = [],
	setAvailableTimeSlots = Function.prototype,
	slotsLoading,
	isTablet,
	setToastMsg = Function.prototype,
}) => {
	const [selectedMonth, setSelectedMonth] = useState(selectedDate || new Date());
	const phoenixBlumeTurnOffVideoFileUpload = useBooleanFlagValue('phoenix-blume-turn-off-video-file-upload');
	const { t } = useTranslation('appointments');

	const handleUploadFiles = async e => uploadFiles(e, phoenixBlumeTurnOffVideoFileUpload, setToastMsg, setFiles, t);
	return (
		<>
			<Box sx={calendarStyles}>
				<LocalizationProvider
					adapterLocale={{
						localize: {
							day: n => t(DAYS[n]),
							month: n => t(MONTHS[n]),
						},
						formatLong: {
							date: () => 'MMM d, yyyy',
						},
					}}
					dateAdapter={AdapterDateFns}
				>
					<CalendarPicker
						disablePast
						date={selectedDate}
						renderDay={day => (
							<CalendarDay
								day={day}
								selectedDay={selectedDate}
								selectedMonth={selectedMonth}
								setSelectedDay={setSelectedDate}
								today={new Date()}
							/>
						)}
						views={['year', 'day']}
						onChange={setSelectedDate}
						onMonthChange={setSelectedMonth}
					/>
				</LocalizationProvider>
				{isTablet && (
					<Slots
						slotsLoading={slotsLoading}
						availableTimeSlots={availableTimeSlots}
						setAvailableTimeSlots={setAvailableTimeSlots}
					/>
				)}
				{selectedDate && availableTimeSlots.some(d => d?.selected) ? (
					<Typography color="#344054" fontSize={15} fontWeight={500} sx={{ margin: '20px 0px -12px' }}>
						Chosen slot:{' '}
						<b>
							{moment(selectedDate).format('MMMM DD, YYYY, ')}
							{availableTimeSlots.find(d => d?.selected)?.time || 'N/A'}
						</b>
					</Typography>
				) : null}
			</Box>
			<Divider variant="fullWidth" sx={{ my: 4, width: 'min(100%, 550px)' }} />
			<Typography color="#475467" fontWeight={500} fontSize={20}>
				{t('Additional information (Optional)')}
			</Typography>
			<Typography color="#475467" mt={1} maxWidth="min(100%, 550px)">
				{t(
					'Please use this space to provide any additional information or notes that you believe would be helpful for your upcoming medical appointment'
				)}
			</Typography>
			<Divider variant="fullWidth" sx={{ my: 4, width: 'min(100%, 550px)' }} />
			<Box sx={{ my: 2, color: '#101828', mr: 2, width: 'min(100%,550px)' }}>
				<Typography color="#12121290" fontSize={14} fontWeight={500} mt={2}>
					{t('Upload attachments (Eg: Images, Reports documents, etc..)')}
				</Typography>
				<Button
					sx={{
						border: '2px dotted #D0D5DD',
						borderBlockStyle: 'dashed',
						height: '140px',
						width: '100%',
						color: '#D0D5DD',
						mt: 2,
						borderRadius: '6px',
					}}
					variant="outlined"
					onClick={() => document?.getElementById('upload-files')?.click()}
				>
					<Stack alignItems="center" justifyContent="center">
						<FileUpload color="disabled" sx={{ fontSize: 50 }} />
						<Typography color="#4B5565" fontSize={14} fontWeight={500} mt={1} textTransform="none">
							{t('Click to browse the files and start uploading')}
						</Typography>
					</Stack>
				</Button>
				<input
					hidden
					data-testid="upload-file"
					accept="image/*, application/pdf, .doc,.docx"
					id="upload-files"
					multiple="multiple"
					type="file"
					onChange={handleUploadFiles}
				/>
				<Stack alignItems="start" direction="row" mt={1}>
					<Checkbox defaultChecked sx={{ mt: 1 }} />
					<p
						style={{
							color: '#697586',
							fontSize: '14px',
							lineHeight: '20px',
						}}
					>
						{t('By clicking “I Agree” below, you acknowledge that you have read and understand this', {
							ns: 'profile',
						})}
						<span
							data-testid="open-consent-tc-link"
							style={{
								fontWeight: 500,
								color: 'var(--color-primary)',
								margin: '0px 5px',
								cursor: 'pointer',
							}}
							onClick={() => setOpenTC(true)}
						>
							{t('Informed Consent Statement', { ns: 'profile' })}
						</span>
						{t(
							'and agree to the sharing of your documents, images, and studies for the purpose of receiving healthcare treatment and care.',
							{ ns: 'profile' }
						)}
					</p>
				</Stack>
			</Box>
			<Typography mb={1} variant="p" fontSize={18} display="block" fontWeight={500} color="#344054">
				{t('Notes')}
			</Typography>
			<textarea
				style={{
					height: '150px',
					borderRadius: '4px',
					width: 'min(530px,100%)',
					maxWidth: 'min(530px,100%)',
					background: '#ffffff',
					border: '1px solid #EAECF0',
					outline: 'none',
					marginBottom: isTablet ? '20px' : '100px',
					fontFamily: 'ROBOTO',
					fontSize: '17px',
					padding: '10px',
					resize: 'none',
				}}
				data-testid="notes"
				onChange={e => form.setValue('notes', e.target.value)}
			/>
		</>
	);
};

export default SecondPage;
