import React, { useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FeedIcon from '@mui/icons-material/Feed';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { GenericDrawer } from '@worklist-2/ui/src/components';
import SearchBar from '@worklist-2/ui/src/components/SearchBar';
import Box from '@mui/material/Box';

import ChatContextProvider from '@worklist-2/ui/src/components/Chat/ChatContext';

import Notifications from '../Notifications';
import BlumeHeaderChatContent from './BlumeHeaderChatContent';

import Badge from '@mui/material/Badge';
import {
	searchScopes,
	useSearchScope,
	useAuth,
	mediaTablet,
	useIsMobile,
	useIsTablet,
	mediaTabletOnly,
} from '@worklist-2/core/src';
import AppointmentDrawerContent from '../Appointment/AppointmentDrawer/AppointmentDrawerContent';
import MobileBottomDrawer from '../MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppointmentContext } from '../Appointment/AppointmentContext/AppointmentContext';
import BlumeHeaderBubblesIcon from '@worklist-2/ui/src/assets/icons/BlumeHeaderBubblesIcon.png';
import BlumeAssistant from '../BlumeAssistant/BlumeAssistant';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const buttonStyle = {
	color: 'rgba(255, 255, 255, 0.6)',
};

const activeButtonStyle = {
	color: '#42A5F5',
};

const DRAWER_WIDTH = 375;

const BlumeHeader = () => {
	const { isFullscreen, setIsFullscreen, isOpen, setIsOpen } = useAppointmentContext();
	const navigate = useNavigate();

	const [openSearch, setOpenSearch] = useState(false);
	const [openChat, setOpenChat] = useState(false);
	const [openNotifications, setOpenNotifications] = useState(false);
	const [openRelevantArticles, setOpenRelevantArticles] = useState(false);
	const [openBlumeAssistant, setOpenBlumeAssistant] = useState(false);

	const { updateScope } = useSearchScope(useSearchScope);
	const { notificationReceived, chatMsgReceived, isEmailVerified } = useAuth();
	const isTablet = useIsTablet();
	const isMobile = useIsMobile();
	const enableBlumeChatGptAssistant = useBooleanFlagValue('enable-blume-chat-gpt-assistant');
	const location = useLocation();

	const onClickSearch = useCallback(() => {
		updateScope(searchScopes.patientPortalAll);
		setOpenSearch(true);
	}, []);

	const onClickAppointment = useCallback(() => {
		setIsFullscreen(false);
		if (isMobile) {
			navigate('/appointment');
		} else {
			setIsOpen('Scheduler');
		}
	}, [isMobile]);

	const onClickChat = useCallback(() => {
		setOpenChat(true);
	}, []);

	const onClickNotification = useCallback(() => {
		setOpenNotifications(true);
	}, []);

	const onClickRelevantArticles = useCallback(() => {
		setOpenRelevantArticles(true);
	}, []);

	const onClickBlumeAssistanct = useCallback(() => {
		setOpenBlumeAssistant(true);
	}, []);

	const getChildren = useCallback(() => <SearchBar placeholder="All" setOpenDrawer={setOpenSearch} />, []);

	const getNotifications = useCallback(
		() => (
			<Box sx={{ [mediaTabletOnly]: { padding: '0px 16px 0px 16px' } }}>
				<Notifications setDrawerOpen={setOpenNotifications} />
			</Box>
		),
		[]
	);

	const getBlumeAssistant = useCallback(
		() => (
			<Box sx={{ [mediaTabletOnly]: { padding: '0px 16px 0px 16px' } }}>
				<BlumeAssistant />
			</Box>
		),
		[]
	);

	return (
		<>
			<Box
				id="page-header"
				sx={{
					boxSizing: 'border-box',
					display: 'flex',
					justifyContent: 'flex-end',
					mt: '5px',
					height: 'var(--app-header-height)',
					[mediaTablet]: {
						position: 'fixed',
						width: '100%',
						zIndex: '10',
						top: '0',
						left: '0',
						backgroundColor: '#121212',
						padding: '9px 0',
						marginTop: '0',
					},
				}}
			>
				{isMobile && location.pathname !== '/help' && (
					<IconButton
						sx={
							({
								'&:disabled': {
									color: isMobile ? '#FFFFFF99' : '',
								},
							},
							openSearch ? activeButtonStyle : buttonStyle)
						}
						onClick={onClickSearch}
					>
						<SearchIcon />
					</IconButton>
				)}

				{!isMobile && (
					<IconButton
						sx={
							({
								'&:disabled': {
									color: isTablet ? '#FFFFFF99' : '',
								},
							},
							openSearch ? activeButtonStyle : buttonStyle)
						}
						onClick={onClickSearch}
					>
						<SearchIcon />
					</IconButton>
				)}

				<IconButton
					disabled={!isEmailVerified}
					sx={
						({
							'&:disabled': {
								color: isTablet ? '#FFFFFF99' : '',
							},
						},
						isOpen === 'Scheduler' || location.pathname === '/appointment'
							? activeButtonStyle
							: buttonStyle)
					}
					onClick={onClickAppointment}
				>
					<CalendarMonthIcon />
				</IconButton>

				<IconButton
					disabled={!isEmailVerified}
					sx={
						({
							'&:disabled': {
								color: isTablet ? '#FFFFFF99' : '',
							},
						},
						openChat ? activeButtonStyle : buttonStyle)
					}
					onClick={onClickChat}
				>
					<Badge
						anchorOrigin={{
							horizontal: 'right',
							vertical: 'top',
						}}
						badgeContent=""
						color={chatMsgReceived && isEmailVerified ? 'error' : undefined}
						sx={{
							'& .MuiBadge-badge': { top: '3px', right: '2px' },
						}}
						variant="dot"
					>
						<SmsOutlinedIcon sx={{ marginBottom: '-2.5px !important' }} />
					</Badge>
				</IconButton>

				<IconButton
					disabled={!isEmailVerified}
					sx={
						({
							'&:disabled': {
								color: isTablet ? '#FFFFFF99' : '',
							},
							marginRight: '7px',
						},
						openNotifications ? activeButtonStyle : buttonStyle)
					}
					onClick={onClickNotification}
				>
					<Badge
						anchorOrigin={{
							horizontal: 'right',
							vertical: 'top',
						}}
						badgeContent=""
						color={notificationReceived && isEmailVerified ? 'error' : undefined}
						sx={{
							'& .MuiBadge-badge': { top: '6px', right: '6px' },
						}}
						variant="dot"
					>
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<IconButton
					disabled
					sx={{
						display: 'none',
						'&:disabled': {
							color: isTablet ? '#FFFFFF99' : '',
						},
						marginRight: '7px',
					}}
					onClick={onClickRelevantArticles}
				>
					<FeedIcon />
				</IconButton>
				{enableBlumeChatGptAssistant && location.pathname !== '/home' && (
					<IconButton
						sx={
							({
								'&:disabled': {
									color: isTablet ? '#FFFFFF99' : '',
								},
								marginRight: '7px',
							},
							openBlumeAssistant ? activeButtonStyle : buttonStyle)
						}
						onClick={onClickBlumeAssistanct}
					>
						<img src={BlumeHeaderBubblesIcon} style={{ paddingTop: '10px' }} />
					</IconButton>
				)}
			</Box>

			{isMobile && (
				<>
					<MobileBottomDrawer fullHeight open={isMobile && openSearch} onClose={() => setOpenSearch(false)}>
						{getChildren()}
					</MobileBottomDrawer>

					<MobileBottomDrawer
						fullHeight
						open={isMobile && openNotifications}
						onClose={() => setOpenNotifications(false)}
					>
						{getNotifications()}
					</MobileBottomDrawer>
					<MobileBottomDrawer
						fullHeight
						open={isMobile && openBlumeAssistant}
						onClose={() => setOpenBlumeAssistant(false)}
					>
						{getBlumeAssistant()}
					</MobileBottomDrawer>
				</>
			)}

			<GenericDrawer
				children={getChildren()}
				headerFlag
				drawerColor="rsSecondary.medium"
				drawerOpen={!isMobile && openSearch}
				setDrawerOpen={setOpenSearch}
				title="Search"
				width={isMobile ? '100vw' : DRAWER_WIDTH}
			/>

			<GenericDrawer
				children={getNotifications()}
				headerFlag
				drawerColor="rsSecondary.medium"
				drawerOpen={!isMobile && openNotifications}
				setDrawerOpen={setOpenNotifications}
				title="Notifications"
				toggleDrawerButtonFlag={false}
				width={isMobile ? '100vw' : DRAWER_WIDTH}
			/>

			<GenericDrawer
				headerFlag
				PaperProps={{
					sx: {
						height: '100%',
					},
				}}
				anchor={isMobile ? 'bottom' : undefined}
				drawerColor="rsSecondary.medium"
				drawerOpen={openChat}
				innerSx={{
					'--element-background-color': '#ffffff',
					'--element-foreground-color': '#414141',
					'--element-foreground-color-secondary': '#717171',
					'--element-foreground-color-tertiary': '#a3a3a3',
					'--element-heading-foreground-color': '#121212',

					'--element-content-border-color': '#ececec',
					'--element-content-box-shadow-color': 'rgba(0, 0, 0, 0.1)',
					'--element-content-spacing': '20px',
					'--element-content-spacing-right': 'var(--element-content-spacing)',
					'--element-content-spacing-left': 'var(--element-content-spacing)',
					'--element-content-spacing-all':
						'var(--element-content-spacing) var(--element-content-spacing-right) var(--element-content-spacing) var(--element-content-spacing-left)',

					'--element-header-button-background-color': 'rgba(66, 165, 245, 0.08)',
					'--element-header-button-foreground-color': 'var(--color-primary)',

					'--element-field-background-color': '#f6f6f6',

					'--element-badge-border-color': '#ffffff',

					display: 'grid',
					height: '100%',
					gridTemplateRows: '100%',
					paddingTop: 0,

					'.chat-header': {
						'--element-background-color': 'transparent',
						'--element-foreground-color': 'unset',

						marginBottom: '10px',

						'&::after': {
							background: 'linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0))',
							display: 'block',
							content: '""',
							left: '0',
							position: 'absolute',
							right: '0',
							height: '10px',
							top: '100%',
							width: '100%',
							zIndex: 3,
						},
					},

					'.chat-header-button': {
						'--element-foreground-color': 'var(--color-primary)',
					},

					'.chat-search': {
						paddingTop: 'var(--element-content-spacing)',
					},
				}}
				setDrawerOpen={setOpenChat}
				showHeader={false}
				title="Chat"
				width="100vw"
			>
				<ChatContextProvider
					showAvatarsInExpandedMessagingView
					showAvatarsInNormalMessagingView
					showNewChatCopyLink
					showFiles={false}
					showFormSubmitButtonInField={false}
					showHeaderInCall={false}
					showNavigation={false}
				>
					<BlumeHeaderChatContent setDrawerOpen={setOpenChat} />
				</ChatContextProvider>
			</GenericDrawer>

			<GenericDrawer
				headerFlag
				drawerColor="rsSecondary.medium"
				drawerOpen={!isMobile && isOpen === 'Scheduler'}
				setDrawerOpen={setIsOpen}
				showHeader={false}
				title="Appointment"
				width={isFullscreen ? '100vw' : DRAWER_WIDTH}
			>
				<AppointmentDrawerContent />
			</GenericDrawer>

			<GenericDrawer
				headerFlag
				drawerColor="rsSecondary.medium"
				drawerOpen={openRelevantArticles}
				setDrawerOpen={setOpenRelevantArticles}
				title="RELEVANT ARTICLES"
				width={isMobile ? '100vw' : DRAWER_WIDTH}
			/>
			<GenericDrawer
				children={getBlumeAssistant()}
				drawerColor="rsSecondary.medium"
				drawerOpen={!isMobile && openBlumeAssistant}
				headerFlag={false}
				setDrawerOpen={setOpenBlumeAssistant}
				title="Assistant"
				toggleDrawerButtonFlag={false}
				width={isMobile ? '100vw' : DRAWER_WIDTH}
			/>
		</>
	);
};

export default BlumeHeader;
