import { menuProps } from '../../consts/consts';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FormSelectVariant from '@worklist-2/ui/src/components/FormNew/formPartials/FormFieldVariants/variants/FormSelectVariant';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';

const Patient = () => {
	const { t } = useTranslation('appointments');

	const { isUpdateForm, form, patientList, handleOnPatientChange, helperText } = useNewAppointmentContentV2Context();

	return (
		<FormSelectVariant
			formHook={form}
			name="patient"
			options={patientList}
			props={{
				disabled: isUpdateForm,
				label: t('Patient'),
				helperText,
				onChange: handleOnPatientChange,
				SelectProps: {
					MenuProps: menuProps,
					renderValue: option =>
						(
							(option?.firstName !== null ? `${option?.firstName} ` : '') +
							(option?.lastName !== null ? option?.lastName : '')
						).trim(),
				},
				InputProps: {
					startAdornment: (
						<InputAdornment
							position="start"
							sx={{
								'& .MuiSvgIcon-root': {
									opacity: 1.6,
								},
							}}
						>
							<AccountCircleIcon />
						</InputAdornment>
					),
				},
			}}
			type="select"
		/>
	);
};

export default Patient;
