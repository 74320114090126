import axios from 'axios';
import { useConfig } from '@worklist-2/core/src';
import { notificationType } from '../consts/consts';
import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';

const useGetNotifications = () => {
	const __config = useConfig();

	const { setOaiSyncPendingNotifications } = useNewAppointmentContentV2Context();

	const filterSyncNotifications = results => {
		if (results?.length) {
			const oaiNotifications = results?.filter(
				item =>
					item?.properties?.NotificationType === notificationType.OAI_PATIENT_SYNC ||
					item?.properties?.NotificationType === notificationType.OAI_PATIENT_DATA_SYNC
			);

			if (oaiNotifications?.length) {
				setOaiSyncPendingNotifications(oaiNotifications);
			}
		}
	};

	function getNotificationsApi() {
		axios
			.get(`${__config.data_sources.blume}Notification`)
			.then(result => {
				if (result?.status === 200) {
					filterSyncNotifications(result?.data);
				}
			})
			.catch(e => {
				console.error('Error ', e);
			});
	}

	return {
		getNotificationsApi,
	};
};

export default useGetNotifications;
