import React, { useEffect, useState } from 'react';

// Custom
import ChatList, { ChatListHeader, ChatListItemUser, ChatListContainer } from './ChatList';
import ChatEmpty from './ChatEmpty';
import { ChatHeading3 } from './ChatHeading';
import ChatRemoveButton from './ChatRemoveButton';
import { useTranslation } from 'react-i18next';
import { useChatContext } from './ChatContext';

// MUI
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import _ from 'lodash';

const ChatContactList = ({ frequentContactList, contactList, buttonText, checkedListMenuItems }) => {
	const { t } = useTranslation('chat');
	const { setSelectedContacts, selectedContacts, search } = useChatContext();
	const [filteredContacts, setFilteredContacts] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const contactGroups = Object.values(
		filteredContacts.reduce((r, e) => {
			const group = e?.user?.name[0]?.toUpperCase();
			if (!r[group]) r[group] = { group, contacts: [e] };
			else r[group].contacts.push(e);
			return r;
		}, {})
	).sort((a, b) => (a.group > b.group ? 1 : -1));

	const onChangeContact = (contact, type) => {
		if (type === 'add') {
			setSelectedContacts([...selectedContacts, contact]);
		} else {
			const newSelectedContacts = selectedContacts.filter(c => c.internalId !== contact.internalId);
			setSelectedContacts(newSelectedContacts);
		}
	};

	useEffect(() => {
		const filterResults = () => {
			let sortedList = [];
			if (search != null && search.new?.searchString !== '') {
				sortedList = contactList.filter(
					value =>
						_.startsWith(value.user.name?.toLowerCase(), search.new.searchString.toLowerCase()) ||
						_.startsWith(value.text.toLowerCase(), search.new.searchString.toLowerCase())
				);
			} else {
				sortedList = contactList;
			}
			return sortedList;
		};
		setFilteredContacts(filterResults());
	}, [search, contactList]);

	setTimeout(() => {
		contactList?.length == 0 && frequentContactList?.length == 0 && setIsLoading(false);
	}, 30000);

	useEffect(() => {
		(contactList?.length > 0 || frequentContactList?.length > 0) && setIsLoading(false);
	}, [contactList, frequentContactList]);
	return (
		<ChatListContainer>
			<ChatList>
				{frequentContactList?.length > 0 && (
					<>
						<ChatListHeader>
							<ChatHeading3>{t('newChat.frequentContacts')}</ChatHeading3>
						</ChatListHeader>

						{frequentContactList.map((item, index) => {
							const checked =
								selectedContacts?.filter(contact => (contact.internalId = item.internalId))?.length > 0;

							if (checked && buttonText === 'Add') {
								return (
									<ChatListItemUser key={index} data={item} text={item.text}>
										<ChatRemoveButton onClick={() => {}} />
									</ChatListItemUser>
								);
							}

							return (
								<ChatListItemUser
									key={index}
									checked={checked}
									data={item}
									menuItems={checked ? checkedListMenuItems : undefined}
									text={item.text}
									onChange={onChangeContact}
								/>
							);
						})}
					</>
				)}
				{filteredContacts?.length > 0 && (
					<>
						<ChatListHeader>
							<ChatHeading3>{t('newChat.myContacts')}</ChatHeading3>
						</ChatListHeader>
						{contactGroups.map((group, i) => (
							<>
								{group.group?.trim() && (
									<ChatListHeader>
										<ChatHeading3>{group.group}</ChatHeading3>
									</ChatListHeader>
								)}
								{group.contacts.map((item, index) => {
									const checked =
										selectedContacts?.filter(contact => (contact.internalId = item.internalId))
											?.length > 0;

									return (
										<ChatListItemUser
											key={index}
											checked={checked}
											data={item}
											menuItems={checked ? checkedListMenuItems : undefined}
											text={item.text}
											onChange={onChangeContact}
										/>
									);
								})}
							</>
						))}
					</>
				)}
				{isLoading && search == null && contactList?.length === 0 ? (
					<Skeleton
						sx={{
							height: '100%',
							borderRadius: '10px',
						}}
						variant="rectangular"
					/>
				) : frequentContactList?.length === 0 && filteredContacts?.length === 0 ? (
					<ChatEmpty
						searchString={search?.new?.searchString || contactList.length > 0}
						sx={{
							flex: 'auto',
							padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',
						}}
					>
						<Typography
							component="div"
							sx={{
								color: 'var(--element-foreground-color)',
								fontSize: '20px',
								textAlign: 'center',
							}}
						>
							{(search != null && search.new?.searchString !== '') || contactList.length > 0
								? t('chatSearch.noContactFound')
								: t('chatSearch.noContactInTheList')}
						</Typography>

						<Typography
							component="div"
							sx={{
								color: 'var(--element-foreground-color-secondary)',
								fontSize: '12px',
								textAlign: 'center',
							}}
						>
							{((search != null && search.new?.searchString !== '') || contactList.length > 0) &&
								t('chatSearch.searchForAnotherTerm')}
						</Typography>
					</ChatEmpty>
				) : null}
			</ChatList>
		</ChatListContainer>
	);
};

export default ChatContactList;
