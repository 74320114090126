/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Box, Button, Stack, CircularProgress, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';

const Header = ({ handleNext, onClose, isRequestLoading, disableNext, step, isUpdateForm, t, isTablet }) => (
	<Box
		sx={{
			height: '72px',
			width: '100%',
			minWidth: '100%',
			background: '#ffffff',
			borderBottom: '1px solid #EAECF0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		}}
	>
		<Typography color="#4B5565" fontSize={20} fontWeight={500} pl={isTablet ? 2 : 4}>
			{isUpdateForm ? t('Rescheduling appointment') : t('Booking a new appointment')}
		</Typography>
		{!isTablet ? (
			<Stack direction="row" gap={2} pr={2}>
				<Button
					disabled={isRequestLoading}
					sx={{
						border: '1px solid #D0D5DD',
						color: '#475467',
						borderRadius: '8px',
						textTransform: 'none',
					}}
					variant="outlined"
					onClick={onClose}
				>
					{t('Cancel and close')}
				</Button>
				<Button
					data-testid="next"
					disabled={disableNext}
					sx={{
						background: 'var(--color-primary)',
						color: '#ffffff',
						borderRadius: '8px',
						textTransform: 'none',
						width: isUpdateForm ? 'fit-content' : '80px',
						boxShadow: 'none',
					}}
					variant="contained"
					onClick={handleNext}
				>
					{isRequestLoading ? (
						<CircularProgress
							size={20}
							sx={{
								color: 'var(--color-primary)',
							}}
						/>
					) : step === 0 ? (
						isUpdateForm ? (
							t('Save and confirm')
						) : (
							t('Next')
						)
					) : (
						t('Submit')
					)}
				</Button>
			</Stack>
		) : (
			<IconButton data-testid="close" disabled={isRequestLoading} sx={{ mr: 1 }} onClick={onClose}>
				<Close color="disabled" />
			</IconButton>
		)}
	</Box>
);

export default Header;
