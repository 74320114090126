import React from 'react';
import { Stepper as MStepper, Step, StepLabel } from '@mui/material';

const Stepper = ({ step, isUpdateForm = false, t, isTablet, setStep = Function.prototype, disableNext }) => (
	<MStepper
		activeStep={step}
		color="primary"
		orientation={isTablet ? 'horizontal' : 'vertical'}
		sx={{
			my: isTablet && 3,
			'& .MuiStepLabel-iconContainer .MuiSvgIcon-root .MuiStepIcon-text': {
				fill: '#475467',
				fontWeight: 500,
			},
			'& .MuiStepLabel-iconContainer .MuiSvgIcon-root': {
				color: '#F2F4F7',
				background: '#F2F4F7',
				borderRadius: '100%',
			},
			'& .MuiStepLabel-iconContainer .Mui-active': {
				border: '2px solid var(--color-primary) !important',
			},
			'& .MuiStepLabel-iconContainer .Mui-completed': {
				border: '2px solid var(--color-primary) !important',
			},
			'& .MuiStepLabel-label': {
				color: '#475467',
			},
			'& .MuiStepLabel-label.Mui-active': {
				color: '#101828',
			},
			'& .Mui-completed .MuiSvgIcon-root': {
				color: 'var(--color-primary)',
			},
		}}
		variant="outlined"
	>
		{[
			{ label: 'Enter details', id: 1 },
			{ label: 'Slot selection', id: 2 },
		]
			.filter(d => !isUpdateForm || d?.id === 2)
			.map(({ label, id }, i) => (
				<Step
					key={id}
					data-testid="step"
					onClick={() => !isUpdateForm && (step === 1 || (step === 0 && !disableNext)) && setStep(i)}
				>
					<StepLabel>{t(label)}</StepLabel>
				</Step>
			))}
	</MStepper>
);

export default Stepper;
