import React from 'react';
import { styled } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const AntTabs = styled(Tabs)({
	'& .MuiTabs-indicator': {
		backgroundColor: '#42A5F5',
		top: '40px !important',
	},
	'& .MuiTabs-scrollButtons': {
		color: '#fff',
	},
});

export const AntTab = styled(props => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	minWidth: 0,
	padding: '0px 16px !important',
	minHeight: '33px !important',
	marginTop: '10px !important',
	[theme.breakpoints.up('sm')]: {
		minWidth: 0,
	},
	fontWeight: theme.typography.fontWeightRegular,
	marginRight: theme.spacing(1),
	color: 'rgba(0, 0, 0, 0.85)',
	fontFamily: [
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
	'&:hover': {
		color: '#40a9ff',
		opacity: 1,
	},
	'&.Mui-selected': {
		color: '#42A5F5 !important',
		fontWeight: theme.typography.fontWeightMedium,
	},
	'&.Mui-focusVisible': {
		backgroundColor: '#d1eaff',
	},
}));
