import { Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import { mediaTabletOnly } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';

const AppointmentCardDate = ({ dateStart, dateEnd }) => {
	const { isFullscreen } = useAppointmentContext();

	const { t } = useTranslation('appointments');

	return (
		<Typography
			sx={{
				fontSize: '14px',
				lineHeight: '16px',
				letterSpacing: '0.15px',
				color: 'rgba(18, 18, 18, 0.6)',
				marginRight: '5px',
				[mediaTabletOnly]: isFullscreen && {
					fontSize: '12px',
					lineHeight: '14px',
				},
			}}
		>
			{t(moment(dateStart).format('LT'))} - {t(moment(dateEnd).format('LT'))}
		</Typography>
	);
};

export default AppointmentCardDate;
