// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../patientPortal/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../patientPortal/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bt3kVoqSjT4p0ssrUQFc{box-sizing:border-box;max-height:580px;margin-top:20px;color:var(--main-font-color)}.bt3kVoqSjT4p0ssrUQFc .Pcd1gIUMrBgdAR3VZxH9{padding:0 14px;height:90%;max-height:432px;overflow-y:auto}.bt3kVoqSjT4p0ssrUQFc .w7BQ9Vnf_pK352fl42_w{margin-top:-20px}.bt3kVoqSjT4p0ssrUQFc .aeRk26DBAUAW54P2pYVt{display:flex;justify-content:center;align-items:center;height:10%;min-height:48px;cursor:pointer}.bt3kVoqSjT4p0ssrUQFc .TnpCBuj2AYi30c0sbcyC{color:#42a5f5;font-family:Roboto,serif;font-size:14px;font-style:normal;font-weight:500;line-height:16px;letter-spacing:1.25px;text-transform:uppercase}", "",{"version":3,"sources":["webpack://./../ui/src/components/GlobalSearch/GlobalSearchV2/AutoSuggestDialogV2/AutoSuggestDialogV2.module.scss"],"names":[],"mappings":"AAAA,sBACC,qBAAA,CAEA,gBAAA,CACA,eAAA,CAEA,4BAAA,CAEA,4CACC,cAAA,CACA,UAAA,CACA,gBAAA,CACA,eAAA,CAGD,4CACC,gBAAA,CAGD,4CACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,eAAA,CAEA,cAAA,CAEA,4CACC,aAAA,CAEA,wBAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,qBAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoSuggestDialog": "bt3kVoqSjT4p0ssrUQFc",
	"main": "Pcd1gIUMrBgdAR3VZxH9",
	"emergencySearch": "w7BQ9Vnf_pK352fl42_w",
	"footer": "aeRk26DBAUAW54P2pYVt",
	"footerText": "TnpCBuj2AYi30c0sbcyC"
};
export default ___CSS_LOADER_EXPORT___;
