import { useEffect } from 'react';
import { useNewAppointmentContentV2Context } from '../contexts/NewAppointmentContentV2ContextProvider';

const useDocumentDetailsWatcher = () => {
	const { viewDocDetails, setIsOpenDocument } = useNewAppointmentContentV2Context();

	useEffect(() => {
		if (viewDocDetails) {
			setIsOpenDocument(true);
		}
	}, [viewDocDetails]);
};

export default useDocumentDetailsWatcher;
