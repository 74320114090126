import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CurrentVersion from './CurrentVersion';
import ChangeLog from './ChangeLog';
import PropTypes from 'prop-types';

const NewsModalMobile = ({ isPatientView }) => {
	const [tab, setTab] = useState(0);

	const handleChange = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<Box>
			<Box
				sx={{
					margin: '0 40px',
				}}
			>
				<Tabs
					sx={{
						'& .MuiTabs-flexContainer': {
							justifyContent: 'space-between',
						},
						'& .MuiTab-root': {
							color: '#848484',
							fontSize: '12px',
							fontWeight: '400',
							textTransform: 'none',
						},
						'& .MuiTab-root.Mui-selected': {
							color: 'rsPrimary.main',
						},
						'& .MuiTabs-indicator': {
							backgroundColor: '#6EB8F4',
						},
					}}
					value={tab}
					onChange={handleChange}
				>
					<Tab label="Current Version" />
					<Tab label="Changelog" />
				</Tabs>
			</Box>

			<Box sx={{ padding: '0 12px', boxSizing: 'border-box' }}>
				{tab === 0 ? (
					<CurrentVersion isPatientView={isPatientView} />
				) : (
					<ChangeLog isPatientView={isPatientView} />
				)}
			</Box>
		</Box>
	);
};

NewsModalMobile.propTypes = {
	isPatientView: PropTypes.bool,
};

export default NewsModalMobile;
