import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import FormContainer from './FormContainer';
import Header from './Header';

const InformationSection = ({
	title,
	icon,
	formFields,
	col,
	isSectionEditable,
	isOaiNotification,
	setIsOpenDetail,
	fieldChangeRequest,
	updateNotification,
	autoOpen,
}) => {
	const [isEdit, setIsEdit] = useState(autoOpen);
	const [loading, setLoading] = useState(false);

	const { t } = useTranslation('profile');

	return (
		<Box sx={{ marginBottom: '25px' }}>
			<Header icon={{ component: icon }} setIsEdit={isSectionEditable ? setIsEdit : ''} text={t(title)} />
			<FormContainer
				col={col}
				fieldChangeRequest={fieldChangeRequest}
				formFields={formFields}
				formType={title}
				isEdit={isEdit}
				isOaiNotification={isOaiNotification}
				loading={loading}
				setIsEdit={setIsEdit}
				setIsOpenDetail={setIsOpenDetail}
				setLoading={setLoading}
				updateNotification={updateNotification}
			/>
		</Box>
	);
};

export default InformationSection;
