// Core

import React, { useEffect, useCallback, useState } from 'react';

import PropTypes from 'prop-types';

// MUI

import { Icon, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { useChatContext } from './ChatContext';

// Component

const ChatSearch = ({
	placeholder = 'Search Contacts',
	filter = false,
	sx,
	ButtonProps,
	onValueChange,
	onButtonClick,
	isMain = false,
	inputProps,
	setIsSearching,
	filterUnreadConversation,
	filterByUnRead,
	isClickAction,
	setIsClickAction,
	t,
	isSearching,
}) => {
	const [value, setValue] = useState('');
	const buttonClickDefined = typeof onButtonClick === 'function';
	const { setSearch } = useChatContext();

	useEffect(() => {
		if (typeof onValueChange === 'function') {
			onValueChange(value);
		}
		const delayDebounceFn = setTimeout(() => {
			handleDispatch(value);
		}, 700);

		return () => clearTimeout(delayDebounceFn);
	}, [value]);

	const handleDispatch = useCallback(searchString => {
		if (isMain)
			setSearch({
				main: {
					searchString,
				},
			});
		else
			setSearch({
				new: {
					searchString,
				},
			});
		if (searchString === '') setSearch(null);
	});

	const onSearch = useCallback(event => {
		const searchString = event.currentTarget.value;
		setValue(searchString);
	});

	return (
		<Stack
			alignItems="center"
			className="chat-search"
			direction="row"
			sx={{
				padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',

				'&:first-of-type': {
					paddingTop: 'var(--element-content-spacing)',
				},

				'&:last-of-type': {
					paddingBottom: 'var(--element-content-spacing)',
				},

				...sx,
			}}
		>
			<TextField
				InputProps={{
					className: 'chat-search-field-input',
					sx: {
						backgroundColor: 'var(--element-field-background-color)',
						borderRadius: 'var(--border-radius-base)',
						paddingLeft: buttonClickDefined ? '4px' : undefined,
						paddingRight: '4px',
						...inputProps,
					},
					startAdornment: buttonClickDefined ? (
						<Tooltip data-testid="search-btn" title={!isSearching ? t('icon.search') : ''}>
							<IconButton
								key="button"
								size="small"
								sx={{ marginRight: '5px', ...ButtonProps?.sx }}
								onClick={onButtonClick}
								{...ButtonProps}
							>
								<Icon component={SearchIcon} />
							</IconButton>
						</Tooltip>
					) : (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
					endAdornment: !value ? (
						<Tooltip title={t('icon.searchByVoice')}>
							<IconButton key="voice" size="small" onClick={() => {}}>
								<Icon component={KeyboardVoiceOutlinedIcon} />
							</IconButton>
						</Tooltip>
					) : (
						<Tooltip data-testid="clear-btn" title={t('icon.cancel')}>
							<IconButton
								key="clear"
								size="small"
								onClick={() => {
									setValue('');
									setSearch(null);
								}}
							>
								<Icon component={ClearIcon} />
							</IconButton>
						</Tooltip>
					),
				}}
				data-testid="search-input"
				name="search"
				placeholder={placeholder}
				size="small"
				sx={{ flex: 'auto' }}
				value={value}
				onBlur={event => {
					// Elements with menuitem are from the Chat Conversation item - dropdown menu icon
					// isClickAction is from the Chat New \ select a contact and navigate to the Conversation screen and chat list call item
					// Elements including chat-contact-list-checkbox are from Add Group Participants screen
					if (
						isClickAction ||
						event.relatedTarget?.role?.includes('menuitem') ||
						event.relatedTarget?.id?.includes('chat-contact-list-checkbox')
					) {
						isClickAction && setIsClickAction(false);
						return;
					}
					setValue('');
					setSearch(null);
					typeof setIsSearching === 'function' && setIsSearching(0);
				}}
				onChange={onSearch}
			/>

			{filter ? (
				<Tooltip title={t('icon.sort')}>
					<IconButton
						data-testid="FilterListIcon"
						sx={{ flex: 'none', marginLeft: '10px' }}
						onClick={() => {
							filterUnreadConversation();
						}}
					>
						<Icon component={FilterListIcon} sx={{ color: filterByUnRead ? '#42A5F5' : undefined }} />
					</IconButton>
				</Tooltip>
			) : null}
		</Stack>
	);
};
ChatSearch.propTypes = {
	placeholder: PropTypes.string,
	filter: PropTypes.bool,
	sx: PropTypes.object,
	ButtonProps: PropTypes.object,
	onValueChange: PropTypes.func,
	onButtonClick: PropTypes.func,
	inputProps: PropTypes.object,
	setIsSearching: PropTypes.func,
	isClickAction: PropTypes.bool,
	setIsClickAction: PropTypes.func,
	t: PropTypes.func,
	isSearching: PropTypes.number,
};

export default ChatSearch;
