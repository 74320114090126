import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppointmentDrawerButtonBook from './AppointmentDrawerButtonBook';
import { useIsTabletOnly, mediaTabletOnly, mediaDesktopL } from '@worklist-2/core/src';
import { AppointmemntExpandIcon } from './AppointmentDrawerStickyTop';
import AppointmentCardsView from './AppointmentCardsView';
import { useTranslation } from 'react-i18next';

const AppointmentHeader = ({ showExpandIcon }) => {
	const { t } = useTranslation('appointments');
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginBottom: '30px',
				padding: '0 17px',
				[mediaTabletOnly]: {
					marginBottom: '18px',
					padding: '0 16px',
				},
			}}
		>
			<Typography
				sx={{
					fontSize: '24px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					color: '#12121299',
					textTransform: 'uppercase',
					[mediaDesktopL]: {
						fontSize: '18px',
					},
				}}
			>
				{t('Appointments')}
			</Typography>
			<Box>
				<AppointmentDrawerButtonBook />
				{showExpandIcon && <AppointmemntExpandIcon />}
			</Box>
		</Box>
	);
};
const AppointmentDrawerNavigation = () => {
	const isTabletOnly = useIsTabletOnly();

	return (
		<Box
			data-testid="appointment-navigation"
			sx={{
				background: 'linear-gradient(155.15deg, #B6DEFF 26.64%, rgba(66, 165, 245, 0) 34.32%)',
				padding: '1px',
				borderRadius: '10px',
				width: '450px',
				marginRight: '22px',
				marginLeft: 0,
				[mediaDesktopL]: {
					width: '370px',
					minWidth: '370px',
				},
				[mediaTabletOnly]: {
					width: '266px',
					marginRight: 0,
					marginLeft: '16px',
				},
			}}
		>
			<Box
				sx={{
					background: '#FCFCFC',
					borderRadius: '10px',
					padding: '37px 0px 25px 0px',
					boxShadow: 'inset 5px 4px 15px rgba(48, 141, 217, 0.08)',
					width: '100%',
					height: '100%',
					boxSizing: 'border-box',
					[mediaTabletOnly]: { padding: '24px 0px 15px 0px' },
				}}
			>
				{!isTabletOnly && <AppointmentHeader showExpandIcon={false} />}

				<Box
					data-testid="appointment-cards-view"
					sx={{
						overflowY: 'auto',
						maxHeight: 'calc(100vh - 237px)',
						padding: '0 21px',
						'&::-webkit-scrollbar-track': {
							background: 'transparent',
						},
						[mediaTabletOnly]: {
							padding: '0 5px 0px 10px',
							maxHeight: '100%',
						},
					}}
				>
					<AppointmentCardsView />
				</Box>
			</Box>
		</Box>
	);
};

export default AppointmentDrawerNavigation;
export { AppointmentHeader };
