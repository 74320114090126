// Core

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';

import { jsPDF } from 'jspdf';
import { useScreenshot } from 'use-react-screenshot';

// MUI
import Drawer from '@mui/material/Drawer';

// Custom
import FormTool from './FormTool';
import { useConfig, uploadFileToServer, mediaDesktopL, useIsTablet } from '@worklist-2/core/src';

import { useFormStore } from '../../stores';
import { DocumentReferenceJson } from '../../utils/forms';
import MobileBottomDrawer from '../MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

// expected to be used only inside Blume
const FormDrawerV2 = ({ updateForm, appointment, isExternal }) => {
	const isTablet = useIsTablet();
	const __config = useConfig();
	const { data, setSelectedForm } = useFormStore(state => ({
		data: state.selectedForm,
		setSelectedForm: state.setSelectedForm,
	}));
	const [formData, setFormData] = useState(null);
	const [lastSyncedForm, setLastSyncedForm] = useState(null);
	const [progressPercentage, setProgressPercentage] = useState(0);
	const [validateFormData, setValidateFormData] = useState(false);
	const [isValidForm, setIsValidForm] = useState(false);
	const ref = useRef(null);
	const pageRef = useRef([]);
	const [studyId, setStudyId] = useState(null);
	const [appointmentId, setAppointmentId] = useState(null);
	const [patientId, setPatientId] = useState(null);
	const mavenBlumeSendToPowerserver = useBooleanFlagValue('maven-blume-sendToPowerserver');
	const [image, takeScreenshot] = useScreenshot();
	const [pdfInstance, setPdfInstance] = useState(null);
	const [submitInProgress, setSubmitInProgress] = useState(false);

	const isPdfForm = !!data?.pdfFileName;

	const close = () => {
		setSelectedForm(null);
	};

	const insertSubmitParam = () => {
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.set('isSubmit', true);
		const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
		window.location.assign(newUrl);
	};

	useEffect(() => {
		setFormData(data?.resource);
	}, []);

	useEffect(() => {
		const participant = appointment?.participant;
		if (participant) {
			const id = participant
				?.find(item => item?.actor?.reference.toLowerCase().includes('patient'))
				?.actor.reference.split('/')[1];

			setPatientId(id);
		}

		const supportingInformation = appointment?.supportingInformation;
		if (supportingInformation) {
			setStudyId(supportingInformation[0]?.id);
		}

		if (appointment?.id) {
			setAppointmentId(appointment?.id);
		}
	}, [appointment]);

	/**
	 * Enable input form
	 */
	useEffect(() => {
		if (pdfInstance) {
			const { annotationManager } = pdfInstance.Core;
			annotationManager.disableReadOnlyMode();
		}
	}, [pdfInstance]);

	useEffect(() => {
		if (data?.questionData?.length > 0) {
			pageRef.current = pageRef.current.slice(0, data.questionData.length);
		}
	}, [data?.questionData]);

	const getPageImages = async () => {
		const imageArray = [];

		const promises = pageRef.current.map((e, index) =>
			takeScreenshot(e).then(img => imageArray.push({ index, image: img }))
		);

		await Promise.all(promises);

		return imageArray.sort((a, b) => parseInt(a.index) - parseInt(b.index));
	};

	useEffect(() => {
		// calculate total number of questions and check if they have answers
		if (formData && !isPdfForm) {
			let totalRequiredQues = null;
			let answerRequiredQues = 0;
			let subAnswerQuestions = 0;
			let hasAnswer;
			const allQuestions = formData?.formTemplate?.content.flat(1);
			allQuestions.forEach(question => {
				if (
					question?.type === 'multipleChoiceGrid' ||
					question?.type === 'checkboxGrid' ||
					question?.type === 'shortAnswer'
				) {
					subAnswerQuestions = 0;
					question?.options?.forEach((eachQ, index) => {
						if (
							question?.answer &&
							question?.answer[question?.answer?.length - 1]?.value[index]?.length > 0
						) {
							subAnswerQuestions += 1;
						}
					});
					hasAnswer = question?.options?.length == subAnswerQuestions;
				} else if (question?.type === 'checkbox') {
					if (
						question?.answer &&
						question?.answer[question?.answer?.length - 1]?.value?.filter(ans => ans.checked).length > 0
					) {
						hasAnswer = true;
					}
				} else if (question?.type === 'multipleChoice') {
					let answerFlag = false;
					if (question.answer) {
						question.answer[0].value.forEach(v => {
							if (v.checked) {
								answerFlag = true;
							}
						});
					}

					hasAnswer = answerFlag;
				} else if (question?.answer && question?.answer[question?.answer?.length - 1]?.value) {
					hasAnswer = true;
				}

				// logic to enable/disable Submit button
				// Submit button should not be enabled until all required info has been entered
				if (question?.required) {
					totalRequiredQues += 1;

					if (hasAnswer) {
						answerRequiredQues += 1;
					}
				}
			});

			setProgressPercentage((answerRequiredQues / totalRequiredQues) * 100);
			setIsValidForm(!totalRequiredQues || totalRequiredQues == answerRequiredQues);
		}
	}, [formData]);

	const answerList = ({ answerObj, patient, value, index, ...addnValue }) => {
		if (answerObj?.answer) {
			const updatedList = answerObj.answer.map(answer => {
				if (answer.submitterId === patient?.id) {
					if (['multipleChoiceGrid', 'shortAnswer'].includes(answerObj.type)) {
						const valueList = answer.value;
						valueList[index] = value;
						return {
							...answer,
							value: valueList,
							submitterDate: new Date().toISOString(),
							...addnValue,
						};
					}
					return {
						...answer,
						value,
						submitterDate: new Date().toISOString(),
						...addnValue,
					};
				}
				return answer;
			});
			const foundItem = updatedList.find(answer => answer.submitterId === patient?.id);
			if (foundItem) {
				return updatedList;
			}
			if (['multipleChoiceGrid', 'shortAnswer'].includes(answerObj.type)) {
				const valueObj = Array(answerObj.options?.length).fill(null);
				valueObj[index] = value;
				return [
					...updatedList,
					answerJson({
						patient,
						value: valueObj,
						...addnValue,
					}),
				];
			}
			return [
				...updatedList,
				answerJson({
					patient,
					value,
					...addnValue,
				}),
			];
		}
		return [answerJson({ patient, value, ...addnValue })];
	};

	const answerJson = ({ patient, value, ...addnValue }) => ({
		submitter: patient?.display,
		submitterType: 'patient',
		submitterId: patient?.id,
		submitterDate: new Date().toISOString(),
		value,
		...addnValue,
	});

	const handleFormData = async (key, index, value) => {
		if (!lastSyncedForm) {
			autoSaveDebounce(formData);
		}
		const tempFormObj = { ...formData };
		const questionData = tempFormObj?.formTemplate?.content;
		let pageIndex = -1;
		let questionIndex = -1;
		for (let row_idx = 0; row_idx < questionData?.length; row_idx++) {
			const row = questionData[row_idx];
			const col_idx = row.findIndex(obj => obj.unique_id === key);
			if (col_idx !== -1) {
				pageIndex = row_idx;
				questionIndex = col_idx;
				break;
			}
		}
		const updateAnswer = questionData[pageIndex][questionIndex];
		if (['multipleChoice', 'checkbox'].includes(updateAnswer.type)) {
			updateAnswer.answer = answerList({
				answerObj: updateAnswer,
				patient: data.patient,
				...value,
			});
		}
		if (['signature', 'dropdown', 'date', 'paragraph', 'checkboxGrid'].includes(updateAnswer.type)) {
			updateAnswer.answer = answerList({
				answerObj: updateAnswer,
				patient: data.patient,
				value,
			});
		}

		if (['multipleChoiceGrid', 'shortAnswer'].includes(updateAnswer.type)) {
			const valueObj = Array(updateAnswer.options?.length).fill(null);
			valueObj[index] = value;
			updateAnswer.answer = answerList({
				answerObj: updateAnswer,
				patient: data.patient,
				value: updateAnswer?.answer ? value : valueObj,
				index,
			});
		}
		let validatedFormDataObj = tempFormObj;
		if (validateFormData) {
			validatedFormDataObj = await validateForm(tempFormObj);
		}
		setFormData(validatedFormDataObj);
	};

	const validateForm = formDataObj => {
		const tempFormObj = { ...formDataObj };
		const questionData = tempFormObj?.formTemplate?.content;
		questionData.forEach((page, pageIndex) => {
			page.forEach((question, questionIndex) => {
				if (question?.required) {
					const updateAnswer = questionData[pageIndex][questionIndex];
					// check if answer present for required fields
					if (question?.answer) {
						if (
							question?.type === 'multipleChoiceGrid' ||
							question?.type === 'checkboxGrid' ||
							question?.type === 'shortAnswer'
						) {
							question?.options?.forEach((eachQ, index) => {
								if (!question?.answer[question?.answer?.length - 1]?.value[index]?.length > 0) {
									updateAnswer.error = true;
								} else {
									delete updateAnswer?.error;
								}
							});
						} else if (question?.type === 'checkbox') {
							if (!question?.answer[question?.answer?.length - 1]?.value?.length > 0) {
								updateAnswer.error = true;
							} else {
								delete updateAnswer?.error;
							}
						} else if (!question?.answer[question?.answer?.length - 1]?.value) {
							updateAnswer.error = true;
						} else {
							delete updateAnswer?.error;
						}
					} else {
						updateAnswer.error = true;
					}
				}
			});
		});
		return tempFormObj;
	};

	const removeErrorAttribute = async formDataObj => {
		const tempFormObj = { ...formDataObj };
		const allQuestions = tempFormObj?.formTemplate?.content.flat(1);
		allQuestions.forEach(question => {
			delete question?.error;
		});
		return tempFormObj;
	};

	const getBlobStringFromBlumeForm = async () => {
		const leftMargin = 30;
		const topMargin = 10;
		const doc1 = new jsPDF('p', 'pt', 'a4', true);
		let doc2;

		const width = doc1.internal.pageSize.getWidth() + 60;
		const height = doc1.internal.pageSize.getHeight();
		const ratio = height / (width - 60);
		const pageImgArray = await getPageImages();

		pageImgArray.forEach(({ index, image: pageImg }) => {
			const imgProps = doc1.getImageProperties(pageImg);

			const imgRatio = imgProps.height / imgProps.width;
			const resize = imgRatio > ratio;

			const pageWidth = width;
			const pageHeight = resize ? (width * imgProps.height) / imgProps.width : height;

			if (index === 0) {
				doc2 = new jsPDF('p', 'pt', [pageWidth, pageHeight], true);
			} else {
				doc2.addPage([pageWidth, pageHeight]);
			}

			doc2.addImage(
				pageImg,
				'PNG',
				leftMargin,
				topMargin,
				pageWidth - leftMargin * 2,
				(resize ? pageHeight : (imgProps.height * width) / imgProps.width) - topMargin * 2,
				undefined,
				'FAST'
			);
		});

		const blob = doc2.output('blob');
		return blob;
	};

	const saveDocumentReference = (studyId, patientId, appointmentId) => {
		if (mavenBlumeSendToPowerserver) {
			const leftMargin = 30;
			const topMargin = 10;
			const doc1 = new jsPDF('p', 'pt', 'a4', true);
			let doc2;

			const width = doc1.internal.pageSize.getWidth() + 60;
			const height = doc1.internal.pageSize.getHeight();
			const ratio = height / (width - 60);

			getPageImages().then(pageImgArray => {
				pageImgArray.forEach(({ index, image: pageImg }) => {
					const imgProps = doc1.getImageProperties(pageImg);

					const imgRatio = imgProps.height / imgProps.width;
					const resize = imgRatio > ratio;

					const pageWidth = width;
					const pageHeight = resize ? (width * imgProps.height) / imgProps.width : height;

					if (index === 0) {
						doc2 = new jsPDF('p', 'pt', [pageWidth, pageHeight], true);
					} else {
						doc2.addPage([pageWidth, pageHeight]);
					}

					doc2.addImage(
						pageImg,
						'PNG',
						leftMargin,
						topMargin,
						pageWidth - leftMargin * 2,
						(resize ? pageHeight : (imgProps.height * width) / imgProps.width) - topMargin * 2,
						undefined,
						'FAST'
					);
				});

				const blob = doc2.output('datauristring').split(',')[1];

				if (studyId && patientId && appointmentId && blob) {
					const documentReferenceTosave = JSON.parse(JSON.stringify(DocumentReferenceJson));

					documentReferenceTosave.subject.id = patientId;
					documentReferenceTosave.subject.reference += patientId;

					documentReferenceTosave.context.related[0].id = studyId;
					documentReferenceTosave.context.related[0].reference += studyId;

					documentReferenceTosave.context.related[1].id = appointmentId;
					documentReferenceTosave.context.related[1].reference += appointmentId;

					documentReferenceTosave.content[0].attachment.data = blob;

					axios
						.post(`${__config.data_sources.blume}DocumentReference`, documentReferenceTosave)
						.then(result => {
							if (result?.status === 200) {
								console.log('successfully saved document reference', result);
							} else {
								console.error('failed to save document reference');
							}
						});
				}
			});
		}
	};

	const processSaveAnswerData = (formData, hasUpdate = false) => {
		formData.referenceTemplate = formData.id;
		delete formData.id;
		formData.template = false;
		formData.patient = data.patient;
		formData.study = data.study;

		axios
			.post(`${__config.data_sources.fhir}/Form`, formData)
			.then(async result => {
				if (result.status == '201') {
					console.log('Saving Form successfully');
					// Save pdf answer
					isPdfForm && (await savePdfForm(result.data?.id, result.data?.pdfFileName));
					hasUpdate && updateForm(getCompletedFormData(result));
				} else {
					console.error('Fail to save form');
				}
			})
			.finally(close);
	};

	/**
	 * savePdfForm
	 *  Save pdf information
	 * @param {*} closeDrawer
	 */
	const savePdfForm = async (formId, pdfFileName) => {
		if (pdfInstance) {
			const { documentViewer, annotationManager } = pdfInstance.Core;
			await annotationManager.exportAnnotations().then(async xfdfString => {
				const doc = documentViewer.getDocument();
				await doc.getFileData({ xfdfString }).then(async data => {
					const arr = new Uint8Array(data);
					const uploadFileAnswer = new File([arr], pdfFileName, { type: 'application/pdf' });
					const fileData = new FormData();
					fileData.append('file', uploadFileAnswer);
					const resData = await uploadFileToServer(
						`${__config.data_sources.blume}/Form/attachment/${formId}?isReplaceFile=true`,
						fileData
					);
					if (isExternal) {
						insertSubmitParam();
					}
					if (resData?.length > 0) {
						console.log('Saving Form successfully');
					}
				});
			});
		}
	};

	const saveData = async (closeDrawer = true, autoSave = false) => {
		let countOfUnAnsweredRequiredFields = 0;
		let validatedFormData = {};
		if (isPdfForm) {
			validatedFormData = { ...formData };
			validatedFormData.completed = true;
		} else if (!autoSave) {
			setValidateFormData(true);
			validatedFormData = await validateForm(formData);
			validatedFormData.completed = true;
			countOfUnAnsweredRequiredFields = validatedFormData?.formTemplate?.content
				.flat(1)
				.filter(question => question?.error === true)?.length;
		} else {
			validatedFormData = await removeErrorAttribute(formData);
		}
		if (countOfUnAnsweredRequiredFields === 0) {
			if (!data.completed && data.type == 'technologist') {
				// For saving new Answer Technologist Form
				updateForm(() => processSaveAnswerData(validatedFormData));
			} else if (validatedFormData.template) {
				// For saving new Answer Other Blume Form
				processSaveAnswerData(validatedFormData, true);
			} else if ((!validatedFormData || validatedFormData.resourceType != 'BlumeForm') && !autoSave) {
				// For saving DocumentReference
				saveDocumentReference(studyId, patientId, appointmentId);
			} else {
				// For editing Answer

				try {
					setSubmitInProgress(true);
					let result;

					if (!autoSave && !isPdfForm) {
						const blob = await getBlobStringFromBlumeForm();
						const url = `${__config.data_sources.blume}form/v2/${data.id}`;
						const multipartFormData = new FormData();
						multipartFormData.append('files', blob, data.title);
						multipartFormData.append('json', JSON.stringify(validatedFormData));
						result = await axios({
							method: 'put',
							url,
							data: multipartFormData,
							headers: { 'Content-Type': 'multipart/form-data' },
						});
					} else if (!isPdfForm) {
						result = await axios.put(`${__config.data_sources.blume}Form/${data.id}`, validatedFormData);
					}
					if (isPdfForm) {
						if (!pdfInstance) return;

						const { documentViewer, annotationManager } = pdfInstance.Core;

						const xfdfString = await annotationManager.exportAnnotations();

						const doc = documentViewer.getDocument();

						const fileData = await doc.getFileData({ xfdfString });

						const arr = new Uint8Array(fileData);

						const url = `${__config.data_sources.blume}form/v2/${data.id}?isPdfForm=true&fileName=${data.pdfFileName}`;
						const multipartFormData = new FormData();
						multipartFormData.append('json', JSON.stringify(validatedFormData));
						result = await axios({
							method: 'put',
							url,
							data: multipartFormData,
							headers: { 'Content-Type': 'multipart/form-data' },
						});

						const uploadFileAnswer = new File([arr], data.pdfFileName, { type: 'application/pdf' });
						const formData = new FormData();
						formData.append('file', uploadFileAnswer);

						const resData = await uploadFileToServer(
							`${__config.data_sources.blume}/Form/attachment/${result.data.id}?isReplaceFile=true`,
							formData
						);

						if (resData?.length) console.log('Saving Form successfully');
					}

					const form = getCompletedFormData(result);
					if (updateForm) {
						updateForm(form);
					}
					if (closeDrawer) {
						close();
						setLastSyncedForm(form);
					}
					if (isExternal && !autoSave) {
						insertSubmitParam();
					}
					setSubmitInProgress(false);
				} catch (err) {
					console.error('Error while form update', err);
					setSubmitInProgress(false);
				}
			}
		} else {
			setFormData(validatedFormData);
		}
	};

	const getCompletedFormData = result => ({
		id: result.data.id,
		patient: result.data?.patient,
		completed: result.data?.completed,
		organizationId: result.data.organizationId,
		status: result.data.status.charAt(0).toUpperCase() + result.data.status.slice(1),
		title: result.data.name,
		type: result.data.type,
		description: result.data.description,
		questionData: result.data.formTemplate?.content,
		resource: result.data,
	});

	const autoSaveDebounce = nextValue => {
		if (!submitInProgress && !lastSyncedForm?.completed && nextValue !== lastSyncedForm) {
			saveData(false, true);
		}
	};

	if (!data) {
		return <></>;
	}

	if (isExternal) {
		return (
			<Box
				sx={{
					width: '100%',
					height: '100%',
					position: 'relative',
				}}
			>
				<FormTool
					data-testid="form-drawer-v2-external-tool"
					handleFormData={handleFormData}
					isExternal={isExternal}
					isReadOnly={data?.completed}
					isValidForm={isPdfForm ? true : isValidForm}
					pageRef={pageRef}
					progressPercentage={`${progressPercentage}%`}
					setPdfInstance={setPdfInstance}
					onSave={saveData}
				/>
			</Box>
		);
	}

	return isTablet ? (
		<MobileBottomDrawer fullHeight open={!!data} onClose={close}>
			<FormTool
				handleFormData={handleFormData}
				isValidForm={isPdfForm ? true : isValidForm}
				pageRef={pageRef}
				progressPercentage={`${progressPercentage}%`}
				setPdfInstance={setPdfInstance}
				onSave={saveData}
			/>
		</MobileBottomDrawer>
	) : (
		<Drawer
			PaperProps={{
				sx: {
					background: '#f8f8f8',
					boxSizing: 'border-box',
					maxWidth: '100vw',
					overflow: 'scroll',
					width: '850px',
					[mediaDesktopL]: {
						width: '652px',
					},
				},
			}}
			anchor="right"
			data-testid="form-drawer-v2-drawer"
			open={!!data}
			sx={
				data?.type == 'technologist' && {
					right: 'unset',
					'.MuiBackdrop-root': {
						right: 'unset',
					},
				}
			}
			variant="temporary"
		>
			<div ref={ref}>
				<FormTool
					showCloseBtn
					data-testid="form-drawer-v2-form-tool"
					handleFormData={handleFormData}
					isReadOnly={data?.completed}
					isValidForm={isPdfForm ? true : isValidForm}
					pageRef={pageRef}
					progressPercentage={`${progressPercentage}%`}
					setPdfInstance={setPdfInstance}
					onSave={async () => await saveData(true, false)}
				/>
			</div>
		</Drawer>
	);
};

export default FormDrawerV2;
