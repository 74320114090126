import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Grid from '@mui/material/Grid';

const Header = ({ text, icon, setIsEdit }) => (
	<Box
		data-cy={`information-section-${text?.toLowerCase()}-header`}
		sx={{
			padding: '10px 14px 10px 16px',
			borderRadius: '8px',
			boxShadow: '10px 0px 20px rgba(0, 0, 0, 0.08)',

			'& .edit-icon': {
				visibility: 'hidden',
			},

			'&:hover': {
				'& .edit-icon': {
					visibility: 'visible',
				},
			},
		}}
	>
		<Grid container item>
			<Grid
				item
				sx={{
					paddingRight: '14px',
				}}
				xs={10}
			>
				<Typography
					component="h1"
					sx={{
						display: 'flex',
						alignItems: 'center',
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '24px',
						color: '#12121299',
					}}
				>
					<Icon
						component={icon.component}
						sx={{
							color: icon.color || 'currentColor',
							marginRight: '10px',
							'path[fill]': {
								fill: 'currentColor',
							},
							'path[stroke]': {
								stroke: 'currentColor',
							},
						}}
					/>
					{text}
				</Typography>
			</Grid>
			{setIsEdit && (
				<Grid
					item
					sx={{
						display: 'flex',
						justifyContent: 'end',
						alignItems: 'center',
						cursor: 'pointer',
					}}
					xs={2}
				>
					<Icon
						className="edit-icon"
						component={EditOutlinedIcon}
						data-cy={`edit-${text?.toLowerCase()?.split(' ')?.join('-')}-icon`}
						sx={{
							color: '#12121299',
							right: '35px',
						}}
						onClick={() => setIsEdit(true)}
					/>
				</Grid>
			)}
		</Grid>
	</Box>
);

export default Header;
