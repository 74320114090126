import React from 'react';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Box from '@mui/material/Box';
import { getAppointmentColor } from '../AppointmentUtils/AppointmentUtils';

const AppointmentCardExclamationMarkSection = ({ status }) => {
	const color = getAppointmentColor(status);

	return (
		<Box sx={{ minWidth: '30px' }}>
			<PriorityHighIcon
				data-testid="icon"
				sx={{
					color,
					fontSize: '18px',
				}}
			/>
		</Box>
	);
};

export default AppointmentCardExclamationMarkSection;
