// Core

import React from 'react';

// MUI

import { Box, Icon, IconButton, Stack, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// Custom

import Chat from '@worklist-2/ui/src/components/Chat';
import { useChatContext } from '@worklist-2/ui/src/components/Chat/ChatContext';

import CHAT_SECTIONS from '@worklist-2/ui/src/components/Chat/CHAT_SECTIONS';

import WriteIcon from '@worklist-2/ui/src/assets/icons/Chat/write.svg';
import { useTranslation } from 'react-i18next';
// Component

const BlumeHeaderChatContent = ({ setDrawerOpen }) => {
	const { section, setSection, resolveSectionTitle, resolveBackClickHandler } = useChatContext();
	const { t } = useTranslation('chat');
	const sectionName = section?.name || null;
	const mainSection = sectionName === CHAT_SECTIONS.MAIN;

	const _backClickHandler = React.useMemo(() => {
		const contextBackClickHandler = resolveBackClickHandler();

		if (contextBackClickHandler) {
			return contextBackClickHandler;
		}

		if (typeof setDrawerOpen === 'function') {
			return () => setDrawerOpen(false);
		}
	}, [sectionName, setDrawerOpen, setSection, resolveBackClickHandler]);

	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateRows: 'var(--app-header-height) calc(100% - var(--app-header-height))',
				height: '100%',
			}}
		>
			<Stack
				alignItems="center"
				direction="row"
				gap="10px"
				sx={{
					backgroundColor: 'var(--color-primary)',
					color: '#ffffff',
					padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',
				}}
			>
				{_backClickHandler ? (
					<IconButton sx={{ color: 'currentColor', flex: 'none' }} onClick={_backClickHandler}>
						<Icon component={ChevronLeftIcon} />
					</IconButton>
				) : null}

				<Typography
					component="h2"
					sx={{
						color: 'currentColor',
						flex: 'auto',
						fontSize: '16px',
						fontWeight: 500,
						textTransform: 'uppercase',
					}}
				>
					{t(resolveSectionTitle())}
				</Typography>

				{mainSection ? (
					<IconButton
						sx={{ color: 'currentColor', flex: 'none' }}
						onClick={() => setSection({ name: CHAT_SECTIONS.NEW })}
					>
						<Icon
							component={WriteIcon}
							sx={{
								boxSizing: 'border-box',
								color: 'icon.primary',
								flex: 'none',
								height: '24px',
								padding: '3px 0 0 5px',
								width: '24px',

								'path[fill]': {
									fill: 'currentColor',
								},

								'path[stroke]': {
									stroke: 'currentColor',
								},
							}}
						/>
					</IconButton>
				) : null}
			</Stack>

			<Chat />
		</Box>
	);
};

export default BlumeHeaderChatContent;
