// Core
import React, { useState, useRef } from 'react';

// MUI
import { Box, ClickAwayListener, Icon, IconButton, Popper, Stack, TextField, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';

// PRO-2947
// import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
// import PresentToAllOutlinedIcon from '@mui/icons-material/PresentToAllOutlined';
// import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';

import SendIcon from '@mui/icons-material/Send';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import moment from 'moment';
import axios from 'axios';
import CampaignIcon from '@mui/icons-material/Campaign';
import Link from '@mui/material/Link';

import { useChatContext } from './ChatContext';
import { useTranslation } from 'react-i18next';
import { useAuth, useConfig, mediaTablet } from '@worklist-2/core/src';
import { addParticipant } from './ConversationHelper';

// Custom
import ChatAvatar, { CHAT_AVATAR_SIZE } from './ChatAvatar';
import ChatMessage from './ChatMessage';
import CHAT_SECTIONS from './CHAT_SECTIONS';

import './ChatConversationMessagingAudio.css';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

// Styled
const ChatConversationFormButton = ({ sx, ...props }) => (
	<Tooltip title={props?.title ?? ''}>
		<IconButton
			className="chat-conversation-form-button"
			sx={{
				backgroundColor: 'var(--element-background-color)',
				color: 'var(--element-foreground-color)',
				flex: 'none',
				...sx,
			}}
			{...props}
			data-testid="chatSubmitBtn"
		/>
	</Tooltip>
);

// Component

const ChatConversationMessaging = ({ conversation, isGroupChat, isAiChat = false }) => {
	const phoenixChatVoiceNote = useBooleanFlagValue('phoenix-chat-voice-note');
	const mavenAiChat = useBooleanFlagValue('maven-ai-chat');
	const { contacts, loggedInUser, supportClient, isSupportClientInitialized } = useAuth();
	const loggedEmail = loggedInUser?.email?.toLowerCase();
	const { t, i18n } = useTranslation('chat');
	const {
		setSection,
		expanded,
		showFormSubmitButtonInField,
		showAvatarsInNormalMessagingView,
		showAvatarsInExpandedMessagingView,
		state,
		dispatch,
		section,
		fetchConversationDetail,
		smallView,
		appBlumeMode,
		nonUsEntities,
	} = useChatContext();
	const __config = useConfig();
	const [anchorEl, setAnchorEl] = useState();
	const [audioRecorder, setAudioRecorder] = useState(null);
	const [audioRecordTimer, setAudioRecordTimer] = useState('00:00');
	const audioRecordTimestamp = useRef(new Date().getTime());
	const defaultSupportMsg = {
		index: 0,
		message: t('chatWithSupport.defaultSupportMsg'),
		title: t('chatMain.Support'),
		incoming: true,
	};
	const mySelf = t('chatConversation.me');

	let dateTimeLocale = i18n.language;
	if (i18n.language == 'en') dateTimeLocale = 'en-us';
	const dateTimeFormatOptions = {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	};

	const showAvatars = !expanded ? showAvatarsInNormalMessagingView : showAvatarsInExpandedMessagingView;

	const [conversationDetail, setConversationDetail] = React.useState(conversation);
	const [formTextValue, setFormTextValue] = React.useState('');
	const [data, setData] = React.useState([]);
	const [typingParticipants, setTypingParticipants] = React.useState([]);
	const messagesRef = React.useRef(null);
	const refInstance = useRef(null);

	const createSupportConversation = async () => {
		let twilioUrl = __config.data_sources.breeze;
		let breezeEmail = loggedEmail;
		if (nonUsEntities.includes(__config.resource_group)) {
			twilioUrl = __config.data_sources.pous01_breeze_twilio;
			breezeEmail = `${__config.resource_group}-${loggedEmail}`;
		}

		if (isSupportClientInitialized) {
			const url = `${twilioUrl}/Conversation/request`;
			const customConfig = {
				headers: {
					'Content-Type': 'application/json',
					Authorization: '',
				},
			};
			const payload = {
				From: 'omegaai',
				SenderEmail: breezeEmail,
				SenderId: loggedInUser?.id,
				SenderPhone: loggedInUser?.phone,
				SenderOrganization: loggedInUser?.organization?.display,
				SenderName: loggedInUser?.fullName,
				Message: formTextValue,
			};

			setFormTextValue('');

			await axios.post(url, payload, customConfig).then(async result => {
				const conv = await supportClient.getConversationBySid(result?.data?.conversationSid);

				setConversationDetail(conv);

				setSection({
					name: CHAT_SECTIONS.SUPPORT,
					conversation: conv,
				});
			});
		}
	};

	const onMessageSend = async ({ e, sendMediaOptions, audioMessageLength }) => {
		e?.preventDefault();

		if (isAiChat) {
			const typedMessage = formTextValue;
			dispatch({
				msgtype: 'MESSAGE_ADDED',
				payload: {
					author: loggedInUser?.email,
					body: formTextValue,
					dateCreated: new Date(),
					dateUpdated: new Date(),
					index: 0,
					sid: `omegaAIChatNewMessage${Date.now()}`,
					getMediaByCategory: () => [],
					conversation: {
						_participants: [],
						participant: [],
						sid: `omegaAIChat-${loggedInUser.id ?? 'ramsoft'}`,
					},
				},
			});

			setFormTextValue('');

			let promise;

			setTypingParticipants([
				...typingParticipants,
				{
					email: 'Root',
				},
			]);

			if (mavenAiChat) {
				const url = `${__config.ai_chat_uri}${encodeURIComponent(typedMessage)}`;
				const customConfig = {
					headers: {
						'Content-Type': 'application/json',
					},
				};
				try {
					await axios.get(url, customConfig).then(async result => {
						if (result.status === 200) {
							promise = Promise.resolve(result?.data);
						} else {
							promise = Promise.resolve('Something went wrong, please try again later');
						}
					});
				} catch (e) {
					console.error(e);
					setTypingParticipants([]);
					promise = Promise.resolve('Something went wrong, please try again later');
				}
			} else {
				promise = new Promise((res, rej) => {
					setTimeout(() => {
						res('This is a placeholder for now, to be implemented');
					}, 5000);
				});
			}

			promise?.then(msg => {
				dispatch({
					msgtype: 'MESSAGE_ADDED',
					payload: {
						author: 'OmegaAI',
						body: msg,
						dateCreated: new Date(),
						dateUpdated: new Date(),
						index: 0,
						sid: `omegaAIChatNewMessage${Date.now()}`,
						getMediaByCategory: () => [],
						conversation: {
							_participants: [],
							participant: [],
							sid: `omegaAIChat-${loggedInUser.id ?? 'ramsoft'}`,
						},
					},
				});
			});
			setTypingParticipants([]);
		} else {
			// Create support conversation
			if (section?.name === CHAT_SECTIONS.SUPPORT && !conversationDetail) {
				await createSupportConversation();
				return;
			}

			// Send Blume/OmegaAI conversation message
			const msg = formTextValue;
			setFormTextValue('');

			let identity = loggedEmail;
			if (nonUsEntities.includes(__config.resource_group)) {
				identity = `${__config.resource_group}-${loggedEmail}`;
			}

			// Re-add the participant who left the conversation
			if ((await conversationDetail?.getParticipantsCount()) === 1) {
				const participantLefts = conversationDetail.attributes?.participants?.filter(p => p.email !== identity);

				// Re-add in case one-one chat only
				if (participantLefts.length === 1) {
					await addParticipant(
						participantLefts[0].email,
						participantLefts[0].name,
						true,
						conversationDetail,
						participantLefts[0].internalId,
						participantLefts[0].role,
						participantLefts[0].isBlumeUser
					);

					const convDetail = await fetchConversationDetail(conversationDetail);
					dispatch({
						msgtype: 'CONVERSATION_UPDATED',
						payload: convDetail,
					});
				}
			}

			let messageIndex = null;
			if (sendMediaOptions) {
				messageIndex = await conversationDetail.sendMessage(sendMediaOptions, { length: audioMessageLength });
			} else messageIndex = await conversationDetail.sendMessage(msg);
			await conversationDetail.updateLastReadMessageIndex(messageIndex);

			dispatch({
				msgtype: 'MESSAGE_READ',
				payload: conversationDetail,
			});
		}
	};

	const getNameOfContact = (email, isBlumeUser) => {
		const node = contacts.filter(contact =>
			// OAI app loads both OAI and Blume contacts, so need to use isBlumeUser for filter condiition, no need to use isBlumeUser for Blume app
			contact.isBlumeUser && isBlumeUser !== 'undefined'
				? contact.email === email && contact.isBlumeUser == isBlumeUser
				: contact.email === email
		);
		return node != null ? node[0]?.name : null;
	};

	const scrollToBottom = () => {
		messagesRef.current?.scrollIntoView();
	};

	const getTime = timestamp => {
		const minutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((timestamp % (1000 * 60)) / 1000);
		return `${minutes / 10 > 1 ? minutes : `0${minutes}`}:${seconds / 10 > 1 ? seconds : `0${seconds}`}`;
	};

	const resetCounter = time => {
		if (refInstance.current) {
			clearInterval(refInstance.current);
			refInstance.current = null;
		} else {
			const id = setInterval(() => {
				setAudioRecordTimer(getTime(new Date().getTime() - time));
			}, 1000);
			refInstance.current = id;
		}
	};

	const voiceMessage = () => {
		if (!audioRecorder) {
			audioRecordTimestamp.current = new Date().getTime();
			const audioIN = { audio: true };
			navigator.mediaDevices
				.getUserMedia(audioIN)
				.then(stream => {
					const mediaRecorder = new MediaRecorder(stream);
					const audioChunks = [];

					mediaRecorder.addEventListener('dataavailable', event => {
						audioChunks.push(event.data);
					});

					mediaRecorder.addEventListener('stop', event => {
						const audioBlob = new Blob(audioChunks, { type: 'audio/ogg; codecs=opus' });
						stream.getTracks().forEach(track => {
							track.stop();
						});
						const sendMediaOptions = {
							contentType: 'audio/mpeg',
							filename: `${Date.now()}.mp3`,
							media: audioBlob,
						};
						onMessageSend({
							sendMediaOptions,
							audioMessageLength: new Date().getTime() - audioRecordTimestamp.current,
						});
						// You can now use the audioBlob to upload the audio data to a server or share it with others
					});

					mediaRecorder.start();
					setAudioRecorder(mediaRecorder);
				})
				.catch(err => {
					console.error(err.name, err.message);
				});
		} else {
			audioRecorder.stop();
			setAudioRecorder(null);
			setAudioRecordTimer('00:00');
		}
		resetCounter(new Date().getTime());
	};

	const formSubmitButton =
		!formTextValue && phoenixChatVoiceNote && !isAiChat ? (
			<>
				{audioRecorder && (
					<Box
						sx={{
							position: 'absolute',
							background: appBlumeMode ? '#f6f6f6' : '#272727',
							left: appBlumeMode ? '32px' : '12px',
							width: '120px',
						}}
					>
						<Typography sx={{ color: '#CF6679', display: 'flex' }}>
							<Icon component={KeyboardVoiceOutlinedIcon} sx={{ color: '#CF6679', marginRight: '5px' }} />
							{audioRecordTimer}
						</Typography>
					</Box>
				)}
				<ChatConversationFormButton
					key="voice"
					sx={audioRecorder && { backgroundColor: '#42A5F5' }}
					title={t('icon.voiceMessage')}
					onClick={voiceMessage}
				>
					<Icon component={KeyboardVoiceOutlinedIcon} sx={{ color: audioRecorder && 'white' }} />
				</ChatConversationFormButton>
			</>
		) : (
			<ChatConversationFormButton key="submit" title={t('icon.send')} onClick={e => onMessageSend({ e })}>
				<Icon component={SendIcon} />
			</ChatConversationFormButton>
		);

	const renderAvatarAndMessage = (item, index, showAvatar, isGroupChat) => (
		<Stack
			key={index}
			sx={{
				overflowWrap: 'break-word',
				position: 'relative',
			}}
		>
			<ChatMessage
				date={item.date}
				datetime={item.datetime}
				first={item.first}
				footer={item.footer}
				incoming={item.incoming}
				last={item.last}
				showAvatar={showAvatar && item.showAvatarAndTitle}
				sx={{
					paddingLeft: isGroupChat && expanded ? `${CHAT_AVATAR_SIZE + 10}px` : 0,
				}}
				title={item.showAvatarAndTitle ? item.title : ''}
			>
				<Typography sx={{ whiteSpace: 'pre-wrap' }}>{item.message}</Typography>
			</ChatMessage>
		</Stack>
	);

	const renderMessageDate = (item, index) => {
		const date = new Date(item?.date);
		return (
			<Typography
				key={index}
				className="chat-conversation-date"
				component="div"
				sx={{
					'--element-foreground-color': 'var(--element-foreground-color-tertiary)',
					background: 'var(--element-background-color)',
					color: 'var(--element-foreground-color)',
					fontSize: '12px',
					margin: '0 calc(0px - var(--element-content-spacing))',
					padding: '15px',
					position: 'sticky',
					textAlign: 'center',
					top: 0,
					zIndex: 1,
				}}
			>
				{date?.toLocaleDateString(dateTimeLocale, dateTimeFormatOptions)}
			</Typography>
		);
	};

	const AudioMessage = ({ item, index }) => {
		const { appBlumeMode } = useChatContext();
		const [playing, setPlaying] = useState(false);
		const [currentTime, setCurrentTime] = useState(0);
		const [duration, setDuration] = useState(0);

		const audioRef = useRef(null);

		const handlePlay = () => {
			audioRef.current.play();
			setPlaying(true);
		};

		const handlePause = () => {
			audioRef.current.pause();
			setPlaying(false);
		};
		const handleTimeUpdate = () => {
			setCurrentTime(audioRef.current.currentTime);
			setDuration(audioRef.current.duration);
		};
		const handleSeek = e => {
			audioRef.current.currentTime = e.target.value;
		};
		const isWhite = item.title == 'Me' || !appBlumeMode;
		const boxColor = appBlumeMode
			? item.title == 'Me'
				? '#42a5f5'
				: '#F6F6F6'
			: item.title == 'Me'
			? '#434343'
			: '#272727';
		return (
			<Stack
				key={index}
				sx={{
					overflowWrap: 'break-word',
					position: 'relative',
					height: '90px',
					paddingBottom: '10px',
				}}
			>
				<Box
					sx={{
						width: '292px',
						height: '80px',
						borderRadius: '15px',
						backgroundColor: boxColor,
						alignSelf: isWhite ? 'end' : 'start',
						marginBottom: '3px',
					}}
				>
					{item.datetime ? (
						<Box sx={{ padding: '10px', marginBottom: '10px' }}>
							<Typography
								className="chat-message-datetime"
								component="time"
								data-testid="chat-message-datetime"
								dateTime={item.datetime}
								sx={{
									color: isWhite ? 'white' : 'var(--element-foreground-color)',
									fontSize: '12px',
									marginLeft: '10px',
									whiteSpace: 'nowrap',
									float: 'right',
								}}
							>
								{item.datetime}
							</Typography>
						</Box>
					) : null}
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<ChatAvatar
							data={{ name: item.title == 'Me' ? loggedInUser.fullName : item.title }}
							showBadge={isAiChat}
							status={null}
							sx={{
								position: 'absolute',
								margin: '0px 0px 0px 5px',
								left: !isWhite && '245px',
								width: '40px',
								height: '40px',
							}}
						/>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								width: '245px',
								backgroundColor: 'none',
								padding: isWhite ? '0px 0px 0px 45px' : '0px 45px 0px 0px',
								color: isWhite && 'white',
							}}
						>
							<audio ref={audioRef} src={item.audioMessage} onTimeUpdate={handleTimeUpdate} />
							<IconButton onClick={playing ? handlePause : handlePlay}>
								{playing ? (
									<Tooltip title={t('icon.pause')}>
										<PauseIcon sx={{ color: isWhite && 'white' }} />
									</Tooltip>
								) : (
									<Tooltip title={t('icon.play')}>
										<PlayArrowIcon sx={{ color: isWhite && 'white' }} />
									</Tooltip>
								)}
							</IconButton>
							<input
								className={`slider ${isWhite && 'isWhite'}`}
								max={duration}
								min={0}
								step={0.01}
								style={{
									width: '165px',
									marginRight: '5px',
									appearance: 'none',
									height: '2px',
								}}
								type="range"
								value={currentTime}
								onChange={handleSeek}
							/>
							<Typography
								sx={{
									fontSize: '12px',
								}}
							>
								{playing ? `00:0${Math.round(currentTime)}` : getTime(item.audioMessageLength)}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Stack>
		);
	};

	React.useEffect(() => {
		scrollToBottom();
	}, [data]);

	// Sync conversation Prop with State
	React.useEffect(() => {
		setConversationDetail(conversation);
	}, [conversation]);

	React.useEffect(() => {
		async function asynchronousEffect() {
			if (conversationDetail != null) {
				await conversationDetail.setAllMessagesRead();
				dispatch({
					msgtype: 'MESSAGE_READ',
					payload: conversationDetail,
				});
			}
		}

		asynchronousEffect();
	}, []);

	React.useEffect(() => {
		// Check participants who are typing
		state[conversationDetail?.sid]?.conversation.getParticipants().then(async participants => {
			if (participants.length > 0) {
				const typingUsers = participants.filter(
					participant =>
						participant.identity !== loggedInUser?.email?.toLowerCase() && participant.state.isTyping
				);
				if (typingUsers.length > 0) {
					typingUsers.forEach(participant => {
						setTypingParticipants([
							...typingParticipants,
							{
								email: participant.identity,
								name: getNameOfContact(participant.identity, participant.state.attributes.isBlumeUser),
							},
						]);
					});
				} else {
					setTypingParticipants([]);
				}
			}
		});
	}, [state]);

	React.useEffect(() => {
		const dataGroup = [];
		const messages = state[conversationDetail?.sid]?.messages;

		let isSupport = false;
		if (section?.name === CHAT_SECTIONS.SUPPORT) {
			isSupport = true;
		}

		let breezeEmail = loggedEmail;
		if (nonUsEntities.includes(__config.resource_group)) {
			breezeEmail = `${__config.resource_group}-${loggedEmail}`;
		}

		const groupByDate =
			messages?.reduce((group, message) => {
				const { dateUpdated } = message;
				const date = moment(dateUpdated).format('MMM DD, YYYY');
				group[date] = group[date] ?? [];
				group[date].push(message);
				return group;
			}, {}) ?? {};

		const groupArrays = Object.keys(groupByDate).map(date => ({
			date,
			messages: groupByDate[date],
		}));
		(async () => {
			for (const [index, group] of groupArrays.entries()) {
				dataGroup.push({
					date: group.date,
				});
				for (const message of group.messages) {
					const media = await message.getMediaByCategory(['media']);
					let audioMessage = null;
					let audioMessageLength = null;
					if (media.length === 1 && media[0].contentType === 'audio/mpeg') {
						audioMessage = await media[0].getContentTemporaryUrl();
						audioMessageLength = message?.attributes?.length || null;
					}
					// isBlumeUser attribute is the custom attribute that is created when adding twilio participant
					// For the paticipant who created the conversation, get isBlumeUser from the custom participants attribute
					// added when creating the conversation
					const participants = Array.from(message.conversation._participants).filter(
						participantArr => participantArr[0] === message.state.participantSid
					);
					const isBlumeUser = isAiChat
						? false
						: participants?.length > 0
						? participants[0][1].state.attributes.isBlumeUser
						: message.conversation.attributes?.participants.filter(
								participant => participant.email === message.author && participant?.isCreator
						  )[0]?.isBlumeUser ?? false;

					if (index == 0 && index == group.messages.length - 1) {
						dataGroup.push({
							title:
								(isSupport ? breezeEmail : loggedEmail) !== message.author
									? getNameOfContact(message.author, isBlumeUser) ?? message.author
									: mySelf,
							datetime: moment(message.dateUpdated).format('hh:mm a'),
							message: message.body,
							audioMessage,
							audioMessageLength,
							incoming: (isSupport ? breezeEmail : loggedEmail) !== message.author,
							first: true,
							last: true,
							showAvatarAndTitle: !!isGroupChat,
						});
					} else if (index == 0) {
						dataGroup.push({
							title:
								(isSupport ? breezeEmail : loggedEmail) !== message.author
									? getNameOfContact(message.author, isBlumeUser) ?? message.author
									: mySelf,
							datetime: moment(message.dateUpdated).format('hh:mm a'),
							message: message.body,
							audioMessage,
							audioMessageLength,
							incoming: (isSupport ? breezeEmail : loggedEmail) !== message.author,
							first: true,
							showAvatarAndTitle: !!isGroupChat,
						});
					} else if (index == group.messages.length - 1) {
						dataGroup.push({
							title:
								(isSupport ? breezeEmail : loggedEmail) !== message.author
									? getNameOfContact(message.author, isBlumeUser) ?? message.author
									: mySelf,
							datetime: moment(message.dateUpdated).format('hh:mm a'),
							message: message.body,
							audioMessage,
							audioMessageLength,
							incoming: (isSupport ? breezeEmail : loggedEmail) !== message.author,
							last: true,
							showAvatarAndTitle: !!isGroupChat,
						});
					} else {
						dataGroup.push({
							title:
								(isSupport ? breezeEmail : loggedEmail) !== message.author
									? getNameOfContact(message.author, isBlumeUser) ?? message.author
									: mySelf,
							message: message.body,
							audioMessage,
							audioMessageLength,
							incoming: (isSupport ? breezeEmail : loggedEmail) !== message.author,
							datetime: moment(message.dateUpdated).format('hh:mm a'),
							showAvatarAndTitle: !!isGroupChat,
						});
					}
				}
			}
			setData(dataGroup);
		})();
	}, [state, conversationDetail]);

	const handleClick = event => {
		if (event) {
			setAnchorEl(anchorEl ? null : event.currentTarget);
		} else {
			setAnchorEl(null);
		}
	};

	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateRows: '1fr min-content',
				overflow: 'hidden',
				pb: smallView ? '18px' : '0px',
			}}
		>
			<Stack
				sx={{
					overflow: 'auto',
					padding: '0 var(--element-content-spacing-right) 0 var(--element-content-spacing-left)',
				}}
			>
				{section?.name === CHAT_SECTIONS.SUPPORT && (
					<>{renderAvatarAndMessage(defaultSupportMsg, -1, showAvatars)}</>
				)}

				{data.map((item, index) => {
					if (item.message) {
						const showAvatar = item.incoming && showAvatars;
						return renderAvatarAndMessage(item, index, showAvatar, isGroupChat);
					}

					if (item.date) {
						return renderMessageDate(item, index);
					}

					if (item.audioMessage) {
						return <AudioMessage index={index} item={item} />;
					}

					return null;
				})}

				<div ref={messagesRef} />
			</Stack>
			<Stack
				sx={{
					padding: 'var(--element-content-spacing-all)',
				}}
			>
				{typingParticipants?.length > 0 && (
					<Box
						sx={{
							background: 'rgba(255, 255, 255, 0.05)',
							borderRadius: '5px 5px 5px 0px',
							width: '127px',
							height: '40px',
							mb: '23px',
						}}
					>
						<div className="dot-elastic" style={{ marginLeft: '60px', marginTop: '18px' }} />
					</Box>
				)}
				{isAiChat && (
					<Stack
						alignItems="center"
						direction="row"
						justifyContent="space-between"
						sx={{
							color: '#4BAAF6',
							backgroundColor: '#42A5F51A',
							borderRadius: '10px',
							padding: '6px',
							marginBottom: '10px',
						}}
					>
						<Stack alignItems="center" direction="row" gap="10px">
							<CampaignIcon />
							<Typography sx={{ fontSize: '12px', fontFamily: 'Roboto', lineHeight: '16px' }}>
								{t('Disclaimer Message')}
								{` (`}
								<Link
									href="https://www.ramsoft.com/AI-disclaimer"
									rel="noopener"
									sx={{
										color: '#4BAAF6',
										fontSize: '12px',
										fontFamily: 'Roboto',
										lineHeight: '16px',
										textDecorationColor: '#4BAAF6',
									}}
									target="_blank"
									underline="always"
								>
									{t('Details Disclaimer')}
								</Link>
								).
							</Typography>
						</Stack>
					</Stack>
				)}
				<Stack alignItems="center" direction="row" gap="10px">
					{
						//PRO-2947
						/* <ChatConversationFormButton onClick={() => {}}>
						<Icon
							component={AttachmentOutlinedIcon}
							fontSize="small"
							sx={{
								transform: 'rotate(-30deg)',
							}}
						/>
					</ChatConversationFormButton>

					<ChatConversationFormButton onClick={() => {}}>
						<Icon component={PresentToAllOutlinedIcon} fontSize="small" />
					</ChatConversationFormButton> */
					}

					<TextField
						multiline
						InputProps={{
							className: 'chat-conversation-form-field-input',
							sx: {
								backgroundColor: 'var(--element-field-background-color)',
								borderRadius: 'var(--border-radius-base)',
							},
							endAdornment: showFormSubmitButtonInField ? (
								formSubmitButton
							) : (
								<Tooltip title={t('icon.emoji')}>
									<TagFacesIcon
										sx={{
											[mediaTablet]: {
												display: 'none',
											},
										}}
										onClick={handleClick}
									/>
								</Tooltip>
							),
						}}
						className="chat-conversation-form-field"
						maxRows={5}
						minRows={1}
						placeholder={t('chatConversation.typeHerePlaceholder')}
						size="small"
						sx={{
							flex: 'auto',
						}}
						value={formTextValue}
						onChange={value => {
							setFormTextValue(value.target.value);
						}}
						onKeyPress={async e => {
							if (e.key === 'Enter') {
								await onMessageSend({ e });
							} else {
								conversationDetail?.typing();
							}
						}}
					/>

					<Popper
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						placement="top"
						sx={{
							marginLeft: expanded ? '-28px !important' : '-16px !important',
							marginBottom: '11px !important',
							zIndex: '2100',
						}}
					>
						<ClickAwayListener onClickAway={() => setTimeout(() => handleClick(), 0)}>
							<Box>
								<Picker
									color="#42A5F5"
									emojiSize={24}
									showPreview={false}
									showSkinTones={false}
									style={{
										fontSize: '14px',
										borderRadius: '10px',
										border: '1px solid rgba(18, 18, 18, 0.2)',
										boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
									}}
									onSelect={e => {
										const emoji = e.native;
										setFormTextValue(formTextValue + emoji);
									}}
								/>
							</Box>
						</ClickAwayListener>
					</Popper>
					{!showFormSubmitButtonInField ? formSubmitButton : null}
				</Stack>
			</Stack>
		</Box>
	);
};

export default ChatConversationMessaging;
