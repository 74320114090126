import React, { forwardRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

import { useScheduleStore } from '../../../../stores';

const LocationScreen = ({ form }, ref) => {
	const isLoading = useScheduleStore(state => state.isLoading);
	const getOrgImagingCenterLocations = useScheduleStore(state => state.getOrgImagingCenterLocations);
	const nextStep = useScheduleStore(state => state.nextStep);
	const prevStep = useScheduleStore(state => state.prevStep);
	const locations = useScheduleStore(state => state.locations);
	const modalities = useScheduleStore(state => state.modalities);
	const { getValues, setValue, watch } = form;

	const [searchParams] = useSearchParams();
	const { t } = useTranslation('scheduling');

	const orgId = searchParams.get('orgId');

	// Watch for location form state change
	watch('location');

	useEffect(() => {
		const DicomModalities = modalities.find(({ Name }) => Name === getValues().modality)?.DicomModalities;

		try {
			navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
				getOrgImagingCenterLocations({
					orgId,
					modality: DicomModalities?.toString(),
					lat: latitude,
					long: longitude,
				});
			});
		} catch (err) {
			console.error(err);
		}
	}, [getOrgImagingCenterLocations, getValues, modalities, orgId]);

	const gotToNextStep = useCallback(() => {
		nextStep();
	}, [nextStep]);

	return (
		<Box
			ref={ref}
			sx={{
				maxWidth: '764px',
				margin: 'auto',
				background: '#fff',
				padding: '32px 32px 36px 32px',
				boxShadow: '0px 4px 74px 0px rgba(0, 0, 0, 0.03)',
			}}
		>
			<Box display="flex" justifyContent="space-between" sx={{ marginBottom: '28px' }}>
				<Box display="flex" flexDirection="column" gap="8px">
					<Typography color="#344054">{t('Selected study type')}</Typography>
					<Typography color="#101828" fontSize="20px" fontWeight={500}>
						{getValues().modality}
					</Typography>
				</Box>
				<Box>
					<Button
						data-testid="location-back-btn"
						sx={{
							textTransform: 'uppercase',
							color: 'rsPrimary.main',
							fontWeight: 500,
							fontVariant: 'all-small-caps',
							fontSize: '16px',
						}}
						onClick={prevStep}
					>
						{t('Change Study')}
					</Button>
				</Box>
			</Box>
			<Divider sx={{ display: { lg: 'none' }, margin: '24px 0 24px 0', color: '#D0D5DD' }} />
			<Box>
				<Typography fontWeight={500} sx={{ color: '#475467', marginBottom: '16px' }}>
					{t('Choose imaging center')}
				</Typography>
				<Box display="flex" flexDirection="column" gap="20px">
					{locations.length && !isLoading
						? locations.map(({ name, address }) => (
								<Option
									key={name}
									active={name === getValues().location}
									sx={{ height: { md: 'auto', lg: '85px' } }}
								>
									<OptionButton
										disableRipple
										data-testid="location-option"
										onClick={() => setValue('location', name)}
									>
										<Typography component="span" fontWeight={600} sx={{ color: '#101828' }}>
											{name}
										</Typography>
										<Typography
											component="address"
											sx={{
												fontStyle: 'normal',
												lineHeight: '26px',
												color: '#344054',
												textAlign: 'left',
												width: 'calc(100% - 112px)',
												overflow: { lg: 'hidden' },
												whiteSpace: { sm: 'normal', md: 'normal', lg: 'nowrap' },
												textOverflow: { md: 'initial', lg: 'ellipsis' },
											}}
										>
											{address}
										</Typography>
										<Button
											component="a"
											data-testid="location-view-in-maps-link"
											href={`https://www.google.com/maps/place/${encodeURIComponent(address)}`}
											rel="noopener"
											sx={{
												color: 'rsPrimary.main',
												fontWeight: 500,
												lineHeight: '24px',
												position: 'absolute',
												top: '16px',
												right: '16px',
												textTransform: 'none',
											}}
											target="_blank"
											onClick={ev => ev.stopPropagation()}
										>
											{t('View in maps')}
										</Button>
									</OptionButton>
								</Option>
						  ))
						: null}
					{isLoading ? (
						<div>
							<Skeleton
								data-testid="location-loader"
								sx={{
									height: '408px',
									maxWidth: '100%',
									borderRadius: '10px',
								}}
								variant="rectangular"
							/>
						</div>
					) : null}
				</Box>
			</Box>
			<Box display="flex" justifyContent="flex-end" sx={{ margin: '48px 0 0 0' }}>
				<Button
					color="rsPrimary"
					data-testid="location-next-btn"
					disabled={!getValues().location}
					sx={{
						textTransform: 'uppercase',
						boxShadow: 'none',
						padding: '12px 14px',
						width: { xs: '100%', lg: '150px' },
					}}
					type="button"
					variant="contained"
					onClick={gotToNextStep}
				>
					{t('Next')}
				</Button>
			</Box>
		</Box>
	);
};

const Option = styled(Box)(
	({ active }) => `
    display: flex;
    border-radius: 8px;
    border: ${active ? '2px solid #42A5F5' : '1px solid #D0D5DD'};
    background: ${active ? '#F3FAFF' : 'transparent'};
		transition: background 250ms ease-in-out;
    
    &:hover {
      // 
    } 
  `
);

const OptionButton = styled(ButtonBase)(
	() => `
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    padding: 16px;
    overflow: hidden;
		font-family: Roboto;
  }
`
);

export default forwardRef(LocationScreen);
