import DocumentViewDrawer from '../../../DocumentDrawer';
import FirstPage from './sections/FirstPage/FirstPage';
import SecondPage from './sections/SecondPage/SecondPage';
import React from 'react';
import { useNewAppointmentContentV2Context } from './contexts/NewAppointmentContentV2ContextProvider';
import useFacilityReloadWatcher from './watchers/useFacilityReloadWatcher';
import useInitialize from './hooks/useInitialize';
import usePageChangeWatcher from './watchers/usePageChangeWatcher';
import useGenderBirthDateWatcher from './watchers/useGenderBirthDateWatcher';
import usePatientWatcher from './watchers/usePatientWatcher';
import useDocumentDetailsWatcher from './watchers/useDocumentDetailsWatcher';
import usePatientNotificationWatcher from './watchers/usePatientNotificationWatcher';
import useSyncFormWatcher from './watchers/useSyncFormWatcher';
import SuccessState from './sections/Success/SuccessState';

const NewAppointmentContentV2Wrapper = ({ isUpdateForm }) => {
	const { isOpenDocument, setIsOpenDocument, viewDocDetails, page, mapDefaultCenter } =
		useNewAppointmentContentV2Context();

	useInitialize();

	useFacilityReloadWatcher();
	usePageChangeWatcher();
	usePatientWatcher();
	usePatientNotificationWatcher(Boolean(isUpdateForm));
	useGenderBirthDateWatcher();
	useDocumentDetailsWatcher();
	useSyncFormWatcher();

	return (
		<>
			<DocumentViewDrawer
				documentData={viewDocDetails}
				drawerOpen={isOpenDocument}
				setIsOpenDocument={setIsOpenDocument}
				source="Document"
			/>
			{page === 'first' && <FirstPage />}

			{page === 'second' && <SecondPage />}

			{page === 'success' && <SuccessState />}
		</>
	);
};

export default NewAppointmentContentV2Wrapper;
