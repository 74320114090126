export const getAppointmentColor = status =>
	({
		scheduled: '#C480DC',
		pending: '#929292',
		progressing: '#42A5F5',
		complete: '#75B678',
		rejected: '#EB8787',
		booking: '#CF6679',
		cancelled: '#EB8787',
		requested: '#D09A49',
		parked: '#929292',
	})[status];

export const getAppointmentBadgeColor = status =>
	({
		scheduled: 'rgba(241, 224, 250, 0.8)',
		pending: '#E8E8E8',
		progressing: 'rgba(66, 165, 245, 0.08)',
		complete: 'rgba(117, 182, 120, 0.08)',
		rejected: 'rgba(235, 135, 135, 0.08)',
		booking: 'rgba(207, 102, 121, 0.08)',
		cancelled: 'rgba(235, 135, 135, 0.08)',
		requested: '#FFEDD0',
		parked: '#E8E8E8',
	})[status];

export { scanTypes, getPatientFriendlyModality } from '../V2/utils/index';
