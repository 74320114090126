import { Box } from '@mui/system';
import CustomTextField from '@worklist-2/ui/src/components/CustomTextField/CustomTextField';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mediaMobile } from '@worklist-2/core/src';
import { Select, MenuItem } from '@mui/material';
import phoneNumberData from './CountryCodesAndFlags.json';

const PhoneNumberField = ({ visible, setSeletedCountryCode, onChange }) => {
	const { t } = useTranslation('login');

	const countryCodes = phoneNumberData.slice(0, 7);
	const [selectedCountry, setSelectedCountry] = useState('CA');
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [digits, setDigits] = useState(10);
	useEffect(() => {
		const country = countryCodes.find(({ code }) => code === selectedCountry);
		setDigits(country.digits);
	}, [selectedCountry, countryCodes]);

	useEffect(() => {
		setInitialCountry();
	}, []);
	const setInitialCountry = () => {
		// Attempt to pre-select the country based on user's location
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(position => {
				// Example: Check if the latitude and longitude are within a specific range to determine the country
				const { longitude } = position.coords;
				let countryCode = '';
				if (longitude >= -141.0 && longitude <= -52.6) {
					countryCode = 'CA'; // Canada
				} else if (longitude >= 68.7 && longitude <= 97.25) {
					countryCode = 'IN'; // India
				} else if (longitude >= 102 && longitude <= 110) {
					countryCode = 'VN'; // Viet Nam
				} else {
					countryCode = 'US'; // United States
				}
				setSelectedCountry(countryCode);
				setSeletedCountryCode(countryCode);
			});
		}
	};

	const handleCountryChange = event => {
		setSelectedCountry(event.target.value);
		setSeletedCountryCode(event.target.value);
		onChange();
	};

	useEffect(() => {
		// Select the input field
		const phoneNumberInput = document.getElementById('phoneNumberInput');

		// Add event listener for input
		phoneNumberInput.addEventListener('input', event => {
			const inputValue = event.target.value;
			const numericValue = inputValue.replace(/\D/g, '');
			// eslint-disable-next-line no-param-reassign
			event.target.value = numericValue;
		});
	}, []);

	return (
		<Box
			className="phonenumberWrapper"
			id="PhoneNumberField"
			sx={{
				display: 'flex',
				gap: '10px',
				alignItems: 'center',
			}}
		>
			<Select
				data-cy="country-select"
				data-testid="country-select"
				open={dropdownVisible}
				sx={{
					display: visible ? 'block' : 'none',
					'.MuiSelect-select': {
						display: 'flex',
						gap: '10px',
						padding: '9px',
						'.countryName': {
							display: 'none',
						},
					},
				}}
				value={selectedCountry}
				onChange={handleCountryChange}
				onClose={() => setDropdownVisible(false)}
				onOpen={() => setDropdownVisible(true)}
			>
				{countryCodes.map(country => (
					<MenuItem key={country.code} sx={{ gap: '10px' }} value={country.code}>
						<img alt={country.name} src={country.flag} width={30} />
						<Box data-value={country.number} id="countryNumber" style={{ display: 'flex' }}>
							<p className="countryName" style={{ margin: '0px' }}>
								{country.name.split('(')[0]}
							</p>
							<p style={{ margin: '0px' }}>&nbsp;({country.number})</p>
						</Box>
					</MenuItem>
				))}
			</Select>
			<CustomTextField
				fullWidth
				autoComplete="number"
				// disabled={isDisabled}
				id="phoneNumberInput"
				inputProps={{
					maxlength: digits || 10,
				}}
				name="Phone Number"
				placeholder={t('')}
				sx={{
					width: '100%',
					display: visible ? 'block' : 'none',
					[mediaMobile]: {
						width: '100% !important',
					},
				}}
				type="tel"
				onChange={onChange}
				// value={value}
				// onKeyPress={event => onKeyPress(event)}
			/>
		</Box>
	);
};
export default PhoneNumberField;
