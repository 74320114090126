import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import CheckIcon from '@mui/icons-material/Check';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FilterListIcon from '@mui/icons-material/FilterList';

import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import { APPOINTMENT_STATUS } from '../../../consts';

const AppointmentCardsViewHeader = () => {
	const { filter, setFilter } = useAppointmentContext();
	const [anchorEl, setAnchorEl] = useState(null);

	const { t } = useTranslation('appointments');

	const open = Boolean(anchorEl);

	const filterOptions = useMemo(
		() => [
			{
				text: t('All'),
				value: null,
				checked: filter === null,
			},
			{
				text: t('Requested'),
				value: APPOINTMENT_STATUS.REQUESTED,
				checked: filter === APPOINTMENT_STATUS.REQUESTED,
			},

			{
				text: t('Scheduled'),
				value: APPOINTMENT_STATUS.SCHEDULED,
				checked: filter === APPOINTMENT_STATUS.SCHEDULED,
			},
			{
				text: t('Pending'),
				value: APPOINTMENT_STATUS.PENDING,
				checked: filter === APPOINTMENT_STATUS.PENDING,
			},
			{
				text: t('Cancelled'),
				value: APPOINTMENT_STATUS.CANCELLED,
				checked: filter === APPOINTMENT_STATUS.CANCELLED,
			},
		],
		[filter, t]
	);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box
			data-testid="appointment-cards-view-header"
			sx={{ display: 'flex', justifyContent: 'flex-end', margin: '0 0 8px 0' }}
		>
			<IconButton data-testid="appointment-filter-button" onClick={handleClick}>
				<Badge
					badgeContent={1}
					color="rsPrimary"
					invisible={!filter}
					sx={{
						'.MuiBadge-badge': {
							color: '#fff',
							backgroundColor: 'rgba(18, 18, 18, 0.60)',
							top: '17px',
							right: '5px',
							fontSize: '8px',
							height: '12px',
							width: '12px',
							minWidth: '12px',
						},
					}}
				>
					<FilterListIcon />
				</Badge>
			</IconButton>
			<Menu
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						padding: '20px 0 16px 0',
					},
				}}
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
				data-testid="appointment-filter-menu"
				open={open}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				onClick={handleClose}
				onClose={handleClose}
			>
				<Typography
					component="p"
					sx={{
						fontWeight: 500,
						textTransform: 'uppercase',
						letterSpacing: '2.4px',
						margin: '0 0 19px 0',
						padding: '0 28px 0 26px',
					}}
				>
					{t('Filter By')}
				</Typography>
				{filterOptions.map(({ text, value, checked }) => (
					<MenuItem
						key={value}
						data-testid="appointment-filter-menu-item"
						sx={{
							padding: '8px 28px 8px 26px',
							display: 'flex',
							alignItems: 'center',

							'&:last-child': {
								paddingBottom: 0,
							},
						}}
						onClick={() => setFilter(value)}
					>
						<CheckIcon
							color="rsPrimary"
							sx={{
								opacity: checked ? 1 : 0,
								pointerEvents: checked ? 'all' : 'none',
								marginRight: '10px',
							}}
						/>
						{text}
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
};

export default AppointmentCardsViewHeader;
