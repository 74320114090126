import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { fr } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import Box from '@mui/material/Box';
import AppointmentDrawerButtonBook from '../AppointmentDrawer/AppointmentDrawerButtonBook';
import { useAppointmentContext } from '../AppointmentContext/AppointmentContext';
import AppointmentCalendarSmallDay from './AppointmentCalendarSmallDay';
import { mediaTablet, mediaMobile, useIsTablet } from '@worklist-2/core/src/utils/responsiveUtils';

import moment from 'moment';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { YearPicker } from '@mui/x-date-pickers';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

const AppointmentCalendarSmall = () => {
	const isTablet = useIsTablet();
	const [openToView, setOpenToView] = useState('day');
	const [highlightedDay, setHighlightedDay] = useState();
	const [displayDay, setDisplayDay] = useState(today || new Date());

	const { appointments, selectedDay, setSelectedDay, today, selectedMonth, setSelectedMonth } =
		useAppointmentContext();

	useEffect(() => {
		if (selectedDay) {
			setDisplayDay(selectedDay);
		}
	}, [selectedDay]);

	useEffect(() => {
		if (today) setSelectedMonth(today);
	}, []);

	const handleClick = day => {
		setDisplayDay(moment(displayDay).add(day, 'month').toDate());
	};

	const selectDay = day => {
		if (moment(day).isSame(selectedDay, 'second')) {
			setSelectedDay();
			setHighlightedDay();
			return null;
		}

		// Return if date passed if before today
		if (!moment(day).isSameOrAfter(moment(), 'day')) return null;

		setSelectedDay(day);
		setHighlightedDay(day);
	};

	const { t, i18n } = useTranslation('appointments');

	const renderDay = day => (
		<AppointmentCalendarSmallDay
			appointments={appointments.filter(
				appointment => appointment.dateStart.toDateString() === day.toDateString()
			)}
			day={day}
			selectedDay={highlightedDay}
			selectedMonth={selectedMonth || new Date()}
			setSelectedDay={selectDay}
			today={today || new Date()}
		/>
	);

	const CustomAdapter = options => {
		const adapter = new AdapterDateFns(options);

		const constructDayObject = day => ({
			charAt: () => day,
		});

		return {
			...adapter,

			getWeekdays() {
				const customWeekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map(day => t(day)[0]);

				return customWeekdays.map(day => constructDayObject(day));
			},
		};
	};

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					margin: '0px 20px 4px 30px',
					paddingTop: '12px',
					[mediaTablet]: {
						margin: '0px 16px 4px 16px',
					},
					[mediaMobile]: {
						margin: '0px 8px 4px 16px',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						[mediaTablet]: { flex: 1 },
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							cursor: 'pointer',
						}}
						onClick={() => {
							if (openToView === 'day') {
								setOpenToView('year');
							} else {
								setOpenToView('day');
							}
						}}
					>
						<Typography
							key={displayDay}
							sx={{
								fontSize: '20px',
								lineHeight: '23px',
								letterSpacing: '0.15px',
								color: '#42A5F5',
							}}
						>
							{isTablet
								? t(moment(displayDay).format('MMMM'))
								: i18n?.language === 'en'
								? t(moment(displayDay).format('MMMM')).substring(0, 3)
								: t(moment(displayDay).format('MMMM')).substring(0, 4)}
						</Typography>
						<Typography
							sx={{
								fontSize: '20px',
								lineHeight: '23px',
								letterSpacing: '0.15px',
								color: '#42A5F5',
								paddingLeft: '4px',
							}}
						>
							{moment(displayDay).format('YYYY')}
						</Typography>
						<Tooltip title={openToView === 'day' ? t('Select Year') : t('Select Day')}>
							<ArrowDropDownIcon
								sx={{
									color: '#42A5F5',
									transform: openToView === 'year' ? 'rotate(180deg)' : '',
									transition: '0.2s',
								}}
							/>
						</Tooltip>
					</Box>
					<Box>
						<IconButton disabled={openToView === 'year'} onClick={() => handleClick(-1)}>
							<Tooltip title={t('Prev')}>
								<KeyboardArrowLeftIcon color="#12121299" />
							</Tooltip>
						</IconButton>

						<IconButton disabled={openToView === 'year'} onClick={() => handleClick(1)}>
							<Tooltip title={t('Next')}>
								<KeyboardArrowRightIcon color="#12121299" />
							</Tooltip>
						</IconButton>
					</Box>
				</Box>
				{!isTablet && (
					<Box>
						<AppointmentDrawerButtonBook disabled={openToView === 'year'} />
					</Box>
				)}
			</Box>
			<Box
				sx={{
					marginLeft: '30px',
					marginRight: '20px',
					borderBottom: appointments.length === 0 ? '1px solid #1212121A' : undefined,
					'& .MuiYearPicker-root': {
						maxHeight: '284px',
						'& .PrivatePickersYear-root': {
							'& .Mui-selected': {
								backgroundColor: '#42A5F5',
							},
						},
					},
					'& .MuiCalendarPicker-root': {
						width: '100%',
						'& > div': {
							'&:first-of-type': {
								paddingLeft: '0px',
								paddingRight: '0px',
								display: 'none',
								'& > div:first-of-type': {
									'& > div': {
										fontSize: '20px',
										fontWeight: '400',
										color: '#42A5F5',
										letterSpacing: '0.15px',
									},
									'& > button': {
										color: '#42A5F5',
									},
								},
							},
							'&:last-child': {
								'& > div': {
									justifyContent: 'space-between',
								},
							},
						},
					},
					'& .PrivatePickersFadeTransitionGroup-root': {
						fontSize: '20px',
						lineHeight: '23px',
						letterSpacing: '0.15px',
						color: 'rgba(18, 18, 18, 0.6)',
						'& > div > div': {
							justifyContent: 'space-between',
						},
					},
					'& .PrivatePickersSlideTransition-root': {
						minHeight: '244px',
						'& > div': {
							paddingBottom: '2px',
							'& > div': {
								justifyContent: 'space-between',
							},
						},
					},
					'& .MuiTypography-root': {
						fontSize: '16px',
						color: 'rgba(18, 18, 18, 0.6)',
						width: '42px',
					},
					[mediaTablet]: {
						marginLeft: '16px',
						marginRight: '16px',
					},
				}}
			>
				{openToView === 'day' ? (
					<LocalizationProvider dateAdapter={CustomAdapter}>
						<CalendarPicker
							date={displayDay}
							openTo="day"
							renderDay={renderDay}
							onChange={selectDay}
							onMonthChange={setSelectedMonth}
						/>
					</LocalizationProvider>
				) : (
					<LocalizationProvider dateAdapter={CustomAdapter} locale={fr}>
						<YearPicker
							date={displayDay}
							minDate={moment().subtract(5, 'years').toDate()}
							onChange={newDate => {
								setSelectedDay(newDate);
								setOpenToView('day');
							}}
						/>
					</LocalizationProvider>
				)}
			</Box>
		</>
	);
};

export default AppointmentCalendarSmall;
