import { useEffect } from 'react';

const googleTranslateElementInit = () => {
	new window.google.translate.TranslateElement(
		{
			pageLanguage: 'en',
			autoDisplay: false,
		},
		'google_translate_element'
	);
};

const useGoogleTranslation = () => {
	useEffect(() => {
		const addScript = document.createElement('script');
		addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
		document.body.appendChild(addScript);
		window.googleTranslateElementInit = googleTranslateElementInit;
	}, []);
};

export default useGoogleTranslation;
