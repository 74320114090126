import React, { useState, useRef, useEffect } from 'react';
import Lottie from 'lottie-web';
import { useMsal } from '@azure/msal-react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { InteractionStatus } from '@azure/msal-browser';
import axios from 'axios';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { default as MuiGrid } from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import {
	useConfig,
	useAuth,
	useAppModeContext,
	useSelector,
	mediaTablet,
	mediaMobile,
	useIsMobile,
} from '@worklist-2/core/src';
import LoginField from '@worklist-2/ui/src/components/LoginField';
import OutlineButtonWithIcon from '@worklist-2/ui/src/components/OutlineButtonWithIcon';
import NewsModal from '@worklist-2/ui/src/components/News/NewsModal';
import animation from '@worklist-2/ui/src/assets/animation/web/json1/data.json';
import MobileBottomDrawer from '../../components/MobileBottomDrawer/MobileDrawer/MobileBottomDrawer';
import NewsModalMobile from '@worklist-2/ui/src/components/News/NewsModalMobile';
import { useTranslation } from 'react-i18next';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import PhoneNumberField from './PhoneNumberField/PhoneNumberField';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

export const getBrowserName = () => {
	const browserInfo = navigator.userAgent;
	if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
		return 'Opera';
	}
	if (browserInfo.includes('Edg')) {
		return 'Edge';
	}
	if (browserInfo.includes('Chrome')) {
		return 'Chrome';
	}
	if (browserInfo.includes('Safari')) {
		return 'Safari';
	}
	if (browserInfo.includes('Firefox')) {
		return 'Firefox';
	}
	return 'unknown';
};
const NewLoginBlumeView = () => {
	const { region, language, updateValue } = useSelector();
	const __config = useConfig();
	const { loggedIn, setLoggingIn, updateAccessToken } = useAuth();
	const { updateAppMode, isPatientPortalMode } = useAppModeContext();
	const appBlumeMode = isPatientPortalMode();
	const [errorMessage, setErrorMessage] = useState('');
	const buttonRef = useRef(null);
	const [showNewsModal, toggleNewsModal] = useState(false);
	const isDisplayPage = useRef(false);
	const { instance, inProgress } = useMsal();
	const { i18n } = useTranslation();
	const { t } = useTranslation('login');
	const [searchParams] = useSearchParams();
	const hashID = searchParams.get('language');
	const location = useLocation();
	const [isDisable, setIsDisable] = useState(false);
	const phoenixBlumeNewSchedulingFlow = useBooleanFlagValue('phoenix-blume-new-scheduling-flow');
	const sprinterOaiLandingpageNews = useBooleanFlagValue('sprinter-oai-landingpage-news');
	const sprinterNewBlumeSignupWithoutPhoneNumber = useBooleanFlagValue(
		'sprinter-new-blume-signup-without-phone-number'
	);
	const [loginOption, setLoginOption] = useState(sprinterNewBlumeSignupWithoutPhoneNumber ? 'Email' : 'Phone');
	const [selectedCountryCode, setSeletedCountryCode] = useState('CA');
	const rerenderCount = useRef(0);

	const handleButtonClick = option => {
		setLoginOption(option);
		onFieldChange();
	};

	useEffect(() => {
		const verifyButton = document.getElementById('loginButton');
		// Add event listener for keydown event "Enter"
		document.addEventListener('keydown', event => {
			if (event.key === 'Enter') {
				if (!verifyButton.disabled) {
					verifyButton.click();
				}
			}
		});
	}, [loginOption]);

	useEffect(() => {
		const browser = getBrowserName();

		// Safari-specific handling for back button cache issue
		/**
		 * If browser back button was used, flush cache
		 * This ensures that user will always see an accurate, up-to-date view based on their state
		 * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
		 * https://gomakethings.com/fixing-safaris-back-button-browser-cache-issue-with-vanilla-js/
		 */
		if (browser === 'Safari') {
			window.onpageshow = function (event) {
				if (event.persisted) {
					window.location.reload();
				}
			};
		}
	}, []);

	const navigate = useNavigate();

	const onFieldChange = email => {
		setErrorMessage('');
		email?.setCustomValidity('');
	};

	const onKeyPress = event => {
		if (event.key === 'Enter') {
			buttonRef.current.click();
			event.preventDefault();
		}
	};

	useEffect(() => {
		// If not loggedIn and has orgID param redirect to scheduling route
		const orgId = searchParams.get('orgId');

		if (!phoenixBlumeNewSchedulingFlow || !orgId || loggedIn) return;
		navigate(`/book-appointment?orgId=${orgId}`);
	}, [loggedIn, navigate, phoenixBlumeNewSchedulingFlow, searchParams]);

	useEffect(() => {
		i18n.changeLanguage(hashID);
		updateValue('language', hashID);
	}, []);

	// if we're here, we're logging in
	useEffect(() => setLoggingIn(true));
	/**
	 * App mode
	 */
	useEffect(() => {
		updateAppMode('patientPortal');

		const animationData = document.querySelector('#AnimationData');
		if (Lottie.loadAnimation) {
			Lottie.loadAnimation({
				container: animationData,
				animationData: animation,
				autoplay: true,
				loop: false,
			});
		}

		const animationDataMobile = document.querySelector('#AnimationDataMobile');

		if (Lottie.loadAnimation) {
			Lottie.loadAnimation({
				container: animationDataMobile,
				animationData: animation,
				autoplay: true,
				loop: false,
			});
		}
		if (error !== null) {
			setErrorMessage(
				error_description.startsWith('AADB2C99001')
					? t('The email address is already registred.')
					: error_description
			);
		}
	}, []);

	/**
	 * For User Invitation flow
	 */

	/**
	 * Renders the navbar component with a sign-in button if a user is not authenticated
	 */
	function useQueryParams() {
		const params = new URLSearchParams(window ? window.location.search : {});

		return new Proxy(params, {
			get(target, prop) {
				return target.get(prop);
			},
		});
	}

	const {
		code,
		error,
		error_description,
		DOB,
		hashId,
		internalManagingOrganizationID,
		studyInstanceUID,
		creatorUserId,
		isUploaded,
		isMobile,
		token,
		oAiAuthToken,
	} = useQueryParams();

	if (code !== null) {
		const tokenRequest = {
			code,
			redirectUri: 'urn:ietf:wg:oauth:2.0:oob',
			scopes: __config.auth.omegaai.scopes,
			codeVerifier: '1234567890123456789012345678901234567890123',
		};
		// acquire a token by exchanging the code
		instance
			.acquireTokenByCode(tokenRequest)
			.then(() => {
				window.location.search = '';
			})
			.catch(e => {
				console.error(e);
				isDisplayPage.current = true;
			});
	} else {
		isDisplayPage.current = true;
	}

	const handleSignup = (msalInstance, signUpInfo = '', extraParams = {}) => {
		const queryParameters = {
			siteName: 'PP',
			login_uri: __config.auth.omegaai.msal.patient_portal_logout_redirect_uri,
			regionSelected: region,
			languageSelected: language,
			ui_locales: language,
			country: selectedCountryCode,
			signUpInfo,
			...extraParams,
		};
		const loginRequest = {
			scopes: __config.auth.omegaai.scopes,
			extraQueryParameters: queryParameters,
			redirectUri: __config.auth.omegaai.msal.patient_portal_redirect_uri,
			prompt: 'select_account',
		};
		if (inProgress === InteractionStatus.None) {
			msalInstance.loginRedirect(loginRequest).catch(e => {
				console.error(e);
				setErrorMessage(e);
			});
		}
	};

	useEffect(() => {
		const currentURL = window.location.pathname;
		if (currentURL.startsWith('/external-form')) {
			updateAccessToken(`bearer ${token}`);

			axios.interceptors.request.use(
				config => {
					config.headers.authorization = `Bearer ${token}`;
					config.headers.accept = `*/*`;
					return config;
				},
				error => Promise.reject(error)
			);
		}
		if (currentURL.startsWith('/externalImageViewer')) {
			if (oAiAuthToken) {
				updateAccessToken(`bearer ${oAiAuthToken}`);
				axios.interceptors.request.use(
					config => {
						config.headers.authorization = `Bearer ${oAiAuthToken}`;
						config.headers.accept = `*/*`;
						config.headers['ramsoft-internalmanagingorganizationid'] = internalManagingOrganizationID;
						config.headers.StudyInstanceUID = studyInstanceUID;
						return config;
					},
					error => Promise.reject(error)
				);

				const viewUrl = `/externalImageViewer/?internalManagingOrganizationID=${internalManagingOrganizationID}&studyInstanceUID=${studyInstanceUID}&creatorUserId=${creatorUserId}&isUploaded=${isUploaded}&isMobile=${isMobile}}&oAiAuthToken=${oAiAuthToken}`;
				navigate(viewUrl);
			} else {
				console.log("Mobile Viewer URL doesn't have oAiAuthToken");
			}
		} else {
			console.log("URL path does not start with '/external'");
		}
	}, []);

	const validateEmailDomain = text => {
		if (text && text.includes('@')) {
			const emailSegment = text.split('@');
			return emailSegment.pop().includes('.');
		}
		return false;
	};

	const handleLogin = (event, msalInstance, email, countryNumber, phoneNumber) => {
		// Add click event listener to the button
		const isEmailLogin = loginOption === 'Email';
		const fieldValue = isEmailLogin ? email.value : phoneNumber && phoneNumber.value;
		const loginHint = isEmailLogin ? fieldValue : (countryNumber && countryNumber.dataset.value) + fieldValue;

		setIsDisable(true);
		setErrorMessage('');

		email.setCustomValidity('');
		if (!fieldValue) {
			setErrorMessage(isEmailLogin ? 'Please enter your email address' : 'Please enter your phone number');
			event.preventDefault();
			setIsDisable(false);
		} else if (isEmailLogin && (!email.validity.valid || !validateEmailDomain(fieldValue))) {
			setErrorMessage(email.validationMessage || 'Please enter a valid email address');
			event.preventDefault();
			setIsDisable(false);
		} else {
			const url = `${__config.data_sources.blume}User/validate/${loginHint.replace(
				'+',
				''
			)}?isPhoneNumber=${!isEmailLogin}`;
			axios
				.get(url)
				.then(response => {
					if (response.data.domainhint) {
						const queryParameters = {
							login_hint: loginHint,
							domain_hint: response.data.domainhint,
							siteName: 'PP',
							regionSelected: region,
							country: selectedCountryCode,
							languageSelected: language,
							isPhone: !isEmailLogin,
							deviceId: localStorage.getItem(`${email.value.toLocaleLowerCase()}_deviceId`),
							userAgent: encodeURIComponent(navigator.userAgent),
						};

						if (
							__config.data_dog &&
							__config.data_dog.applicationId &&
							!__config.data_dog.applicationId.includes('DATADOG_APPLICATION_ID')
						) {
							queryParameters.ddappid = __config.data_dog.applicationId;
							queryParameters.ddt = __config.data_dog.clientToken;
							queryParameters.ddenv = __config.data_dog.env;
							queryParameters.ddsite = __config.data_dog.site;
						}

						if (response.data.email && response.data.domainhint !== 'local') {
							queryParameters.login_hint = response.data.email;
						}

						const loginRequest = {
							scopes: __config.auth.omegaai.scopes,
							redirectUri: __config.auth.omegaai.msal.patient_portal_redirect_uri,
						};

						const kmsi = localStorage.getItem('kmsi');
						if (kmsi && kmsi.toUpperCase() === email.value.toUpperCase()) {
							// KMSI enabled for this user
							queryParameters.iskmsi = true;
						} else {
							loginRequest.prompt = 'select_account';
						}

						loginRequest.extraQueryParameters = queryParameters;

						if (inProgress === InteractionStatus.None) {
							msalInstance.loginRedirect(loginRequest).catch(e => {
								console.error(e);
								setErrorMessage(e);
								setIsDisable(false);
							});
						}
					} else {
						setIsDisable(false);
						throw new Error('Invalid domainHint, please try again!');
					}
				})
				.catch(e => {
					handleSignup(
						instance,
						isEmailLogin ? `{"email":"${email.value}"}` : `{"phone":"${phoneNumber && phoneNumber.value}"}`,
						{ isAccountNotExists: true }
					);
				});
		}
	};

	const handleAutoLogin = (msalInstance, email, userInfo = '') => {
		// Clear all session storage data to avoid intraction error
		sessionStorage.clear();

		rerenderCount.current += 1;

		sessionStorage.setItem('login_hint', email);
		const url = `${__config.data_sources.blume}User/validate/${email}`;
		axios
			.get(url)
			.then(response => {
				if (response.data.domainhint) {
					const queryParameters = {
						login_hint: email,
						domain_hint: response.data.domainhint,
						siteName: 'PP',
						regionSelected: region,
						languageSelected: language,
						deviceId: localStorage.getItem(`${email.toLocaleLowerCase()}_deviceId`),
						userAgent: encodeURIComponent(navigator.userAgent),
					};
					if (
						__config.data_dog &&
						__config.data_dog.applicationId &&
						!__config.data_dog.applicationId.includes('DATADOG_APPLICATION_ID')
					) {
						queryParameters.ddappid = __config.data_dog.applicationId;
						queryParameters.ddt = __config.data_dog.clientToken;
						queryParameters.ddenv = __config.data_dog.env;
						queryParameters.ddsite = __config.data_dog.site;
					}

					const loginRequest = {
						scopes: __config.auth.omegaai.scopes,
						redirectUri: __config.auth.omegaai.msal.patient_portal_redirect_uri,
					};

					const kmsi = localStorage.getItem('kmsi');
					if (kmsi && kmsi.toUpperCase() === email.toUpperCase()) {
						// KMSI enabled for this user
						queryParameters.iskmsi = true;
					} else {
						loginRequest.prompt = 'select_account';
					}

					loginRequest.extraQueryParameters = queryParameters;

					if (inProgress === InteractionStatus.None) {
						msalInstance.loginRedirect(loginRequest).catch(e => {
							// If the error is not interaction_in_progress, set the error message
							// https://stackoverflow.com/questions/66405214/browserautherror-interaction-in-progress-interaction-is-currently-in-progress
							if (e.errorCode?.toString() == 'interaction_in_progress') {
								console.warn(`Render count: ${rerenderCount.current}, exception: ${e}`);
							} else {
								setErrorMessage(e);
								console.error(`Render count: ${rerenderCount.current}, exception: ${e}`);
							}
							setIsDisable(false);
						});
					}
				} else {
					setIsDisable(false);
					throw new Error('Invalid domainHint, please try again!');
				}
			})
			.catch(e => {
				handleSignup(instance, userInfo);
			});
	};

	// redirect to b2c signup page if the route is /signup. To be used for blume invites
	useEffect(() => {
		if (
			(location.pathname === '/signup' || location.pathname.includes('/studyDetail')) &&
			!loggedIn &&
			appBlumeMode
		) {
			const searchUrl = location.search;
			if (searchUrl.includes('?')) {
				const decodedData = searchUrl.split('?');
				if (decodedData[1]) {
					let queryParams = window.atob(decodedData[1]);
					queryParams = new URLSearchParams(queryParams);
					const dob = queryParams.get('dob');
					const fName = queryParams.get('fName');
					const lName = queryParams.get('lName');
					const email = queryParams.get('email');
					const phone = queryParams.get('phone');
					if (location.pathname === '/signup') {
						handleSignup(instance, JSON.stringify({ dob, fName, lName, email, phone }));
					} else if (location.pathname.includes('/studyDetail')) {
						handleAutoLogin(instance, email, JSON.stringify({ fName, lName, email, phone }));
					}
				} else {
					handleSignup(instance);
				}
			} else {
				handleSignup(instance);
			}
		}
	}, [location, appBlumeMode]);

	const isMobileView = useIsMobile();

	if (window.location.pathname.startsWith('/externalImageViewer')) {
		return null;
	}

	return isDisplayPage.current && location.pathname !== '/signup' ? (
		<>
			<AppBar
				data-testid="new-login-blume-view"
				sx={{
					backgroundColor: 'transparent',
					boxShadow: 'none',
					paddingLeft: '50px',
					paddingRight: '30px',
					height: '78px',
					[mediaTablet]: {
						padding: '23px 16px',
						boxSizing: 'border-box',
						height: 'unset',
					},
				}}
			>
				<Toolbar
					sx={{
						padding: '0px',
						height: '100%',
						paddingLeft: '0px !important',
						paddingRight: '0px !important',
						[mediaTablet]: {
							minHeight: 'unset',
						},
					}}
				>
					<Box
						sx={{
							'& > :not(style) + :not(style)': {
								ml: '20px',
							},
							opacity: '0.87',
						}}
					>
						{!sprinterOaiLandingpageNews && (
							<OutlineButtonWithIcon
								icon={<ArticleOutlined />}
								text={t('NEWS')}
								onClick={() => toggleNewsModal(true)}
							/>
						)}
					</Box>
				</Toolbar>
			</AppBar>
			<MuiGrid
				container
				columns={100}
				spacing={0}
				sx={{
					flexWrap: 'unset',
				}}
			>
				<LeftPanel />
				<RightPanel>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							margin: 'auto',
							[mediaTablet]: {
								justifyContent: 'end',
							},
						}}
					>
						{sprinterNewBlumeSignupWithoutPhoneNumber ? (
							<></>
						) : (
							<Box
								sx={{
									display: 'flex',
									width: '100%',
									justifyContent: 'space-between',
									gap: '10px',
									marginTop: '50px',
									[mediaMobile]: {
										gap: '5px',
									},
								}}
							>
								<Button
									startIcon={
										<PhoneIcon
											sx={{
												height: '45px',
												fontSize: '30px !important',
												[mediaTablet]: {
													height: '24px',
												},
												[mediaMobile]: {
													height: '20px',
												},
											}}
										/>
									}
									sx={{
										backgroundColor: 'white',
										color: '#101828bd',
										border: '1px solid #D0D5DD',
										width: '100%',
										textTransform: 'none',
										'&:hover': {
											backgroundColor: 'white',
										},
										...(loginOption === 'Phone' && {
											color: '#42A5F5',
											border: '2px solid #42A5F5',
										}),
										[mediaTablet]: {
											fontSize: '16px',
											fontWeight: '400',
										},
										[mediaMobile]: {
											fontSize: '14px',
											lineHeight: '24px',
										},
									}}
									onClick={() => handleButtonClick('Phone')}
								>
									{t('Login with Phone')}
								</Button>
								<Button
									startIcon={
										<EmailIcon
											sx={{
												height: '45px',
												fontSize: '30px !important',
												[mediaTablet]: {
													height: '24px',
												},
												[mediaMobile]: {
													height: '20px',
												},
											}}
										/>
									}
									sx={{
										backgroundColor: 'white',
										color: '#101828bd',
										border: '1px solid #D0D5DD',
										width: '100%',
										textTransform: 'none',
										'&:hover': {
											backgroundColor: 'white',
										},
										...(loginOption === 'Email' && {
											color: '#42A5F5',
											border: '2px solid #42A5F5',
										}),
										[mediaTablet]: {
											fontSize: '16px',
											fontWeight: '400',
										},
										[mediaMobile]: {
											fontSize: '14px',
											lineHeight: '24px',
										},
									}}
									onClick={() => handleButtonClick('Email')}
								>
									{t('Login with Email')}
								</Button>
							</Box>
						)}

						<Box
							id="FormWrapper"
							sx={{
								display: 'inline-flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
								height: 'fit-content',
								borderRadius: '20px',
								width: '100%',
								maxWidth: '600px',
								margin: 'auto',
								[mediaTablet]: {
									justifyContent: 'unset',
									margin: '0 0 100px 0',
									zIndex: '1',
									backgroundImage:
										'radial-gradient(100px at 50% 0px, transparent 50px, white 0px) !important',
									backgroundColor: 'unset',
								},
								[mediaMobile]: {
									margin: '0 0 30px 0',
									borderBottomLeftRadius: '0',
									borderBottomRightRadius: '0',
									backgroundImage:
										'radial-gradient(100px at 50% 0px, transparent 34px, white 0px) !important',
								},
							}}
						>
							<Box
								validate
								component="form"
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									marginBottom: '70px',
									height: 'fit-content',
									width: '100%',
									[mediaTablet]: {
										margin: '0',
									},
								}}
							>
								<Box
									sx={{
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignContent: 'center',
										flexWrap: 'wrap',
										[mediaTablet]: {
											flexDirection: 'column',
											alignItems: 'center',
										},
										[mediaMobile]: {
											flexDirection: 'column',
											alignItems: 'center',
										},
									}}
								>
									<Box
										sx={{
											margin: '20px 0',
											minHeight: '81px',
											width: '100%',
											'& .MuiOutlinedInput-input': {
												padding: '10px 14px',
												height: '21px',
											},
											'& #phoneNumberLabel': {
												fontStyle: 'normal',
												fontWeight: 700,
												fontSize: '12px',
												lineHeight: '20px',
												color: '#101828bd',
												display: 'flex',
												alignItems: 'center',
											},
											[mediaTablet]: {
												'& #phoneNumberLabel': {
													fontSize: '14px',
													fontWeight: '500',
													lineHeight: '20px',
												},
											},
											[mediaMobile]: {
												width: '100%',
											},
										}}
									>
										<label id="phoneNumberLabel">
											{t(loginOption === 'Phone' ? ' Phone number' : 'Email')}
										</label>

										<PhoneNumberField
											setSeletedCountryCode={setSeletedCountryCode}
											visible={loginOption === 'Phone'}
											onChange={() => onFieldChange()}
										/>

										<LoginField
											autoFocus
											placeholder=""
											sx={{
												'& .MuiOutlinedInput-input': {
													padding: '10px 14px',
												},
											}}
											visible={loginOption !== 'Phone'}
											width="100%"
											onChange={() => onFieldChange(emailLogin)}
											onKeyPress={onKeyPress}
										/>

										<FormLabel
											data-cy="labelerror_lgp1"
											id="loginError"
											style={{
												color: '#CF6679',
												maxWidth: '400px',
												fontSize: '12px',
												paddingLeft: '7px',
												display: !errorMessage ? 'none' : 'block',
												lineHeight: '16px',
												letterSpacing: '0.4px',
											}}
										>
											{t(errorMessage)}
										</FormLabel>
									</Box>

									<Button
										ref={buttonRef}
										fullWidth
										color="rsPrimary"
										disabled={isDisable}
										id="loginButton"
										sx={{
											width: '100%',
											height: '40px',
											mt: '1px',
											borderRadius: '6px',
											fontSize: '16px',
											lineHeight: '18px',
											fontWeight: '500',
											letterSpacing: '1.25px',
											color: '#FFFFFFD9',
											opacity: '1',
											[mediaMobile]: {
												width: '100% !important',
												maxWidth: '100% !important',
												height: '43px',
											},
											'&.Mui-disabled': {
												background: '#42a5f594',
												color: '#FFFFFFD9',
											},
										}}
										type="button"
										variant="contained"
										onClick={event =>
											handleLogin(
												event,
												instance,
												emailLogin ?? undefined,
												countryNumber,
												phoneNumberInput
											)
										}
									>
										{t('Proceed')}
									</Button>

									<Box
										sx={{
											marginTop: '50px',
											[mediaTablet]: {
												marginTop: '20px',
											},
										}}
									>
										<FormLabel
											sx={{
												fontSize: '16px',
												lineHeight: '19px',
												color: '#101828bd',
												display: 'inline',
												fontWeight: '500',
												[mediaTablet]: {
													fontSize: '16px',
													fontWeight: '400',
													lineHeight: '24px',
												},
											}}
										>
											{t('Don’t have an account?')}&ensp;
											<Link
												id="BlumeSignup"
												sx={{
													color: '#42A5F5',
													opacity: '1',
													cursor: 'pointer',
												}}
												onClick={event => handleSignup(instance)}
											>
												{t('Register')}
											</Link>
										</FormLabel>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				</RightPanel>
			</MuiGrid>
			{/* news modal */}
			{isMobileView ? (
				<MobileBottomDrawer fullHeight open={showNewsModal} onClose={() => toggleNewsModal(false)}>
					<NewsModalMobile isPatientView />
				</MobileBottomDrawer>
			) : (
				<NewsModal isPatientView open={showNewsModal} onClose={() => toggleNewsModal(false)} />
			)}
		</>
	) : null;
};

export default NewLoginBlumeView;
