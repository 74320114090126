import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import InputBase from '@mui/material/InputBase';

import { LocalizationProvider } from '@mui/x-date-pickers';

const DatePicker = ({ setDateOfBirth, name, ...props }) => {
	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState('');

	const handleDateChange = newValue => {
		setValue(newValue);
		setDateOfBirth(newValue);
		setInputValue(formatDate(newValue));
	};

	const handleInputChange = event => {
		const newValue = event.target.value;
		setInputValue(newValue);

		if (isValidDate(newValue)) {
			const formattedDate = moment(newValue, 'DD/MM/YYYY').toDate();
			setValue(formattedDate);
			setDateOfBirth(formattedDate);
		} else {
			handleDateChange(null);
			setInputValue(newValue);
		}
	};

	const isValidDate = dateString => moment(dateString, 'DD/MM/YYYY', true).isValid();

	const formatDate = input => {
		if (!input) return '';

		return moment(input).format('DD/MM/YYYY');
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<InputBase
				data-testid="binded-data-input"
				placeholder="dd/MM/yyyy"
				sx={{
					position: 'absolute',
					left: '10px',
					top: '33px',
					opacity: '1',
					width: 'calc(100% - 50px)',
					caretColor: '#000',
					zIndex: 10,
				}}
				type="text"
				value={inputValue}
				onChange={handleInputChange}
			/>
			<DesktopDatePicker
				InputAdornmentProps={{ 'data-cy': 'calendar-icon' }}
				InputProps={{ name, 'data-testid': name }}
				inputFormat="dd/MM/yyyy"
				renderInput={params => (
					<TextField
						sx={{
							'.MuiInputBase-input': {
								padding: '8px 12px',
							},

							'.MuiSvgIcon-root': {
								opacity: '1 !important',
							},

							'& fieldset': { border: '1px solid #D0D5DD' },
							'& .MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
								{
									borderColor: '#D0D5DD !important',
									borderWidth: '1px',
								},

							'& input': {
								pointerEvents: 'none !important',
								caretColor: 'transparent',
								opacity: '0',
							},
						}}
						{...params}
					/>
				)}
				value={value}
				onChange={handleDateChange}
				{...props}
			/>
		</LocalizationProvider>
	);
};

export default DatePicker;
