import React from 'react';
import ReactPlayer from 'react-player';

const SectionMyDocumentVideoPlayer = ({ saasUrl }) => (
	<ReactPlayer
		controls
		playing
		height="50%"
		style={{
			margin: '0 auto',
			borderRadius: '10px',
		}}
		url={saasUrl}
		width="unset"
	/>
);

export default SectionMyDocumentVideoPlayer;
