import React from 'react';

function useCardDeck(cards = []) {
	const shownRef = React.useRef(false);
	const [cardsOrder, setCardsOrder] = React.useState(cards.map((item, index) => index));

	const setCardAsFirst = React.useCallback(
		cardIndex => {
			const currentOrder = cardsOrder[cardIndex];

			if (currentOrder === 0) {
				return false;
			}

			const newCardsOrder = cardsOrder.map(item => {
				if (item === currentOrder) {
					return 0;
				} else if (item < currentOrder) {
					return item + 1;
				}
				return item;
			});

			shownRef.current = true;

			setCardsOrder(newCardsOrder);

			return true;
		},
		[cardsOrder, setCardsOrder]
	);

	return {
		cardsOrder,
		shown: shownRef.current,
		setCardAsFirst,
	};
}

export default useCardDeck;
