import React, { createContext, useContext, useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { useAuth } from '@worklist-2/core/src';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { publicBookingSchema } from '../consts/consts';
import { createFilterOptions } from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';
import { useSettingsViewContext } from '@worklist-2/patientPortal/src/context/SettingsViewContext';

const NewAppointmentContentV2Context = createContext({});

const NewAppointmentContentV2ContextProvider = ({
	isUpdateForm,
	setOpenDrawer,
	appointment,
	onTriggerMapFullScreen,
	children,
}) => {
	const { patientMapping, profiles } = useAuth();
	const { t } = useTranslation('appointments');

	const [filesUploaded, setFilesUploaded] = useState([]);
	const [inputDateTime, setInputDateTime] = useState();
	const [inputDateTimeEng, setInputDateTimeEng] = useState();
	const [validate, setValidate] = useState(false);
	const [page, setPage] = useState('first');
	const [mapFullScreen, setMapFullScreen] = useState(false);
	const [facilityList, setFacilityList] = useState([]);
	const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
	const [selectedDay, setSelectedDay] = useState([]);
	const [isLoadingFacility, setIsLoadingFacility] = useState(false);
	const [mapDefaultCenter, setMapDefaultCenter] = useState({
		lat: 12.843774508382714,
		lng: 77.66221950654179,
	});
	const [showUserMarker, setShowUserMarker] = useState(false);
	const [googleSearchResults, setGoogleSearchResults] = useState([]);
	const [hasLocationPermission, setHasLocationPermission] = useState(false);
	const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
	const [isOpenDocument, setIsOpenDocument] = useState(false);
	const [viewDocDetails, setViewDocDetails] = useState();
	const [selectedStudies, setSelectedStudies] = useState([]);
	const [studies, setStudies] = useState([]);
	const [priorStudiesId, setPriorStudies] = useState([]);
	const [selectedProfile, setSelectedProfile] = useState(false);
	const [isNotificationCollapsed, setIsNotificationCollapsed] = useState(true);
	const [oaiSyncPendingNotifications, setOaiSyncPendingNotifications] = useState([]);
	const [oaiSyncPatientNotifications, setOaiSyncPatientNotifications] = useState([]);
	const [isGenderAvailable, setIsGenderAvailable] = useState([]);
	const [isDateOfBirthAvailable, setIsDateOfBirthAvailable] = useState([]);
	const [isRequestLoading, setIsRequestLoading] = useState(false);
	const [genderOptions, setGenderOptions] = useState([]);
	const [organizationTimeZone, setOrganizationTimeZone] = useState('');
	const [appointmentSlotsLoading, setAppointmentSlotsLoading] = useState(false);
	const [rescheduleScanType, SetRescheduleScanType] = useState(null);
	const [rescheduleFacility, setRescheduleFacility] = useState(null);
	const [hasPendingCRs, setHasPendingCRs] = useState(false);
	const { user } = useSettingsViewContext();

	const fileInputRef = useRef(null);

	const form = useForm({
		resolver: !isUpdateForm && yupResolver(publicBookingSchema),
	});
	const patient = form.watch('patient');
	const scanType = form.watch('scanType');
	const facility = form.watch('facility');
	const userLocation = form.watch('userLocation');

	const formErrors = form?.formState?.errors || {};
	const helperText = isUpdateForm || hasPendingCRs ? t('Read-only') : t('Required');

	const current_location_option = {
		description: t('Use my current location'),
		place_id: 'current_location',
		value: t('Current location selected'),
	};

	const accounts = useMemo(
		() => Object.entries(profiles).map(([key, value]) => ({ ...value, profileId: key })),
		[profiles]
	);

	const patientList = useMemo(
		() => accounts.filter(item => item.firstName !== null || item.lastName !== null),
		[accounts, patientMapping]
	);

	const isDisabledNext = () => {
		if (isUpdateForm) {
			return Boolean(!patient || !scanType || !facility || isRequestLoading);
		}

		return Boolean(!patient || !scanType || !facility || !userLocation || isRequestLoading);
	};

	const disableNext = isDisabledNext();

	useEffect(() => {
		setIsGenderAvailable(Boolean(accounts?.find(acc => acc?.profileId === user?.profileId)?.gender));
	}, []);

	const handleOnPatientChange = (_, value) => {
		const selectedPatient = value?.props?.value;
		form.setValue('patient', selectedPatient);
		setIsGenderAvailable(Boolean(selectedPatient?.gender));
	};

	const onNextClick = form.handleSubmit(() => {
		if (facility?.ianaTimezone !== Intl.DateTimeFormat().resolvedOptions().timeZone) {
			setOrganizationTimeZone(facility?.timeZone);
		}
		setPage('second');
	});

	const getLocation = () =>
		new Promise((resolve, reject) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					position => {
						resolve(position);
						setHasLocationPermission(true);
					},
					() => {
						setHasLocationPermission(false);
						reject();
					}
				);
			} else {
				setHasLocationPermission(false);
				reject();
			}
		});

	const setDefaultMapCenter = () => {
		setHasLocationPermission(false);
		setMapDefaultCenter({
			lat: 12.843774508382714,
			lng: 77.66221950654179,
		});
	};

	const searchPlaces = useCallback(
		debounce(async query => {
			const service = new window.google.maps.places.AutocompleteService();
			service.getQueryPredictions({ input: query }, predictions => {
				setGoogleSearchResults(predictions);
			});
			return googleSearchResults;
		}, 500),
		[setGoogleSearchResults]
	);

	const _filterOptions = createFilterOptions();

	const filterOptions = (options, state) => {
		const results = _filterOptions(options, state);
		if (!results.includes(current_location_option) && hasLocationPermission) {
			results.unshift(current_location_option);
		}
		return results;
	};

	const searchFacilities = async () => facilityList;

	const no_facilities = {
		organizationName: 'No Facilities available',
		internalOrganizationID: 'no_facilities_available',
	};

	const filterFacilityOptions = (options, state) => {
		if (state.inputValue != '') {
			const results = _filterOptions(options, state);
			if (results.length === 0) {
				results.unshift(no_facilities);
			}
			return results;
		}
		if (facilityList.length > 0) {
			return facilityList;
		}
		return [no_facilities];
	};

	return (
		<NewAppointmentContentV2Context.Provider
			value={{
				isUpdateForm,
				setOpenDrawer,
				appointment,
				onTriggerMapFullScreen,
				filesUploaded,
				setFilesUploaded,
				inputDateTime,
				setInputDateTime,
				inputDateTimeEng,
				setInputDateTimeEng,
				validate,
				setValidate,
				page,
				setPage,
				mapFullScreen,
				setMapFullScreen,
				facilityList,
				setFacilityList,
				availableTimeSlots,
				setAvailableTimeSlots,
				selectedDay,
				setSelectedDay,
				isLoadingFacility,
				setIsLoadingFacility,
				mapDefaultCenter,
				setMapDefaultCenter,
				showUserMarker,
				setShowUserMarker,
				googleSearchResults,
				setGoogleSearchResults,
				hasLocationPermission,
				setHasLocationPermission,
				tooltipIsOpen,
				setTooltipIsOpen,
				isOpenDocument,
				setIsOpenDocument,
				viewDocDetails,
				setViewDocDetails,
				fileInputRef,
				selectedStudies,
				setSelectedStudies,
				studies,
				setStudies,
				selectedProfile,
				setSelectedProfile,
				isNotificationCollapsed,
				setIsNotificationCollapsed,
				oaiSyncPendingNotifications,
				setOaiSyncPendingNotifications,
				oaiSyncPatientNotifications,
				setOaiSyncPatientNotifications,
				isGenderAvailable,
				setIsGenderAvailable,
				isDateOfBirthAvailable,
				setIsDateOfBirthAvailable,
				isRequestLoading,
				setIsRequestLoading,
				genderOptions,
				setGenderOptions,
				organizationTimeZone,
				setOrganizationTimeZone,
				appointmentSlotsLoading,
				setAppointmentSlotsLoading,
				rescheduleScanType,
				SetRescheduleScanType,
				rescheduleFacility,
				setRescheduleFacility,
				form,
				current_location_option,
				accounts,
				patientList,
				handleOnPatientChange,
				onNextClick,
				getLocation,
				setDefaultMapCenter,
				searchPlaces,
				_filterOptions,
				filterOptions,
				searchFacilities,
				no_facilities,
				filterFacilityOptions,
				formErrors,
				helperText,
				disableNext,
				hasPendingCRs,
				setHasPendingCRs,
				priorStudiesId,
				setPriorStudies,
			}}
		>
			{children}
		</NewAppointmentContentV2Context.Provider>
	);
};

const useNewAppointmentContentV2Context = () => useContext(NewAppointmentContentV2Context);

export { NewAppointmentContentV2Context, NewAppointmentContentV2ContextProvider, useNewAppointmentContentV2Context };
