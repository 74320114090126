import { debounce } from 'lodash';
import useGetNotifications from './useGetNotifications';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const useAppointmentStatusChange = () => {
	const phoenixBlumeOaiPatientDemographicSync = useBooleanFlagValue('phoenix-blume-oai-patient-demographic-sync');

	const { getNotificationsApi } = useGetNotifications();

	const debouncedGetNotifications = debounce(getNotificationsApi, 3000);

	const appointmentStatusChange = () => {
		// If both flags are enabled
		if (phoenixBlumeOaiPatientDemographicSync) {
			debouncedGetNotifications();
		}
	};

	return {
		appointmentStatusChange,
	};
};

export default useAppointmentStatusChange;
