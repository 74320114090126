import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

import { useScheduleStore } from '../../../../stores/schedule';

const GoBackBtn = () => {
	const prevStep = useScheduleStore(state => state.prevStep);

	const { t } = useTranslation('scheduling');

	return (
		<Button
			color="rsPrimary"
			data-testid="go-back-btn"
			startIcon={<ArrowBackIos height={7.41} width={12} />}
			sx={{ color: '#42A5F5', textTransform: 'none', padding: '5px' }}
			variant="outline"
			onClick={prevStep}
		>
			{t('Go back')}
		</Button>
	);
};

export default GoBackBtn;
