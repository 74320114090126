import React, { useEffect } from 'react';
import requestSent from '@worklist-2/ui/src/assets/animation/Blume/request_sent.gif';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';

let timeout;

const SuccessState = () => {
	const { t } = useTranslation('appointments');
	const { setOpenDrawer } = useNewAppointmentContentV2Context();

	useEffect(() => {
		timeout = setTimeout(() => {
			setOpenDrawer(null);
		}, 1800);

		return () => clearTimeout(timeout);
	}, []);

	return (
		<Box
			sx={{
				width: '100%',
				fontFamily: 'roboto',
				textAlign: 'center',
				color: '#121212',
				fontSize: '32px',
				fontWeight: 500,
			}}
		>
			<img alt="Success" src={requestSent} width="100%" />
			<p style={{ opacity: 0.8 }}>{t('Your request has been sent')}</p>
		</Box>
	);
};

export default SuccessState;
