import { CARD_HEIGHT } from '../../consts/consts';
import OaiSyncNotifications from '../../components/OaiSyncNotifications';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useNewAppointmentContentV2Context } from '../../contexts/NewAppointmentContentV2ContextProvider';
import useAppointmentStatusChange from '../../hooks/useAppointmentStatusChange';
import moment from 'moment';

const OAISyncNotifications = () => {
	const { isNotificationCollapsed, setIsNotificationCollapsed, oaiSyncPatientNotifications } =
		useNewAppointmentContentV2Context();
	const [clubNotifications, setClubNotifications] = useState([]);
	const { appointmentStatusChange } = useAppointmentStatusChange();

	useEffect(() => {
		const clubbedNotificationsObj = {};
		for (const not of oaiSyncPatientNotifications) {
			const date = moment(not.generationTime).format('MM-DD-YYYY');
			if (clubbedNotificationsObj[date]) {
				clubbedNotificationsObj[date].push(not);
			} else {
				clubbedNotificationsObj[date] = [not];
			}
		}
		setClubNotifications(Object.values(clubbedNotificationsObj) || []);
	}, [oaiSyncPatientNotifications]);

	return (
		<Box
			sx={{
				marginTop: isNotificationCollapsed ? `${CARD_HEIGHT}px` : '',
				marginBottom: '10px',
				position: 'relative',
			}}
		>
			<OaiSyncNotifications
				appointmentStatusChange={appointmentStatusChange}
				clubNotifications={clubNotifications}
				isNotificationCollapsed={isNotificationCollapsed}
				oaiSyncPatientNotifications={oaiSyncPatientNotifications}
				setClubNotifications={setClubNotifications}
				setIsNotificationCollapsed={setIsNotificationCollapsed}
			/>

			{isNotificationCollapsed && oaiSyncPatientNotifications?.length > 1 && (
				<Box
					sx={{
						position: 'absolute',
						bottom: `${CARD_HEIGHT - 55}px`,
						right: '0px',
						padding: '3px 6px',
						boxShadow: ' 0px 4px 8px 0px #0000001A',
						borderRadius: '50%',
						background: '#F3F3F3',
					}}
				>
					{clubNotifications?.length > 1 && (
						<Typography
							sx={{
								color: '#42A5F5',
								fontSize: '14px',
								letterSpacing: '0.25px',
								weight: '400',
							}}
						>
							{`+${clubNotifications?.length - 1}`}
						</Typography>
					)}
				</Box>
			)}
		</Box>
	);
};

export default OAISyncNotifications;
